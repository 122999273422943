import { SupportedSceneObjectTypes, bboxInfoInterface, gizmoInfoInterface } from 'src/types';
import { useAppSelector } from 'src/store/reducers/hook';
import { useMemo } from 'react';
import store from 'src/store/store';
import { stringToColor } from 'src/utils/colors';
import { useAssetSelectionNotifier } from 'src/hooks';
import { Html } from '@react-three/drei';
import { getSceneObject, getWorldTransform } from '../helpers';
import * as THREE from 'three';

type AssetControllerProps = {
  bbox: bboxInfoInterface;
  gizmo: gizmoInfoInterface;
  pivotProps: any;
};

const ActiveUsersBbox = ({ activeUsersArr }: { activeUsersArr: any[] }) => {
  const sceneObjectList = useAppSelector((store) => store.sceneViewer.ids);

  useAssetSelectionNotifier();

  const boundingBoxes = useMemo(() => {
    return activeUsersArr.map((user) => {
      const { id } = user.selection;
      const instance = getSceneObject(id);

      if (instance) {
        const worlTransform = getWorldTransform(instance.id);

        return {
          refBBox: instance.localProperties.originalBBox,
          position: worlTransform.position,
          rotation: worlTransform.rotation,
          scale:
            instance.type === SupportedSceneObjectTypes.viewport
              ? ([1.0, 1.0, 1.0] as [number, number, number])
              : worlTransform.scale,
        };
      }
    });
  }, [sceneObjectList, activeUsersArr]);

  return (
    <>
      {activeUsersArr.map((user, i) => {
        const bbox = boundingBoxes[i];
        const fullname = store.getState().users.entities[user.id]?.fullname;
        const color = stringToColor(user.id);

        if (bbox) {
          return (
            <>
              {fullname && (
                <Html position={bbox.position} zIndexRange={[100, 0]}>
                  <div style={{ color, fontSize: '12px', fontWeight: 'bold', textWrap: 'nowrap' }}>
                    {fullname}
                  </div>
                </Html>
              )}
              <group
                key={user.id}
                position={bbox.position}
                rotation={bbox.rotation}
                scale={bbox.scale}
              >
                <box3Helper args={[bbox.refBBox ? bbox.refBBox : new THREE.Box3(), color]} />
              </group>
            </>
          );
        }

        return <></>;
      })}
    </>
  );
};

const AssetController = ({ bbox, gizmo, pivotProps }: AssetControllerProps) => {
  const usersOnline = useAppSelector((store) => store.collaboration.storyboard);

  const activeUsersArr = useMemo(() => {
    const currentUserId = store.getState().app.currentUser?.id;

    return Object.values(usersOnline).filter((user) => user.id !== currentUserId && user.selection);
  }, [usersOnline]);

  return <ActiveUsersBbox activeUsersArr={activeUsersArr} />;
};

export default AssetController;
