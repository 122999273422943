export async function pasteImageFromClipboard(callback: (blob: Blob) => void) {
  try {
    const clipboardItems = await navigator.clipboard.read();

    for (const item of clipboardItems) {
      if (item.types.includes('image/png')) {
        const blob = await item.getType('image/png');
        callback(blob);
      }
    }
  } catch (err) {
    console.error('Error pasting image from clipboard:', err);
  }
}

export async function doesClipboardContainImage() {
  try {
    if (!navigator.clipboard) {
      return;
    }

    const clipboardItems = await navigator.clipboard.read();
    // check if last item in clipboard is an image
    for (const item of clipboardItems) {
      if (item.types.includes('image/png')) {
        return true;
      }
    }
  } catch (err) {
    console.error('Error checking clipboard for image:', err);
  }

  return false;
}

export async function clearClipboard() {
  try {
    await navigator.clipboard.write([]);
  } catch (err) {
    console.error('Error clearing clipboard:', err);
  }
}
