import * as THREE from 'three';
import { ThreeEvent } from '@react-three/fiber';
import {
  ModeTypes,
  SceneObjectActionTypes,
  SupportedSceneObjectTypes,
  ViewportObject,
} from 'src/types';
import { useGLTF } from '@react-three/drei';
import { useSceneViewer } from '../hooks/useSceneViewer';
import { MeshStandardMaterial } from 'three';
import useAssetManipulation from '../hooks/useAssetManipulation';
import { useRef } from 'react';
import short from 'short-uuid';
type previewViewportAssetProps = {
  config: ViewportObject;
  color?: string;
};

function PreviewViewportAsset(props: previewViewportAssetProps) {
  const scaleMatrix = new THREE.Matrix4();
  scaleMatrix.scale(new THREE.Vector3(1.0, 1.0, 1.0));
  const primitiveRef = useRef<THREE.Group>(null);
  useAssetManipulation({
    config: props.config,
    ref: primitiveRef,
    actions: {},
    names: [],
    scene: new THREE.Group(),
    Mode: ModeTypes.preview,
  });
  return (
    <group
      position={props.config.backendProperties.position}
      rotation={props.config.backendProperties.rotation}
    >
      <CameraMesh config={props.config} color={props.color} />
    </group>
  );
}

const CameraMesh = (props: { config: ViewportObject; color?: string }) => {
  const viewportMesh = useGLTF('Viewport.glb').scene.clone();
  const { handleSceneObjectAction } = useSceneViewer();

  if (props.config.localProperties.originalBBox === undefined) {
    const bbox = new THREE.Box3().setFromObject(viewportMesh, true);
    let animation: any;

    if (props.config.backendProperties.animation === null) {
      animation = {
        currentState: 0,
        states: [
          {
            name: 'base',
            position: [
              props.config.backendProperties.position[0],
              props.config.backendProperties.position[1],
              props.config.backendProperties.position[2],
            ],
            rotation: [
              props.config.backendProperties.rotation[0],
              props.config.backendProperties.rotation[1],
              props.config.backendProperties.rotation[2],
            ],
            scale: [1, 1, 1],
          },
        ],
      };
    }

    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: props.config.id,
        type: props.config.type,
        localProperties: {
          originalBBox: bbox,
        },
        backendProperties: {
          ...props.config.backendProperties,
          animation:
            props.config.backendProperties.animation === null
              ? animation
              : props.config.backendProperties.animation,
        },
      },
    ]);
  }

  if (props.color) {
    viewportMesh.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        const material = new MeshStandardMaterial({
          color: props.color,
          emissive: props.color,
          roughness: 1,
          transparent: true,
          opacity: 0.5,
          metalness: 0,
        });

        child.material = material;
      }
    });
  }

  return <primitive object={viewportMesh} />;
};

export default PreviewViewportAsset;
