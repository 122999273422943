import { createContext } from 'react';
import type { SupportedNodeTypes } from 'src/types';
import * as THREE from 'three';

export interface sceneRefInterface {
  scene: THREE.Scene;
}

export type commentInstance = {
  id: string;
  position: [number, number, number];
  type: SupportedNodeTypes.comment;
};

export type CommentInstances = commentInstance[];

export type RightClickMenuItem = {
  [Name: string]: {
    function: Function;
    disabled: boolean;
  };
};

export type ContextType = {
  snapToGround: boolean;
  setSnapToGround: Function;
  multiSelectConstrainProportions: boolean;
  setMultiSelectConstrainProportions: Function;
  takeScreenshot: (save?: boolean) => void;
};

export const context = createContext<ContextType>({
  snapToGround: false,
  setSnapToGround: (snapToGround: boolean) => {},
  multiSelectConstrainProportions: false,
  setMultiSelectConstrainProportions: (multiSelectConstrainProportions: boolean) => {},
  takeScreenshot: () => {},
});
