import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createProjectAPI } from 'src/apis/projects';
import { z } from 'zod';

type Props = {
  onCreate: (data: any) => void;
};

const ProjectScheme = z.object({
  name: z.string().min(1, 'Project name should not be empty'),
});

type ProjectSchemaType = z.infer<typeof ProjectScheme>;

const NewProjectButton = ({ onCreate }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<ProjectSchemaType>({ resolver: zodResolver(ProjectScheme) });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef<any>(null);
  const finalRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleCreateProject = ({ name }: any) => {
    setIsLoading(true);
    createProjectAPI(name)
      .then(({ data, error }: any) => {
        if (data) {
          onCreate(data);
          reset();
          // const projectId = data.id;
          // if (projectId) {
          //  ... perform default actions to project here, such as copying default assets to the project folder.
          // }
          onClose();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const { ref: inputRef, ...restNameRegister } = register('name');

  return (
    <>
      <Button fontSize="sm" borderRadius="full" onClick={onOpen} leftIcon={<AddIcon />}>
        Project
      </Button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay bg="transparent" />
        <form onSubmit={handleSubmit(handleCreateProject)}>
          <ModalContent
            border="1px solid #333"
            rounded="2xl"
            bg="rgba(18, 17, 23, 0.6) !important"
            backdropFilter="blur(10px)"
          >
            <ModalHeader>Create new Project</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl isInvalid={!!errors.name?.message}>
                {/* <FormLabel>Project Name</FormLabel> */}
                <Input
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleSubmit(handleCreateProject)();
                    }
                  }}
                  ref={(el) => {
                    inputRef(el);
                    initialRef.current = el;
                  }}
                  {...restNameRegister}
                  placeholder="Enter name of the project..."
                />
              </FormControl>
            </ModalBody>
            <ModalFooter gap={4}>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                isDisabled={!isValid || isLoading}
                isLoading={isLoading}
                loadingText="Creating.."
                colorScheme="blue"
              >
                Create
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
};

export default NewProjectButton;
