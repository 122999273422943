import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { context, ContextType } from 'src/components/sceneViewer/context';
import PropertyField from './PropertyField';
import PropertyHeading from './PropertyHeading';
import { BiExpandVertical, BiCollapseVertical } from 'react-icons/bi';
import { radToDeg, degToRad, applyProportionalScaling } from 'src/utils/helper';
import {
  Box,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Select,
  Switch,
  Tooltip,
} from '@chakra-ui/react';
import * as THREE from 'three';
import { Button } from '@chakra-ui/react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import {
  AssetObject,
  AssetObjectAnimation,
  SceneEvent,
  SceneEventActionTypes,
  SceneEventTriggerTargets,
  SceneObjectActionTypes,
  gizmoInfoInterface,
} from 'src/types';
import { useAppSelector } from 'src/store/reducers/hook';
import { useSceneViewer } from '../hooks/useSceneViewer';
import { useSceneInteractions } from '../hooks/useSceneInteractions';
import { getWorldTransform, toLocalTransform } from '../helpers';
import MaterialRow from './MaterialProperties/MaterialRow';
import { CloseIcon, PlusSquareIcon } from '@chakra-ui/icons';
import store from 'src/store/store';
import { UIcon } from 'src/components/icons';
export default function AssetProperties(props: { id: string }) {
  // scene_scale is used to scale the grid to the size of the scene. (1 = cm, 0.01 = m)
  const scene_scale = 1.0;
  const { setSnapToGround } = useContext<ContextType>(context);

  const { handleSceneObjectAction, handleShowEvents, handleSceneEventsAction } = useSceneViewer();
  const { onGizmoUpdate } = useSceneInteractions();
  const gizmoInfo = useAppSelector((store) => store.sceneViewer.gizmoInfo);

  const assetSceneObject = useAppSelector(
    (store) => store.sceneViewer.entities[props.id]
  ) as AssetObject;
  const assetEvents = Object.values(useAppSelector((store) => store.sceneViewer.events)).filter(
    (event) => event.triggerObject === assetSceneObject.id
  );
  const assetTargetEvents = Object.values(
    useAppSelector((store) => store.sceneViewer.events)
  ).filter((event) => event.targetObject === assetSceneObject.id);
  const showEvents = useAppSelector((store) => store.sceneViewer.showEvents);
  const [animationState, setAnimationState] = useState<any[]>(
    assetSceneObject.localProperties.animationState as any[]
  );
  const [annotationShow, setAnnotationShow] = useState(false);
  const [selectedAnimation, setSelectedAnimation] = useState(0);
  const [assetStates, setAssetStates] = useState<AssetObjectAnimation['states']>([]);

  const handleAddState = (
    newState: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    let addState: {
      name: string;
      position: [number, number, number];
      rotation: [number, number, number];
      scale: [number, number, number];
      material_base: any;
    } = {
      name: newState,
      position: assetSceneObject.backendProperties.position,
      rotation: assetSceneObject.backendProperties.rotation,
      scale: assetSceneObject.backendProperties.scale,
      material_base: assetSceneObject.localProperties.material_base,
    };

    setAssetStates([...assetStates, addState]);
    console.log(assetStates.length);
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: assetSceneObject.id,
        type: assetSceneObject.type,
        localProperties: assetSceneObject.localProperties,

        backendProperties: {
          ...assetSceneObject.backendProperties,
          animation: {
            ...assetSceneObject.backendProperties.animation,
            states: [...assetStates, addState],
            currentState: assetStates.length,
          },
        },
      },
    ]);
  };
  const handleDeleteState = (index: number) => {
    const pendingUpdates: any[] = [];
    assetTargetEvents.forEach((event) => {
      pendingUpdates.push({
        ...event,
        sequence: {
          ...event.sequence,
          timeline: event.sequence.timeline.map((state) =>
            state.name === assetStates[index].name
              ? {
                  name: 'base',
                  duration: 1,
                  delay: 0,
                }
              : state
          ),
        },
      });
    });
    const newStates = assetStates.filter((state, i) => i !== index);
    setAssetStates(newStates);
    let currentState = assetSceneObject.backendProperties.animation.currentState;
    let p = assetSceneObject.backendProperties.position;
    let r = assetSceneObject.backendProperties.rotation;
    let s = assetSceneObject.backendProperties.scale;
    let material_base = assetSceneObject.localProperties.material_base;
    if (currentState === index) {
      let getState = assetSceneObject.backendProperties.animation.states[currentState - 1];
      p = getState.position;
      r = getState.rotation;
      s = getState.scale;
      material_base = getState.material_base;
    }
    console.log(p, r, s);
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: assetSceneObject.id,
        type: assetSceneObject.type,
        localProperties: {
          ...assetSceneObject.localProperties,
          material_base: material_base,
        },
        backendProperties: {
          ...assetSceneObject.backendProperties,
          position: p,
          rotation: r,
          scale: s,
          animation: {
            ...assetSceneObject.backendProperties.animation,
            states: newStates,
            currentState: currentState === index ? currentState - 1 : currentState,
          },
          metadata: {
            ...assetSceneObject.backendProperties.metadata,
            material_base: material_base,
          },
        },
      },
    ]);

    handleSceneEventsAction(SceneEventActionTypes.update, pendingUpdates);
  };
  const handleAddEvent = (
    newEvent: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    const projectId = store.getState().app.projectId;
    const scene_id = store.getState().instance.current_sceneId;
    const workspace = store.getState().app.currentUser?.workspace_id;
    if (!projectId || !scene_id || !workspace) {
      return;
    }
    let addEvent: SceneEvent = {
      project_id: projectId,
      scene_id: scene_id,
      workspace_id: workspace,
      name: newEvent,
      triggerObject: assetSceneObject.id,
      sequence: {
        timeline: [
          {
            name: assetSceneObject.backendProperties.animation.states[0].name,
            duration: 1,
            delay: 0,
          },
          {
            name: assetSceneObject.backendProperties.animation.states[0].name,
            duration: 1,
            delay: 0,
          },
        ],
      },
      targetObject: assetSceneObject.id,
      trigger: 'start',
      target: SceneEventTriggerTargets.object,
    };
    handleSceneEventsAction(SceneEventActionTypes.insert, [addEvent]);
  };
  const handleDeleteEvent = (event: SceneEvent) => {
    handleSceneEventsAction(SceneEventActionTypes.delete, [event]);
  };
  const handleStateClick = (index: number) => {
    let getState = assetSceneObject.backendProperties.animation.states[index];

    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: assetSceneObject.id,
        type: assetSceneObject.type,
        localProperties: {
          ...assetSceneObject.localProperties,
          material_base: getState.material_base,
        },
        backendProperties: {
          ...assetSceneObject.backendProperties,
          position: getState.position,
          rotation: getState.rotation,
          scale: getState.scale,
          animation: {
            ...assetSceneObject.backendProperties.animation,
            currentState: index,
          },
          metadata: {
            ...assetSceneObject.backendProperties.metadata,
            material_base: getState.material_base,
          },
        },
      },
    ]);
  };
  const handleAnnotation = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: assetSceneObject.id,
          type: assetSceneObject.type,
          localProperties: {
            annotationShow: true,
          },
          backendProperties: assetSceneObject.backendProperties,
        },
      ]);
    } else {
      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: assetSceneObject.id,
          type: assetSceneObject.type,
          localProperties: {
            annotationShow: false,
          },
          backendProperties: assetSceneObject.backendProperties,
        },
      ]);
    }
  };
  const handleAssetPropertyChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: string,
    propIndex: number
  ) => {
    const objectCopy = structuredClone(assetSceneObject.backendProperties);

    const worldTransform = getWorldTransform(objectCopy.id);
    if (field === 'position') {
      const newPosition = [...objectCopy.position];
      newPosition[propIndex] = parseFloat(event.target.value) / scene_scale;

      const localTransform = toLocalTransform(objectCopy.id, {
        position: newPosition as [number, number, number],
        rotation: worldTransform.rotation,
        scale: worldTransform.scale,
      });

      // objectCopy.position = newPosition as any;
      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'rotation') {
      const newRotation = [...objectCopy.rotation];
      newRotation[propIndex] = degToRad(parseFloat(event.target.value));
      // objectCopy.rotation = newRotation as any;

      const localTransform = toLocalTransform(objectCopy.id, {
        position: worldTransform.position,
        rotation: newRotation as [number, number, number],
        scale: worldTransform.scale,
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'scale') {
      let newScale = [...objectCopy.scale];
      newScale[propIndex] = parseFloat(event.target.value);

      if (objectCopy.constrain_proportions) {
        newScale = applyProportionalScaling([...objectCopy.scale] as any, [...newScale] as any);
      }

      const localTransform = toLocalTransform(objectCopy.id, {
        position: worldTransform.position,
        rotation: worldTransform.rotation as [number, number, number],
        scale: newScale as [number, number, number],
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'bbox') {
      const size = new THREE.Vector3();

      assetSceneObject?.localProperties?.originalBBox?.getSize(size);

      const oldDim = propIndex === 0 ? size.x : propIndex === 1 ? size.y : size.z;
      let newScale = [...objectCopy.scale];
      newScale[propIndex] = parseFloat(event.target.value) / scene_scale / oldDim;

      if (objectCopy.constrain_proportions) {
        newScale = applyProportionalScaling([...objectCopy.scale] as any, [...newScale] as any);
      }

      const localTransform = toLocalTransform(objectCopy.id, {
        position: worldTransform.position,
        rotation: worldTransform.rotation as [number, number, number],
        scale: newScale as [number, number, number],
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    }

    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: assetSceneObject.id,
        type: assetSceneObject.type,
        localProperties: {},
        backendProperties: {
          ...objectCopy,
        },
      },
    ]);
  };

  const handleConstrainProportionsChange = () => {
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: assetSceneObject.id,
        type: assetSceneObject.type,
        localProperties: {},
        backendProperties: {
          constrain_proportions: !assetSceneObject.backendProperties.constrain_proportions,
        },
      },
    ]);
  };

  const handleSnapToGPlane = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSnapToGround(true);
    } else {
      // setGizmoInfo({
      //   ...gizmoInfo,
      //   show: [true, true, true]
      // })
      onGizmoUpdate({
        show: [true, true, true],
      } as Partial<gizmoInfoInterface>);
    }
  };

  const handleSelectAnimation = (event: ChangeEvent<HTMLSelectElement>): void => {
    const value = event.target.value;

    let newanimationState: any[] = [];
    animationState.map((state, index) => {
      if (index === parseInt(value)) {
        newanimationState.push({
          ...state,
          isPlaying: !state.isPlaying,
        });
      } else if (index === selectedAnimation) {
        newanimationState.push({
          ...state,
          isPlaying: false,
        });
      } else {
        newanimationState.push(state);
      }
    });
    setSelectedAnimation(parseInt(value));
    setAnimationState(newanimationState);
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: assetSceneObject.id,
        type: assetSceneObject.type,
        localProperties: {
          animationState: newanimationState,
        },
        backendProperties: assetSceneObject.backendProperties,
      },
    ]);
  };

  const handlePlayPause = () => {
    let newanimationState: any[] = [];
    animationState.map((state, index) => {
      if (index === selectedAnimation) {
        newanimationState.push({
          ...state,
          isPlaying: !state.isPlaying,
        });
      } else {
        newanimationState.push(state);
      }
    });
    setAnimationState(newanimationState);
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: assetSceneObject.id,
        type: assetSceneObject.type,
        localProperties: {
          animationState: newanimationState,
        },
        backendProperties: assetSceneObject.backendProperties,
      },
    ]);
  };

  useEffect(() => {
    const currentAnimationState = assetSceneObject.localProperties.animationState;
    setAnimationState(currentAnimationState as any[]);

    if (currentAnimationState !== undefined && currentAnimationState?.length > 0) {
      currentAnimationState?.forEach((state, index) => {
        if (state.isPlaying) {
          setSelectedAnimation(index);
          return;
        }
      });
    }
    const annotationslength = assetSceneObject.localProperties.annotationslength;
    if (annotationslength !== undefined) {
      if (annotationslength > 0) {
        setAnnotationShow(true);
      }
    }
    if (assetSceneObject.backendProperties.animation) {
      setAssetStates(assetSceneObject.backendProperties.animation.states);
    }

    return () => {
      handleShowEvents(false, '', '');
    };
  }, [assetSceneObject.id]);

  let assetProperties = null;

  if (assetSceneObject) {
    const worldTransform = getWorldTransform(assetSceneObject.id);

    let boundingBoxProperties = null;
    if (assetSceneObject.localProperties.originalBBox !== undefined) {
      const size = new THREE.Vector3();
      assetSceneObject.localProperties.originalBBox.getSize(size);
      const width = size.x * worldTransform.scale[0] * scene_scale;
      const height = size.y * worldTransform.scale[1] * scene_scale;
      const depth = size.z * worldTransform.scale[2] * scene_scale;
      boundingBoxProperties = (
        <>
          <br />
          <PropertyHeading>Bounding Box</PropertyHeading>
          <label className="label">Dimension</label>
          <PropertyField
            value={(width * scene_scale).toFixed(2)}
            field="bbox"
            instanceIndex={-1}
            propIndex={0}
            handlePropertyChange={handleAssetPropertyChange}
            type="number"
            disabled={!gizmoInfo.show[0]}
          />
          <PropertyField
            value={(height * scene_scale).toFixed(2)}
            field="bbox"
            instanceIndex={-1}
            propIndex={1}
            handlePropertyChange={handleAssetPropertyChange}
            type="number"
            disabled={!gizmoInfo.show[1]}
          />
          <PropertyField
            value={(depth * scene_scale).toFixed(2)}
            field="bbox"
            instanceIndex={-1}
            propIndex={2}
            handlePropertyChange={handleAssetPropertyChange}
            type="number"
            disabled={!gizmoInfo.show[2]}
          />
        </>
      );
    }

    assetProperties = (
      <div className="assetProperties">
        <Accordion allowMultiple>
          <AccordionItem gap={0} border={'none'}>
            <AccordionButton padding={0}>
              <Box as="span" flex="1" textAlign="left">
                <PropertyHeading>States</PropertyHeading>
              </Box>
              <IconButton
                aria-label="save"
                size="xs"
                icon={<UIcon name="add" />}
                onClick={(e) => handleAddState(`state${assetStates.length}`, e)}
              />

              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel maxHeight={'120px'} overflowY={'scroll'} padding={0} margin={0}>
              {assetStates &&
                assetStates.length !== 0 &&
                assetStates.map((state, index) => (
                  <Flex
                    padding={1}
                    paddingLeft={2}
                    gap={1}
                    alignItems={'center'}
                    justifyContent={'start'}
                    rounded={5}
                    backgroundColor={
                      index === assetSceneObject.backendProperties.animation.currentState
                        ? '#3E3B3E'
                        : 'transparent'
                    }
                    _hover={{ backgroundColor: '#3E3B3E' }}
                    sx={{
                      '&:hover .second-child': {
                        display: 'block',
                      },
                    }}
                  >
                    <div onClick={() => handleStateClick(index)} style={{ flexGrow: '1' }}>
                      <label className="label"> {state.name}</label>
                    </div>
                    {index !== 0 && (
                      <CloseIcon
                        cursor={'pointer'}
                        backgroundColor={'#2c2a2c'}
                        rounded={100}
                        p={1}
                        w={4}
                        display={'none'}
                        marginRight={2}
                        className="second-child"
                        onClick={() => handleDeleteState(index)}
                      />
                    )}
                  </Flex>
                ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Accordion allowMultiple>
          <AccordionItem gap={0} border={'none'}>
            <AccordionButton padding={0}>
              <Box as="span" flex="1" textAlign="left">
                <PropertyHeading>Events</PropertyHeading>
              </Box>
              <IconButton
                aria-label="save"
                size="xs"
                icon={<UIcon name="add" />}
                onClick={(e) => handleAddEvent(`event${assetEvents.length}`, e)}
              />

              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel maxHeight={'120px'} overflowY={'scroll'} padding={0} margin={0}>
              {assetEvents &&
                assetEvents.length !== 0 &&
                assetEvents.map((event, index) => (
                  <Flex
                    padding={1}
                    paddingLeft={2}
                    gap={1}
                    w={'100%'}
                    alignItems={'center'}
                    justifyContent={'start'}
                    backgroundColor={showEvents.id === event.id ? '#3E3B3E' : 'transparent'}
                    rounded={5}
                    _hover={{ backgroundColor: '#3E3B3E' }}
                    sx={{
                      '&:hover .second-child': {
                        display: 'block',
                      },
                    }}
                  >
                    <div
                      onClick={() => {
                        if (event.id)
                          handleShowEvents(!showEvents.show, assetSceneObject.id, event.id);
                      }}
                      style={{ flexGrow: '1' }}
                    >
                      <label className="label">{event.name}</label>
                    </div>

                    <CloseIcon
                      cursor={'pointer'}
                      backgroundColor={'#2c2a2c'}
                      rounded={100}
                      p={1}
                      w={4}
                      display={'none'}
                      marginRight={2}
                      className="second-child"
                      onClick={() => handleDeleteEvent(event)}
                    />
                  </Flex>
                ))}
              {assetEvents && assetEvents.length === 0 && (
                <>
                  <center>
                    <label className="label">No Events</label>
                  </center>
                </>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <PropertyHeading>Transform</PropertyHeading>
        <label className="label">Position</label>
        <PropertyField
          value={(worldTransform.position[0] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[0]}
        />
        <PropertyField
          value={(worldTransform.position[1] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[1]}
        />
        <PropertyField
          value={(worldTransform.position[2] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[2]}
        />

        <br />
        <label className="label">Rotation</label>
        <PropertyField
          value={radToDeg(worldTransform.rotation[0]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!(gizmoInfo.show[1] && gizmoInfo.show[2])}
        />
        <PropertyField
          value={radToDeg(worldTransform.rotation[1]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!(gizmoInfo.show[0] && gizmoInfo.show[2])}
        />
        <PropertyField
          value={radToDeg(worldTransform.rotation[2]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!(gizmoInfo.show[0] && gizmoInfo.show[1])}
        />
        <br />
        <label className="label_scale">Scale</label>
        <Tooltip label="Constrain Proportions" zIndex={1001} bg="grey">
          <Button
            className={`prop-btn ${
              assetSceneObject.backendProperties.constrain_proportions ? 'prop-btn-active' : ''
            }`}
            size="sm"
            onClick={handleConstrainProportionsChange}
          >
            <Icon
              color="white"
              as={
                assetSceneObject.backendProperties.constrain_proportions
                  ? BiCollapseVertical
                  : BiExpandVertical
              }
            ></Icon>
          </Button>
        </Tooltip>
        <PropertyField
          value={worldTransform.scale[0].toFixed(2)}
          field="scale"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[0]}
        />
        <PropertyField
          value={worldTransform.scale[1].toFixed(2)}
          field="scale"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[1]}
        />
        <PropertyField
          value={worldTransform.scale[2].toFixed(2)}
          field="scale"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[2]}
        />

        {boundingBoxProperties}

        <br />
        <PropertyHeading>Snapping</PropertyHeading>
        <label className="label">Snap To Ground Plane</label>
        <Switch onChange={(event) => handleSnapToGPlane(event)} />
        {animationState?.length > 0 && <PropertyHeading>Animations</PropertyHeading>}

        {animationState?.length > 0 && (
          <Flex minWidth="auto" p={'2'} alignItems="center" gap="2">
            <Select
              value={selectedAnimation}
              onChange={handleSelectAnimation}
              variant="unstyled"
              height={'30px'}
            >
              {animationState?.map((state, index) => (
                <option key={state.name} value={index}>
                  {state.name}
                </option>
              ))}
            </Select>
            <ButtonGroup gap="2">
              <Button onClick={handlePlayPause} size="xs">
                {animationState[selectedAnimation].isPlaying ? 'Pause' : 'Play'}
              </Button>
            </ButtonGroup>
          </Flex>
        )}
        {annotationShow && (
          <>
            <PropertyHeading>Annotations</PropertyHeading>
            <label className="label">Show Annotations</label>
            <Switch
              defaultChecked={assetSceneObject.localProperties.annotationShow}
              onChange={(event) => handleAnnotation(event)}
            />
          </>
        )}
        {animationState?.length === 0 &&
          (assetSceneObject.backendProperties.material ||
            assetSceneObject.localProperties.material_base) && (
            <Materials
              assetId={assetSceneObject.id}
              materialId={assetSceneObject.backendProperties.material}
            />
          )}
      </div>
    );
  }

  return <>{assetProperties}</>;
}

const Materials = ({ assetId, materialId }: any) => {
  const id = materialId ?? assetId;
  const type = materialId ? 'materials' : 'entities';

  return (
    <>
      <br />
      <PropertyHeading>Materials</PropertyHeading>
      <MaterialRow id={id} data={{ id, type, assets: [assetId] }} />
    </>
  );
};
