import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  AspectRatio,
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { Environment, OrbitControls, PerspectiveCamera, useProgress } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import short from 'short-uuid';
import * as THREE from 'three';

import { insertMaterialAPI, updateMaterialAPI } from 'src/apis/materials';
import ColorInput from 'src/components/ColorInput';
import { UIcon } from 'src/components/icons';
import { useAssetUpload } from 'src/features/SceneViewer/hooks/useAssetUpload';
import { useAppSelector } from 'src/store/reducers/hook';
import { addMaterial, updateCurrentMaterial } from 'src/store/reducers/sceneViewer';
import store from 'src/store/store';
import { SceneObjectActionTypes } from 'src/types';
import { getTextureURL, uploadFileToS3WithProgressToast } from 'src/utils/aws';
import { uploadProjectAssetPath } from 'src/utils/cloud';
import { getUUID } from 'src/utils/ids';
import { loadTextureAsync } from 'src/utils/three';

import { getSceneObject } from '../helpers';
import { useSceneViewer } from '../hooks/useSceneViewer';
import MaterialLibrary from '../MaterialLibrary';
import PropertyHeading from './PropertyHeading';

enum TextureMapTypes {
  color = 'color',
  normal = 'normal',
  roughness = 'roughness',
  metalness = 'metalness',
  displacement = 'displacement',
  ao = 'ao',
}

const defaultProperties = {
  color: '#000000',
  textures: {
    map: null,
    dmap: null,
    metmap: null,
    nmap: null,
    rmap: null,
    aomap: null,
  },
  opacity: 1.0,
  metalness: 0.0,
  roughness: 0.0,
  displacementScale: 0.0,
  aoMapIntensity: 0.0,
  normalScale: [0.0, 0.0],
};

const DefaultLibraries = [
  {
    id: 1,
    label: 'My Library',
  },
  {
    id: 2,
    label: 'Unproject Library',
  },
];

// scene_scale is used to scale the grid to the size of the scene. (1 = cm, 0.01 = m)

export default function AssetMaterials(props: {
  currentMaterial: { id: string; type: 'materials' | 'entities'; assets: string[] };
  show: boolean;
  setEditMap: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      type: string;
    }>
  >;
}) {
  const canvasRef = useRef<any>(null);
  const [tab, setTab] = useState('default');
  const { handleSceneObjectAction } = useSceneViewer();
  const { getAssetPath } = useAssetUpload();
  const [properties, setProperties] = useState(defaultProperties);

  const [library, setLibrary] = useState(DefaultLibraries[0]);
  const [edit, setEdit] = useState(false);

  const materialObj = useAppSelector(
    (store) => store.sceneViewer[props.currentMaterial?.type]?.[props.currentMaterial?.id]
  );

  const { uploadThumbnail } = useAssetUpload();

  const onPropertyChange = (key: keyof typeof defaultProperties, value: any) => {
    setProperties((s) => ({
      ...s,
      [key]: value,
    }));
  };

  const updateAsset = (updatedValue: Record<string, any>) => {
    if (!materialObj) return;

    Object.entries(updatedValue).forEach(([key, value]) => {
      onPropertyChange(key as keyof typeof defaultProperties, value);
    });

    if (props.currentMaterial?.type === 'materials') {
      const material_base = {
        material_base: {
          ...materialObj.material_base,
          ...updatedValue,
        },
      };
      updateMaterialAPI(materialObj.id, material_base);
      store.dispatch(
        addMaterial({
          ...materialObj,
          ...material_base,
        })
      );
    } else {
      const updatelist = [];
      let newStates: any[] = [];
      if (materialObj && materialObj.backendProperties.animation !== null) {
        // Create a deep copy of the states array to avoid modifying the original object
        newStates = materialObj.backendProperties.animation.states.map((state: any) => ({
          ...state,
        }));

        let indexToModify = materialObj.backendProperties.animation.currentState as number;
        if (newStates[indexToModify].material_base) {
          newStates[indexToModify] = {
            ...newStates[indexToModify],
            material_base: {
              ...materialObj.backendProperties.metadata.material_base,
              ...updatedValue,
            },
          };
        }
      }
      const newbackendProperties = {
        ...materialObj.backendProperties,
        metadata: {
          ...materialObj.backendProperties.metadata,
          material_base: {
            ...materialObj.backendProperties.metadata.material_base,
            ...updatedValue,
          },
        },
        animation: {
          ...materialObj.backendProperties.animation,
          states: newStates,
        },
      };

      const newlocalProperties = {
        ...materialObj.localProperties,
        material_base: {
          ...materialObj.localProperties.material_base,
          ...updatedValue,
        },
      };

      updatelist.push({
        id: materialObj.id,
        type: materialObj.type,
        localProperties: newlocalProperties,
        backendProperties: newbackendProperties,
      });

      handleSceneObjectAction(SceneObjectActionTypes.update, updatelist);
    }
  };

  const handleOpacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const opacity = parseFloat(event.target.value);
    updateAsset({ opacity });
  };

  const handleMetalnessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const metalness = parseFloat(event.target.value);
    updateAsset({ metalness });
  };

  const handleRoughnessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const roughness = parseFloat(event.target.value);

    updateAsset({ roughness });
  };
  const handleDisplacementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const displacementScale = parseFloat(event.target.value);

    updateAsset({ displacementScale });
  };

  const handleAoMapIntensityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const aoMapIntensity = parseFloat(event.target.value);

    updateAsset({ aoMapIntensity });
  };
  const handleNormalScaleChange = (event: React.ChangeEvent<HTMLInputElement>, axis: string) => {
    if (axis === 'x') {
      const normalScale = [parseFloat(event.target.value), properties.normalScale[1]];
      updateAsset({ normalScale });
    } else {
      const normalScale = [properties.normalScale[0], parseFloat(event.target.value)];
      updateAsset({ normalScale });
    }
  };

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const color = e.target.value;
    updateAsset({ color });
  };

  const updateTextures = async () => {
    if (!materialObj) return;

    const material = materialObj.material_base ?? materialObj.localProperties.material_base;
    try {
      let colormap =
        material?.map.map.src != null
          ? await loadTextureAsync(
              material?.map.map.src as string,
              materialObj.tag,
              material?.map.map.tiling,
              material?.map.map.offset,
              material?.map.map.rotation
            )
          : null;
      let normalmap =
        material?.map.nmap.src != null
          ? await loadTextureAsync(
              material?.map.nmap.src as string,
              materialObj.tag,
              material?.map.nmap.tiling,
              material?.map.nmap.offset,
              material?.map.nmap.rotation
            )
          : null;
      let roughnessmap =
        material?.map.rmap.src != null
          ? await loadTextureAsync(
              material?.map.rmap.src as string,
              materialObj.tag,
              material?.map.rmap.tiling,
              material?.map.rmap.offset,
              material?.map.rmap.rotation
            )
          : null;
      let metalnessmap =
        material?.map.metmap.src != null
          ? await loadTextureAsync(
              material?.map.metmap.src as string,
              materialObj.tag,
              material?.map.metmap.tiling,
              material?.map.metmap.offset,
              material?.map.metmap.rotation
            )
          : null;
      let displacementmap =
        material?.map.dmap.src != null
          ? await loadTextureAsync(
              material?.map.dmap.src as string,
              materialObj.tag,
              material?.map.dmap.tiling,
              material?.map.dmap.offset,
              material?.map.dmap.rotation
            )
          : null;
      let aoMap =
        material?.map.aomap.src != null
          ? await loadTextureAsync(
              material?.map.aomap.src as string,
              materialObj.tag,
              material?.map.aomap.tiling,
              material?.map.aomap.offset,
              material?.map.aomap.rotation
            )
          : null;

      onPropertyChange('textures', {
        map: colormap as THREE.Texture | null,
        nmap: normalmap as THREE.Texture | null,
        rmap: roughnessmap as THREE.Texture | null,
        metmap: metalnessmap as THREE.Texture | null,
        dmap: displacementmap as THREE.Texture | null,
        aomap: aoMap as THREE.Texture | null,
      });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    const material = materialObj?.material_base ?? materialObj?.localProperties?.material_base;

    if (material) {
      onPropertyChange('color', material?.color as string);
      onPropertyChange('opacity', material?.opacity);
      onPropertyChange('metalness', material?.metalness);
      onPropertyChange('roughness', material?.roughness);
      onPropertyChange('displacementScale', material?.displacementScale);
      onPropertyChange('aoMapIntensity', material?.aoMapIntensity);
      onPropertyChange('normalScale', material?.normalScale);

      updateTextures();
    }
  }, [
    props.currentMaterial?.id,
    materialObj?.backendProperties?.animation?.currentState,
    materialObj?.localProperties?.material_base.map,
    materialObj?.material_base?.map,
  ]);

  const onSave = () => {
    setEdit(true);
  };

  const onCancel = () => {
    setEdit(false);
    setTab('default');
  };

  const handleSaveMaterial = async (name: string) => {
    const canvas = canvasRef?.current;
    const sceneId = store.getState().instance.current_sceneId;
    const workspaceId = store.getState().app.currentUser?.workspace_id;
    const projectId = store.getState().app.projectId;
    if (!canvas || !workspaceId) return;

    const canvasBase64 = canvas?.toDataURL('image/png', 0.7);
    const trimmedName = name?.trim()?.split?.('.')?.[0];
    const key = uploadProjectAssetPath('textures', workspaceId, projectId);
    uploadThumbnail(sceneId, trimmedName, canvasBase64, key);

    const res = await insertMaterialAPI({
      material_base:
        materialObj.material_base ?? materialObj.backendProperties.metadata.material_base,
      name,
      project_id: projectId,
      thumbnail: trimmedName + '.png',
    });

    if (res?.id) {
      store.dispatch(addMaterial(res));

      if (props.currentMaterial.type === 'materials') {
        const assets = props.currentMaterial.assets;
        assets?.forEach((assetId) => {
          const asset = getSceneObject(assetId);

          if (asset) {
            handleSceneObjectAction(SceneObjectActionTypes.update, [
              {
                id: asset.id,
                localProperties: {},
                type: asset.type,
                backendProperties: {
                  material: res.id,
                },
              },
            ]);
          }
        });
      } else {
        handleSceneObjectAction(SceneObjectActionTypes.update, [
          {
            id: materialObj.id,
            localProperties: {},
            type: materialObj.type,
            backendProperties: {
              material: res.id,
            },
          },
        ]);
      }

      store.dispatch(updateCurrentMaterial({ id: res.id, show: false }));
    }
  };

  const onMaterialSelect = (id: string) => {
    let materialId = id;
    const material = store.getState().sceneViewer.materials[id];
    const projectId = store.getState().app.projectId;

    if (material.public) {
      materialId = getUUID();
      const mat = {
        ...material,
        id: materialId,
        project_id: projectId,
        public: false,
      };

      store.dispatch(addMaterial(mat));
      insertMaterialAPI(mat);
    }

    store.dispatch(updateCurrentMaterial({ id: materialId, type: 'materials' }));
    const assets = store.getState().sceneViewer.currentMaterial?.current?.assets;

    if (assets) {
      assets.forEach((assetId: string) => {
        const asset = getSceneObject(assetId);
        if (asset)
          handleSceneObjectAction(SceneObjectActionTypes.update, [
            {
              id: asset.id,
              localProperties: {},
              type: asset.type,
              backendProperties: {
                material: materialId,
              },
            },
          ]);
      });
    }
  };

  const onSelect = () => {
    setTab('library');
  };

  if (!materialObj) return <></>;

  const material = materialObj.material_base ?? materialObj.localProperties.material_base;

  return (
    <div>
      <Flex align="center" justify="space-between" px={2}>
        {tab === 'library' ? (
          <Menu>
            <MenuButton
              size="xs"
              rounded="md"
              variant="ghost"
              as={Button}
              fontSize="14px"
              my={2}
              rightIcon={<ChevronDownIcon />}
            >
              {library.label}
            </MenuButton>
            <MenuList p={3} maxW="32ch">
              {DefaultLibraries.map((lib) => (
                <MenuItem
                  key={lib.id}
                  aria-label={lib.label}
                  overflow="hidden"
                  rounded="md"
                  w="full"
                  fontSize="sm"
                  onClick={() => setLibrary(lib)}
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {lib.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        ) : (
          <PropertyHeading>{edit ? 'Save Material as' : 'Material'}</PropertyHeading>
        )}
        <Flex gap={2}>
          {edit || tab === 'library' ? (
            <IconButton
              aria-label="close"
              size="xs"
              variant="ghost"
              icon={<UIcon name="close" fontSize={12} />}
              onClick={onCancel}
            />
          ) : (
            <>
              <IconButton
                aria-label="save"
                size="xs"
                icon={<UIcon name="add" />}
                onClick={onSave}
              />
              <IconButton
                aria-label="materials"
                size="xs"
                icon={<UIcon name="materials" />}
                onClick={onSelect}
              />
            </>
          )}
        </Flex>
      </Flex>
      {tab === 'library' ? (
        <Box height="360px">
          <MaterialLibrary library={library} onSelect={onMaterialSelect} />
        </Box>
      ) : (
        <>
          <Center>
            <Canvas
              style={{ height: 160, width: 160 }}
              ref={canvasRef}
              gl={{ antialias: true, preserveDrawingBuffer: true }}
            >
              <PerspectiveCamera makeDefault position={[0, 0, 3]} />
              <ambientLight intensity={5} />
              <Environment preset="city" />
              <OrbitControls enablePan={false} enableZoom={false} />
              <MaterialSphere properties={properties} />
            </Canvas>
          </Center>
          <Box height="fit-content" maxHeight="200px" overflowY="auto">
            {edit ? (
              <SaveMaterial onSubmit={handleSaveMaterial} />
            ) : (
              <Box height="100%">
                <AddTextureButton
                  label="Color"
                  mapType={TextureMapTypes.color}
                  setEditMap={props.setEditMap}
                  tag={materialObj.tag}
                  fileName={material?.map?.map.src}
                >
                  <div
                    style={{
                      flexGrow: 1,
                    }}
                  />
                  <ColorInput value={properties.color} onChange={handleColorChange} />
                </AddTextureButton>
                <Flex padding={2} gap={2} alignItems={'center'} justifyContent={'start'}>
                  <Text m={0} fontSize="small" color="gray">
                    Opacity
                  </Text>
                  <div
                    style={{
                      flexGrow: 1,
                    }}
                  />
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    name="opacity"
                    value={properties.opacity}
                    onChange={handleOpacityChange}
                  />
                  <Text fontSize="small" m={0}>
                    {parseFloat(properties.opacity.toString()).toFixed(2)}
                  </Text>
                </Flex>
                <AddTextureButton
                  label="Metalness"
                  mapType={TextureMapTypes.metalness}
                  setEditMap={props.setEditMap}
                  tag={materialObj.tag}
                  fileName={material?.map?.metmap.src}
                >
                  <input
                    type="range"
                    min="0"
                    max="1"
                    name="metalness"
                    step="0.01"
                    value={properties.metalness}
                    onChange={handleMetalnessChange}
                  />
                  <Text fontSize="small" m={0}>
                    {parseFloat(properties.metalness.toString()).toFixed(2)}
                  </Text>
                </AddTextureButton>
                <AddTextureButton
                  label="Roughness"
                  mapType={TextureMapTypes.roughness}
                  setEditMap={props.setEditMap}
                  tag={materialObj.tag}
                  fileName={material?.map?.rmap.src}
                >
                  <input
                    type="range"
                    min="0"
                    max="1"
                    name="roughness"
                    step="0.01"
                    value={properties.roughness}
                    onChange={handleRoughnessChange}
                  />
                  <Text fontSize="small" m={0}>
                    {parseFloat(properties.roughness.toString()).toFixed(2)}
                  </Text>
                </AddTextureButton>

                <AddTextureButton
                  label="DisplacementMap"
                  mapType={TextureMapTypes.displacement}
                  setEditMap={props.setEditMap}
                  fileName={material?.map?.dmap.src}
                >
                  <input
                    type="range"
                    style={{ width: '70%' }}
                    min="0"
                    max="30"
                    name="displacementScale"
                    step="0.01"
                    value={properties.displacementScale}
                    onChange={handleDisplacementChange}
                  />
                  <Text fontSize="small" m={0}>
                    {parseFloat(properties.displacementScale.toString()).toFixed(2)}
                  </Text>
                </AddTextureButton>
                <AddTextureButton
                  label="aoMap"
                  mapType={TextureMapTypes.ao}
                  setEditMap={props.setEditMap}
                  fileName={material?.map?.aomap.src}
                >
                  <input
                    type="range"
                    min="0"
                    max="1"
                    name="aoMapIntensity"
                    step="0.01"
                    value={properties.aoMapIntensity}
                    onChange={handleAoMapIntensityChange}
                  />
                  <Text fontSize="small" m={0}>
                    {parseFloat(properties.aoMapIntensity.toString()).toFixed(2)}
                  </Text>
                </AddTextureButton>
                <AddTextureButton
                  label="NormalMap"
                  mapType={TextureMapTypes.normal}
                  setEditMap={props.setEditMap}
                  tag={materialObj.tag}
                  fileName={material?.map?.nmap.src}
                />
                <Flex justifyContent={'space-evenly'} alignItems={'center'}>
                  <Text m={0} fontSize="small" color="gray">
                    X
                  </Text>
                  <input
                    type="range"
                    style={{ width: '26%' }}
                    min="-1"
                    max="1"
                    name="normalScale"
                    step="0.01"
                    value={properties.normalScale[0]}
                    onChange={(e) => handleNormalScaleChange(e, 'x')}
                  />
                  <Text fontSize="small" m={0}>
                    {parseFloat(properties.normalScale[0].toString()).toFixed(2)}
                  </Text>
                  <Text m={0} fontSize="small" color="gray">
                    Y
                  </Text>
                  <input
                    type="range"
                    style={{ width: '26%' }}
                    min="-1"
                    max="1"
                    name="normalScale"
                    step="0.01"
                    value={properties.normalScale[1]}
                    onChange={(e) => handleNormalScaleChange(e, 'y')}
                  />
                  <Text fontSize="small" m={0}>
                    {parseFloat(properties.normalScale[1].toString()).toFixed(2)}
                  </Text>
                </Flex>
              </Box>
            )}
          </Box>
        </>
      )}
    </div>
  );
}

const SaveMaterial = ({ onSubmit }: any) => {
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await onSubmit(value);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex dir="column" gap={2} py={2} px={2}>
      <Input variant="ghost" placeholder="Enter material name..." autoFocus onChange={onChange} />
      <Button onClick={handleSubmit} isLoading={isLoading} type="submit" size="sm">
        Save
      </Button>
    </Flex>
  );
};

const AddTextureButton = ({ label, tag, fileName, setEditMap, mapType, children }: any) => {
  const textureURL = useMemo(() => {
    if (!fileName) return '';
    return getTextureURL(fileName, tag);
  }, [fileName]);

  return (
    <Flex padding={2} gap={2} alignItems="center" justifyContent="start">
      <Text m={0} fontSize="small" color="gray">
        {label}
      </Text>
      {children ? (
        <Flex gap={2} align="center" flex={1}>
          {children}
        </Flex>
      ) : (
        <div
          style={{
            flexGrow: 1,
          }}
        />
      )}

      {fileName ? (
        <AspectRatio
          minW="18px"
          minH="18px"
          objectFit="cover"
          objectPosition="center"
          ratio={1 / 1}
          onClick={() => {
            setEditMap({
              show: true,
              type: mapType,
            });
          }}
        >
          <Image
            borderRadius="0.25rem"
            src={textureURL}
            alt="e"
            crossOrigin=""
            onError={(e) => {
              e.currentTarget.src = 'unnamed.webp';
            }}
          />
        </AspectRatio>
      ) : (
        <UIcon
          mb={1}
          border="1px solid gray"
          color="gray"
          borderRadius="0.25rem"
          fontSize={18}
          name="texture"
          onClick={() => {
            setEditMap({
              show: true,
              type: mapType,
            });
          }}
        />
      )}
    </Flex>
  );
};

const MaterialSphere = ({ properties }: { properties: typeof defaultProperties }) => {
  const materialref = useRef<THREE.MeshStandardMaterial>(null);
  const { progress } = useProgress();

  if (progress === 100) {
    toast.dismiss(11);
  }

  useEffect(() => {
    if (materialref.current) {
      materialref.current.map = properties.textures.map;
      materialref.current.normalMap = properties.textures.nmap;
      materialref.current.roughnessMap = properties.textures.rmap;
      materialref.current.metalnessMap = properties.textures.metmap;
      materialref.current.displacementMap = properties.textures.dmap;
      materialref.current.aoMap = properties.textures.aomap;
      materialref.current.needsUpdate = true;
    }
  }, [properties.textures, materialref]);

  return (
    <mesh castShadow receiveShadow position={[0, 0, 0]}>
      <sphereGeometry args={[1, 32, 32]} />
      <meshStandardMaterial
        ref={materialref}
        attach={'material'}
        needsUpdate={true}
        transparent
        color={properties.color}
        opacity={properties.opacity}
        metalness={properties.metalness}
        roughness={properties.roughness}
        displacementScale={properties.displacementScale}
        aoMapIntensity={properties.aoMapIntensity}
        normalScale={new THREE.Vector2(properties.normalScale[0], properties.normalScale[1])}
        map={properties.textures.map}
        normalMap={properties.textures.nmap}
        roughnessMap={properties.textures.rmap}
        metalnessMap={properties.textures.metmap}
        displacementMap={properties.textures.dmap}
        aoMap={properties.textures.aomap}
      />
    </mesh>
  );
};
