import {
  Flex,
  AspectRatio,
  Box,
  Text,
  Stack,
  Input,
  Image,
  FormControl,
  GridItem,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/store/reducers/hook';
import { openContextMenu } from 'src/store/reducers/modals';
import store from 'src/store/store';
import { Tables } from 'src/types';
import timeHelper from 'src/utils/time';
import { z } from 'zod';

interface ProjectProps {
  project: Tables<'projects'>;
  selected: string | undefined;
  onRename: (id: string, name: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
}

const ProjectNameScheme = z.object({
  name: z.string().min(3, 'Project name must be at least 3 characters long'),
});

type ProjectNameSchemeType = z.infer<typeof ProjectNameScheme>;

const Project = ({ project, onRename, onDelete, selected }: ProjectProps) => {
  const dispatch = useAppDispatch();
  const [rename, toggleRename] = useState(false);

  const {
    register,
    handleSubmit,
    setFocus,

    formState: { errors, isValid },
  } = useForm<ProjectNameSchemeType>({ resolver: zodResolver(ProjectNameScheme) });

  const onRightClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const isOpen = store.getState().modal.contextMenu.isOpen;

    if (isOpen) return;

    dispatch(
      openContextMenu({
        items: {
          rename: {
            disabled: false,
            function: () => {
              toggleRename(true);
              setFocus('name');
            },
          },
          delete: {
            disabled: false,
            function: () => onDelete(project.id),
          },
        },
        position: { x: e.clientX, y: e.clientY },
      })
    );
  };

  const handleRename = ({ name }: any) => {
    if (project.name !== name)
      onRename(project.id, name)
        .then(() => {
          toggleRename(false);
        })
        .then(() => {
          if (document.activeElement instanceof HTMLElement) document.activeElement.blur();
        });
  };

  return (
    <GridItem
      key={project.id}
      borderRadius={16}
      borderWidth="2px"
      borderColor={selected === project.id ? 'blue.700' : '#333'}
      borderStyle="solid"
      minH="fit-content"
      userSelect="none"
      data-project-id={project.id}
      data-project-name={project.name}
      transition={'all .2s ease-in-out'}
      p={2}
      maxW="24rem"
      _hover={{ backgroundColor: 'rgba(50,50,50, 0.5)' }}
      onContextMenu={onRightClick}
    >
      <Flex flexDirection="column" gap={2}>
        <AspectRatio minH="12rem" ratio={16 / 9} objectFit="cover" objectPosition="center">
          <Image src="/project.jpg" borderRadius={8} />
        </AspectRatio>
        <Stack gap={0}>
          <form onSubmit={handleSubmit(handleRename)}>
            <FormControl isInvalid={!!errors.name?.message}>
              <Input
                className="editable"
                placeholder="Project name"
                variant="ghost"
                bg="none"
                textColor="white"
                p={0}
                pointerEvents={rename ? 'auto' : 'none'}
                {...register('name', {
                  onBlur: (e) => {
                    e.stopPropagation();
                    setTimeout(() => {
                      toggleRename(false);
                    }, 100);
                  },
                })}
                defaultValue={project.name}
              />
            </FormControl>
          </form>
          <Text m={0} fontSize="small" color="gray.500">
            Created {timeHelper(project.created_at).fromNow()}
          </Text>
        </Stack>
      </Flex>
    </GridItem>
  );
};

export default Project;
