import {
  SupportedControllerModels,
  GroupObject,
  SupportedSceneObjectTypes,
  ViewportObject,
  SupportedGroupTypes,
  ModeTypes,
  SceneObjectActionTypes,
} from 'src/types';

import { calculateGroupBBox, getSceneObject } from '../helpers';

import PreviewAsset from './PreviewAsset';
import PreviewUiAsset from './PreviewUiAsset';
import PreviewViewportAsset from './PreviewViewportAsset';
import PreviewHandInteractionAsset from './PreviewHandInteractionAsset';
import PreviewInteractionAsset from './PreviewInteractionAsset';
import useAssetManipulation from '../hooks/useAssetManipulation';
import { useEffect, useRef } from 'react';
import { useSceneViewer } from '../hooks/useSceneViewer';
import { Group } from 'three';
import { useFrame } from '@react-three/fiber';
import { useAppSelector } from 'src/store/reducers/hook';

function PreviewGroupAsset(props: any) {
  const grpRef = useRef<THREE.Group>(null);
  const { handleSceneObjectAction } = useSceneViewer();
  const assetEvents = Object.values(useAppSelector((store) => store.sceneViewer.events)).filter(
    (event) => event.targetObject === props.config.id
  );
  const { clickFunction, hoverFunction } = useAssetManipulation({
    config: props.config,
    ref: grpRef,
    actions: {},
    names: [],
    scene: new Group(),
    Mode: ModeTypes.preview,
  });

  useEffect(() => {
    let events: {
      id: string;
      play: boolean;
    }[] = [];

    if (assetEvents && assetEvents.length > 0) {
      events = assetEvents.map((event) => {
        return {
          id: event.id as string,
          play: event.trigger === 'start' ? true : false,
        };
      });
    }
    handleSceneObjectAction(
      SceneObjectActionTypes.update,
      [
        {
          type: props.config.type,
          id: props.config.id,
          localProperties: {
            ...props.config.localProperties,
            events: events,
            currentState: 0,
          },
          backendProperties: {},
        },
      ],
      false
    );
  }, [assetEvents.length]);

  return (
    <group
      ref={grpRef}
      onClick={clickFunction}
      onPointerOver={hoverFunction}
      position={props.config.backendProperties.animation.states[0].position}
      rotation={props.config.backendProperties.animation.states[0].rotation}
      scale={props.config.backendProperties.animation.states[0].scale}
    >
      {props.config.localProperties.children &&
        props.config.localProperties.children.map((groupMember: any) => {
          const sceneObject = getSceneObject(groupMember.id) as any;
          if (sceneObject) {
            if (sceneObject.type === SupportedSceneObjectTypes.asset) {
              return <PreviewAsset key={sceneObject.id} config={sceneObject} />;
            } else if (sceneObject.type === SupportedSceneObjectTypes.head) {
              return <PreviewViewportAsset key={sceneObject.id} config={sceneObject} />;
            } else if (sceneObject.type === SupportedSceneObjectTypes.ui) {
              return <PreviewUiAsset key={sceneObject.id} config={sceneObject} />;
            } else if (sceneObject.type === SupportedSceneObjectTypes.controller) {
              const metadata = sceneObject.backendProperties.metadata;
              if (metadata?.type === SupportedControllerModels.quest3) {
                return <PreviewInteractionAsset key={metadata.subType} config={sceneObject} />;
              } else if (metadata?.type === SupportedControllerModels.hands) {
                return <PreviewHandInteractionAsset key={sceneObject.id} config={sceneObject} />;
              }
            } else if (SupportedGroupTypes.includes(sceneObject.type)) {
              return <PreviewGroupAsset key={sceneObject.id} config={sceneObject} />;
            }
          }
          return null;
        })}
    </group>
  );
}

export default PreviewGroupAsset;
