import * as THREE from 'three';
import { ThreeEvent, useFrame } from '@react-three/fiber';
import {
  SupportedControllerModels,
  SceneObjectActionTypes,
  SupportedSceneObjectTypes,
  SupportedGroupTypes,
} from 'src/types';
import { useSceneViewer } from '../hooks/useSceneViewer';
import { calculateGroupBBox, getSceneObject } from '../helpers';
import SceneAsset from './SceneAsset';
import SceneViewportAsset from './SceneViewportAsset';
import SceneUiAsset from './SceneUiAsset';
import SceneHandInteractionAsset from './SceneHandInteractionAsset';
import SceneInteractionAsset from './SceneInteractionAsset';
import short from 'short-uuid';

const defaultSceneGroupAssetProps = {
  onClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  handleDrag: (l: THREE.Matrix4, dl: THREE.Matrix4, w: THREE.Matrix4, dw: THREE.Matrix4) => {},
  onRightClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  onDoubleClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  disablePivot: false,
};

function SceneGroupAsset(props: any & typeof defaultSceneGroupAssetProps) {
  const { handleSceneObjectAction } = useSceneViewer();

  useFrame(() => {
    if (props.config.localProperties.originalBBox === undefined) {
      let animation: any;
      if (props.config.backendProperties.animation === null) {
        animation = {
          currentState: 0,
          states: [
            {
              name: 'base',
              position: [
                props.config.backendProperties.position[0],
                props.config.backendProperties.position[1],
                props.config.backendProperties.position[2],
              ],
              rotation: [
                props.config.backendProperties.rotation[0],
                props.config.backendProperties.rotation[1],
                props.config.backendProperties.rotation[2],
              ],
              scale: [1, 1, 1],
              material_base: props.config.backendProperties.material_base,
            },
          ],
        };
      }

      if (props.config.localProperties.children) {
        const bbox = calculateGroupBBox(props.config.localProperties.children);
        if (bbox) {
          handleSceneObjectAction(
            SceneObjectActionTypes.update,
            [
              {
                type: props.config.type,
                id: props.config.id,
                localProperties: {
                  originalBBox: bbox,
                },
                backendProperties: {
                  ...props.config.backendProperties,
                  animation:
                    props.config.backendProperties.animation === null
                      ? animation
                      : props.config.backendProperties.animation,
                },
              },
            ],
            false
          );
        }
      }
    }
  });

  return (
    <group
      onPointerOver={props.onPointerOver}
      onPointerOut={props.onPointerOut}
      position={props.config.backendProperties.position}
      rotation={props.config.backendProperties.rotation}
      scale={props.config.backendProperties.scale}
    >
      {props.config.localProperties.children &&
        props.config.localProperties.children.map((groupMember: any) => {
          const sceneObject = getSceneObject(groupMember.id) as any;
          if (sceneObject) {
            if (sceneObject.type === SupportedSceneObjectTypes.asset) {
              return (
                <SceneAsset
                  key={sceneObject.id}
                  config={sceneObject}
                  onClick={props.onClick}
                  onRightClick={props.onRightClick}
                  onDoubleClick={props.onDoubleClick}
                />
              );
            } else if (sceneObject.type === SupportedSceneObjectTypes.head) {
              return (
                <SceneViewportAsset
                  key={sceneObject.id}
                  config={sceneObject}
                  onClick={props.onClick}
                  onRightClick={props.onRightClick}
                  onDoubleClick={props.onDoubleClick}
                />
              );
            } else if (sceneObject.type === SupportedSceneObjectTypes.ui) {
              return (
                <SceneUiAsset
                  key={sceneObject.id}
                  config={sceneObject}
                  onClick={props.onClick}
                  onRightClick={props.onRightClick}
                  onDoubleClick={props.onDoubleClick}
                />
              );
            } else if (sceneObject.type === SupportedSceneObjectTypes.controller) {
              const metadata = sceneObject.backendProperties.metadata;

              if (metadata?.type === SupportedControllerModels.quest3) {
                return (
                  <SceneInteractionAsset
                    key={metadata.subType}
                    config={sceneObject}
                    onClick={props.onClick}
                    onRightClick={props.onRightClick}
                    onDoubleClick={props.onDoubleClick}
                  />
                );
              } else if (metadata?.type === SupportedControllerModels.hands) {
                return (
                  <SceneHandInteractionAsset
                    key={sceneObject.id}
                    config={sceneObject}
                    onClick={props.onClick}
                    onRightClick={props.onRightClick}
                    onDoubleClick={props.onDoubleClick}
                  />
                );
              }
            } else if (SupportedGroupTypes.includes(sceneObject.type)) {
              return (
                <SceneGroupAsset
                  key={sceneObject.id}
                  config={sceneObject}
                  onClick={props.onClick}
                  onRightClick={props.onRightClick}
                  onDoubleClick={props.onDoubleClick}
                />
              );
            }
          }
          return null;
        })}
    </group>
  );
}

SceneGroupAsset.defaultProps = defaultSceneGroupAssetProps;
export default SceneGroupAsset;
