import { Button, useToast } from '@chakra-ui/react';
import { Provider } from '@supabase/supabase-js';
import { useEffect, useState } from 'react';
import { FaGoogle } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'src/hooks';
import { supabase } from 'src/SupabaseClient';

export const OAuthButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const [popup, setPopup] = useState<any>(null);

  const toast = useToast();
  const { loginWithProvider, addAuthenticatedUser } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const urlParams = location.hash.substring(1);
    const token = new URLSearchParams(urlParams).get('access_token');

    if (token) {
      setIsLoading(true);
      supabase.auth
        .getSession()
        .then((res) => {
          if (res.data) {
            const user = res.data.session?.user;
            if (user) {
              addAuthenticatedUser(user);
            }
            return;
          }

          toast({
            status: 'error',
            description: 'Unable to login',
            duration: 5000,
          });
        })
        .catch((err) => {
          toast({
            status: 'error',
            description: 'Unable to login',
            duration: 5000,
          });
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  /**
   * 
   * Use this code to implement POPUP based OAuth

  useEffect(() => {
    if (!popup) return;

    const channel = new BroadcastChannel('popup-channel');
    channel.addEventListener('message', getAuthResult);

    return () => {
      channel.removeEventListener('message', getAuthResult);
      setPopup(null);
    };
  }, [popup]);

  const getAuthResult = (e: any) => {
    if (e.origin !== window.location.origin) return;
    const { token, error } = e.data ?? {};

    if (error) {
      toast({
        status: 'error',
        description: error,
        duration: 5000,
      });

      setIsLoading(false);
      return;
    }

    setPopup(null);
  };
   */

  const handleLoginWithProvider = (provider: Provider) => {
    setIsLoading(true);
    loginWithProvider(provider).catch((err) => {
      setIsLoading(false);
    });
  };

  return (
    <Button
      leftIcon={<FaGoogle />}
      isLoading={isLoading}
      isDisabled={isLoading}
      variant="outline"
      onClick={() => handleLoginWithProvider('google')}
      colorScheme="teal"
      mb={8}
      w="full"
    >
      Sign In with Google
    </Button>
  );
};
