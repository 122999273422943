import { ButtonGroup, IconButton } from '@chakra-ui/react';
import { NodeToolbar } from 'reactflow';
import {
  PiTextHOneBold,
  PiTextHTwoBold,
  PiTextHThreeBold,
  PiTextBBold,
  PiTextItalicBold,
  PiTextUnderlineBold,
} from 'react-icons/pi';
import { createElement, memo, useState } from 'react';

export enum TextCategory {
  headings = 'headings',
  formatting = 'formatting',
}

export const textStyleOptions: Record<string, any> = {
  h1: {
    icon: PiTextHOneBold,
    style: {
      fontSize: 40,
      fontWeight: 600,
    },
    category: TextCategory.headings,
  },
  h2: {
    icon: PiTextHTwoBold,
    style: {
      fontSize: 24,
      fontWeight: 600,
    },
    category: TextCategory.headings,
  },
  h3: {
    icon: PiTextHThreeBold,
    style: {
      fontSize: 18,
      fontWeight: 600,
    },
    category: TextCategory.headings,
  },
  bold: {
    icon: PiTextBBold,
    style: {
      fontWeight: 600,
    },
    category: TextCategory.formatting,
  },
  italic: {
    icon: PiTextItalicBold,
    style: { fontStyle: 'italic' },
    category: TextCategory.formatting,
  },
  underline: {
    icon: PiTextUnderlineBold,
    style: {
      textDecoration: 'underline',
    },
    category: TextCategory.formatting,
  },
};

type TextToolbarProps = {
  id: string;
  selected?: string[];
  onSelect: any;
};

function TextToolbar(props: TextToolbarProps) {
  const [selected, setSelected] = useState<string[]>(props.selected || []);

  const onChange = (type: string, item: any) => {
    const isPresent = selected.includes(type);
    let newSelected: string[] = [];

    if (isPresent) {
      newSelected = selected.filter((s) => s !== type);
    } else {
      newSelected =
        item.category === TextCategory.headings
          ? [
              ...selected.filter((i) => textStyleOptions[i].category !== TextCategory.headings),
              type,
            ]
          : [...selected, type];
    }

    props.onSelect(props.id, {
      toolbar: {
        selected: newSelected,
      },
    });
    setSelected(newSelected);
  };

  return (
    <NodeToolbar className="nodrag" offset={32}>
      <ButtonGroup bg="#3d3d3d" borderRadius="md" size="sm" isAttached variant="ghost">
        {Object.keys(textStyleOptions).map((type, i) => {
          return (
            <IconButton
              key={type}
              aria-label={type}
              color="#e6e6e6"
              icon={createElement(textStyleOptions[type].icon, { size: 18 })}
              style={selected.includes(type) ? { backgroundColor: '#6d8fff' } : {}}
              onClick={(e) => {
                e.stopPropagation();
                onChange(type, textStyleOptions[type]);
              }}
            />
          );
        })}
      </ButtonGroup>
    </NodeToolbar>
  );
}

export default memo(TextToolbar);
