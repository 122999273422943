import { combineReducers, configureStore } from '@reduxjs/toolkit';
import InstanceReducer from './reducers/InstanceReducer';
import storyboard from './reducers/storyboard';
import sceneViewer from './reducers/sceneViewer';
import users from './reducers/users';
import comments from './reducers/comments';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import app from './reducers/app';
import history from './reducers/history';
import modal from './reducers/modals';
import collaboration from './reducers/collaboration';
import cache from './reducers/cache';
import integrations from './reducers/integrations';
import metadata from './reducers/metadata';
import createMigrate from 'redux-persist/es/createMigrate';
import { migrations } from './migrations';

const rootReducer = combineReducers({
  instance: InstanceReducer,
  storyboard,
  sceneViewer,
  app,
  users,
  comments,
  history,
  collaboration,
  cache,
  modal,
  integrations,
  metadata,
});

const persistConfig = {
  key: 'unproject-app',
  version: 6,
  storage,
  blacklist: ['storyboard', 'comments', 'history', 'cache', 'collaboration', 'modal'],
  migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
