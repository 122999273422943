import { ChangeEvent, useEffect, useState } from 'react';
import PropertyField from './PropertyField';
import PropertyHeading from './PropertyHeading';
import { radToDeg, degToRad, applyProportionalScaling } from 'src/utils/helper';
import * as THREE from 'three';
import { Box, Flex, Button, Icon, Tooltip, IconButton } from '@chakra-ui/react';
import { BiExpandVertical, BiCollapseVertical } from 'react-icons/bi';
import {
  AssetObjectAnimation,
  GroupObject,
  SceneEvent,
  SceneEventActionTypes,
  SceneEventTriggerTargets,
  SceneObject,
  SceneObjectActionTypes,
  SceneObjectMetadata,
  SupportedSceneObjectTypes,
} from 'src/types';
import { useAppSelector } from 'src/store/reducers/hook';
import { useSceneViewer } from '../hooks/useSceneViewer';
import { getSceneObject, getWorldTransform, toLocalTransform } from '../helpers';
import MaterialProperties from './MaterialProperties/MaterialProperties';
import store from 'src/store/store';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import short from 'short-uuid';
import { CloseIcon, PlusSquareIcon } from '@chakra-ui/icons';
import { getAssetUrlWithToken } from 'src/utils/aws';
import { UIcon } from 'src/components/icons';

export default function GroupProperties(props: { id: string }) {
  // scene_scale is used to scale the grid to the size of the scene. (1 = cm, 0.01 = m)
  const scene_scale = 1.0;

  const { handleSceneObjectAction, handleShowEvents, handleSceneEventsAction } = useSceneViewer();

  const gizmoInfo = useAppSelector((store) => store.sceneViewer.gizmoInfo);
  const showEvents = useAppSelector((store) => store.sceneViewer.showEvents);
  const sceneObject = useAppSelector(
    (store) => store.sceneViewer.entities[props.id]
  ) as GroupObject;
  const assetEvents = Object.values(useAppSelector((store) => store.sceneViewer.events)).filter(
    (event) => event.triggerObject === sceneObject.id
  );
  const assetTargetEvents = Object.values(
    useAppSelector((store) => store.sceneViewer.events)
  ).filter((event) => event.targetObject === sceneObject.id);
  const sceneObjectList = useAppSelector((store) => store.sceneViewer.ids);
  const [assetStates, setAssetStates] = useState<AssetObjectAnimation['states']>([]);

  const handleAddState = (
    newState: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    let addState: {
      name: string;
      position: [number, number, number];
      rotation: [number, number, number];
      scale: [number, number, number];
      material_base: any;
    } = {
      name: newState,
      position: sceneObject.backendProperties.position,
      rotation: sceneObject.backendProperties.rotation,
      scale: sceneObject.backendProperties.scale,
      material_base: sceneObject.localProperties.material_base,
    };

    setAssetStates([...assetStates, addState]);
    console.log(assetStates.length);
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: sceneObject.id,
        type: sceneObject.type,
        localProperties: sceneObject.localProperties,
        backendProperties: {
          ...sceneObject.backendProperties,
          animation: {
            ...sceneObject.backendProperties.animation,
            states: [...assetStates, addState],
            currentState: assetStates.length,
          },
        },
      },
    ]);
  };
  const handleDeleteState = (index: number) => {
    const pendingUpdates: any[] = [];
    assetTargetEvents.forEach((event) => {
      pendingUpdates.push({
        ...event,
        sequence: {
          ...event.sequence,
          timeline: event.sequence.timeline.map((state) =>
            state.name === assetStates[index].name
              ? {
                  name: 'base',
                  duration: 1,
                  delay: 0,
                }
              : state
          ),
        },
      });
    });
    const newStates = assetStates.filter((state, i) => i !== index);
    setAssetStates(newStates);
    let currentState = sceneObject.backendProperties.animation.currentState;
    let p = sceneObject.backendProperties.position;
    let r = sceneObject.backendProperties.rotation;
    let s = sceneObject.backendProperties.scale;
    let material_base = sceneObject.localProperties.material_base;
    if (currentState === index) {
      let getState = sceneObject.backendProperties.animation.states[currentState - 1];
      p = getState.position;
      r = getState.rotation;
      s = getState.scale;
      material_base = getState.material_base;
    }
    console.log(p, r, s);
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: sceneObject.id,
        type: sceneObject.type,
        localProperties: {
          ...sceneObject.localProperties,
          material_base: material_base,
        },
        backendProperties: {
          ...sceneObject.backendProperties,
          position: p,
          rotation: r,
          scale: s,
          animation: {
            ...sceneObject.backendProperties.animation,
            states: newStates,
            currentState: currentState === index ? currentState - 1 : currentState,
          },
          metadata: {
            ...sceneObject.backendProperties.metadata,
            material_base: material_base,
          },
        },
      },
    ]);

    handleSceneEventsAction(SceneEventActionTypes.update, pendingUpdates);
  };
  const handleAddEvent = (
    newEvent: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    const projectId = store.getState().app.projectId;
    const scene_id = store.getState().instance.current_sceneId;
    const workspace = store.getState().app.currentUser?.workspace_id;
    if (!projectId || !scene_id || !workspace) {
      return;
    }
    let addEvent: SceneEvent = {
      project_id: projectId,
      scene_id: scene_id,
      workspace_id: workspace,
      name: newEvent,
      triggerObject: sceneObject.id,
      sequence: {
        timeline: [
          {
            name: sceneObject.backendProperties.animation.states[0].name,
            duration: 1,
            delay: 0,
          },
          {
            name: sceneObject.backendProperties.animation.states[0].name,
            duration: 1,
            delay: 0,
          },
        ],
      },
      targetObject: sceneObject.id,
      trigger: 'start',
      target: SceneEventTriggerTargets.object,
    };
    handleSceneEventsAction(SceneEventActionTypes.insert, [addEvent]);
  };
  const handleDeleteEvent = (event: SceneEvent) => {
    handleSceneEventsAction(SceneEventActionTypes.delete, [event]);
  };
  const handleStateClick = (index: number) => {
    let getState = sceneObject.backendProperties.animation.states[index];
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: sceneObject.id,
        type: sceneObject.type,
        localProperties: {
          ...sceneObject.localProperties,
          material_base: getState.material_base,
        },
        backendProperties: {
          ...sceneObject.backendProperties,
          position: getState.position,
          rotation: getState.rotation,
          scale: getState.scale,
          animation: {
            ...sceneObject.backendProperties.animation,
            currentState: index,
          },
          metadata: {
            ...sceneObject.backendProperties.metadata,
            material_base: getState.material_base,
          },
        },
      },
    ]);
  };
  const handleGroupPropertyChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: string,
    propIndex: number
  ) => {
    const objectCopy = structuredClone(sceneObject.backendProperties);

    const worldTransform = getWorldTransform(objectCopy.id);
    if (field === 'position') {
      const newPosition = [...objectCopy.position];
      newPosition[propIndex] = parseFloat(event.target.value) / scene_scale;

      const localTransform = toLocalTransform(objectCopy.id, {
        position: newPosition as [number, number, number],
        rotation: worldTransform.rotation,
        scale: worldTransform.scale,
      });

      // objectCopy.position = newPosition as any;
      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'rotation') {
      const newRotation = [...objectCopy.rotation];
      newRotation[propIndex] = degToRad(parseFloat(event.target.value));
      // objectCopy.rotation = newRotation as any;

      const localTransform = toLocalTransform(objectCopy.id, {
        position: worldTransform.position,
        rotation: newRotation as [number, number, number],
        scale: worldTransform.scale,
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'scale') {
      let newScale = [...objectCopy.scale];
      newScale[propIndex] = parseFloat(event.target.value);

      if (objectCopy.constrain_proportions) {
        newScale = applyProportionalScaling([...objectCopy.scale] as any, [...newScale] as any);
      }

      // objectCopy.scale = newScale as any;

      const localTransform = toLocalTransform(objectCopy.id, {
        position: worldTransform.position,
        rotation: worldTransform.rotation as [number, number, number],
        scale: newScale as [number, number, number],
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'bbox') {
      const size = new THREE.Vector3();
      sceneObject.localProperties?.originalBBox?.getSize(size);
      const oldDim = propIndex === 0 ? size.x : propIndex === 1 ? size.y : size.z;
      let newScale = [...objectCopy.scale];
      newScale[propIndex] = parseFloat(event.target.value) / scene_scale / oldDim;

      if (objectCopy.constrain_proportions) {
        newScale = applyProportionalScaling([...objectCopy.scale] as any, [...newScale] as any);
      }

      const localTransform = toLocalTransform(objectCopy.id, {
        position: worldTransform.position,
        rotation: worldTransform.rotation as [number, number, number],
        scale: newScale as [number, number, number],
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    }

    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: sceneObject.id,
        type: sceneObject.type,
        localProperties: {},
        backendProperties: {
          ...objectCopy,
        },
      },
    ]);
  };

  const handleConstrainProportionsChange = () => {
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: sceneObject.id,
        type: sceneObject.type,
        localProperties: {},
        backendProperties: {
          constrain_proportions: !sceneObject.backendProperties.constrain_proportions,
        },
      },
    ]);
  };
  useEffect(() => {
    setAssetStates(sceneObject.backendProperties.animation.states);
    return () => {
      handleShowEvents(false, '', '');
    };
  }, [sceneObject.id]);

  let groupProperties = null;
  if (sceneObject) {
    const worldTransform = getWorldTransform(sceneObject.id);

    let boundingBoxProperties = null;
    if (sceneObject.localProperties.originalBBox !== undefined) {
      const size = new THREE.Vector3();
      const bbox = new THREE.Box3(
        sceneObject.localProperties.originalBBox.min,
        sceneObject.localProperties.originalBBox.max
      );
      bbox.getSize(size);
      const width = size.x * worldTransform.scale[0] * scene_scale;
      const height = size.y * worldTransform.scale[1] * scene_scale;
      const depth = size.z * worldTransform.scale[2] * scene_scale;
      boundingBoxProperties = (
        <>
          <br />
          <PropertyHeading>Bounding Box</PropertyHeading>
          <label className="label">Dimension</label>
          <PropertyField
            value={(width * scene_scale).toFixed(2)}
            field="bbox"
            instanceIndex={-1}
            propIndex={0}
            handlePropertyChange={handleGroupPropertyChange}
            type="number"
            disabled={!gizmoInfo.show[0]}
          />
          <PropertyField
            value={(height * scene_scale).toFixed(2)}
            field="bbox"
            instanceIndex={-1}
            propIndex={1}
            handlePropertyChange={handleGroupPropertyChange}
            type="number"
            disabled={!gizmoInfo.show[1]}
          />
          <PropertyField
            value={(depth * scene_scale).toFixed(2)}
            field="bbox"
            instanceIndex={-1}
            propIndex={2}
            handlePropertyChange={handleGroupPropertyChange}
            type="number"
            disabled={!gizmoInfo.show[2]}
          />
        </>
      );
    }
    groupProperties = (
      <div className="groupProperties">
        <Accordion allowMultiple>
          <AccordionItem gap={0} border={'none'}>
            <AccordionButton padding={0}>
              <Box as="span" flex="1" textAlign="left">
                <PropertyHeading>States</PropertyHeading>
              </Box>
              <IconButton
                aria-label="save"
                size="xs"
                icon={<UIcon name="add" />}
                onClick={(e) => handleAddState(`state${assetStates.length}`, e)}
              />
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel maxHeight={'120px'} overflowY={'scroll'} padding={0} margin={0}>
              {assetStates.map((state, index) => (
                <Flex
                  key={state.name}
                  padding={1}
                  paddingLeft={2}
                  gap={1}
                  alignItems={'center'}
                  justifyContent={'start'}
                  rounded={5}
                  backgroundColor={
                    index === sceneObject.backendProperties.animation.currentState
                      ? '#3E3B3E'
                      : 'transparent'
                  }
                  _hover={{ backgroundColor: '#3E3B3E' }}
                  sx={{
                    '&:hover .second-child': {
                      display: 'block',
                    },
                  }}
                >
                  <div onClick={() => handleStateClick(index)} style={{ flexGrow: '1' }}>
                    <label className="label"> {state.name}</label>
                  </div>
                  {index !== 0 && (
                    <CloseIcon
                      cursor={'pointer'}
                      backgroundColor={'#2c2a2c'}
                      rounded={100}
                      p={1}
                      w={4}
                      display={'none'}
                      marginRight={2}
                      className="second-child"
                      onClick={() => handleDeleteState(index)}
                    />
                  )}
                </Flex>
              ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Accordion allowMultiple>
          <AccordionItem gap={0} border={'none'}>
            <AccordionButton padding={0}>
              <Box as="span" flex="1" textAlign="left">
                <PropertyHeading>Events</PropertyHeading>
              </Box>
              <IconButton
                aria-label="save"
                size="xs"
                icon={<UIcon name="add" />}
                onClick={(e) => handleAddEvent(`event${assetEvents.length}`, e)}
              />
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel maxHeight={'120px'} overflowY={'scroll'} padding={0} margin={0}>
              {assetEvents &&
                assetEvents.length !== 0 &&
                assetEvents.map((event, index) => (
                  <Flex
                    padding={1}
                    paddingLeft={2}
                    gap={1}
                    w={'100%'}
                    alignItems={'center'}
                    justifyContent={'start'}
                    rounded={5}
                    _hover={{ backgroundColor: '#3E3B3E' }}
                    sx={{
                      '&:hover .second-child': {
                        display: 'block',
                      },
                    }}
                  >
                    <div
                      onClick={() => {
                        if (event.id) handleShowEvents(!showEvents.show, sceneObject.id, event.id);
                      }}
                      style={{ flexGrow: '1' }}
                    >
                      <label className="label">{event.name}</label>
                    </div>

                    <CloseIcon
                      cursor={'pointer'}
                      backgroundColor={'#2c2a2c'}
                      rounded={100}
                      p={1}
                      w={4}
                      display={'none'}
                      marginRight={2}
                      className="second-child"
                      onClick={() => handleDeleteEvent(event)}
                    />
                  </Flex>
                ))}
              {assetEvents && assetEvents.length === 0 && (
                <>
                  <center>
                    <label className="label">No Events</label>
                  </center>
                </>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <PropertyHeading>Transform</PropertyHeading>
        <label className="label">Position</label>
        <PropertyField
          value={(worldTransform.position[0] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleGroupPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[0]}
        />
        <PropertyField
          value={(worldTransform.position[1] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handleGroupPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[1]}
        />
        <PropertyField
          value={(worldTransform.position[2] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handleGroupPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[2]}
        />

        <br />
        <label className="label">Rotation</label>
        <PropertyField
          value={radToDeg(worldTransform.rotation[0]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleGroupPropertyChange}
          type="number"
          disabled={!(gizmoInfo.show[1] && gizmoInfo.show[2])}
        />
        <PropertyField
          value={radToDeg(worldTransform.rotation[1]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handleGroupPropertyChange}
          type="number"
          disabled={!(gizmoInfo.show[0] && gizmoInfo.show[2])}
        />
        <PropertyField
          value={radToDeg(worldTransform.rotation[2]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handleGroupPropertyChange}
          type="number"
          disabled={!(gizmoInfo.show[0] && gizmoInfo.show[1])}
        />
        <br />
        <label className="label_scale">Scale</label>
        <Tooltip label="Constrain Proportions" zIndex={1001} bg="grey">
          <Button
            className={`prop-btn ${sceneObject.backendProperties.constrain_proportions ? 'prop-btn-active' : ''}`}
            size="sm"
            onClick={handleConstrainProportionsChange}
          >
            <Icon
              color="white"
              as={
                sceneObject.backendProperties.constrain_proportions
                  ? BiCollapseVertical
                  : BiExpandVertical
              }
            ></Icon>
          </Button>
        </Tooltip>
        <PropertyField
          value={worldTransform.scale[0].toFixed(2)}
          field="scale"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleGroupPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[0]}
        />
        <PropertyField
          value={worldTransform.scale[1].toFixed(2)}
          field="scale"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handleGroupPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[1]}
        />
        <PropertyField
          value={worldTransform.scale[2].toFixed(2)}
          field="scale"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handleGroupPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[2]}
        />

        {boundingBoxProperties}
        <MaterialsContainer id={sceneObject.id} />
      </div>
    );
  }

  return <>{groupProperties}</>;
}

const MaterialsContainer = ({ id }: any) => {
  const sceneObjectList = useAppSelector((store) => store.sceneViewer.ids);
  const currentMaterial = useAppSelector((store) => store.sceneViewer.currentMaterial);

  const [materials, setMaterials] = useState<
    {
      id: string;
      type: 'materials' | 'entities';
      assets: string[];
    }[]
  >([]);

  const getChildMaterials = () => {
    const materials = {} as Record<
      string,
      {
        id: string;
        type: 'materials' | 'entities';
        assets: string[]; // Assets with same material
      }
    >;

    const materialSet = new Set();

    function traverseChild(gid: string) {
      sceneObjectList.forEach((childId: string) => {
        const asset = getSceneObject(childId);
        const attachedMaterialId = asset?.backendProperties.material;

        if (asset && asset.backendProperties.parent_group_id === gid) {
          if (asset.type === SupportedSceneObjectTypes.asset) {
            if (attachedMaterialId) {
              const material = store.getState().sceneViewer.materials[attachedMaterialId];

              if (materialSet.has(attachedMaterialId)) {
                const entry = materials[attachedMaterialId]!;
                materials[attachedMaterialId] = {
                  ...entry,
                  assets: [...entry.assets, asset.id],
                };
              } else {
                materialSet.add(attachedMaterialId);
                materials[material.id] = {
                  id: material.id,
                  type: 'materials',
                  assets: [asset.id],
                };
              }
            } else if (asset.localProperties.material_base) {
              materials[asset.id] = {
                id: asset.id,
                type: 'entities',
                assets: [asset.id],
              };
            }
          } else if (asset.type === SupportedSceneObjectTypes.group) {
            traverseChild(asset.id);
          }
        }
      });
    }

    traverseChild(id);
    return Object.values(materials);
  };

  useEffect(() => {
    const mat = getChildMaterials();
    setMaterials(mat);
  }, [sceneObjectList, id, currentMaterial.current?.id]);

  if (!materials?.length) return <></>;

  return (
    <>
      <br />
      <PropertyHeading>Materials</PropertyHeading>
      <MaterialProperties materials={materials} />
    </>
  );
};
