import { supabase } from 'src/SupabaseClient';

export const getAllMaterialsAPI = async (projectId: string) => {
  const { data: instances } = await supabase
    .from('materials')
    .select('*')
    .or(`project_id.eq.${projectId},project_id.is.null`);

  return instances;
};

export const insertMaterialAPI = async (instance: any) => {
  const { data: insertedData, error } = await supabase
    .from('materials')
    .insert(instance)
    .select()
    .single();

  return insertedData;
};

export const deleteMaterialAPI = async (id: string) => {
  await supabase.from('materials').delete().eq('id', id);
};

export const updateMaterialAPI = async (id: string, data: any) => {
  await supabase.from('materials').update(data).eq('id', id);
};
