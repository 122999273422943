import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import PropertyField from '../PropertyField';
import PropertyHeading from '../PropertyHeading';
import { radToDeg, degToRad, applyProportionalScaling } from 'src/utils/helper';
import * as THREE from 'three';
import {
  LightObject,
  SceneObjectActionTypes,
  SupportedSceneObjectTypes,
} from 'src/types';
import { useAppSelector } from 'src/store/reducers/hook';
import { useSceneViewer } from '../../hooks/useSceneViewer';
import { getSceneObject, getWorldTransform, toLocalTransform } from '../../helpers';

export default function LightProperties(props: {
  id: string;
}) {
  // scene_scale is used to scale the grid to the size of the scene. (1 = cm, 0.01 = m)
  const scene_scale = 1.0;

  const { handleSceneObjectAction } = useSceneViewer();
  const gizmoInfo = useAppSelector((store) => store.sceneViewer.gizmoInfo);
  const sceneObject = useAppSelector(
    (store) => store.sceneViewer.entities[props.id]
  ) as LightObject;

  const sceneObjectList = useAppSelector((store) => store.sceneViewer.ids);

  const handleLightColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value;
    const updatelist = [];
    const newbackendProperties = {
      ...sceneObject.backendProperties,
      metadata: {
        ...sceneObject.backendProperties.metadata,
        color: newColor,
      },
    };
    updatelist.push({
      id: sceneObject.id,
      type: sceneObject.type,
      localProperties: {},
      backendProperties: {...newbackendProperties},
    });
    handleSceneObjectAction(SceneObjectActionTypes.update, updatelist);
  }

  const handleLightPropertyChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: string,
    propIndex: number
  ) => {
    const objectCopy = structuredClone(sceneObject.backendProperties);

    const worldTransform = getWorldTransform(objectCopy.id);
    if (field === 'position') {
      const newPosition = [...objectCopy.position];
      newPosition[propIndex] = parseFloat(event.target.value) / scene_scale;

      const localTransform = toLocalTransform(objectCopy.id, {
        position: newPosition as [number, number, number],
        rotation: worldTransform.rotation,
        scale: worldTransform.scale,
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'rotation') {
      const newRotation = [...objectCopy.rotation];
      newRotation[propIndex] = degToRad(parseFloat(event.target.value));

      const localTransform = toLocalTransform(objectCopy.id, {
        position: worldTransform.position,
        rotation: newRotation as [number, number, number],
        scale: worldTransform.scale,
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'intensity') {
      objectCopy.metadata.intensity = parseFloat(event.target.value);
    } else {
      (objectCopy as any)['metadata']['config'][field] = parseFloat(event.target.value);
    }

    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: sceneObject.id,
        type: sceneObject.type,
        localProperties: {},
        backendProperties: {
          ...objectCopy,
        },
      },
    ]);
  };

  
  let lightProperties = null;
  if (sceneObject) {
    const worldTransform = getWorldTransform(sceneObject.id);
    lightProperties = (
      <div className="lightProperties">
        <PropertyHeading>Transform</PropertyHeading>
        <label className="label">Position</label>
        <PropertyField
          value={(worldTransform.position[0] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleLightPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[0]}
        />
        <PropertyField
          value={(worldTransform.position[1] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handleLightPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[1]}
        />
        <PropertyField
          value={(worldTransform.position[2] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handleLightPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[2]}
        />

        <br />
        <label className="label">Rotation</label>
        <PropertyField
          value={radToDeg(worldTransform.rotation[0]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleLightPropertyChange}
          type="number"
          disabled={!(gizmoInfo.show[1] && gizmoInfo.show[2])}
        />
        <PropertyField
          value={radToDeg(worldTransform.rotation[1]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handleLightPropertyChange}
          type="number"
          disabled={!(gizmoInfo.show[0] && gizmoInfo.show[2])}
        />
        <PropertyField
          value={radToDeg(worldTransform.rotation[2]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handleLightPropertyChange}
          type="number"
          disabled={!(gizmoInfo.show[0] && gizmoInfo.show[1])}
        />
        <br />
        <PropertyHeading>Light Properties</PropertyHeading>
        <label className="label">Intensity</label>
        <PropertyField
          value={(sceneObject.backendProperties.metadata.intensity).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleLightPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[0]}
        />

        <br />
        <label className="label">Color</label>
        <input type='color' value={sceneObject.backendProperties.metadata.color} onChange={handleLightColorChange}/>
        {
          Object.entries(sceneObject.backendProperties.metadata.config ? sceneObject.backendProperties.metadata.config : {}).map(([key, value]) => {
            if (key === 'target') return (<></>);
            return (
              <>
                <br />
                <label className="label">{key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}</label>
                <PropertyField
                  value={(value).toFixed(2)}
                  field={key}
                  instanceIndex={-1}
                  propIndex={0}
                  handlePropertyChange={handleLightPropertyChange}
                  type="number"
                />
              </>
            )
          })
        }
      </div>
    );
  }

  return <>{lightProperties}</>;
}
