import { CloudStorageManager } from 'src/services/storage';
import { CloudProviderConfig } from 'src/services/storage/storage.types';

export const getPublicAssetURL = () => {
  return `https://${process.env.REACT_APP_ASSET_LIBRARY_S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com`;
};

export const getPublicStorage = () => {
  const publicProvider = getPublicProvider();
  return new CloudStorageManager(publicProvider.provider, publicProvider.config);
};

export const getPublicProvider = () => {
  return {
    provider: 'aws' as CloudProviderConfig['provider'],
    config: {
      accessKeyId: String(process.env.REACT_APP_AWS_ACCESS_KEY_ID),
      secretAccessKey: String(process.env.REACT_APP_AWS_SECRET_ACCESS_KEY),
      region: String(process.env.REACT_APP_AWS_REGION),
      params: { Bucket: 'unproject-data' },
    },
  };
};
