import useSceneViewerInit from 'src/features/SceneViewer/hooks/useInit';
import ScenePreViewer from 'src/components/sceneViewer/ScenePreViewer';
const Previewer = () => {
  useSceneViewerInit();
  // const dispatch = useAppDispatch();
  // const sceneId = useAppSelector((store) => store.instance.current_sceneId);
  // const projectId = store.getState().app.projectId;
  // useEffect(() => {
  //   if (sceneId !== '') return;
  //   if (projectId !== '') return;

  //   dispatch(setSceneId('fa146f7b-95f0-4be8-88a9-09e73f1baf2c'));
  //   dispatch(setProject({ id: '1538ff3a-3c68-458e-8e2b-44c20460408a', name: 'new' }));
  // }, [sceneId, projectId]);
  return (
    <>
      <ScenePreViewer />

      {/* <StoryLineDrawer /> */}
      {/* <Modals /> */}
    </>
  );
};

export default Previewer;
