import { AddIcon } from '@chakra-ui/icons';
import {
  Menu,
  Image,
  Text,
  MenuButton,
  IconButton,
  Center,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { setCurrentUser } from 'src/store/reducers/app';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { supabase } from 'src/SupabaseClient';
import { ROUTES } from 'src/utils/constants';
import { getCurrentUser } from 'src/utils/requests';
import FloatingContainer from '../FloatingContainer';

const WorkspaceSelector = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((s) => s.app.currentUser);
  const workspaces = useAppSelector((s) => s.app.workspaces);

  const handleCreateWorkspace = () => {
    navigate(ROUTES.create_workspace);
  };

  const changeWorkspace = async (workspaceId: string) => {
    const { data: updateUser } = await supabase.auth.updateUser({
      data: {
        workspace_id: workspaceId,
      },
    });

    if (updateUser?.user) {
      const currentUser = getCurrentUser(updateUser.user);
      dispatch(setCurrentUser(currentUser));
    }
  };

  return (
    <FloatingContainer w="15rem" gap={4}>
      <Menu>
        <MenuButton
          w="full"
          as={IconButton}
          pl={1}
          justifyContent="start"
          pr={3}
          aria-label="Options"
          icon={
            <Center gap={3}>
              <Image src="/logo_32.png" alt="unproject logo" width="32px" height="32px" />
              <Text fontSize="sm" m={0}>
                {workspaces?.find((workspace) => workspace.id === currentUser?.workspace_id)?.name}
              </Text>
            </Center>
          }
          borderRadius="full"
          variant="ghost"
        />
        <MenuList p={3} maxW="32ch">
          {workspaces
            ?.filter((workspace) => workspace.id !== currentUser?.workspace_id)
            ?.map((workspace) => {
              return (
                <MenuItem
                  key={workspace.name}
                  aria-label={workspace.name ?? 'project'}
                  overflow="hidden"
                  rounded="md"
                  w="full"
                  fontSize="sm"
                  onClick={() => {
                    changeWorkspace(workspace.id);
                  }}
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {workspace.name}
                </MenuItem>
              );
            })}
          <MenuItem onClick={handleCreateWorkspace} icon={<AddIcon />} rounded="md" fontSize="sm">
            New Workspace
          </MenuItem>
        </MenuList>
      </Menu>
    </FloatingContainer>
  );
};

export default WorkspaceSelector;
