import { useEffect } from 'react';
import useUndoRedo from 'src/hooks/useUndoRedo';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { setActiveTool } from 'src/store/reducers/InstanceReducer';
import { InstanceToolType } from 'src/types';
import { createShorcutKeybindings } from 'src/utils/shortcuts';
import useSceneMenu from './useSceneMenu';

const useSceneViewerShortcuts = () => {
  const dispatch = useAppDispatch();
  const { resume, undo, redo } = useUndoRedo();
  const { getContextMenu, handlePaste } = useSceneMenu();
  const selectedObjects = useAppSelector((store) => store.sceneViewer.selectedObjects);

  const handleAddComment = () => {
    dispatch(setActiveTool(InstanceToolType.comment));
  };

  const handleEscape = () => {
    dispatch(setActiveTool(undefined));
  };

  const selectedObjectShortcuts = (selected: any) => {
    if (!selected?.length) return {};

    const contextMenu = getContextMenu();
    const excludeKeys = ['paste'];
    const items = Object.entries(contextMenu).reduce(
      (acc, [key, value]) => {
        if (excludeKeys.includes(key)) return acc;

        acc[key] = value.disabled ? undefined : value.function;
        return acc;
      },
      {} as Record<string, Function | undefined>
    );

    return items;
  };

  useEffect(() => {
    document.addEventListener('paste', handlePaste);

    return () => document.removeEventListener('paste', handlePaste);
  }, []);

  useEffect(() => {
    resume();
    const unsubscribe = createShorcutKeybindings({
      ...selectedObjectShortcuts(selectedObjects),
      undo: undo,
      redo: redo,
      addComment: handleAddComment,
      clear: handleEscape,
    });

    // * Remove event listener when SceneViewer unmounts
    return () => {
      unsubscribe();
    };
  }, [selectedObjects]);
};

export default useSceneViewerShortcuts;
