import { ChangeEvent, useState } from 'react';
import PropertyField from './PropertyField';
import PropertyHeading from './PropertyHeading';
import { radToDeg, degToRad, radToDeg_twoPI, applyProportionalScaling } from 'src/utils/helper';
import * as THREE from 'three';
import { SceneObjectActionTypes, UIObject } from 'src/types';
import { Button, Flex, Icon, Tooltip } from '@chakra-ui/react';
import { BiExpandVertical, BiCollapseVertical, BiSync } from 'react-icons/bi';
import { useAppSelector } from 'src/store/reducers/hook';
import { useSceneViewer } from '../hooks/useSceneViewer';
import { useAssetUpload } from 'src/features/SceneViewer/hooks/useAssetUpload';
import { getWorldTransform, toLocalTransform } from '../helpers';

export default function UiProperties(props: { id: string }) {
  // scene_scale is used to scale the grid to the size of the scene. (1 = cm, 0.01 = m)
  const scene_scale = 1.0;
  const [syncing, setIsSyncing] = useState(false);
  const connectedWithFigma = useAppSelector((store) => store.integrations.auth['figma']);
  const uiSceneObject = useAppSelector((store) => store.sceneViewer.entities[props.id]) as UIObject;
  const gizmoInfo = useAppSelector((store) => store.sceneViewer.gizmoInfo);

  const { handleSceneObjectAction } = useSceneViewer();
  const { syncFigmaFile } = useAssetUpload();

  const onSyncFigmaChanges = async () => {
    setIsSyncing(true);
    syncFigmaFile(uiSceneObject.backendProperties);
    setIsSyncing(false);
  };

  const handleUiPropertyChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: string,
    propIndex: number
  ) => {
    const objectCopy = structuredClone(uiSceneObject.backendProperties);
    const worldTransform = getWorldTransform(objectCopy.id);
    if (field === 'position') {
      const newPosition = [...objectCopy.position];
      newPosition[propIndex] = parseFloat(event.target.value) / scene_scale;
      // objectCopy.position = newPosition as any;

      const localTransform = toLocalTransform(objectCopy.id, {
        position: newPosition as [number, number, number],
        rotation: worldTransform.rotation,
        scale: worldTransform.scale,
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'rotation') {
      const newRotation = [...objectCopy.rotation];
      newRotation[propIndex] = degToRad(parseFloat(event.target.value));
      // objectCopy.rotation = newRotation as any;

      const localTransform = toLocalTransform(objectCopy.id, {
        position: worldTransform.position,
        rotation: newRotation as [number, number, number],
        scale: worldTransform.scale,
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'scale') {
      let newScale = [...objectCopy.scale];
      newScale[propIndex] = parseFloat(event.target.value);

      if (objectCopy.constrain_proportions) {
        newScale = applyProportionalScaling([...objectCopy.scale] as any, [...newScale] as any);
      }

      const localTransform = toLocalTransform(objectCopy.id, {
        position: worldTransform.position,
        rotation: worldTransform.rotation as [number, number, number],
        scale: newScale as [number, number, number],
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];

      // objectCopy.scale = newScale as any;
    } else if (field === 'corner_radius') {
      objectCopy.metadata.corner_radius = parseFloat(event.target.value);
    } else if (field === 'corner_steps') {
      objectCopy.metadata.corner_steps = parseInt(event.target.value);
    } else if (field === 'curve_angle') {
      objectCopy.metadata.curve_angle = degToRad(parseFloat(event.target.value));
    } else if (field === 'curve_steps') {
      objectCopy.metadata.curve_steps = parseInt(event.target.value);
    } else if (field === 'height') {
      objectCopy.metadata.height = parseFloat(event.target.value);
    } else if (field === 'width') {
      objectCopy.metadata.width = parseFloat(event.target.value);
    } else if (field === 'bbox') {
      const size = new THREE.Vector3();
      uiSceneObject.localProperties.originalBBox?.getSize(size);
      const oldDim = propIndex === 0 ? size.x : propIndex === 1 ? size.y : size.z;
      let newScale = [...objectCopy.scale];
      newScale[propIndex] = parseFloat(event.target.value) / scene_scale / oldDim;

      if (objectCopy.constrain_proportions) {
        newScale = applyProportionalScaling([...objectCopy.scale] as any, [...newScale] as any);
      }

      // objectCopy.scale = newScale as any;

      const localTransform = toLocalTransform(objectCopy.id, {
        position: worldTransform.position,
        rotation: worldTransform.rotation as [number, number, number],
        scale: newScale as [number, number, number],
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    }

    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: uiSceneObject.id,
        type: uiSceneObject.type,
        localProperties: {},
        backendProperties: {
          ...objectCopy,
        },
      },
    ]);
  };

  const handleConstrainProportionsChange = () => {
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: uiSceneObject.id,
        type: uiSceneObject.type,
        localProperties: {},
        backendProperties: {
          constrain_proportions: !uiSceneObject.backendProperties.constrain_proportions,
        },
      },
    ]);
  };

  let uiProperties = null;
  if (uiSceneObject) {
    const worldTransform = getWorldTransform(uiSceneObject.id);

    let boundingBoxProperties = null;
    if (uiSceneObject.localProperties.originalBBox !== undefined) {
      const size = new THREE.Vector3();
      uiSceneObject.localProperties.originalBBox.getSize(size);
      const width = size.x * worldTransform.scale[0] * scene_scale;
      const height = size.y * worldTransform.scale[1] * scene_scale;
      const depth = size.z * worldTransform.scale[2] * scene_scale;
      boundingBoxProperties = (
        <>
          <br />
          <PropertyHeading>Bounding Box</PropertyHeading>
          <label className="label">Dimension</label>
          <PropertyField
            value={(width * scene_scale).toFixed(2)}
            field="bbox"
            instanceIndex={-1}
            propIndex={0}
            handlePropertyChange={handleUiPropertyChange}
            type="number"
            disabled={!gizmoInfo.show[0]}
          />
          <PropertyField
            value={(height * scene_scale).toFixed(2)}
            field="bbox"
            instanceIndex={-1}
            propIndex={1}
            handlePropertyChange={handleUiPropertyChange}
            type="number"
            disabled={!gizmoInfo.show[1]}
          />
          <PropertyField
            value={(depth * scene_scale).toFixed(2)}
            field="bbox"
            instanceIndex={-1}
            propIndex={2}
            handlePropertyChange={handleUiPropertyChange}
            type="number"
            disabled={!gizmoInfo.show[2]}
          />
        </>
      );
    }

    uiProperties = (
      <div className="uiProperties">
        <PropertyHeading>Transform</PropertyHeading>
        <label className="label">Position</label>
        <PropertyField
          value={(worldTransform.position[0] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleUiPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[0]}
        />
        <PropertyField
          value={(worldTransform.position[1] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handleUiPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[1]}
        />
        <PropertyField
          value={(worldTransform.position[2] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handleUiPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[2]}
        />

        <br />
        <label className="label">Rotation</label>
        <PropertyField
          value={radToDeg(worldTransform.rotation[0]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleUiPropertyChange}
          type="number"
          disabled={!(gizmoInfo.show[1] && gizmoInfo.show[2])}
        />
        <PropertyField
          value={radToDeg(worldTransform.rotation[1]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handleUiPropertyChange}
          type="number"
          disabled={!(gizmoInfo.show[0] && gizmoInfo.show[2])}
        />
        <PropertyField
          value={radToDeg(worldTransform.rotation[2]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handleUiPropertyChange}
          type="number"
          disabled={!(gizmoInfo.show[0] && gizmoInfo.show[1])}
        />
        <br />
        <label className="label_scale">Scale</label>
        <Tooltip label="Constrain Proportions" zIndex={1001} bg="grey">
          <Button
            className={`prop-btn ${
              uiSceneObject.backendProperties.constrain_proportions ? 'prop-btn-active' : ''
            }`}
            size="sm"
            onClick={handleConstrainProportionsChange}
          >
            <Icon
              color="white"
              as={
                uiSceneObject.backendProperties.constrain_proportions
                  ? BiCollapseVertical
                  : BiExpandVertical
              }
            ></Icon>
          </Button>
        </Tooltip>
        <PropertyField
          value={worldTransform.scale[0].toFixed(2)}
          field="scale"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleUiPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[0]}
        />
        <PropertyField
          value={worldTransform.scale[1].toFixed(2)}
          field="scale"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handleUiPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[1]}
        />
        <PropertyField
          value={worldTransform.scale[2].toFixed(2)}
          field="scale"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handleUiPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[2]}
        />

        <br />
        <PropertyHeading>Plane</PropertyHeading>
        <label className="label">Height</label>
        <PropertyField
          value={(uiSceneObject.backendProperties.metadata.height * scene_scale).toFixed(2)}
          field="height"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleUiPropertyChange}
          type="number"
        />
        <label className="label">Width</label>
        <PropertyField
          value={(uiSceneObject.backendProperties.metadata.width * scene_scale).toFixed(2)}
          field="width"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleUiPropertyChange}
          type="number"
        />

        <br />
        <label className="label">Curve Angle</label>
        <PropertyField
          value={radToDeg_twoPI(uiSceneObject.backendProperties.metadata.curve_angle).toFixed(2)}
          field="curve_angle"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleUiPropertyChange}
          type="number"
        />
        <label className="label">Steps</label>
        <PropertyField
          value={uiSceneObject.backendProperties.metadata.curve_steps}
          field="curve_steps"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleUiPropertyChange}
          type="number"
        />

        <br />
        <label className="label">Corner Radius</label>
        <PropertyField
          value={uiSceneObject.backendProperties.metadata.corner_radius.toFixed(2)}
          field="corner_radius"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleUiPropertyChange}
          type="number"
        />
        {boundingBoxProperties}
        {connectedWithFigma && uiSceneObject.backendProperties.metadata.meta?.type === 'figma' && (
          <>
            <br />
            <PropertyHeading>Material</PropertyHeading>
            <Flex align="center" justify="space-between">
              <label className="label">Sync updates from Figma</label>
              <Tooltip label="Sync" zIndex={1001} bg="grey">
                <Button
                  className="prop-btn"
                  size="sm"
                  onClick={onSyncFigmaChanges}
                  isLoading={syncing}
                  disabled={syncing}
                >
                  <Icon color="white" as={BiSync} />
                </Button>
              </Tooltip>
            </Flex>
          </>
        )}
      </div>
    );
  }

  return <>{uiProperties}</>;
}
