import AssetProperties from './AssetProperties';
import CameraProperties, { HeadProperties, InteractionContainer } from './CameraProperties';
import UiProperties from './UiProperties';
import LightProperties from './LightProperties/LightProperties';
import './PropertyPanel.css';
import { SupportedSceneObjectTypes } from 'src/types';
import MultiSelectProperties from './MultiSelectProperties';
import GroupProperties from './GroupProperties';
import { ErrorBoundary } from 'react-error-boundary';
import { getOldestParent, getSceneObject } from '../helpers';
import { useAppSelector } from 'src/store/reducers/hook';
import SceneProperties from '../SceneProperties';

const Properties = (props: { id: string; type: SupportedSceneObjectTypes }) => {
  switch (props.type) {
    case SupportedSceneObjectTypes.viewport:
      return <CameraProperties id={props.id} />;
    case SupportedSceneObjectTypes.asset:
      return <AssetProperties id={props.id} />;
    case SupportedSceneObjectTypes.ui:
      return <UiProperties id={props.id} />;
    case SupportedSceneObjectTypes.head:
      const headParent = getOldestParent(props.id)?.backendProperties;
      if (!headParent) return <></>;
      return <HeadProperties id={headParent.id} />;
    case SupportedSceneObjectTypes.interactions:
      const interactionParent = getSceneObject(props.id)?.backendProperties.parent_group_id;
      if (!interactionParent) return <></>;
      return <InteractionContainer id={interactionParent} />;
    case SupportedSceneObjectTypes.group:
      return <GroupProperties id={props.id} />;
    case SupportedSceneObjectTypes.light:
      return <LightProperties id={props.id} />;
    default:
      return <></>;
  }
};

export default function PropertyPanel() {
  const selectedObjects = useAppSelector((store) => store.sceneViewer.selectedObjects);

  return (
    <div className="propertyPanel">
      <ErrorBoundary resetKeys={selectedObjects} fallback={<></>}>
        {selectedObjects.length > 1 ? (
          <MultiSelectProperties />
        ) : selectedObjects.length === 1 ? (
          <Properties id={selectedObjects[0].id} type={selectedObjects[0].type} />
        ) : (
          <SceneProperties />
        )}
      </ErrorBoundary>
    </div>
  );
}
