import React, { forwardRef, HTMLAttributes, CSSProperties, useState } from 'react';
import { Flex, Icon, IconButton, Input, Text } from '@chakra-ui/react';
import { BiCaretDown, BiCaretRight } from 'react-icons/bi';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { BsArrowsMove, BsBox, BsCameraVideo, BsPerson, BsImage, BsSquare } from 'react-icons/bs';
import { FaLock } from 'react-icons/fa6';
import { SupportedSceneObjectTypes } from 'src/types';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import classnames from 'classnames';

const getSupportedIcon = (type: SupportedSceneObjectTypes) => {
  switch (type) {
    case SupportedSceneObjectTypes.asset:
      return BsBox;
    case SupportedSceneObjectTypes.group:
      return BsSquare;
    case SupportedSceneObjectTypes.interactions:
      return BsArrowsMove;
    case SupportedSceneObjectTypes.controller:
    case SupportedSceneObjectTypes.head:
      return BsPerson;
    case SupportedSceneObjectTypes.ui:
      return BsImage;
    case SupportedSceneObjectTypes.viewport:
      return BsCameraVideo;
    default:
      return BsCameraVideo;
  }
};

export interface Props extends HTMLAttributes<HTMLLIElement> {
  id: string | undefined;
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  indentationWidth: number;
  value: string;
  active?: boolean;
  highlight?: boolean;
  data: any;
  onCollapse?(): void;
  onRemove?(): void;
  onRename?: SubmitHandler<FieldValues>;
  wrapperRef?(node: HTMLLIElement): void;
}

const EditableLabel = ({ value, onRename, setIsEditing }: any) => {
  const { register, handleSubmit, setFocus } = useForm();

  React.useEffect(() => {
    setFocus('name', { shouldSelect: true });
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit(onRename)} style={{ flex: 1 }}>
      <Input
        className="editable"
        placeholder="name"
        variant="ghost"
        bg="none"
        textColor="white"
        fontSize="small"
        autoFocus={true}
        defaultValue={value}
        p={0}
        {...register('name', {
          required: 'Name is required',
          maxLength: { value: 50, message: 'Name is too long' },
          onBlur: (e) => {
            e.stopPropagation();
            setTimeout(() => {
              setIsEditing(false);
            }, 100);
          },
        })}
      />
    </form>
  );
};

export const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      onRemove,
      style,
      active,
      highlight,
      wrapperRef,
      onRename,
      data,
      ...props
    },
    ref
  ) => {
    const [isEditing, setIsEditing] = useState(false);

    const type = data?.type;
    const value = data?.name ?? type;

    return (
      <li
        ref={wrapperRef}
        data-asset-id={props.id}
        data-asset-type={type}
        data-asset-locked={data?.locked ? 'true' : 'false'}
        className={classnames('tree-item', {
          'highlight-tree-item': highlight,
          'active-tree-item': active,
          'tree-item-rename': isEditing,
          clone,
          ghost,
          indicator,
        })}
        style={
          {
            listStyle: 'none',
            paddingLeft: `${indentationWidth * depth}px`,
          } as React.CSSProperties
        }
        {...props}
      >
        <Flex
          px={2}
          h={8}
          rounded="md"
          ref={ref}
          align="center"
          gap={2}
          style={style}
          onClick={(e) => {
            if (e.detail === 2) {
              setIsEditing(true);
            }
          }}
          userSelect="none"
          {...handleProps}
        >
          <Icon as={getSupportedIcon(type as any)} />
          {isEditing && !ghost && onRename ? (
            <EditableLabel value={value} setIsEditing={setIsEditing} onRename={onRename} />
          ) : (
            <Text
              fontSize="small"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              flexGrow="1"
              lineHeight={8}
              m={0}
            >
              {value}
            </Text>
          )}
          <Flex align="center" justify="center">
            {data?.locked && <Icon as={FaLock} fontSize={12} />}
            {onCollapse && !ghost && (
              <IconButton
                variant="ghost"
                onClick={(e) => {
                  e.stopPropagation();
                  onCollapse();
                }}
                aria-label="collapse"
                icon={collapsed ? <BiCaretRight /> : <BiCaretDown />}
                size="xs"
              />
            )}
          </Flex>
        </Flex>
      </li>
    );
  }
);

type TProps = {
  id: UniqueIdentifier;
} & any;

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
  isSorting || wasDragging ? false : true;

export const SortableTreeItem: React.FC<TProps> = ({ id, depth, ...props }) => {
  const disabled = props.data?.system_generated;

  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
    disabled,
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <TreeItem
      style={style}
      id={id}
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      depth={depth}
      ghost={isDragging}
      disableInteraction={isSorting}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      {...props}
    />
  );
};
