import { CloudStorageManager } from 'src/services/storage';
import { setIsInitializing } from 'src/store/reducers/app';
import store from 'src/store/store';
import { supabase } from 'src/SupabaseClient';
import { getCloudStorageCacheKey } from 'src/utils/cloud';
import { getPublicProvider } from 'src/utils/public';

export const getCloudStorage = async (refresh = false) => {
  const workspaceId = store.getState().app.currentUser?.workspace_id;
  if (!workspaceId) return;

  const cloudStorageKey = getCloudStorageCacheKey(workspaceId);
  let cacheItem = localStorage.getItem(cloudStorageKey);

  let providerDetails = cacheItem ? JSON.parse(cacheItem) : undefined;
  if (!providerDetails || refresh) {
    try {
      if (refresh) store.dispatch(setIsInitializing(true));
      providerDetails = await getSignedURLOfCloud(workspaceId);
    } catch (err) {
      console.error(err);
    }
  }

  const cloud = getPublicProvider();

  if (providerDetails?.config) {
    cloud.provider = providerDetails.provider === 'unproject' ? 'aws' : providerDetails.provider;
    cloud.config = providerDetails.config;
  }

  localStorage.setItem(cloudStorageKey, JSON.stringify(cloud));
  if (refresh) store.dispatch(setIsInitializing(false));

  const instance = new CloudStorageManager(cloud.provider, cloud.config);

  return instance;
};

export const getSignedURLOfCloud = async (workspace: string) => {
  const { data } = await supabase.functions.invoke('signed-url', {
    body: {
      id: workspace,
    },
  });

  return data;
};

export const useCloudStorage = () => {
  return {
    getSignedURLOfCloud,
    getCloudStorage,
  };
};
