import { useBounds } from '@react-three/drei';
import { ThreeEvent, useThree } from '@react-three/fiber';
import {
  forwardRef,
  ReactNode,
  Suspense,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { PivotControls } from 'src/components/pivotControls';
import { context, ContextType, sceneRefInterface } from 'src/components/sceneViewer/context';
import { addComment, setSelectedComment } from 'src/store/reducers/comments';
import { useAppSelector } from 'src/store/reducers/hook';
import { openContextMenu } from 'src/store/reducers/modals';
import store from 'src/store/store';
import {
  bboxInfoInterface,
  ContantScaleObjectTypes,
  DUMMY_NODE,
  gizmoInfoInterface,
  InstanceToolType,
  refTransformsInterface,
  SceneObjectActionTypes,
  sceneObjectRefInterface,
  SupportedGroupTypes,
  SupportedNodeTypes,
  SupportedSceneObjectTypes,
} from 'src/types';
import {
  applyProportionalScaling,
  getPRS,
  getTransformationMatrix,
  quaternionToEuler,
} from 'src/utils/helper';
import { createShorcutKeybindings } from 'src/utils/shortcuts';
import * as THREE from 'three';
import FloatingUsers from '../FloatingUser';
import {
  checkDescendent,
  getNextChild,
  getOldestParent,
  getSceneObject,
  getWorldTransform,
  toLocalTransform,
} from '../helpers';
import { useSceneInteractions } from '../hooks/useSceneInteractions';
import useSceneMenu from '../hooks/useSceneMenu';
import { useSceneViewer } from '../hooks/useSceneViewer';
import { useSceneObjectSelection } from '../hooks/useSelectObjects';
import SceneObject from '../SceneObject';
import AssetController from './AssetController';
import BoundingBox from './BoundingBox';
import SceneAsset from './SceneAsset';
import SceneComment from './SceneComment';
import SceneGroupAsset from './SceneGroupAsset';
import SceneUiAsset from './SceneUiAsset';
import SceneLightAsset from './SceneLightAsset';

type SceneProps = {
  children?: ReactNode;
  addCamera?: boolean;
  disablePivot?: boolean;
};

const Scene = forwardRef<sceneRefInterface, SceneProps>((props, ref) => {
  // State Variables
  const { multiSelectConstrainProportions, setMultiSelectConstrainProportions } =
    useContext<ContextType>(context);
  const focusapi = useBounds();
  const { scene } = useThree();

  const dispatch = useDispatch();
  const { createContextMenu } = useSceneMenu();
  const { handleSceneObjectAction, onCurrentViewportUpdate, onSelectObject, getSelectedObjects } =
    useSceneViewer();
  const { onBBoxUpdate, onGizmoUpdate, onRefTransformUpdate } = useSceneInteractions();
  const commentIds = useAppSelector((store) => store.comments.ids);
  const commentMode =
    useAppSelector((store) => store.instance.activeTool) === InstanceToolType.comment;

  const sceneObjectList = useAppSelector((store) => store.sceneViewer.ids);

  const list = useMemo(() => {
    const list = {
      viewportList: [] as string[],
      assetList: [] as string[],
      uiList: [] as string[],
      groupList: [] as string[],
      lightList: [] as string[],
    };

    sceneObjectList.forEach((id: any) => {
      const item = getSceneObject(id);

      if (item) {
        switch (item.type) {
          case SupportedSceneObjectTypes.viewport:
            list.viewportList.push(id);
            break;
          case SupportedSceneObjectTypes.asset:
            list.assetList.push(id);
            break;
          case SupportedSceneObjectTypes.ui:
            list.uiList.push(id);
            break;
          case SupportedSceneObjectTypes.group:
            list.groupList.push(id);
            break;
          case SupportedSceneObjectTypes.light:
            list.lightList.push(id);
            break;
          default:
            break;
        }
      }
    });

    return list;
  }, [sceneObjectList]);

  const bboxInfo = useAppSelector((store) => store.sceneViewer.bboxInfo);

  const comments = useMemo(() => {
    return commentIds.filter((id) => {
      const comment = store.getState().comments.entities[id];
      return !comment.parent_id && !comment.filename;
    });
  }, [commentIds]);

  useSceneObjectSelection();

  const handleSelectNode = () => dispatch(setSelectedComment(DUMMY_NODE));

  const addDummyCommentNode = (type: SupportedSceneObjectTypes, selectedInstance: string) => {
    const instance = getSceneObject(selectedInstance);
    if (instance) {
      dispatch(
        addComment({
          id: DUMMY_NODE,
          position: instance.backendProperties.position,
          type: SupportedNodeTypes.comment,
        })
      );

      handleSelectNode();
    }
  };

  // Callback function to add new comment to scene
  const handleAddComments = () => {
    const selectedObjects = getSelectedObjects();

    if (selectedObjects.length === 1) {
      addDummyCommentNode(selectedObjects[0].type, selectedObjects[0].id);
    }
  };

  const handleFocus = () => {
    console.log('focus');
    const selectedObjects = getSelectedObjects();
    if (selectedObjects.length === 1) {
      const sceneObject = getSceneObject(selectedObjects[0].id);
      let objectBBox = bboxInfo.refBBox?.clone();

      if (objectBBox && sceneObject) {
        objectBBox.applyMatrix4(
          new THREE.Matrix4().compose(
            new THREE.Vector3(bboxInfo.position[0], bboxInfo.position[1], bboxInfo.position[2]),
            new THREE.Quaternion().setFromEuler(
              new THREE.Euler(bboxInfo.rotation[0], bboxInfo.rotation[1], bboxInfo.rotation[2])
            ),
            new THREE.Vector3(bboxInfo.scale[0], bboxInfo.scale[1], bboxInfo.scale[2])
          )
        );
        focusapi.refresh(objectBBox).fit();
      }
    } else if (selectedObjects.length === 0) {
      focusapi.refresh().fit();
    }
  };

  useEffect(() => {
    const unsubscribe = createShorcutKeybindings({
      focus: handleFocus,
    });

    return unsubscribe;
  }, [bboxInfo]);

  useImperativeHandle(
    ref,
    () => ({
      scene: scene,
    }),
    [scene]
  );

  const isAssetSelectedByOtherUser = (id: string) => {
    const userId = store.getState().app.currentUser?.id;

    const usersObj = store.getState().collaboration.storyboard;
    const activeUsers = Object.keys(usersObj).filter((user) => user !== userId);

    return activeUsers.some((user) => {
      const userSelection = usersObj[user].selection;
      if (userSelection && userSelection.id === id) return true;
      return false;
    });
  };

  // Event Utils
  const onAssetClick = (
    event: ThreeEvent<MouseEvent>,
    id: string,
    type: SupportedSceneObjectTypes,
    overrideToNormalAlways: boolean = false,
    removeRCMenu: boolean = true
  ) => {
    event.stopPropagation();
    let selectedObjects = getSelectedObjects();
    if (isAssetSelectedByOtherUser(id)) return;

    if (removeRCMenu) {
      dispatch(openContextMenu({ items: undefined }));
    }

    // CTRL Key is pressed when clicking objects
    if ((event.ctrlKey || event.metaKey || event.shiftKey) && !overrideToNormalAlways) {
      onGizmoUpdate({
        show: [true, true, true],
      } as Partial<gizmoInfoInterface>);
      // If already selected then remove it
      const oldestParent = getOldestParent(id);
      if (oldestParent === undefined) return;
      const index = selectedObjects.findIndex(
        (item) => item.id === oldestParent.id && item.type === oldestParent.type
      );

      const selected = getSceneObject(id);

      if (selected?.backendProperties.system_generated) {
        selectedObjects = selectedObjects.filter((item) => {
          const obj = getSceneObject(item.id);
          return !obj?.backendProperties.system_generated;
        });
      }

      if (index !== -1) {
        const newArray = [...selectedObjects.slice(0, index), ...selectedObjects.slice(index + 1)];
        onSelectObject(newArray);
      } else {
        onSelectObject([
          ...selectedObjects,
          {
            id: oldestParent.id,
            type: oldestParent.type,
          },
        ]);
      }
    }

    // Normal case
    else {
      setMultiSelectConstrainProportions(false);
      // Originally only one object is selected

      if (!checkSelected(id, type)) {
        if (selectedObjects.length === 1) {
          if (selectedObjects[0].id !== id) {
            onGizmoUpdate({
              show: [true, true, true],
            } as Partial<gizmoInfoInterface>);
          }
        } else {
          onGizmoUpdate({
            show: [true, true, true],
          } as Partial<gizmoInfoInterface>);
        }

        const oldestParent = getOldestParent(id);

        if (oldestParent === undefined) return;

        if (oldestParent.type === SupportedSceneObjectTypes.viewport) {
          // setCurrentViewport(id);
          onCurrentViewportUpdate(oldestParent.id);
        }

        onSelectObject([
          {
            id: oldestParent.id,
            type: oldestParent.type,
          },
        ]);
      }
    }

    if (commentMode) {
      handleAddComments();
    }
  };

  // When gizmo drag start
  // set sceneObjectsRef in refTransforms to selectedObjects
  const handleAssetDragStart = () => {
    const sceneObjectsRef = [] as sceneObjectRefInterface[];
    const selectedObjects = getSelectedObjects();

    selectedObjects.forEach((selectedObject) => {
      let p = [0.0, 0.0, 0.0];
      let r = [0.0, 0.0, 0.0];
      let s = [1.0, 1.0, 1.0];
      let sceneObject = getSceneObject(selectedObject.id);
      if (sceneObject) {
        const worldTransform = getWorldTransform(selectedObject.id);
        p = worldTransform.position;
        r = worldTransform.rotation;
        s = worldTransform.scale;
      }

      sceneObjectsRef.push({
        id: selectedObject.id,
        type: selectedObject.type,
        transform: getTransformationMatrix(
          p as [number, number, number],
          r as [number, number, number],
          s as [number, number, number]
        ),
      });
    });

    const p = store.getState().sceneViewer.gizmoInfo.position;
    const s = store.getState().sceneViewer.gizmoInfo.scale;

    const updatedBbox = store.getState().sceneViewer.bboxInfo;

    onRefTransformUpdate({
      gizmoRef: getTransformationMatrix(p, [0.0, 0.0, 0.0], s),
      sceneObjectsRef: [...sceneObjectsRef],
      bboxRef: getTransformationMatrix(
        updatedBbox.position,
        updatedBbox.rotation,
        updatedBbox.scale
      ),
    } as Partial<refTransformsInterface>);
  };

  const handleAssetDrag = (
    l: THREE.Matrix4,
    dl: THREE.Matrix4,
    w: THREE.Matrix4,
    dw: THREE.Matrix4
  ) => {
    // Check if scaling should be constrained
    let constrainProportions = false;
    const selectedObjects = getSelectedObjects();

    if (selectedObjects.length === 1) {
      const sceneObject = getSceneObject(selectedObjects[0].id);
      if (sceneObject) {
        if (sceneObject.type !== SupportedSceneObjectTypes.viewport) {
          constrainProportions = sceneObject.backendProperties.constrain_proportions;
        }
      }
    } else if (selectedObjects.length > 1) {
      constrainProportions = multiSelectConstrainProportions;
    }

    const pendingUpdates = [] as any[];

    // Apply the new transformation on all the selected objects
    const refTransforms = store.getState().sceneViewer.refTransforms;
    selectedObjects.forEach((selectedObject, selectedObjectIndex) => {
      const sceneObject = getSceneObject(selectedObject.id);
      const index = refTransforms.sceneObjectsRef.findIndex(
        (item) => item.id === selectedObject.id && item.type === selectedObject.type
      );
      if (index === -1) return;

      // Calculate object transform Matrix.
      const objectMatrix = refTransforms.sceneObjectsRef[index].transform.clone();

      // Matrix using initial transform (transform at drag start).
      // NOTE: Taking old rotation as zero because the gizmo always has zero rotation.
      const oldMatrix = refTransforms.gizmoRef.clone();

      // Matrix using current transform
      const newMatrix = w.clone();
      const finalGroupPosition = new THREE.Vector3();
      const finalGroupRotation = new THREE.Quaternion();
      const finalGroupScale = new THREE.Vector3();
      newMatrix.decompose(finalGroupPosition, finalGroupRotation, finalGroupScale);
      // correct scale if constrain Proportions
      if (constrainProportions) {
        let newScale = [finalGroupScale.x, finalGroupScale.y, finalGroupScale.z];
        // let oldScale = [...bboxInfo.refScale] as [number, number, number];
        let oldScale = new THREE.Vector3();
        refTransforms.gizmoRef.decompose(new THREE.Vector3(), new THREE.Quaternion(), oldScale);
        newScale = applyProportionalScaling([oldScale.x, oldScale.y, oldScale.z], [...newScale] as [
          number,
          number,
          number,
        ]);
        finalGroupScale.set(newScale[0], newScale[1], newScale[2]);
      }
      newMatrix.copy(
        new THREE.Matrix4().compose(finalGroupPosition, finalGroupRotation, finalGroupScale)
      );

      // Calculate delta transform.
      const gizmoChangeMatrix = new THREE.Matrix4();
      gizmoChangeMatrix.copy(newMatrix.multiply(oldMatrix.invert()));

      // Apply delta transform on Object Matrix
      objectMatrix.copy(gizmoChangeMatrix.clone().multiply(objectMatrix));

      const finalObjectPosition = new THREE.Vector3();
      const finalObjectRotation = new THREE.Quaternion();
      const finalObjectScale = new THREE.Vector3();
      objectMatrix.decompose(finalObjectPosition, finalObjectRotation, finalObjectScale);

      const p = [finalObjectPosition.x, finalObjectPosition.y, finalObjectPosition.z];
      const r = quaternionToEuler([
        finalObjectRotation.x,
        finalObjectRotation.y,
        finalObjectRotation.z,
        finalObjectRotation.w,
      ]);
      let s = [finalObjectScale.x, finalObjectScale.y, finalObjectScale.z];

      const localTransform = toLocalTransform(selectedObject.id, {
        position: p as [number, number, number],
        rotation: r as [number, number, number],
        scale: s as [number, number, number],
      });
      let newStates: {
        name: string;
        position: [number, number, number];
        rotation: [number, number, number];
        scale: [number, number, number];
      }[] = [];
      if (sceneObject && sceneObject.backendProperties.animation !== null) {
        // Create a deep copy of the states array to avoid modifying the original object
        newStates = sceneObject.backendProperties.animation.states.map((state) => ({ ...state }));

        let indexToModify = sceneObject.backendProperties.animation.currentState as number;

        // Create a new object with the updated position, rotation, and scale
        newStates[indexToModify] = {
          ...newStates[indexToModify],
          position: [
            localTransform.position[0],
            localTransform.position[1],
            localTransform.position[2],
          ],
          rotation: [
            localTransform.rotation[0],
            localTransform.rotation[1],
            localTransform.rotation[2],
          ],
          scale: [localTransform.scale[0], localTransform.scale[1], localTransform.scale[2]],
        };
      }
      // Update to database
      pendingUpdates.push({
        type: selectedObject.type,
        id: selectedObject.id,
        localProperties: {},
        backendProperties:
          // * If the object is a viewport, we only need to update position and rotation
          selectedObject.type === SupportedSceneObjectTypes.viewport
            ? {
                position: localTransform.position,
                rotation: localTransform.rotation,
                animation: {
                  ...sceneObject?.backendProperties.animation,
                  states: newStates,
                },
              }
            : {
                position: localTransform.position,
                rotation: localTransform.rotation,
                scale: localTransform.scale,
                animation: {
                  ...sceneObject?.backendProperties.animation,
                  states: newStates,
                },
              },
      });
    });

    handleSceneObjectAction(SceneObjectActionTypes.update, pendingUpdates);
  };

  const handleAssetDragEnd = () => {
    handleAssetDragStart();
  };

  const onPointerOver = (asset: any) => {
    const enableHover = store.getState().instance.activeTool === InstanceToolType.comment;
    if (!enableHover || !asset) return;
    document.body.style.cursor = 'pointer';

    try {
      const worldTransform = getWorldTransform(asset.id);

      onGizmoUpdate({
        position: worldTransform.position,
        rotation: worldTransform.rotation,
        scale: worldTransform.scale,
        show: [true, true, true],
      } as Partial<gizmoInfoInterface>);

      onBBoxUpdate({
        position: worldTransform.position,
        rotation: worldTransform.rotation,
        scale: worldTransform.scale,
        refBBox: asset.localProperties.originalBBox,
      } as Partial<bboxInfoInterface>);
    } catch (err) {
      console.error(err);
    }
  };

  const onPointerOut = () => {
    const enableHover = store.getState().instance.activeTool === InstanceToolType.comment;

    if (!enableHover) return;

    document.body.style.cursor = 'auto';
  };

  const checkSelected = (id: string, type: SupportedSceneObjectTypes) => {
    let isSelected = false;
    const selectedObjects = getSelectedObjects();
    // In selected
    selectedObjects.forEach((item) => {
      if (item.id === id && item.type === type) {
        isSelected = true;
      }

      if (SupportedGroupTypes.includes(item.type)) {
        isSelected = checkDescendent(id, item.id);
      }
    });

    return isSelected;
  };

  // When Right Clicked on asset directly
  const onAssetRightClick = (
    event: ThreeEvent<MouseEvent>,
    id: string,
    type: SupportedSceneObjectTypes
  ) => {
    event.stopPropagation();

    const isSelected = checkSelected(id, type);
    if (!isSelected) {
      // Override to Normal case for onAssetClick always;
      onAssetClick(event, id, type, true, false);
    }

    createContextMenu(event);
  };

  const onAssetDoubleClick = (
    event: ThreeEvent<MouseEvent>,
    id: string,
    type: SupportedSceneObjectTypes
  ) => {
    event.stopPropagation();
    const selectedObjects = getSelectedObjects();
    if (selectedObjects.length === 1) {
      const sceneObject = getNextChild(id, selectedObjects[0].id);

      if (sceneObject) {
        onSelectObject([
          {
            id: sceneObject.id,
            type: sceneObject.type,
          },
        ]);
      }
    }
  };

  return (
    <>
      {list.assetList.map((id) => {
        return (
          <SceneObject
            key={id}
            id={id}
            onPointerOver={onPointerOver}
            onPointerOut={onPointerOut}
            onClick={onAssetClick}
            onRightClick={onAssetRightClick}
          >
            <SceneAsset />
          </SceneObject>
        );
      })}
      <Suspense>{props.children}</Suspense>
      {list.uiList.map((id) => {
        return (
          <SceneObject
            id={id}
            key={id}
            onPointerOver={onPointerOver}
            onPointerOut={onPointerOut}
            onClick={onAssetClick}
            onRightClick={onAssetRightClick}
          >
            <SceneUiAsset />
          </SceneObject>
        );
      })}

      {list.viewportList.map((id) => {
        return (
          <SceneObject
            id={id}
            key={id}
            onPointerOver={onPointerOver}
            onPointerOut={onPointerOut}
            onClick={onAssetClick}
            onRightClick={onAssetRightClick}
            onDoubleClick={onAssetDoubleClick}
          >
            <SceneGroupAsset />
          </SceneObject>
        );
      })}

      {list.groupList.map((id) => {
        return (
          <SceneObject
            id={id}
            key={id}
            onPointerOver={onPointerOver}
            onPointerOut={onPointerOut}
            onClick={onAssetClick}
            onRightClick={onAssetRightClick}
            onDoubleClick={onAssetDoubleClick}
          >
            <SceneGroupAsset />
          </SceneObject>
        );
      })}

      {list.lightList.map((id) => {
        return (
          <SceneObject
            id={id}
            key={id}
            onPointerOver={onPointerOver}
            onPointerOut={onPointerOut}
            onClick={onAssetClick}
            onRightClick={onAssetRightClick}
            onDoubleClick={onAssetDoubleClick}
          >
            <SceneLightAsset />
          </SceneObject>
        );
      })}

      <SceneObjectHelpers
        disablePivot={props.disablePivot}
        onRightClick={onAssetRightClick}
        onDrag={handleAssetDrag}
        onDragEnd={handleAssetDragEnd}
        onDragStart={handleAssetDragStart}
        commentMode={commentMode}
      />

      {!props.disablePivot && (
        <>
          {comments.map((id) => {
            const node = store.getState().comments.entities[id];
            return <SceneComment key={id} node={node} />;
          })}
        </>
      )}
      <FloatingUsers />
    </>
  );
});

const SceneObjectHelpers = ({
  disablePivot,
  onDrag,
  onDragStart,
  onDragEnd,
  onRightClick,
  commentMode,
}: any) => {
  const { getSelectedObjects } = useSceneViewer();
  const { onBBoxUpdate, onGizmoUpdate } = useSceneInteractions();

  const bboxInfo = useAppSelector((store) => store.sceneViewer.bboxInfo);
  const gizmoInfo = useAppSelector((store) => store.sceneViewer.gizmoInfo);
  const sceneObjects = useAppSelector((store) => store.sceneViewer.entities);

  const scaleMatrix = new THREE.Matrix4().scale(
    new THREE.Vector3(gizmoInfo.scale[0], gizmoInfo.scale[1], gizmoInfo.scale[2])
  );

  useEffect(() => {
    const selectedObjects = getSelectedObjects();

    if (selectedObjects.length === 1) {
      let sceneObject = getSceneObject(selectedObjects[0].id);
      if (sceneObject) {
        const worldTransform = getWorldTransform(selectedObjects[0].id);
        const scale = ContantScaleObjectTypes.includes(sceneObject.type)
          ? [1.0, 1.0, 1.0]
          : worldTransform.scale;

        onGizmoUpdate({
          position: worldTransform.position,
          rotation: [0.0, 0.0, 0.0],
          scale: worldTransform.scale,
        } as Partial<gizmoInfoInterface>);

        onBBoxUpdate({
          position: worldTransform.position,
          rotation: worldTransform.rotation,
          scale: scale,
        } as Partial<bboxInfoInterface>);
      }
    } else if (selectedObjects.length > 1) {
      // We want to calculate change without using viewport
      // Because for viewport scale is constant
      let done = false;
      const changeMatrix = new THREE.Matrix4();
      for (let i = 0; i < selectedObjects.length; i++) {
        if (selectedObjects[i].type !== SupportedSceneObjectTypes.viewport) {
          const sceneObject = getSceneObject(selectedObjects[i].id);
          const oldMatrix = store
            .getState()
            .sceneViewer.refTransforms.sceneObjectsRef.find(
              (item) => item.id === selectedObjects[i].id && item.type === selectedObjects[i].type
            );
          if (sceneObject && sceneObject.type !== SupportedSceneObjectTypes.viewport && oldMatrix) {
            const newTransform = getWorldTransform(sceneObject.id);
            done = true;
            changeMatrix.copy(
              getTransformationMatrix(
                newTransform.position,
                newTransform.rotation,
                newTransform.scale
              )
                .clone()
                .multiply(oldMatrix.transform.clone().invert())
            );
            break;
          }
        }
      }

      // If all viewports or all interactions
      if (!done) {
        const sceneObject = getSceneObject(selectedObjects[0].id);
        const oldMatrix = store
          .getState()
          .sceneViewer.refTransforms.sceneObjectsRef.find(
            (item) => item.id === selectedObjects[0].id && item.type === selectedObjects[0].type
          );
        if (sceneObject && oldMatrix) {
          done = true;
          const newTransform = getWorldTransform(sceneObject.id);
          changeMatrix.copy(
            getTransformationMatrix(
              newTransform.position,
              newTransform.rotation,
              newTransform.scale
            )
              .clone()
              .multiply(oldMatrix.transform.clone().invert())
          );
        }
      }

      // Apply change matrix on bbox ref
      // Do not update ref as ref is updated directly after drag end
      const oldBBoxMatrix = store.getState().sceneViewer.refTransforms.bboxRef.clone();
      const finalBBoxMatrix = new THREE.Matrix4();
      finalBBoxMatrix.copy(changeMatrix.clone().multiply(oldBBoxMatrix));
      let prs = getPRS(finalBBoxMatrix);

      onGizmoUpdate({
        position: prs.position,
        rotation: [0.0, 0.0, 0.0],
        scale: prs.scale,
      } as Partial<gizmoInfoInterface>);

      onBBoxUpdate({
        position: prs.position,
        rotation: prs.rotation,
        scale: prs.scale,
      } as Partial<bboxInfoInterface>);
    }
  }, [sceneObjects]);

  const { createContextMenu } = useSceneMenu();

  const onBBoxRightClick = (event: ThreeEvent<MouseEvent>) => {
    event.stopPropagation();
    const selectedObjects = getSelectedObjects();

    if (selectedObjects.length > 1) {
      createContextMenu(event);
    } else {
      if (selectedObjects.length === 1) {
        onRightClick(event, selectedObjects[0].id, selectedObjects[0].type);
      }
    }
  };

  return (
    <>
      <AssetController
        bbox={bboxInfo}
        gizmo={gizmoInfo}
        pivotProps={{
          disablePivot: disablePivot,
          onDrag: onDrag,
          onDragStart: onDragStart,
          onDragEnd: onDragEnd,
        }}
      />
      {!commentMode && !disablePivot && (
        <group position={gizmoInfo.position} rotation={gizmoInfo.rotation}>
          <PivotControls
            matrix={scaleMatrix}
            activeAxes={[
              gizmoInfo.show[0] && !disablePivot,
              gizmoInfo.show[1] && !disablePivot,
              gizmoInfo.show[2] && !disablePivot,
            ]}
            fixed={true}
            scale={100}
            depthTest={false}
            onDrag={(l, dl, w, dw) => onDrag(l, dl, w, dw)}
            onDragStart={() => onDragStart()}
            onDragEnd={() => onDragEnd()}
            autoTransform={false}
          />
        </group>
      )}

      {!disablePivot && (gizmoInfo.show[0] || gizmoInfo.show[1] || gizmoInfo.show[2]) ? (
        bboxInfo.refBBox !== undefined ? (
          <group
            position={bboxInfo.position}
            rotation={bboxInfo.rotation}
            scale={bboxInfo.scale}
            onContextMenu={(event) => onBBoxRightClick(event)}
            renderOrder={5}
          >
            <BoundingBox bbox={bboxInfo.refBBox} color={0xffff00} />
          </group>
        ) : undefined
      ) : undefined}
    </>
  );
};

// Scene.defaultProps = defaultSceneProps
export default Scene;
