import { useRef, useEffect } from 'react';

import './SceneViewer.css';
import { sceneRefInterface } from './context';
import { useDispatch } from 'react-redux';

import { setComments } from 'src/store/reducers/comments';
import { useSceneCollaboration } from '../../features/Collaboration/hooks/useSceneCollaboration';

import { useAppSelector } from 'src/store/reducers/hook';
import { useSceneViewer } from './hooks/useSceneViewer';

import { useFloatingHeads } from 'src/features/Collaboration/hooks/useFloatingHeads';
import { stringToColor } from 'src/utils/colors';
import LoadingContainer from './LoadingContainer';
import useSceneViewerShortcuts from './hooks/useSceneViewerShortcuts';
import MainPreviewPanel from './MainPreviewPanel';

export default function ScenePreViewer() {
  const containerRef = useRef();
  const viewportSceneRef = useRef<sceneRefInterface>(null);

  const dispatch = useDispatch();

  const { getSceneObjectHandler, onCameraConfigUpdate, onSelectObject } = useSceneViewer();

  const sceneId = useAppSelector((store) => store.instance.current_sceneId);
  const following = useAppSelector((store) => store.collaboration.following);

  // Fetch all instances from Supabase on load
  useEffect(() => {
    //TODO: Refactor SceneViewer to use Redux
    dispatch(setComments([]));
    onSelectObject([]);
  }, [sceneId]);

  const groupInstanceHandler = getSceneObjectHandler();

  // * Keyboard Shortcuts
  useSceneViewerShortcuts();
  // useFloatingHeads(onCameraConfigUpdate);
  useSceneCollaboration({
    groupInstanceHandler,
  });

  return (
    <div
      style={{
        transition: 'background-color 0.5s',
        background: following ? stringToColor(following) : 'transparent',
      }}
      className="sceneViewer"
      ref={containerRef as any}
    >
      <LoadingContainer />
      <MainPreviewPanel isFollowing={following} ref={viewportSceneRef} />
    </div>
  );
}
