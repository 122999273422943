import { useDisclosure } from '@chakra-ui/react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Portal,
} from '@chakra-ui/react';

type ControllerOptionProps = {
  label: string;
  parentController: 'left' | 'right';
  state: string;
  handleControllerStateChange: Function;
};

export const ControllerOption = (props: ControllerOptionProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const handlePopoverButtonClick = (color: string) => {
    onToggle();

    props.handleControllerStateChange(props.parentController, props.label, color);
  };

  const colorOptions = [
    '#fc0303',
    '#fc9403',
    '#f4fc03',
    '#20fc03',
    '#03a1fc',
    '#6203fc',
    '#fc03be',
    '#ffffff',
  ];

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      placement="left"
      closeOnBlur={true}
      strategy="absolute"
    >
      <div className="controllerOption" onClick={onToggle}>
        <PopoverTrigger>
          <button
            className="controllerOption-button"
            style={{
              backgroundColor: props.state,
            }}
          ></button>
        </PopoverTrigger>
        <label className="controllerOption-label">{props.label}</label>
      </div>
      <Portal>
        <div
          style={{
            position: 'absolute',
            zIndex: 1001,
          }}
        >
          <PopoverContent
            className="controllerOption-popover"
            bg={'rgba(18, 17, 23, 0.6)'}
            border={'none'}
            color="white"
            fontSize={'12px'}
            backdropFilter="auto"
            backdropBlur="8px"
            width="auto"
          >
            <PopoverCloseButton />
            <PopoverHeader border="none" fontWeight={500}>
              Button Color
            </PopoverHeader>
            <PopoverBody>
              <div className="controllerOption-popover-body">
                {colorOptions.map((item, index) => {
                  if (index % 4 === 0 && index !== 0) {
                    return (
                      <>
                        <br />
                        <button
                          style={{
                            backgroundColor: item,
                          }}
                          className="controllerOption-popover-button"
                          onClick={() => {
                            handlePopoverButtonClick(item);
                          }}
                        ></button>
                      </>
                    );
                  } else {
                    return (
                      <button
                        style={{
                          backgroundColor: item,
                        }}
                        className="controllerOption-popover-button"
                        onClick={() => {
                          handlePopoverButtonClick(item);
                        }}
                      ></button>
                    );
                  }
                })}
              </div>
            </PopoverBody>
          </PopoverContent>
        </div>
      </Portal>
    </Popover>
  );
};
