import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  ModalFooter,
  ModalHeader,
  RadioGroup,
  Radio,
  Stack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { closeModal } from 'src/store/reducers/modals';
import store from 'src/store/store';
import {
  ModalType,
  SceneActionSubType,
  SceneObjectActionTypes,
  SupportedSceneObjectTypes,
} from 'src/types';
import { getSceneObject } from '../sceneViewer/helpers';
import useSceneMenu from '../sceneViewer/hooks/useSceneMenu';
import { useSceneViewer } from '../sceneViewer/hooks/useSceneViewer';

const ForegroundModal = () => {
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<string>('1');
  const [isLoading, setIsLoading] = useState(false);
  const { handleSceneObjectAction } = useSceneViewer();
  const { onSetObjectsProperty } = useSceneMenu();
  const modal = useAppSelector((store) => store.modal.active[ModalType.foreground]);

  const onClose = () => {
    dispatch(closeModal(ModalType.foreground));
  };

  if (!modal) return null;

  const onClick = () => {
    const { id, type, background } = modal.data;
    setIsLoading(true);
    onSetObjectsProperty(id, type, { background });

    if (selected === '2') {
      const currentSceneId = store.getState().instance.current_sceneId;
      const projectId = store.getState().app.projectId;
      const sceneInstance = store
        .getState()
        .storyboard.nodes.find((node: any) => node.id === currentSceneId) as any;

      const storylineScenes = store
        .getState()
        .storyboard.nodes.filter(
          (node: any) => node.storyline === sceneInstance?.storyline && node.id !== currentSceneId
        )
        .map((node) => node.id);

      if (storylineScenes?.length) {
        const promises = handleSceneObjectAction(
          SceneObjectActionTypes.insert,
          storylineScenes.map((sceneId) => {
            let extras = {};
            if (type !== SupportedSceneObjectTypes.group) {
              const assetData = getSceneObject(id)?.backendProperties;
              if (assetData) {
                const { id, ...rest } = assetData;
                extras = rest;
              }
            }

            return {
              id: null,
              type,
              localProperties: {
                id,
                subType: SceneActionSubType.copy,
                updateStore: false,
              },
              backendProperties: {
                ...extras,
                scene_id: sceneId,
                project_id: projectId,
                background,
              },
            };
          }),
          false,
          false
        );

        promises
          .then((res) => {
            if (res)
              Promise.all(res)
                .then(() => {
                  setIsLoading(false);
                  onClose();
                })
                .catch((err) => {
                  console.log(err);
                  setIsLoading(false);
                  onClose();
                });
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            onClose();
          });
      }
    } else {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Modal size="2xl" onClose={onClose} isOpen={!!modal} isCentered>
      <ModalOverlay bg="#23232362" backdropFilter="blur(5px)" />
      <ModalContent p={0}>
        <ModalCloseButton zIndex={10} />
        <ModalHeader>Choose Import Scope</ModalHeader>
        <ModalBody>
          <RadioGroup
            defaultValue={selected}
            onChange={(value) => {
              setSelected(value);
            }}
          >
            <Stack>
              <Radio value="1">Import to the currently active scene only</Radio>
              <Radio value="2">Import to all scenes within this storyline</Radio>
            </Stack>
          </RadioGroup>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button tabIndex={1} onClick={onClick} isLoading={isLoading}>
            Import
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ForegroundModal;
