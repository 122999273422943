import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
  Stack,
  Text,
  Switch,
  IconButton,
  Flex,
  Button,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { ModalType } from 'src/types';
import { closeModal } from 'src/store/reducers/modals';
import { useState } from 'react';
import { removeIntegrationAuth, toggleAutoSync } from 'src/store/reducers/integrations';
import { DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import { deleteIntegrationAuthAPI } from 'src/apis/integrations';

const FigmaImportSettings = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const figmaAuth = useAppSelector((store) => store.integrations.auth['figma']);
  const autoSyncEnabled = useAppSelector((store) => store.integrations.sync?.['figma']);
  const modal = useAppSelector((store) => store.modal.active[ModalType.figmaImportSettings]);

  const onClose = () => {
    dispatch(closeModal(ModalType.figmaImportSettings));
  };

  const handleAutoSyncToggle = () => {
    dispatch(toggleAutoSync('figma'));
  };

  const handleDisconnect = async () => {
    if (figmaAuth.id) {
      try {
        setIsLoading(true);
        await deleteIntegrationAuthAPI(figmaAuth.id);
        onClose();
        dispatch(removeIntegrationAuth('figma'));
        dispatch(closeModal(ModalType.figmaImport));
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const isOpen = !!modal;

  if (!isOpen) return null;

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" alignItems="center" display="flex" gap={4}>
          <SettingsIcon />
          Figma Settings
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={5}>
          <Stack spacing={2}>
            <Flex justifyContent="space-between" align="center">
              <Text fontSize="medium" m={0}>
                Enable auto sync
              </Text>
              <Switch size="md" defaultChecked={autoSyncEnabled} onChange={handleAutoSyncToggle} />
            </Flex>
            <Flex justifyContent="space-between" align="center">
              <Text m={0} fontSize="medium" color="red.400">
                Remove Figma Integration
              </Text>
              <IconButton
                aria-label="remove figma integration"
                color="red.400"
                size="sm"
                variant="ghost"
                icon={<DeleteIcon />}
                isLoading={isLoading}
                isDisabled={isLoading}
                onClick={handleDisconnect}
              />
            </Flex>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FigmaImportSettings;
