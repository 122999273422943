import { Flex, Text } from '@chakra-ui/react';
import { ChangeEventHandler } from 'react';

interface ColorInputProps {
  value: string;
  name?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  position?: 'start' | 'end';
}

const ColorInput = ({ value, name = 'color', onChange, position = 'start' }: ColorInputProps) => {
  return (
    <Flex
      align="center"
      gap={2}
      w="fit-content"
      flexDirection={position === 'end' ? 'row-reverse' : 'row'}
    >
      <Text fontSize="small" m={0}>
        {value}
      </Text>
      <input type="color" name={name} value={value} onChange={onChange} />
    </Flex>
  );
};

export default ColorInput;
