import { cloneElement, ReactElement, Suspense } from 'react';
import { useAppSelector } from 'src/store/reducers/hook';
import type { SceneObjectPropsType } from 'src/types';

interface SceneObjectProps extends Partial<SceneObjectPropsType> {
  id: string;
  children: ReactElement;
}
const PreviewSceneObject = ({ id, children, ...restProps }: SceneObjectProps) => {
  const sceneObject = useAppSelector((store) => store.sceneViewer.entities[id]);

  if (sceneObject.backendProperties.parent_group_id !== null) {
    return null;
  }

  return (
    <Suspense key={sceneObject.id}>
      {cloneElement(children, {
        ...restProps,
        config: sceneObject,
      })}
    </Suspense>
  );
};

export default PreviewSceneObject;
