import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  metadata: {} as Record<string, any>,
};

const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    setMetadatas: (state, { payload }) => {
      state.metadata = payload.reduce((acc: Record<string, any>, item: any) => {
        acc[item.entity_id] = item;
        return acc;
      }, {});
    },

    deleteMetadata: (state, { payload: { id } }) => {
      delete state.metadata[id];
    },

    addMetadata: (state, { payload }) => {
      state.metadata[payload.entity_id] = payload;
    },
  },
});

export const { setMetadatas, addMetadata, deleteMetadata } = metadataSlice.actions;

export default metadataSlice.reducer;
