// import { ControllerPropertyList } from './ControllerPropertyList';
import { useMemo } from 'react';
import { useAppSelector } from 'src/store/reducers/hook';
import store from 'src/store/store';
import { SupportedControllerModels, SupportedSceneObjectTypes } from 'src/types';
import { findChildrenOfType } from 'src/utils/tree';
import { HandPropertyList } from './HandPropertyList';

export const HandView = (props: {
  config: any;
  parentViewport: string;
  handleHandStateChange: Function;
}) => {
  return (
    <div className="handPreview">
      <HandProperties
        model={props.config.type}
        id={props.config.id}
        handleControllerStateChange={props.handleHandStateChange}
      />
    </div>
  );
};

const HandProperties = (props: {
  model: SupportedControllerModels;
  id: string;
  handleControllerStateChange: Function;
}) => {
  const sceneAssetIds = useAppSelector((store) => store.sceneViewer.ids);

  const controllers = useMemo(() => {
    const sceneAssets = Object.values(store.getState().sceneViewer.entities);
    const controllerAssets = findChildrenOfType(
      sceneAssets,
      props.id,
      SupportedSceneObjectTypes.controller
    );

    return controllerAssets;
  }, [sceneAssetIds]);

  return (
    <>
      {controllers.map((controller: any, index) => {
        const metadata = controller.backendProperties.metadata;

        return (
          <HandPropertyList
            key={index}
            action={metadata.actionConfig[metadata.subType]}
            type={metadata.type}
            parentController={metadata.subType}
            handleHandStateChange={props.handleControllerStateChange}
          />
        );
      })}
    </>
  );
};
