import {
  Flex,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Center,
} from '@chakra-ui/react';
import { MdLogout, MdSettings } from 'react-icons/md';
import { BsCardImage } from 'react-icons/bs';

import { useEffect, useState } from 'react';
import { FaRegClock } from 'react-icons/fa';
import { useAppSelector, useAppStore } from 'src/store/reducers/hook';
import { getAllProjectsAPI } from 'src/apis/projects';
import NewProjectButton from 'src/features/Projects/components/NewProject.button';
import { useAuth } from 'src/hooks';
import { UserAvatar } from '../components/Avatar';
import { Tables } from 'src/types';
import FloatingContainer from 'src/components/FloatingContainer';
import WorkspaceSettings from 'src/components/WorkspaceSettings';
import WorkspaceSelector from 'src/components/WorkspaceSelector';
import UserAssets from 'src/components/UserAssets';
import Recents from 'src/features/Projects/components/Recents';
import Invite from 'src/features/Invite';

function Projects() {
  const { logout } = useAuth();
  const { clearStores } = useAppStore();
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState<Tables<'projects'>[]>([]);

  const currentUser = useAppSelector((state) => state.app.currentUser);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    const workspace = currentUser?.workspace_id;
    if (workspace) {
      getAllProjectsAPI(workspace)
        .then((data: any[]) => setProjects(data ?? []))
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    }
  }, [currentUser?.workspace_id]);

  const onSelectIndex = (event: any) => {
    setSelectedIndex(event);
  };

  const handleLogout = () => {
    logout();
    clearStores();
  };

  return (
    <Flex backgroundColor="rgba(28,28,28,255)" h="100vh" color="white" flexDirection="column">
      <Flex p={2} paddingLeft={6} gap={4} paddingRight={6} justifyContent="space-between">
        <WorkspaceSelector />
        <FloatingContainer pl={1} gap={2}>
          <Center gap={2}>
            <NewProjectButton onCreate={(data) => setProjects((s) => [...s, data])} />
            {currentUser?.workspace_id && <Invite id={currentUser.workspace_id} type="workspace" />}
          </Center>

          <Menu>
            <MenuButton
              as={IconButton}
              pl={1}
              pr={3}
              aria-label="Options"
              icon={
                <Center gap={3}>
                  <UserAvatar
                    id={currentUser?.id}
                    src={currentUser?.avatar_url}
                    name={currentUser?.fullname ?? currentUser?.username}
                  />
                  <Text fontSize="sm" m={0}>
                    {currentUser?.fullname ?? currentUser?.username}
                  </Text>
                </Center>
              }
              borderRadius="full"
              variant="ghost"
            />
            <MenuList p={2}>
              <MenuItem rounded="md" color="red.400" icon={<MdLogout />} onClick={handleLogout}>
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </FloatingContainer>
      </Flex>
      <Flex p={3} paddingTop={10}>
        <Tabs
          isLazy
          onChange={onSelectIndex}
          isManual={true}
          w="100vw"
          display="flex"
          orientation="vertical"
          variant="unstyled"
          defaultIndex={selectedIndex}
        >
          <TabList className="tablist">
            <Tab value={0} h={10} w="full">
              <Flex
                className={
                  selectedIndex == 0
                    ? 'projects_page_button project_page_seclectedIndex'
                    : 'projects_page_button'
                }
                alignItems="center"
              >
                <FaRegClock />
                <Flex>
                  <span>Recently added</span>
                </Flex>
              </Flex>
            </Tab>

            <Tab value={2} h={10} w="full">
              <Flex
                className={
                  selectedIndex == 1
                    ? 'projects_page_button project_page_seclectedIndex'
                    : 'projects_page_button'
                }
                alignItems="center"
                justifyContent="center"
              >
                <BsCardImage />
                <Flex>
                  <span>Assets</span>
                </Flex>
              </Flex>
            </Tab>
            <Tab value={2} h={10} w="full">
              <Flex
                className={
                  selectedIndex == 2
                    ? 'projects_page_button project_page_seclectedIndex'
                    : 'projects_page_button'
                }
                alignItems="center"
                justifyContent="center"
              >
                <MdSettings />
                <Flex>
                  <span>Settings</span>
                </Flex>
              </Flex>
            </Tab>
          </TabList>

          <Flex flexGrow="1" px={3}>
            <TabPanels>
              <TabPanel>
                <Recents isLoading={isLoading} projects={projects} setProjects={setProjects} />
              </TabPanel>
              <TabPanel>
                <Flex justifyContent="space-between">
                  <Text fontSize="30px">Assets</Text>
                </Flex>
                <UserAssets type="workspace" onClick={(asset: string) => {}} />
              </TabPanel>
              <TabPanel>
                <Flex flexDirection="column">
                  <WorkspaceSettings />
                </Flex>
              </TabPanel>
            </TabPanels>
          </Flex>
        </Tabs>
      </Flex>
    </Flex>
  );
}

export default Projects;
