import { createSlice, current } from '@reduxjs/toolkit';

import {
  SceneObject,
  bboxInfoInterface,
  gizmoInfoInterface,
  refTransformsInterface,
  sceneObjectRefInterface,
  cameraConfigInterface,
  SceneObjectMetadata,
  SupportedSceneObjectTypes,
  SceneEvent,
} from 'src/types';
import { excludeKeys } from 'src/utils/helper';
import * as THREE from 'three';

type SelectedObject = {
  id: string;
  type: SupportedSceneObjectTypes;
};

const initialState = {
  ids: [] as Array<string>,
  entities: {} as Record<string, SceneObject<SceneObjectMetadata>>,

  currentMaterial: {} as any,
  materials: {} as Record<string, any>,
  events: {} as Record<string, SceneEvent>,
  selectedObjects: [] as SelectedObject[],
  bboxInfo: {
    position: [0.0, 0.0, 0.0],
    rotation: [0.0, 0.0, 0.0],
    scale: [1.0, 1.0, 1.0],
    refBBox: undefined,
  } as bboxInfoInterface,
  gizmoInfo: {
    position: [0.0, 0.0, 0.0],
    rotation: [0.0, 0.0, 0.0],
    scale: [1.0, 1.0, 1.0],
    show: [false, false, false],
  } as gizmoInfoInterface,
  refTransforms: {
    gizmoRef: new THREE.Matrix4(),
    bboxRef: new THREE.Matrix4(),
    sceneObjectsRef: [] as sceneObjectRefInterface[],
  } as refTransformsInterface,
  showLoading: true as boolean,
  showEvents: {
    show: false,
    id: '',
    index: '',
  },
  currentViewport: null as null | string,
  firstFetchDone: {} as Record<string, number>,
  cameraConfig: {
    position: [-5.0, 10.0, 5.0],
    target: [0.0, 0.0, 0.0],
    upVec: [0.0, 1.0, 0.0],
  } as cameraConfigInterface,
};

export type SceneViewerKeys = Array<keyof typeof initialState>;

const sceneViewerSlice = createSlice({
  name: 'sceneViewer',
  initialState,
  reducers: {
    addSceneObject: (state, { payload: { sceneObject } }) => {
      state.ids.push(sceneObject.id);
      state.entities[sceneObject.id] = sceneObject;
    },

    setSceneObjectList: (state, { payload }) => {
      state.entities = payload.reduce(
        (acc: Record<string, SceneObject<SceneObjectMetadata>>, item: any) => {
          acc[item.id] = item;
          return acc;
        },
        {}
      );

      state.ids = payload.map((item: SceneObject<SceneObjectMetadata>) => item.id);
    },

    updateSceneObject: (state, { payload: { id, type, sceneObjectChanges } }) => {
      state.entities[id].localProperties = {
        ...state.entities[id].localProperties,
        ...sceneObjectChanges.localProperties,
      };

      state.entities[id].backendProperties = {
        ...state.entities[id].backendProperties,
        ...sceneObjectChanges.backendProperties,
      };
    },

    removeSceneObject: (state, { payload: { id, type } }) => {
      state.ids = state.ids.filter((commentId) => commentId !== id);
      delete state.entities[id];

      state.selectedObjects = state.selectedObjects.filter((item) => item.id !== id);
    },
    addSceneEvents: (state, { payload: { event } }) => {
      state.events[event.id] = event;
    },
    setSceneEvents: (state, { payload }) => {
      state.events = payload.reduce((acc: Record<string, SceneEvent>, item: SceneEvent) => {
        if (item.id) acc[item.id] = item;
        return acc;
      }, {});
    },

    updateSceneEvents: (state, { payload: { id, changes } }) => {
      state.events[id] = {
        ...state.events[id],
        ...changes,
      };
    },

    removeSceneEvents: (state, { payload: { id } }) => {
      delete state.events[id];
    },

    cleanSceneViewer: (
      state,
      { payload: keysToExclude = [] }: { payload: Array<keyof typeof initialState> }
    ) => {
      const initState = excludeKeys(initialState, keysToExclude);
      return { ...state, ...initState };
    },

    updateBBoxInfo: (state, { payload: { changes } }) => {
      state.bboxInfo = {
        ...state.bboxInfo,
        ...changes,
      };
    },

    setMaterials: (state, { payload }) => {
      state.materials = payload.reduce((acc: Record<string, any>, item: any) => {
        acc[item.id] = item;
        return acc;
      }, {});
    },

    deleteMaterial: (state, { payload: { id } }) => {
      delete state.materials[id];
    },

    addMaterial: (state, { payload }) => {
      state.materials[payload.id] = payload;
    },

    updateGizmoInfo: (state, { payload: { changes } }) => {
      state.gizmoInfo = {
        ...state.gizmoInfo,
        ...changes,
      };
    },

    updateRefTrasforms: (state, { payload: { changes } }) => {
      state.refTransforms = {
        ...state.refTransforms,
        ...changes,
      };
    },

    updateShowLoading: (state, { payload: loading }) => {
      state.showLoading = loading;
    },
    updateShowEvents: (state, { payload: showEvents }) => {
      state.showEvents = showEvents;
    },
    updateCurrentViewport: (state, { payload: newViewport }) => {
      state.currentViewport = newViewport;
    },

    updateFirstFetchDone: (state, { payload: sceneFetched }) => {
      state.firstFetchDone[sceneFetched] = Date.now();
    },

    updateCameraConfig: (state, { payload: { position, target, upVec } }) => {
      state.cameraConfig = {
        position: position,
        target: target,
        upVec: upVec,
      };
    },

    debugPrintState: (state) => {
      console.log(current(state));
    },

    setSelectedObjects: (state, { payload: selectedObjects }) => {
      state.selectedObjects = selectedObjects;
    },

    setCurrentMaterial: (state, { payload }) => {
      state.currentMaterial = payload;
    },

    updateCurrentMaterial: (state, { payload }) => {
      const { show, ...restPayload } = payload;
      state.currentMaterial = {
        ...state.currentMaterial,
        show,
        current: {
          ...state.currentMaterial.current,
          ...restPayload,
        },
      };
    },
  },
});

export const {
  addSceneObject,
  addSceneEvents,
  updateSceneObject,
  updateSceneEvents,
  removeSceneObject,
  removeSceneEvents,
  cleanSceneViewer,
  updateBBoxInfo,
  updateGizmoInfo,
  updateRefTrasforms,
  updateShowLoading,
  updateShowEvents,
  updateCurrentViewport,
  updateFirstFetchDone,
  updateCameraConfig,
  debugPrintState,
  setSceneObjectList,
  setSelectedObjects,
  setMaterials,
  addMaterial,
  deleteMaterial,
  setCurrentMaterial,
  updateCurrentMaterial,
  setSceneEvents,
} = sceneViewerSlice.actions;

export default sceneViewerSlice.reducer;
