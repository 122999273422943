import { Box, Flex } from '@chakra-ui/react';
import { useMemo } from 'react';

import { useAppSelector } from 'src/store/reducers/hook';
import { setCurrentMaterial } from 'src/store/reducers/sceneViewer';
import store from 'src/store/store';
import { getAssetUrlWithToken } from 'src/utils/aws';
import { getPublicAssetURL } from 'src/utils/public';

interface MaterialProps {
  id: string;
  type: 'materials' | 'entities';
  material: any;
  data: any;
}

const MaterialRowContainer = ({ id, material, data }: MaterialProps) => {
  const currentMaterial = useAppSelector((store) => store.sceneViewer.currentMaterial);

  const assetUrl = useMemo(() => {
    const fileName = material?.thumbnail ?? material?.map?.map.src;

    if (fileName?.startsWith('blob') || fileName?.startsWith('data')) {
      return fileName;
    }

    const isPublic = material.public || material.thumbnail?.split('/')?.length > 1;

    if (isPublic) {
      const publicBucketURL = getPublicAssetURL();
      const srcKey = 'materials/' + material.tag + '/' + material.thumbnail;

      return `${publicBucketURL}/${srcKey}`;
    }

    return getAssetUrlWithToken(fileName, 'textures');
  }, [material]);

  return (
    <Flex
      key={id}
      onClick={() =>
        store.dispatch(
          setCurrentMaterial({
            show: currentMaterial.current?.id === id ? !currentMaterial.show : true,
            current: data,
          })
        )
      }
      padding={1}
      paddingLeft={2}
      gap={1}
      alignItems={'center'}
      justifyContent={'start'}
      rounded="md"
      transition="all 0.2s ease-in-out"
      backgroundColor={
        currentMaterial.current?.id === id && currentMaterial.show ? '#3E3B3E' : 'transparent'
      }
      _hover={{ backgroundColor: '#3E3B3E' }}
    >
      <Box maxW="20px" borderRadius="100%" maxH="20px" overflow="hidden">
        <div
          style={{
            width: '20px',
            height: '20px',
            backgroundSize: 'cover',
            backgroundColor: material?.map?.map.src === null ? material.color : 'transparent',
            backgroundImage:
              material.thumbnail || material?.map?.map.src ? `url(${assetUrl})` : 'none',
            transform: material.thumbnail ? 'scale(1.3)' : 'none',
          }}
        />
      </Box>
      <label className="label">{material.name}</label>
    </Flex>
  );
};

const MaterialSelector = (props: MaterialProps) => {
  const material = useAppSelector((store) => store.sceneViewer.materials[props.id]);

  return <MaterialRowContainer {...props} type="materials" material={material} />;
};

const AssetMaterialSelector = (props: MaterialProps) => {
  const material = useAppSelector((store) => store.sceneViewer.entities[props.id])?.localProperties
    ?.material_base;

  return <MaterialRowContainer {...props} type="entities" material={material} />;
};

const MaterialRow = (props: any) => {
  if (props.data.type === 'materials') return <MaterialSelector {...props} />;

  return <AssetMaterialSelector {...props} />;
};

export default MaterialRow;
