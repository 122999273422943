import { Grid, OrbitControls, PerspectiveCamera, Stage } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ARButton, XR, Interactive, XRInteractionEvent } from '@react-three/xr';
import { useAppSelector } from 'src/store/reducers/hook';
// import store from 'src/store/store';
import { stringToColor } from 'src/utils/colors';
import { sceneRefInterface } from './context';
import { useSceneViewer } from './hooks/useSceneViewer';
// import WASDcontroles from './WASDcontroles';
import PreviewScene from './preview/PreviewScene';
import { useHitTest, useXR } from '@react-three/xr';
import store from 'src/store/store';
import {
  SceneEventTriggerTargets,
  SceneObjectActionTypes,
  SupportedSceneObjectTypes,
} from 'src/types';
import { useLocation } from 'react-router-dom';
import { calculateCameraConfig } from './helpers';
import { useThree } from '@react-three/fiber';
// import { openContextMenu } from 'src/store/reducers/modals';
// import useSceneMenu from './hooks/useSceneMenu';
// import { setActiveTool } from 'src/store/reducers/InstanceReducer';
// import { InstanceToolType } from 'src/types';

const XRcontainer = forwardRef<sceneRefInterface, any>((props, ref) => {
  // const dispatch = useAppDispatch();

  const orbitControlsRef = useRef<any>();
  const cameraRef = useRef<any>();
  const { isPresenting } = useXR();
  const reticleRef = useRef<any>();
  const [grpPosition, setGrpPosition] = useState<[x: number, y: number, z: number]>([0, 0, 0]);
  // const { notifyOthersOnCameraChange, onSelectObject } = useSceneViewer();

  useHitTest((hitMatrix, hit) => {
    hitMatrix.decompose(
      reticleRef.current?.position,
      reticleRef.current?.quaternion,
      reticleRef.current?.scale
    );

    reticleRef.current?.rotation.set(-Math.PI / 2, 0, 0);
  });
  const placeModel = (e: XRInteractionEvent) => {
    let position = e.intersection?.object.position.clone();
    if (position) setGrpPosition([position.x, position.y, position.z]);
  };
  return (
    <>
      {isPresenting && (
        <Interactive onSelect={placeModel}>
          <mesh ref={reticleRef} rotation-x={-Math.PI / 2}>
            <ringGeometry args={[0.1, 0.25, 32]} />
            <meshStandardMaterial color={'white'} />
          </mesh>
        </Interactive>
      )}

      {/* <Stage
        shadows
        adjustCamera={false}
        center={{
          disable: true,
        }}
      > */}
      {/* <PreviewScene addCamera={true} ref={ref} /> */}
      {/* <directionalLight castShadow intensity={2.0} /> */}
      {/* <PerspectiveCamera makeDefault position={[-5.0, 10.0, 5.0]} near={0.025} ref={cameraRef} /> */}

      {!isPresenting && <PreviewScene addCamera={true} ref={ref} />}
      {isPresenting && (
        <group position={grpPosition}>
          <PreviewScene addCamera={true} ref={ref} />
        </group>
      )}
      {/* <Ground /> */}

      <OrbitControls
        ref={orbitControlsRef}
        makeDefault
        // onChange={(e) => {
        //   const isFollowing = store.getState().collaboration.following;
        //   if (cameraRef.current && !isFollowing) {
        //     const camera = cameraRef.current;
        //     notifyOthersOnCameraChange({
        //       position: [camera.position.x, camera.position.y, camera.position.z],
        //       rotation: [
        //         camera.rotation.x,
        //         camera.rotation.y,
        //         camera.rotation.z,
        //         camera.rotation.w,
        //       ],
        //     });
        //   }
        // }}
      />
      {/* </Stage> */}
    </>
  );
});
const MainPreviewPanel = forwardRef<sceneRefInterface, any>((props, ref) => {
  // const dispatch = useAppDispatch();
  const containerRef = useRef<any>();
  const sceneObjectList = store.getState().sceneViewer.entities;
  const { handleSceneObjectAction } = useSceneViewer();
  const sceneEvents = store.getState().sceneViewer.events;
  const location = useLocation();
  // When clicked on empty space in Canvas
  const onCanvasClicked = (event: any) => {
    const Events = Object.values(sceneEvents).filter(
      (event) => event.target === SceneEventTriggerTargets.scene && event.trigger === 'click'
    );

    Events.forEach((event) => {
      if (event.targetObject === null) return;

      const targetObject = sceneObjectList[event.targetObject];

      if (!targetObject.localProperties.events) return;

      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: targetObject.id,
          type: targetObject.type,
          localProperties: {
            ...targetObject.localProperties,
            events: targetObject.localProperties.events.map((ev) => {
              if (ev.id === event.id) {
                return {
                  ...ev,
                  play: true,
                };
              }
              return ev;
            }),
          },
          backendProperties: targetObject.backendProperties,
        },
      ]);
    });
  };
  const onHover = (event: any) => {
    const Events = Object.values(sceneEvents).filter(
      (event) => event.target === SceneEventTriggerTargets.scene && event.trigger === 'hover'
    );

    Events.forEach((event) => {
      if (event.targetObject === null) return;

      const targetObject = sceneObjectList[event.targetObject];

      if (!targetObject.localProperties.events) return;

      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: targetObject.id,
          type: targetObject.type,
          localProperties: {
            ...targetObject.localProperties,
            events: targetObject.localProperties.events.map((ev) => {
              if (ev.id === event.id) {
                return {
                  ...ev,
                  play: true,
                };
              }
              return ev;
            }),
          },
          backendProperties: targetObject.backendProperties,
        },
      ]);
    });
  };
  useEffect(() => {}, [location.pathname, sceneEvents]);
  return (
    <div
      className="mainPanel"
      ref={containerRef}
      onClick={onCanvasClicked}
      onPointerOver={onHover}
      id="mainPanel"
      style={{
        transition: 'border 200ms ease-in',
        borderRadius: props.isFollowing ? '1rem' : 'none',
        border: props.isFollowing
          ? `5px solid ${stringToColor(props.isFollowing)}`
          : '5px solid transparent',
      }}
    >
      <ARButton
        sessionInit={{
          requiredFeatures: ['hit-test'],
        }}
      />
      <Canvas
        className="mainViewer"
        eventSource={containerRef}
        // style={{ background: '#212121' }}
        gl={{
          antialias: true,
          powerPreference: 'high-performance',
        }}
        onCreated={({ gl }) => {
          gl.setPixelRatio(window.devicePixelRatio);
        }}
        flat
        shadows
      >
        <XR>
          <XRcontainer ref={ref} />
        </XR>
      </Canvas>
    </div>
  );
});

export default MainPreviewPanel;
