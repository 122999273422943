import PropertyField from './PropertyField';
import PropertyHeading from './PropertyHeading';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { radToDeg, degToRad } from 'three/src/math/MathUtils';
import {
  AssetObjectAnimation,
  HeadObject,
  SceneEvent,
  SceneEventActionTypes,
  SceneEventTriggerTargets,
  SceneObjectActionTypes,
  SupportedSceneObjectTypes,
  ViewportObject,
} from 'src/types';
import { InteractionsProperties } from './interactionsProperties/InteractionsProperties';
import { useAppSelector } from 'src/store/reducers/hook';
import { useSceneViewer } from '../hooks/useSceneViewer';
import { getSceneObject, getWorldTransform, toLocalTransform } from '../helpers';
import { Box, Flex, IconButton, Stack } from '@chakra-ui/react';
import store from 'src/store/store';
import short from 'short-uuid';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { CloseIcon, PlusSquareIcon } from '@chakra-ui/icons';
import { UIcon } from 'src/components/icons';
// scene_scale is used to scale the grid to the size of the scene. (1 = cm, 0.01 = m)
const scene_scale = 1.0;

export default function CameraProperties(props: { id: string }) {
  const { handleSceneObjectAction } = useSceneViewer();
  const viewportSceneObject = useAppSelector(
    (store) => store.sceneViewer.entities[props.id]
  ) as ViewportObject;

  if (!viewportSceneObject) return null;

  const onChange = (object: any) => {
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: object.id,
        type: object.type,
        localProperties: {},
        backendProperties: {
          ...object,
        },
      },
    ]);
  };

  return (
    <Stack spacing={0.5}>
      <ViewportProperties viewport={viewportSceneObject} onChange={onChange} />
      <HeadProperties id={viewportSceneObject.id} onChange={onChange} />
      <InteractionContainer id={viewportSceneObject.id} />
    </Stack>
  );
}

type Props = {
  id: string;
  onChange?: (object: any) => void;
};

const ViewportProperties = ({
  viewport,
  onChange,
}: { viewport: ViewportObject } & Partial<Props>) => {
  const handleViewportPropertyChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: string,
    propIndex: number
  ) => {
    const objectCopy = structuredClone(viewport.backendProperties);
    const worldTransform = getWorldTransform(objectCopy.id);
    const selectedValue = event.target.value;

    if (field === 'position') {
      const newPosition = [...objectCopy.position];
      newPosition[propIndex] = parseFloat(selectedValue) / scene_scale;

      const localTransform = toLocalTransform(objectCopy.id, {
        position: newPosition as [number, number, number],
        rotation: worldTransform.rotation,
        scale: worldTransform.scale,
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'rotation') {
      const newRotation = [...objectCopy.rotation];
      newRotation[propIndex] = degToRad(parseFloat(selectedValue));
      // objectCopy.rotation = newRotation as any;

      const localTransform = toLocalTransform(objectCopy.id, {
        position: worldTransform.position,
        rotation: newRotation as [number, number, number],
        scale: worldTransform.scale,
      });
      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'label') {
      const newLabel = selectedValue;
      if (newLabel !== 'viewport-primary') {
        objectCopy.metadata.label = newLabel;
      }
    }

    onChange?.(objectCopy);
  };
  const { handleSceneObjectAction, handleShowEvents, handleSceneEventsAction } = useSceneViewer();
  const showEvents = useAppSelector((store) => store.sceneViewer.showEvents);
  const [assetStates, setAssetStates] = useState<AssetObjectAnimation['states']>([]);
  const assetEvents = Object.values(useAppSelector((store) => store.sceneViewer.events)).filter(
    (event) => event.triggerObject === viewport.id
  );
  const assetTargetEvents = Object.values(
    useAppSelector((store) => store.sceneViewer.events)
  ).filter((event) => event.targetObject === viewport.id);
  useEffect(() => {
    if (viewport.backendProperties.animation) {
      setAssetStates(viewport.backendProperties.animation.states);
    }
  }, [viewport.id]);
  if (!viewport) return null;

  const worldTransform = getWorldTransform(viewport.id);
  const isPrimary = viewport.localProperties.isPrimary;

  const handleAddState = (
    newState: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    let addState: {
      name: string;
      position: [number, number, number];
      rotation: [number, number, number];
      scale: [number, number, number];
      material_base: any;
    } = {
      name: newState,
      position: viewport.backendProperties.position,
      rotation: viewport.backendProperties.rotation,
      scale: viewport.backendProperties.scale,
      material_base: null,
    };

    setAssetStates([...assetStates, addState]);
    console.log(assetStates.length);
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: viewport.id,
        type: viewport.type,
        localProperties: viewport.localProperties,
        backendProperties: {
          ...viewport.backendProperties,
          animation: {
            ...viewport.backendProperties.animation,
            states: [...assetStates, addState],
            currentState: assetStates.length,
          },
        },
      },
    ]);
  };
  const handleDeleteState = (index: number) => {
    const pendingUpdates: any[] = [];
    assetTargetEvents.forEach((event) => {
      pendingUpdates.push({
        ...event,
        sequence: {
          ...event.sequence,
          timeline: event.sequence.timeline.map((state) =>
            state.name === assetStates[index].name
              ? {
                  name: 'base',
                  duration: 1,
                  delay: 0,
                }
              : state
          ),
        },
      });
    });
    const newStates = assetStates.filter((state, i) => i !== index);
    setAssetStates(newStates);
    let currentState = viewport.backendProperties.animation.currentState;
    let p = viewport.backendProperties.position;
    let r = viewport.backendProperties.rotation;
    let s = viewport.backendProperties.scale;
    let material_base = viewport.localProperties.material_base;
    if (currentState === index) {
      let getState = viewport.backendProperties.animation.states[currentState - 1];
      p = getState.position;
      r = getState.rotation;
      s = getState.scale;
      material_base = getState.material_base;
    }
    console.log(p, r, s);
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: viewport.id,
        type: viewport.type,
        localProperties: {
          ...viewport.localProperties,
          material_base: material_base,
        },
        backendProperties: {
          ...viewport.backendProperties,
          position: p,
          rotation: r,
          scale: s,
          animation: {
            ...viewport.backendProperties.animation,
            states: newStates,
            currentState: currentState === index ? currentState - 1 : currentState,
          },
          metadata: {
            ...viewport.backendProperties.metadata,
            material_base: material_base,
          },
        },
      },
    ]);

    handleSceneEventsAction(SceneEventActionTypes.update, pendingUpdates);
  };
  const handleAddEvent = (
    newEvent: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    const projectId = store.getState().app.projectId;
    const scene_id = store.getState().instance.current_sceneId;
    const workspace = store.getState().app.currentUser?.workspace_id;
    if (!projectId || !scene_id || !workspace) {
      return;
    }
    let addEvent: SceneEvent = {
      project_id: projectId,
      scene_id: scene_id,
      workspace_id: workspace,
      name: newEvent,
      triggerObject: viewport.id,
      sequence: {
        timeline: [
          {
            name: viewport.backendProperties.animation.states[0].name,
            duration: 1,
            delay: 0,
          },
          {
            name: viewport.backendProperties.animation.states[0].name,
            duration: 1,
            delay: 0,
          },
        ],
      },
      targetObject: viewport.id,
      trigger: 'start',
      target: SceneEventTriggerTargets.object,
    };
    handleSceneEventsAction(SceneEventActionTypes.insert, [addEvent]);
  };
  const handleDeleteEvent = (event: SceneEvent) => {
    handleSceneEventsAction(SceneEventActionTypes.delete, [event]);
  };
  const handleStateClick = (index: number) => {
    let getState = viewport.backendProperties.animation.states[index];
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: viewport.id,
        type: viewport.type,
        localProperties: {
          ...viewport.localProperties,
          material_base: getState.material_base,
        },
        backendProperties: {
          ...viewport.backendProperties,
          position: getState.position,
          rotation: getState.rotation,
          scale: getState.scale,
          animation: {
            ...viewport.backendProperties.animation,
            currentState: index,
          },
          metadata: {
            ...viewport.backendProperties.metadata,
            material_base: getState.material_base,
          },
        },
      },
    ]);
  };
  return (
    <div>
      <Accordion allowMultiple>
        <AccordionItem gap={0} border={'none'}>
          <AccordionButton padding={0}>
            <Box as="span" flex="1" textAlign="left">
              <PropertyHeading>States</PropertyHeading>
            </Box>
            <IconButton
              aria-label="save"
              size="xs"
              icon={<UIcon name="add" />}
              onClick={(e) => handleAddState(`state${assetStates.length}`, e)}
            />
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel maxHeight={'120px'} overflowY={'scroll'} padding={0} margin={0}>
            {assetStates.map((state, index) => (
              <Flex
                key={state.name}
                padding={1}
                paddingLeft={2}
                gap={1}
                alignItems={'center'}
                rounded={5}
                justifyContent={'start'}
                backgroundColor={
                  index === viewport.backendProperties.animation.currentState
                    ? '#3E3B3E'
                    : 'transparent'
                }
                _hover={{ backgroundColor: '#3E3B3E' }}
                sx={{
                  '&:hover .second-child': {
                    display: 'block',
                  },
                }}
              >
                <div onClick={() => handleStateClick(index)} style={{ flexGrow: '1' }}>
                  <label className="label"> {state.name}</label>
                </div>
                {index !== 0 && (
                  <CloseIcon
                    cursor={'pointer'}
                    backgroundColor={'#2c2a2c'}
                    rounded={100}
                    p={1}
                    w={4}
                    display={'none'}
                    marginRight={2}
                    className="second-child"
                    onClick={() => handleDeleteState(index)}
                  />
                )}
              </Flex>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Accordion allowMultiple>
        <AccordionItem gap={0} border={'none'}>
          <AccordionButton padding={0}>
            <Box as="span" flex="1" textAlign="left">
              <PropertyHeading>Events</PropertyHeading>
            </Box>
            <IconButton
              aria-label="save"
              size="xs"
              icon={<UIcon name="add" />}
              onClick={(e) => handleAddEvent(`event${assetEvents.length}`, e)}
            />
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel maxHeight={'120px'} overflowY={'scroll'} padding={0} margin={0}>
            {assetEvents &&
              assetEvents.length !== 0 &&
              assetEvents.map((event, index) => (
                <Flex
                  padding={1}
                  paddingLeft={2}
                  gap={1}
                  w={'100%'}
                  alignItems={'center'}
                  justifyContent={'start'}
                  rounded={5}
                  _hover={{ backgroundColor: '#3E3B3E' }}
                  sx={{
                    '&:hover .second-child': {
                      display: 'block',
                    },
                  }}
                >
                  <div
                    onClick={() => {
                      if (event.id) handleShowEvents(!showEvents.show, viewport.id, event.id);
                    }}
                    style={{ flexGrow: '1' }}
                  >
                    <label className="label">{event.name}</label>
                  </div>

                  <CloseIcon
                    cursor={'pointer'}
                    backgroundColor={'#2c2a2c'}
                    rounded={100}
                    p={1}
                    w={4}
                    display={'none'}
                    marginRight={2}
                    className="second-child"
                    onClick={() => handleDeleteEvent(event)}
                  />
                </Flex>
              ))}
            {assetEvents && assetEvents.length === 0 && (
              <>
                <center>
                  <label className="label">No Events</label>
                </center>
              </>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <PropertyHeading>Transform</PropertyHeading>

      <label className="label">Position</label>
      <PropertyField
        value={(worldTransform.position[0] * scene_scale).toFixed(2)}
        field="position"
        instanceIndex={-1}
        propIndex={0}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />
      <PropertyField
        value={(worldTransform.position[1] * scene_scale).toFixed(2)}
        field="position"
        instanceIndex={-1}
        propIndex={1}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />
      <PropertyField
        value={(worldTransform.position[2] * scene_scale).toFixed(2)}
        field="position"
        instanceIndex={-1}
        propIndex={2}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />

      <br />
      <label className="label">Rotation</label>
      <PropertyField
        value={radToDeg(worldTransform.rotation[0]).toFixed(2)}
        field="rotation"
        instanceIndex={-1}
        propIndex={0}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />

      <PropertyField
        value={radToDeg(worldTransform.rotation[1]).toFixed(2)}
        field="rotation"
        instanceIndex={-1}
        propIndex={1}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />

      <PropertyField
        value={radToDeg(worldTransform.rotation[2]).toFixed(2)}
        field="rotation"
        instanceIndex={-1}
        propIndex={2}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />
      <label className="label">Label</label>
      <PropertyField
        value={viewport.backendProperties.metadata.label}
        field="label"
        classNames="fit-width"
        instanceIndex={-1}
        propIndex={0}
        handlePropertyChange={handleViewportPropertyChange}
        type="string"
        disabled={isPrimary}
      />
    </div>
  );
};

export const InteractionContainer = ({ id }: Props) => {
  const assetIds = useAppSelector((store) => store.sceneViewer.ids);

  const interactionObject = useMemo(() => {
    for (let id of assetIds) {
      const asset = store.getState().sceneViewer.entities[id];

      if (
        asset &&
        asset.backendProperties.parent_group_id === id &&
        asset.type === SupportedSceneObjectTypes.interactions
      ) {
        return asset;
      }
    }
  }, [assetIds, id]);

  return <InteractionsProperties config={interactionObject?.backendProperties} parent={id} />;
};

export const HeadProperties = ({ id, onChange }: Props) => {
  const sceneAssetsIds = useAppSelector((store) => store.sceneViewer.ids);

  const sceneObject = useMemo(() => {
    let headObj = null as HeadObject | null;
    sceneAssetsIds.forEach((assetId) => {
      const asset = getSceneObject(assetId);

      if (
        asset &&
        asset.backendProperties.parent_group_id === id &&
        asset.localProperties.children
      ) {
        const child = asset.localProperties.children.find(
          (child) => child.type === SupportedSceneObjectTypes.head
        );

        if (child) {
          headObj = getSceneObject(child.id) as HeadObject;
        }
      }
    });

    return headObj;
  }, [sceneAssetsIds]);

  const handleViewportPropertyChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: string,
    propIndex: number
  ) => {
    if (!sceneObject) return;

    const selectedValue = event.target.value;
    const objectCopy = structuredClone(sceneObject.backendProperties);

    if (field === 'height') {
      const newHeight = parseInt(selectedValue);
      objectCopy.metadata.height = newHeight;
    } else if (field === 'width') {
      const newWidth = parseInt(selectedValue);
      objectCopy.metadata.width = newWidth;
    } else if (field === 'fov') {
      const newFov = parseFloat(selectedValue);
      objectCopy.metadata.fov = newFov;
    }

    onChange?.(objectCopy);
  };

  if (!sceneObject) return null;

  return (
    <div>
      <PropertyHeading>Camera Properties</PropertyHeading>
      <label className="label">Height</label>
      <PropertyField
        value={sceneObject.backendProperties.metadata.height * scene_scale}
        field="height"
        instanceIndex={-1}
        propIndex={0}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />
      <label className="label">Width</label>
      <PropertyField
        value={sceneObject.backendProperties.metadata.width * scene_scale}
        field="width"
        instanceIndex={-1}
        propIndex={0}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />
      <label className="label">FOV</label>
      <PropertyField
        value={sceneObject.backendProperties.metadata.fov.toFixed(2)}
        field="fov"
        instanceIndex={-1}
        propIndex={0}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />
    </div>
  );
};
