import {
  AspectRatio,
  Button,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { ChangeEvent, ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import { set } from 'react-hook-form';
import { toast } from 'react-toastify';
import short from 'short-uuid';
import { useDebouncedCallback } from 'use-debounce';

import { insertMetadataAPI, updateMetadataAPI } from 'src/apis/metadata';
import { useAssetUpload } from 'src/features/SceneViewer/hooks/useAssetUpload';
import { useAppSelector } from 'src/store/reducers/hook';
import { addMetadata } from 'src/store/reducers/metadata';
import store from 'src/store/store';
import { getTextureURL, uploadFileToS3WithProgressToast } from 'src/utils/aws';
import { getUUID } from 'src/utils/ids';
import { getPublicStorage } from 'src/utils/public';

import ColorInput from '../ColorInput';
import { UIcon } from '../icons';
import PropertyField from './propertyPanel/PropertyField';
import PropertyHeading from './propertyPanel/PropertyHeading';

const DefaultSceneProperties = {
  background: {
    color: '#202020',
    opacity: 100,
    enabled: true,
    img: null,
  },
  ambientLight: {
    color: '#ffffff',
    intensity: 2.0,
    enabled: true,
  },
  environment: {
    label: null,
    key: null,
    enabled: true,
  },
  ground: {
    gridEnabled: true,
    shadow: 50,
  },
  effects: {
    ssao: false,
    bloom: false,
    tonemaping: 'none',
  },
};

const SceneProperties = () => {
  const [environments, setEnvironments] = useState([]);

  const sceneId = useAppSelector((store) => store.instance.current_sceneId);

  const metadata = useAppSelector((store) => store.metadata.metadata[sceneId]);

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    const key = e.target.name;

    updateProperties(key, value);
  };

  const updateProperties = (key: string, value: any) => {
    const properties = metadata?.metadata ?? {};

    let meta = {
      background: {
        ...DefaultSceneProperties.background,
        ...properties.background,
      },
      ambientLight: {
        ...DefaultSceneProperties.ambientLight,
        ...properties.ambientLight,
      },
      environment: {
        ...DefaultSceneProperties.environment,
        ...properties.environment,
      },
      ground: {
        ...DefaultSceneProperties.ground,
        ...properties.ground,
      },
      effects: {
        ...DefaultSceneProperties.effects,
        ...properties.effects,
      },
    };

    set(meta, key, value);
    const data = { entity_id: sceneId, metadata: meta } as any;

    if (!metadata) {
      data.id = getUUID();
    } else {
      data.id = metadata.id;
    }

    store.dispatch(addMetadata(data));
    onChange(data, metadata?.id);
  };

  const onChange = useDebouncedCallback(async (data: any, id: string) => {
    if (id) {
      await updateMetadataAPI(id, metadata);
    } else {
      await insertMetadataAPI(data);
    }
  }, 700);

  const handleToggleAmbientLight = () => {
    const current =
      metadata?.metadata?.ambientLight?.enabled ?? DefaultSceneProperties.ambientLight.enabled;
    updateProperties('ambientLight.enabled', !current);
  };

  const handleToggleBackground = () => {
    const current =
      metadata?.metadata?.background?.enabled ?? DefaultSceneProperties.background.enabled;
    updateProperties('background.enabled', !current);
  };
  const handleToggleEnvironment = () => {
    const current =
      metadata?.metadata?.environment?.enabled ?? DefaultSceneProperties.environment.enabled;
    updateProperties('environment.enabled', !current);
  };
  const handleToggleGrid = () => {
    const current =
      metadata?.metadata?.ground.gridEnabled ?? DefaultSceneProperties.ground.gridEnabled;
    updateProperties('ground.gridEnabled', !current);
  };
  const handleToggleEffects = (effect: string) => {
    let current: boolean = false;
    if (effect === 'ssao') {
      current = metadata?.metadata?.effects?.ssao ?? DefaultSceneProperties.effects.ssao;
    } else {
      current = metadata?.metadata?.effects?.bloom ?? DefaultSceneProperties.effects.bloom;
    }

    updateProperties(`effects.${effect}`, !current);
  };
  const handleGroundShadowChange = (val: number) => {
    updateProperties(`ground.shadow`, val);
  };
  const properties = metadata?.metadata;
  const isAmbientLightEnabled =
    metadata?.metadata?.ambientLight?.enabled ?? DefaultSceneProperties.ambientLight.enabled;
  const isBackgroundEnabled =
    metadata?.metadata?.background?.enabled ?? DefaultSceneProperties.background.enabled;
  const isEnvironmentEnabled =
    metadata?.metadata?.environment?.enabled ?? DefaultSceneProperties.environment.enabled;
  const isgridEnabled =
    metadata?.metadata?.ground.gridEnabled ?? DefaultSceneProperties.ground.gridEnabled;
  const isSSAOEnabled = metadata?.metadata?.effects?.ssao ?? DefaultSceneProperties.effects.ssao;
  const isBloomEnabled = metadata?.metadata?.effects?.bloom ?? DefaultSceneProperties.effects.bloom;
  const groundShadowValue =
    metadata?.metadata?.ground?.shadow ?? DefaultSceneProperties.ground.shadow;
  useEffect(() => {
    const publicStorage = getPublicStorage();
    publicStorage.getAssets('unproject-asset-library-nv', 'environments/hdri').then((res) => {
      setEnvironments(
        res
          .filter((item: any) => item.Size)
          .map((item: any) => {
            const label = item.Key.split('/').at(-1).split('.').at(0);
            return {
              label,
              key: item.Key,
            };
          })
      );
    });
  }, []);
  const { getAssetPath } = useAssetUpload();
  const addBackgroudImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      var file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = async function (event) {
        const uuid = short.generate();
        const { key, path } = getAssetPath(file.name, {
          category: 'textures',
          level: 'project',
          uuid,
        });

        try {
          let toastId = toast(`Uploading background Image`, {
            position: 'bottom-center',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });

          await uploadFileToS3WithProgressToast(file, path, {
            toastId: toastId,
            showToast: true,
            parentname: 'backgroundImage',
            childname: file.name,
          });

          updateProperties('background.img', key);
        } catch (err) {
          console.error('Error in file upload or database update:', err);
        }
      };
      reader.readAsDataURL(file);
      e.target.value = '';
    }
  };
  const deleteBackground = () => {
    updateProperties('background.img', null);
  };
  const textureURL = useMemo(() => {
    if (!properties?.background?.img) return '';
    return getTextureURL(properties?.background?.img, '');
  }, [properties?.background?.img]);
  return (
    <Flex flexDirection="column" mb={2}>
      <PropertyHeading>Background</PropertyHeading>
      <Flex px={2} align="center" gap={2}>
        <Menu>
          <Tooltip bg="grey" label="Background Image">
            <MenuButton
              as={IconButton}
              icon={
                textureURL !== '' ? (
                  <AspectRatio
                    minW="18px"
                    minH="18px"
                    objectFit="cover"
                    objectPosition="center"
                    ratio={1 / 1}
                  >
                    <Image
                      borderRadius="0.25rem"
                      src={textureURL}
                      alt="e"
                      onError={(e: any) => {
                        e.currentTarget.src = 'unnamed.webp';
                      }}
                      crossOrigin=""
                    />
                  </AspectRatio>
                ) : (
                  <UIcon
                    border="1px solid gray"
                    color="gray"
                    borderRadius="0.25rem"
                    fontSize={18}
                    name="texture"
                  />
                )
              }
              className="btn"
              p={2}
              color="white"
              transition="all 0.2s"
            />
          </Tooltip>

          <MenuList mt={2} px={2} py={2}>
            <MenuItem className="menuItem">
              <label htmlFor="bgimg">
                <span>{textureURL !== '' ? 'Replace Image' : 'Add Image'}</span>
              </label>
              <input type="file" id={'bgimg'} onChange={(e) => addBackgroudImage(e)} hidden />
            </MenuItem>
            <MenuItem onClick={deleteBackground} className="menuItem">
              <span>Remove Image</span>
            </MenuItem>
          </MenuList>
        </Menu>

        <Flex
          align="center"
          w="full"
          pointerEvents={isBackgroundEnabled && textureURL === '' ? 'auto' : 'none'}
          opacity={isBackgroundEnabled ? 1 : 0.4}
          color={isBackgroundEnabled && textureURL === '' ? '#eee' : 'gray'}
        >
          <Flex w="full">
            <ColorInput
              name="background.color"
              value={properties?.background?.color ?? DefaultSceneProperties.background.color}
              onChange={handleOnChange}
              position="end"
            />
          </Flex>
          <PropertyField
            value={
              properties?.background
                ? properties?.background?.opacity
                : DefaultSceneProperties.background.opacity
            }
            field="opacity"
            instanceIndex={-1}
            propIndex={0}
            classNames="fit-width"
            handlePropertyChange={handleOnChange}
            type="number"
            name="background.opacity"
          />
        </Flex>
        <IconButton
          aria-label="background opacity"
          icon={<UIcon name={isBackgroundEnabled ? 'bulbOn' : 'bulbOff'} fontSize="14" />}
          variant="ghost"
          onClick={handleToggleBackground}
          size="xs"
        />
      </Flex>
      <PropertyHeading>Ambient light</PropertyHeading>
      <Flex px={2} align="center" gap={2}>
        <Flex
          align="center"
          w="full"
          pointerEvents={isAmbientLightEnabled ? 'auto' : 'none'}
          opacity={isAmbientLightEnabled ? 1 : 0.4}
          color={isAmbientLightEnabled ? '#eee' : 'gray'}
        >
          <Flex w="full">
            <ColorInput
              name="ambientLight.color"
              value={properties?.ambientLight?.color ?? DefaultSceneProperties.ambientLight.color}
              onChange={handleOnChange}
              position="end"
            />
          </Flex>
          <PropertyField
            value={
              properties?.ambientLight
                ? properties?.ambientLight?.intensity
                : DefaultSceneProperties.ambientLight.intensity
            }
            field="intensity"
            instanceIndex={-1}
            classNames="fit-width"
            name="ambientLight.intensity"
            propIndex={1}
            handlePropertyChange={handleOnChange}
            type="number"
            disabled={!isAmbientLightEnabled}
          />
        </Flex>
        <IconButton
          aria-label="Ambient light intensity"
          icon={<UIcon name={isAmbientLightEnabled ? 'bulbOn' : 'bulbOff'} fontSize="14" />}
          variant="ghost"
          onClick={handleToggleAmbientLight}
          size="xs"
        />
      </Flex>
      <PropertyHeading>Environment</PropertyHeading>
      <Flex px={2} align="center" gap={2}>
        <Menu>
          <MenuButton
            size="xs"
            w="full"
            rounded="md"
            variant="ghost"
            isDisabled={!isEnvironmentEnabled}
            as={Button}
            rightIcon={<UIcon name="menu" />}
          >
            {metadata?.metadata?.environment?.label ?? 'Select'}
          </MenuButton>
          <MenuList p={3} maxW="32ch">
            {environments.map((tag: any) => (
              <MenuItem
                key={tag.key}
                aria-label={tag.label}
                overflow="hidden"
                rounded="md"
                w="full"
                fontSize="sm"
                onClick={() => {
                  updateProperties('environment', tag);
                }}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {tag.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <IconButton
          aria-label="Environment"
          icon={<UIcon name={isEnvironmentEnabled ? 'bulbOn' : 'bulbOff'} fontSize="14" />}
          variant="ghost"
          onClick={handleToggleEnvironment}
          size="xs"
        />
      </Flex>
      <PropertyHeading>Ground</PropertyHeading>
      <Flex px={2} align="center" gap={2}>
        <Text textAlign={'center'} m={0} fontSize="sm">
          Grid
        </Text>
        <IconButton
          aria-label="grid"
          alignItems={'center'}
          pt={0.7}
          icon={<UIcon name={isgridEnabled ? 'checked' : 'unckecked'} fontSize="14" />}
          variant="ghost"
          onClick={handleToggleGrid}
          size="xs"
        />
        <Text textAlign={'center'} m={0} fontSize="sm">
          Shadow
        </Text>
        <RangeSlider
          mr={2}
          colorScheme="#282828"
          aria-label={['min', 'max']}
          defaultValue={[0.0, 1]}
          value={[0.0, groundShadowValue]}
          onChange={(e) => {
            handleGroundShadowChange(e[1]);
          }}
        >
          <RangeSliderTrack>
            <RangeSliderFilledTrack />
          </RangeSliderTrack>

          <RangeSliderThumb index={1} />
        </RangeSlider>
        <Text textAlign={'center'} m={0} fontSize="sm">
          {(groundShadowValue / 100).toFixed(2)}
        </Text>
      </Flex>
      <PropertyHeading>Post Processing</PropertyHeading>
      <Flex px={2} align="center" gap={2}>
        <Text textAlign={'center'} m={0} fontSize="sm">
          SSAO
        </Text>
        <IconButton
          aria-label="ssao"
          pt={0.7}
          icon={<UIcon name={isSSAOEnabled ? 'checked' : 'unckecked'} fontSize="14" />}
          variant="ghost"
          onClick={() => handleToggleEffects('ssao')}
          size="xs"
        />
        <Text textAlign={'center'} m={0} fontSize="sm">
          Bloom
        </Text>
        <IconButton
          aria-label="bloom"
          pt={0.7}
          icon={<UIcon name={isBloomEnabled ? 'checked' : 'unckecked'} fontSize="14" />}
          variant="ghost"
          onClick={() => handleToggleEffects('bloom')}
          size="xs"
        />
      </Flex>
      <Flex px={2} justifyContent={'center'} alignItems={'center'} gap={2}>
        <Text textAlign={'left'} w={'60%'} m={0} fontSize="sm">
          Tone Mapping :
        </Text>
        <Select
          value={metadata?.metadata?.effects?.tonemaping ?? 'none'}
          size="sm"
          variant="unstyled"
          fontSize="xs"
          mb={0.5}
          fontWeight={'semibold'}
          onChange={(e) => {
            updateProperties('effects.tonemaping', e.target.value);
          }}
        >
          {[
            'none',
            'ACES_FILMIC',
            'PBR_NEUTRAL',
            'NEUTRAL',
            'LINEAR',
            'REINHARD',
            'REINHARD2',
            'REINHARD2_ADAPTIVE',
            'CINEON',
            'UNCHARTED2',
            'AGX',
          ].map((val: any) => (
            <option key={val} value={val}>
              {val}
            </option>
          ))}
        </Select>
      </Flex>
    </Flex>
  );
};

export default SceneProperties;
