import { Button } from '@chakra-ui/react';
import { useState } from 'react';
import MaterialRow from './MaterialRow';

const LIST_VISIBLE_SIZE = 4;

const MaterialProperties = ({ materials }: any) => {
  const [expand, setExpand] = useState(false);

  return (
    <>
      {materials.slice(0, expand ? materials.length : LIST_VISIBLE_SIZE).map((material: any) => (
        <MaterialRow key={material.id} id={material.id} data={material} />
      ))}
      {!expand && materials.length > LIST_VISIBLE_SIZE && (
        <Button
          color="gray"
          w="full"
          fontSize="xs"
          variant="ghost"
          size="sm"
          onClick={() => setExpand(true)}
        >
          Show more {materials.length - LIST_VISIBLE_SIZE} items
        </Button>
      )}
    </>
  );
};

export default MaterialProperties;
