import * as OV from 'online-3d-viewer';
import { toast } from 'react-toastify';
import short from 'short-uuid';
import { Mesh, Object3D, Texture } from 'three';
import { DRACOLoader } from 'three/examples/jsm/Addons';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { useSceneViewer } from 'src/components/sceneViewer/hooks/useSceneViewer';
import { getFigmaAssetImage } from 'src/services/figma';
import store from 'src/store/store';
import {
  AssetCategories,
  MaterialMap,
  SceneObjectActionTypes,
  SceneObjectFileTypes,
  SupportedSceneObjectTypes,
} from 'src/types';
import { uploadFileToS3Async, uploadFileToS3WithProgressToast } from 'src/utils/aws';
import { uploadProjectAssetPath } from 'src/utils/cloud';
import { getTransformationMatrix } from 'src/utils/helper';
import { getShortId } from 'src/utils/ids';
import { svgToPng } from 'src/utils/image';
import timeHelper from 'src/utils/time';
import { getFileType, getNameWithoutExtension, getSupportedFiles } from 'src/utils/upload';

interface DataType {
  id?: string;
  backendProperties?: Record<string, any>;
}

interface UploadImageOptions extends AssetOptions {
  operation?: {
    type: SceneObjectActionTypes;
    data: DataType;
  };
  showToast?: boolean;
}

interface UploadAssetsOptions {
  data?: DataType;
  upload: AssetOptions;
}

interface AssetOptions {
  updateDB?: boolean;
  callback?: () => void;
  level?: 'workspace' | 'project';
  category?: AssetCategories;
  uuid?: string | undefined;
}

/**
 * Hook used for Uploading assets
 */
export const useAssetUpload = () => {
  const { handleSceneObjectAction } = useSceneViewer();

  /**
   * Get path of asset
   * @param fileName
   * @param options
   * @returns
   */
  const getAssetPath = (fileName: string, options: AssetOptions) => {
    const workspaceId = store.getState().app.currentUser?.workspace_id!;
    const { level = 'project', category = 'objects', uuid = short.generate() } = options ?? {};

    const name = String(fileName).trim().replace(/\s/g, '-');
    const key = uuid + '/' + name;

    let projectId;
    if (level === 'project') {
      projectId = store.getState().app.projectId;
    }

    return {
      path: uploadProjectAssetPath(category, workspaceId, projectId) + '/' + key,
      key,
    };
  };

  /**
   * Upload image file to Cloud
   * @param file Image file
   * @param options Upload options
   */
  const uploadImage = async (file: File, options: UploadImageOptions = {}) => {
    const projectId = store.getState().app.projectId;
    const workspaceId = store.getState().app.currentUser?.workspace_id!;

    const { operation, uuid, level, category, showToast = true } = options;

    const { path, key } = getAssetPath(file.name, {
      uuid,
      level,
      category,
    });

    const rowScope =
      level === 'workspace'
        ? {
            workspace_id: workspaceId,
            scene_id: null,
          }
        : {
            workspace_id: workspaceId,
            project_id: projectId,
          };

    try {
      uploadFileToS3WithProgressToast(file, path, {
        showToast,
      }).then(() => {
        let height = 1.0;
        let width = 1.0;
        const reader = new FileReader();

        reader.onload = (e) => {
          if (e.target) {
            const img = new Image();
            img.onload = (e) => {
              height = img.height / 1000.0;
              width = img.width / 1000.0;

              const action = operation?.type || SceneObjectActionTypes.insert;

              const updatedBackendProperties = {
                ...operation?.data?.backendProperties,
                ...rowScope,
                category,
              } as any;

              let updatedMetadata = {
                material_url: key,
                height: height,
                width: width,
                name: file.name,
              };

              if (updatedBackendProperties['metadata'] !== undefined) {
                updatedMetadata = {
                  ...updatedMetadata,
                  ...updatedBackendProperties['metadata'],
                };
              }

              updatedBackendProperties['metadata'] = updatedMetadata;

              handleSceneObjectAction(action, [
                {
                  id: null,
                  type: SupportedSceneObjectTypes.ui,
                  localProperties: {},
                  ...operation?.data,
                  backendProperties: updatedBackendProperties,
                },
              ]);
            };

            img.src = e.target?.result as string;
          }
        };

        reader.readAsDataURL(file);
      });
    } catch (err) {
      console.error('Error in file upload or database update:', err);
    }
  };

  /**
   * Upload texture image from Url
   * @param url URL
   * @param options Upload options
   */
  const uploadImageFromUrl = async (url: string, options?: UploadImageOptions) => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Image fetch failed with status: ${response.status}`);
    }

    const buffer = await response.arrayBuffer();
    const uint8Array = new Uint8Array(buffer);

    const blob = await svgToPng(uint8Array);

    let name = options?.operation?.data?.backendProperties?.metadata?.name;

    if (!name) {
      name = `${getShortId()}.png`;
    }

    const file = new File([blob], name, {
      type: 'image/png',
    });

    uploadImage(file, options);
  };

  /**
   * Upload 3D assets to S3 and update DB entry
   * @param {File} file - File object
   * @param uuid - Unique ID for file
   * @param toastId - toast ref for displaying updates
   * @param threejsObjectJSON
   * @param filetype - File format
   * @param grpid - ID of group asset belongs to
   * @param material - Any material associated with asset
   * @param options - Options for asset upload
   * @param parentname - Name of parent group/node
   */
  const upload3DAsset = async (
    file: File,
    uuid?: string,
    toastId: number | string | null = null,
    threejsObjectJSON: any | undefined = undefined,
    filetype?: SceneObjectFileTypes,
    grpid: string | null = null,
    material: any = {},
    options: UploadAssetsOptions = {} as UploadAssetsOptions,
    parentname: string = ''
  ) => {
    const projectId = store.getState().app.projectId;
    const scene_id = store.getState().instance.current_sceneId;
    const workspace = store.getState().app.currentUser?.workspace_id;
    const category = options?.upload?.category;

    const { path, key } = getAssetPath(file.name, {
      uuid,
      category,
      ...options.upload,
    });

    const name = file.name?.split('.')?.at(0);

    const rowScope =
      options.upload?.level === 'workspace'
        ? {
            workspace_id: workspace,
            scene_id: null,
            project_id: null,
          }
        : {
            workspace_id: workspace,
            project_id: projectId,
            scene_id: scene_id,
          };

    try {
      // FBX format file
      if (filetype === SceneObjectFileTypes.fbx) {
        const uploadFBX = async () => {
          handleSceneObjectAction(SceneObjectActionTypes.insert, [
            {
              id: null,
              type: SupportedSceneObjectTypes.asset,
              localProperties: {
                insertedThisSession: true,
                localThreejsObjectJSON: URL.createObjectURL(file),
              },
              backendProperties: {
                ...(options.data?.backendProperties ?? {}),
                ...rowScope,
                category,
                name,
                metadata: {
                  file: key,
                  filetype: SceneObjectFileTypes.fbx,
                  material_base: material,
                },
                parent_group_id: grpid,
                animation: {
                  currentState: 0,
                  states: [
                    {
                      name: 'base',
                      position: [0, 0, 0],
                      rotation: [0, 0, 0],
                      scale: [1, 1, 1],
                      material_base: material,
                    },
                  ],
                },
              },
            },
          ]);

          uploadFileToS3WithProgressToast(file, path, {
            toastId: toastId,
            showToast: true,
            parentname: parentname,
            childname: name,
          });
        };

        uploadFBX();
      }
      // GLB format file
      else if (filetype === SceneObjectFileTypes.glb) {
        const loader = new GLTFLoader();
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        const name = file.name?.split('.')?.at(0);

        fileReader.onload = () => {
          const dracoLoader = new DRACOLoader();
          dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
          loader.setDRACOLoader(dracoLoader);

          loader.load(
            fileReader.result as string,
            async () => {
              handleSceneObjectAction(SceneObjectActionTypes.insert, [
                {
                  id: null,
                  type: SupportedSceneObjectTypes.asset,
                  localProperties: {
                    insertedThisSession: true,
                    localThreejsObjectJSON: threejsObjectJSON,
                    isChild: grpid === null ? false : true,
                  },
                  backendProperties: {
                    ...(options.data?.backendProperties ?? {}),
                    ...rowScope,
                    category,
                    name,
                    metadata: {
                      file: key,
                      filetype: SceneObjectFileTypes.glb,
                      material_base: material,
                    },
                    parent_group_id: grpid,
                    animation: {
                      currentState: 0,
                      states: [
                        {
                          name: 'base',
                          position: [0, 0, 0],
                          rotation: [0, 0, 0],
                          scale: [1, 1, 1],
                          material_base: material,
                        },
                      ],
                    },
                  },
                },
              ]);

              uploadFileToS3WithProgressToast(file, path, {
                toastId: toastId,
                showToast: true,
                parentname: parentname,
                childname: name,
              });
            },
            undefined,
            (error) => {
              console.error(error);
              toast.error(('Failed to load 3D model: ' + (error as any).message) as string);
            }
          );
        };
      }
    } catch (err) {
      console.error('Error in file upload or database update:', err);
    }
  };

  /**
   * Convert incoming asset data to GLB file
   * & upload to S3
   * @param data
   * @param filename - Name of asset file
   * @param uuid - Unique ID
   * @param toastId - Toast ref to display updates
   * @param animations - Animations
   * @param material - Material associated with asset
   * @param grpid - Associated Group ID
   * @param options - Options for asset upload
   * @param parentname - Name of parent group/node
   */
  const convertToGLBAndUpload = (
    data: Object3D | Object3D[],
    filename: string,
    uuid: string,
    toastId: number | string | null = null,
    animations: any[] = [],
    material: any = {},
    grpid: string | null = null,
    options: UploadAssetsOptions = {} as UploadAssetsOptions,
    parentname: string = ''
  ) => {
    const gltfExporter = new GLTFExporter();
    gltfExporter.parse(
      data,
      async (gltfJson) => {
        const blob = new Blob([gltfJson as ArrayBuffer], { type: 'application/octet-stream' });
        const newFilename = getNameWithoutExtension(filename) + '.glb';

        const convertedFile = new File([blob], newFilename, { type: blob.type });
        upload3DAsset(
          convertedFile,
          uuid,
          toastId,
          gltfJson,
          SceneObjectFileTypes.glb,
          grpid,
          material,
          options,
          parentname
        );
      },
      (error) => {
        console.error(error);
        toast.error(('Failed to load 3D model: ' + (error as any).message) as string);
      },
      {
        binary: true,
        animations: animations,
      }
    );
  };

  /**
   * Read and upload image thumbnail
   * @param id
   * @param label
   * @param base64String
   */
  const uploadThumbnail = async (id: string, label: string, base64String: string, key?: string) => {
    const projectId = store.getState().app.projectId;
    const workspaceId = store.getState().app.currentUser?.workspace_id;

    if (typeof base64String === 'string' && workspaceId)
      fetch(base64String).then((imageURL) => {
        imageURL.blob().then((imageBlob) => {
          const file = new File([imageBlob], label + '.png', {
            type: 'image/png',
          });

          try {
            const dstKey =
              (key ?? uploadProjectAssetPath('ui', workspaceId, projectId, id)) + '/' + file.name;
            uploadFileToS3Async(file, dstKey);
          } catch (err) {
            console.error('Error in uploading: ', err);
          }
        });
      });
  };

  /**
   * Sync with Figma
   * @param asset
   * @returns
   */
  const syncFigmaFile = async (asset: any) => {
    const node = asset.metadata.meta;

    if (!node) return;

    const figmaAuth = store.getState().integrations.auth['figma']?.auth_data?.access_token;

    if (!figmaAuth) return;

    const assetData = await getFigmaAssetImage(figmaAuth, node.node_url);

    if (!assetData) return;

    const imageUrl = Object.values(assetData.images).at(0) as string;

    if (!imageUrl) return;

    await uploadImageFromUrl(imageUrl, {
      category: 'ui',
      level: 'project',
      operation: {
        type: SceneObjectActionTypes.update,
        data: {
          id: asset.id,
          backendProperties: {
            metadata: {
              name: asset.metadata.name,
              meta: {
                type: 'figma',
                node_url: node.node_url,
                lastUpdated: timeHelper().utc().format(),
              },
            },
          },
        },
      },
      showToast: false,
    });
  };

  /**
   * Upload all assets sent in list
   * @param fileList Asset list
   * @param options Upload options
   */
  const uploadAssets = async (
    fileList: FileList,
    options: UploadAssetsOptions = {} as UploadAssetsOptions
  ) => {
    const files = getSupportedFiles(fileList);
    const uuid = short.generate();
    const image_indices = [] as number[];
    const { upload, ...restUploadOptions } = options;

    files.forEach((file, index) => {
      const fileType = getFileType(file);
      if (fileType === 'ui') {
        image_indices.push(index);
      }
    });

    if (image_indices.length) {
      const Imagepromises = [] as any[];
      for (let i = 0; i < image_indices.length; i++) {
        const index = image_indices[i];
        const name = files[index]?.name?.split('.')?.at(0);
        Imagepromises.push(
          uploadImage(files[index], {
            uuid,
            category: 'ui',
            ...upload,
            operation: {
              type: SceneObjectActionTypes.insert,
              data: {
                ...(restUploadOptions.data ?? {}),
                backendProperties: {
                  ...(restUploadOptions.data?.backendProperties ?? {}),
                  name,
                  scene_id: store.getState().instance.current_sceneId,
                },
              },
            },
          })
        );
      }
      Promise.all(Imagepromises);
    }

    if (image_indices.length < fileList.length) {
      processAndUpload3DAssets(fileList, options);
    }
  };

  /**
   * Util fn to round a number to 2 places
   * @param number
   * @returns
   */
  const rNbr = (number: number): number => parseFloat(number.toFixed(2));

  /**
   * Draw texture on a canvas, snapshot texture
   * and upload to cloud S3
   * @param texture
   * @param filename
   * @param parentname
   * @param matname
   * @param toastId
   * @returns
   */
  const uploadTexture = (
    texture: Texture,
    filename: string,
    parentname: string,
    matname: string,
    toastId: number | string | null = null
  ): string | null | undefined => {
    const uuid = short.generate();
    const { path, key } = getAssetPath(filename, {
      uuid,
      category: 'textures'
    });

    if (texture) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      if (!texture.image) return;

      canvas.width = texture.image.width;
      canvas.height = texture.image.height;
      context?.drawImage(texture.image, 0, 0);

      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], filename, { type: blob.type });

          try {
            uploadFileToS3WithProgressToast(file, path, {
              toastId: toastId,
              showToast: true,
              parentname: parentname,
              childname: matname,
            });
          } catch (err) {
            console.error('Error in file upload or database update:', err);
          }
        } else {
          console.error('Failed to create Blob from canvas');
        }
      });
    }

    return key;
  };

  type MaterialProps = {
    name: string;
    type: string;
    color: string;
    opacity: number;
    metalness: number;
    roughness: number;
    displacementScale: number;
    aoMapIntensity: number;
    normalScale: [number, number];
    map: {
      map: MaterialMap;
      aomap: MaterialMap;
      rmap: MaterialMap;
      dmap: MaterialMap;
      nmap: MaterialMap;
      metmap: MaterialMap;
    };
  };

  /**
   * Create internal node representations
   * by creating child groups, if necessary.
   * Convert to required format and upload to cloud S3
   */
  function processChildren(
    node: Object3D | Mesh,
    toastId: number | string | null,
    gid: string | null,
    filename: string,
    assettype: SceneObjectFileTypes,
    isRoot = true,
    options: UploadAssetsOptions = {} as UploadAssetsOptions,
    parentname: string = ''
  ) {
    if (node.children.length !== 0) {
      const workspace = store.getState().app.currentUser?.workspace_id!;
      const projectId = store.getState().app.projectId;
      const scene_id = store.getState().instance.current_sceneId;

      const grpid = short.uuid();
      let fname: string = node.name;
      if (isRoot) {
        fname = getNameWithoutExtension(filename);
      }

      const rowScope =
        options.upload?.level === 'workspace'
          ? {
              workspace_id: workspace,
              scene_id: null,
              project_id: null,
            }
          : {
              workspace_id: workspace,
              project_id: projectId,
              scene_id: scene_id,
            };

      const actionPromise = handleSceneObjectAction(SceneObjectActionTypes.insert, [
        {
          id: grpid,
          type: SupportedSceneObjectTypes.group,
          localProperties: {},
          backendProperties: {
            ...rowScope,
            category: options?.upload?.category,
            position: [rNbr(node.position.x), rNbr(node.position.y), rNbr(node.position.z)],
            rotation: [node.rotation.x, node.rotation.y, node.rotation.z],
            scale: [node.scale.x, node.scale.y, node.scale.z],
            name: fname,
            parent_group_id: gid,
            metadata: {},
            animation: {
              currentState: 0,
              states: [
                {
                  name: 'base',
                  position: [0, 0, 0],
                  rotation: [0, 0, 0],
                  scale: [1, 1, 1],
                  material_base: null,
                },
              ],
            },
          },
        },
      ]);

      actionPromise.then((insertPromises) => {
        if (insertPromises) {
          Promise.all(insertPromises).then((insertedData) => {
            const group_id = insertedData[0][0].id;
            node.children.forEach((child) =>
              processChildren(
                child,
                toastId,
                group_id,
                filename,
                assettype,
                false,
                options,
                parentname
              )
            );
          });
        }
      });
    } else {
      const uuid = short.generate();
      let material: MaterialProps = {
        name: '',
        type: '',
        color: '',
        opacity: 1,
        metalness: 0,
        roughness: 0,
        displacementScale: 0,
        aoMapIntensity: 0,
        normalScale: [0, 0],
        map: {
          map: {
            src: null,
            tiling: [1, 1],
            offset: [1, 1],
            rotation: 0,
          },
          aomap: {
            src: null,
            tiling: [1, 1],
            offset: [1, 1],
            rotation: 0,
          },
          rmap: {
            src: null,
            tiling: [1, 1],
            offset: [1, 1],
            rotation: 0,
          },
          dmap: {
            src: null,
            tiling: [1, 1],
            offset: [1, 1],
            rotation: 0,
          },
          nmap: {
            src: null,
            tiling: [1, 1],
            offset: [1, 1],
            rotation: 0,
          },
          metmap: {
            src: null,
            tiling: [1, 1],
            offset: [1, 1],
            rotation: 0,
          },
        },
      };

      if ((node as THREE.Mesh).isMesh) {
        const mesh = node as THREE.Mesh;
        const mat = mesh.material as THREE.MeshStandardMaterial;
        const fname = getNameWithoutExtension(filename);
        let color = '';

        let map: MaterialMap = {
          src: null,
          tiling: [1, 1],
          offset: [1, 1],
          rotation: 0,
        };
        let aomap: MaterialMap = {
          src: null,
          tiling: [1, 1],
          offset: [1, 1],
          rotation: 0,
        };
        let rmap: MaterialMap = {
          src: null,
          tiling: [1, 1],
          offset: [1, 1],
          rotation: 0,
        };
        let nmap: MaterialMap = {
          src: null,
          tiling: [1, 1],
          offset: [1, 1],
          rotation: 0,
        };
        let dmap: MaterialMap = {
          src: null,
          tiling: [1, 1],
          offset: [1, 1],
          rotation: 0,
        };
        let metmap: MaterialMap = {
          src: null,
          tiling: [1, 1],
          offset: [1, 1],
          rotation: 0,
        };

        if (mat.color) {
          color = mat.color.getHexString();
        } else {
          color = 'ffffff';
        }

        // If textures are available, seperate & upload them to the Cloud
        if (mat.map != null) {
          map.src = uploadTexture(
            mat.map,
            fname + mat.name,
            parentname,
            mat.name,
            toastId
          ) as string;
          map.offset = [mat.map.offset.x, mat.map.offset.y];
          map.rotation = mat.map.rotation;
          map.tiling = [mat.map.repeat.x, mat.map.repeat.y];
          if (mat.aoMap != null) {
            aomap.src = uploadTexture(
              mat.aoMap,
              fname + mat.name + 'ao',
              parentname,
              mat.name,
              toastId
            ) as string;
            aomap.offset = [mat.aoMap.offset.x, mat.aoMap.offset.y];
            aomap.rotation = mat.aoMap.rotation;
            aomap.tiling = [mat.aoMap.repeat.x, mat.aoMap.repeat.y];
          }
          if (mat.roughnessMap != null) {
            rmap.src = uploadTexture(
              mat.roughnessMap,
              fname + mat.name + 'r',
              parentname,
              mat.name,
              toastId
            ) as string;
            rmap.offset = [mat.roughnessMap.offset.x, mat.roughnessMap.offset.y];
            rmap.rotation = mat.roughnessMap.rotation;
            rmap.tiling = [mat.roughnessMap.repeat.x, mat.roughnessMap.repeat.y];
          }
          if (mat.normalMap != null) {
            nmap.src = uploadTexture(
              mat.normalMap,
              fname + mat.name + 'n',
              parentname,
              mat.name,
              toastId
            ) as string;
            nmap.offset = [mat.normalMap.offset.x, mat.normalMap.offset.y];
            nmap.rotation = mat.normalMap.rotation;
            nmap.tiling = [mat.normalMap.repeat.x, mat.normalMap.repeat.y];
          }
          if (mat.displacementMap != null) {
            dmap.src = uploadTexture(
              mat.displacementMap,
              fname + mat.name + 'd',
              parentname,
              mat.name,
              toastId
            ) as string;
            dmap.offset = [mat.displacementMap.offset.x, mat.displacementMap.offset.y];
            dmap.rotation = mat.displacementMap.rotation;
            dmap.tiling = [mat.displacementMap.repeat.x, mat.displacementMap.repeat.y];
          }
          if (mat.metalnessMap != null) {
            metmap.src = uploadTexture(
              mat.metalnessMap,
              fname + mat.name + 'met',
              parentname,
              mat.name,
              toastId
            ) as string;
            metmap.offset = [mat.metalnessMap.offset.x, mat.metalnessMap.offset.y];
            metmap.rotation = mat.metalnessMap.rotation;
            metmap.tiling = [mat.metalnessMap.repeat.x, mat.metalnessMap.repeat.y];
          }
        }

        material = {
          name: mat.name,
          type: mat.type,
          color: `#${color}`,
          opacity: mat.opacity,
          metalness: mat.metalness,
          roughness: mat.roughness,
          displacementScale: mat.displacementScale,
          aoMapIntensity: mat.aoMapIntensity,
          normalScale: [mat.normalScale.x, mat.normalScale.y],
          map: {
            map,
            aomap,
            rmap,
            dmap,
            nmap,
            metmap,
          },
        };
      }

      if (assettype === SceneObjectFileTypes.glb) {
        const file = node.name + '.glb';

        node.applyMatrix4(
          getTransformationMatrix([0.0, 0.0, 0.0], [0.0, 0.0, 0.0], [1.0, 1.0, 1.0])
        );

        convertToGLBAndUpload(
          node,
          file,
          uuid,
          toastId,
          [],
          material,
          gid,
          {
            ...options,
            data: {
              ...(options.data ?? {}),
              backendProperties: {
                ...(options.data?.backendProperties ?? {}),
              },
            },
          },

          parentname
        );
      }
    }
  }

  /**
   * Process all files selected for load
   * and Upload to cloud S3
   * @param fileList
   * @param options
   */
  const processAndUpload3DAssets = async (
    fileList: FileList,
    options: UploadAssetsOptions = {} as UploadAssetsOptions
  ) => {
    const uuid = short.generate();
    let toastId: string | null | number = null;

    const glbFiles = [];
    const fbxFiles = [];
    const glbFormats = ['glb', 'gltf', 'GLB', 'GLTF'];
    const fbxFormats = ['fbx', 'FBX'];
    const otherFiles = [];
    for (let i = 0; i < fileList.length; i++) {
      const fileType = fileList[i].name.split('.').pop();
      if (fileType && glbFormats.includes(fileType)) {
        glbFiles.push(fileList[i]);
      } else if (fileType && fbxFormats.includes(fileType)) {
        fbxFiles.push(fileList[i]);
      } else {
        otherFiles.push(fileList[i]);
      }
    }

    // Process GLB files
    for (let i = 0; i < glbFiles.length; i++) {
      const file = glbFiles[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        const loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
        loader.setDRACOLoader(dracoLoader);
        loader.load(
          e.target?.result as string,
          async (gltf) => {
            toastId = toast(`Processing`, {
              position: 'bottom-center',

              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: 0.1,
            });
            if (gltf.animations.length !== 0) {
              convertToGLBAndUpload(
                gltf.scene,
                file.name,
                uuid,
                toastId,
                gltf.animations,
                {},
                null,
                options,
                file.name
              );
            } else {
              processChildren(
                gltf.scene,
                toastId,
                null,
                file.name,
                SceneObjectFileTypes.glb,
                true,
                options,
                file.name
              );
            }
          },
          undefined,
          (error) => {
            console.error(error);
            toast.error(('Failed to load 3D model: ' + (error as any).message) as string);
          }
        );
      };
      reader.readAsDataURL(file);
    }

    // Process FBX files
    for (let i = 0; i < fbxFiles.length; i++) {
      const file = fbxFiles[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        const loader = new FBXLoader();
        if (!e.target) return;

        let contents = e.target.result;

        const object = loader.parse(contents as ArrayBuffer, file.name);

        upload3DAsset(
          file,
          uuid,
          toastId,
          undefined,
          SceneObjectFileTypes.fbx,
          null,
          {},
          options,
          file.name
        );

        loader.load(
          e.target.result as string,
          async (fbx) => {
            if (fbx.animations.length !== 0) {
              upload3DAsset(
                file,
                uuid,
                toastId,
                fbx,
                SceneObjectFileTypes.fbx,
                null,
                {},
                options,
                file.name
              );
            } else {
              handleOtherFiles([file], options);
            }
          },
          undefined,
          (error) => {
            console.error(error);
            toast.error(('Failed to load 3D model: ' + (error as any).message) as string);
          }
        );
      };
      reader.readAsDataURL(file);
    }

    if (otherFiles.length > 0) {
      handleOtherFiles(otherFiles, options);
    }
  };

  /**
   * Handle files not in GLB, FBX format
   */
  const handleOtherFiles = (files: File[], options = {} as any) => {
    console.log('handleOtherFiles called - for files', files);

    const threeloader = new OV.ThreeModelLoader();
    let settings = new OV.ImportSettings();

    let toastId: number | string | null = null;

    if (files.length > 0) {
      const inputfiles = OV.InputFilesFromFileObjects(files);

      threeloader.LoadModel(inputfiles, settings, {
        onLoadStart: () => {
          toastId = toast(`Processing`, {
            position: 'bottom-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        },
        onFileListProgress: (current: any, total: any) => {},
        onFileLoadProgress: (current: any, total: any) => {},
        onImportStart: () => {},
        onVisualizationStart: () => {},
        onModelFinished: (importResult: any, threeObject: any) => {
          console.log('OV Load Model - Model Finished loading!');
          let meshCount = 0;

          threeObject.traverse(function (object: any) {
            if ((object as THREE.Mesh).isMesh) {
              meshCount++;
            }
          });

          processChildren(
            threeObject,
            toastId,
            null,
            importResult.mainFile,
            SceneObjectFileTypes.glb,
            true,
            options
          );
        },
        onTextureLoaded: () => {},
        onLoadError: (importError: any) => {
          if (toastId !== null) {
            toast.done(toastId);
          }
          if (importError.mainFile !== null) {
            console.error(importError);
            toast.error(('Failed to load 3D model: ' + (importError as any).message) as string);
          }
        },
        onSelectMainFile: (fileNames: any, selectFile: any) => {
          for (let i = 0; i < fileNames.length; i++) {
            selectFile(i);
          }
        },
      });
    }
  };

  return {
    uploadAssets,
    uploadImage,
    upload3DAsset,
    getAssetPath,
    uploadImageFromUrl,
    uploadThumbnail,
    syncFigmaFile,
    processAndUpload3DAssets,
  };
};
