import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AuthCallback = () => {
  const location = useLocation();

  useEffect(() => {
    // Extract the access token from the redirect_url and store it
    const urlParams = location.hash.substring(1);
    const token = new URLSearchParams(urlParams).get('id_token');

    // Send the code to the parent window
    const channel = new BroadcastChannel('popup-channel');
    const message = token ? { token } : { error: 'No token found' };

    channel.postMessage(message);
  }, []);

  return null;
};

export default AuthCallback;
