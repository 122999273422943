import { useRef, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Text,
  Flex,
  TableContainer,
  Center,
  Skeleton,
} from '@chakra-ui/react';
import { FaFile } from 'react-icons/fa';
import { FaFolder } from 'react-icons/fa6';
import { getFileTypeFromExtension } from 'src/utils/upload';
import { AssetCategories } from 'src/types';

interface FileItem {
  id: string;
  name: string;
  type: 'container' | 'blob';
  lastModified: string;
  createdAt: string;
}

interface FileExplorerProps {
  data?: FileItem[];
  onClick: any;
  isLoading?: boolean;
  active: AssetCategories;
  onSelect: (path: string) => void;
  getColorScheme: (id: string) => string;
}

const FileExplorer = ({
  data = [],
  isLoading,
  onSelect,
  getColorScheme,
  active,
  onClick,
}: FileExplorerProps) => {
  const [sortColumn, setSortColumn] = useState<keyof FileItem>('name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());

  const handleSort = (column: keyof FileItem) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const sortedData = [...data].sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
    if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  const handleSelect = (id: string) => {
    const newSelectedItems = new Set(selectedItems);
    if (selectedItems.has(id)) {
      newSelectedItems.delete(id);
    } else {
      newSelectedItems.add(id);
    }
    onSelect(id);
    setSelectedItems(newSelectedItems);
  };

  return (
    <TableContainer>
      <Table size="lg" variant="simple" colorScheme="gray">
        <Thead>
          <Tr>
            <Th width="40px" px={2}>
              {/* <Center>
                <Checkbox
                  isChecked={selectedItems.size === data.length}
                  isIndeterminate={selectedItems.size > 0 && selectedItems.size < data.length}
                  onChange={() => {
                    if (selectedItems.size === data.length) {
                      setSelectedItems(new Set());
                    } else {
                      setSelectedItems(new Set(data.map((item) => item.id)));
                    }
                  }}
                />
              </Center> */}
            </Th>
            <Th>Name</Th>
            <Th>Type</Th>
            <Th>Last Modified</Th>
            <Th>Created At</Th>
          </Tr>
        </Thead>
        <Skeleton as={Tbody} h={100} isLoaded={!isLoading}>
          {sortedData.map((item) => {
            const fileType = getFileTypeFromExtension(item.name);
            const colorScheme = getColorScheme(item.id);
            return (
              <Tr
                key={item.id}
                cursor={item.type === 'container' ? 'pointer' : 'auto'}
                onClick={() => {
                  if (item.type === 'container') onClick(item.name);
                }}
              >
                <Td align="center" px={2}>
                  {item.type !== 'container' && (
                    <Center>
                      <Checkbox
                        onChange={() => {
                          handleSelect(item.id);
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        colorScheme={colorScheme}
                        isChecked={selectedItems.has(item.id)}
                        defaultValue={active}
                      />
                    </Center>
                  )}
                </Td>
                <Td>
                  <Flex align="center" gap={4}>
                    {item.type === 'container' ? <FaFolder size={14} /> : <FaFile size={14} />}
                    <Text display="inline" m={0}>
                      {item.name}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Text display="inline" fontSize="sm" color="gray.500">
                    {fileType.type}
                  </Text>
                </Td>
                <Td>
                  <Text display="inline" fontSize="sm">
                    {item.lastModified}
                  </Text>
                </Td>
                <Td>
                  <Text display="inline" fontSize="sm">
                    {item.createdAt}
                  </Text>
                </Td>
              </Tr>
            );
          })}
        </Skeleton>
      </Table>
    </TableContainer>
  );
};

export default FileExplorer;
