import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getAllIntegrationsAPI, getAllIntegrationsAuthAPI } from 'src/apis/integrations';
import { getWorkspaceAPI } from 'src/apis/workspace';
import { identifyUser, trackPage } from 'src/services/analytics';
import { initSentry } from 'src/services/sentry';
import { setWorkspaces } from 'src/store/reducers/app';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { setIntegrationsAuth, setIntegrations } from 'src/store/reducers/integrations';
import { ROUTES } from 'src/utils/constants';
import { initWasmHandler } from 'src/utils/image';
import { getCloudStorage } from './useCloudStorage';

export const useInit = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector((store) => store.app.currentUser);
  const workspaceId = currentUser?.workspace_id;

  const initAPIs = async () => {
    await Promise.all([
      getWorkspaceAPI().then(({ data: workspaces }) => {
        if (workspaces) {
          dispatch(setWorkspaces(workspaces));
        }
      }),
      getAllIntegrationsAPI()
        .then((res) => dispatch(setIntegrations(res)))
        .catch((err) => {
          console.error(err);
        }),
      getAllIntegrationsAuthAPI({ workspaceId })
        .then((res) => dispatch(setIntegrationsAuth(res)))
        .catch((err) => {
          console.error(err);
        }),
    ]);
  };

  // * Initialize external services
  useEffect(() => {
    initSentry();
    initWasmHandler();
  }, []);

  useEffect(() => {
    initAPIs();
    if (window.location.pathname !== ROUTES.create_workspace) {
      getCloudStorage(true);
    }
  }, [currentUser?.workspace_id]);

  // * Identify user for tracking and initialize APIs
  useEffect(() => {
    if (currentUser) {
      identifyUser(currentUser.email, { id: currentUser.id, email: currentUser.email });
    }
  }, [currentUser]);

  // * Page View Tracker
  useEffect(() => {
    trackPage();
  }, [location]);
};
