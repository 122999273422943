import { AssetCategories } from 'src/types';

/**
   * unprojectData
      workspace
        workspaceIds
            thumbnails
            assets
              objects
              ui
              textures
            projects
              projectIds
                thumbnails
                scenes
                  thumbnails
                assets
                  objects
                  ui
                  textures
   */

export const uploadProjectAssetPath = (
  assetType: AssetCategories,
  workspaceId: string,
  projectId?: string,
  sceneId?: string
) => {
  if (sceneId) {
    return `workspace/${workspaceId}/projects/${projectId}/scenes/${sceneId}/viewportImages`;
  }

  if (projectId) return `workspace/${workspaceId}/projects/${projectId}/assets/${assetType}`;

  return `workspace/${workspaceId}/assets/${assetType}`;
};

export const getBlobURLFromSasURL = (fileName: string, sasUrl: string) => {
  const encodedFileName = encodeURIComponent(fileName);

  const url = new URL(sasUrl);

  url.pathname += `/${encodedFileName}`;

  return url.toString();
};

export const getDefaultContainer = () => {
  return 'unproject-data';
};

export const getContainerAndPath = (key: string) => {
  const firstSlashIndex = key.indexOf('/');

  if (firstSlashIndex === -1) {
    return { container: key, blobPath: '' };
  }

  const container = key.slice(0, firstSlashIndex);
  const path = key.slice(firstSlashIndex + 1);

  return { container, path };
};

export const getBlobName = (name: string) => {
  return name.split('/').at(-1);
};

export const getCloudStorageCacheKey = (workspace: string) => {
  return `cloud-storage-${workspace}`;
};
