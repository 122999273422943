import type { SceneNode, SupportedNodeTypes, TextNode } from 'src/types';

type PromiseOrValue<T> = Promise<T> | T;

const BATCH_SIZE = 6; // number

export const batchArray = <T>(array: T[], batchSize: number = BATCH_SIZE): T[][] => {
  const batches: T[][] = [];
  for (let i = 0; i < array.length; i += batchSize) {
    batches.push(array.slice(i, i + batchSize));
  }
  return batches;
};

export const runBatch = async <T>(
  promises: PromiseOrValue<T>[],
  batchSize: number = BATCH_SIZE
) => {
  const batches = batchArray(promises, batchSize);
  const fulfilled: T[] = [];
  const rejected: PromiseRejectedResult[] = [];

  for await (const batch of batches) {
    const result = await Promise.allSettled(batch);

    fulfilled.push(
      ...(result.filter((val) => val.status === 'fulfilled') as any[]).map((val) => val.value)
    );

    rejected.push(
      ...(result.filter((val) => val.status === 'rejected') as PromiseRejectedResult[])
    );
  }

  return {
    fulfilled,
    rejected,
  };
};

export const getCurrentUser = (user: any) => {
  const { id, email, user_metadata, role } = user;

  return {
    id,
    email,
    username: user_metadata.username ?? email,
    fullname: user_metadata.full_name,
    avatar_url: user_metadata.avatar_url,
    workspace_id: user_metadata.workspace_id,
    role: role,
  };
};

export const nodeMapper = (nodes: Array<SceneNode | TextNode>, type: SupportedNodeTypes) => {
  const typedNodes = nodes.map((node) => ({
    ...node,
    type,
  }));

  return typedNodes;
};

type AsyncFunction<T> = (...args: any[]) => Promise<T>;

export async function executeAsyncFunctions<T>(
  asyncFunctions: AsyncFunction<T>[],
  isSequential: boolean
): Promise<T[]> {
  if (isSequential) {
    const results: T[] = [];
    for (const asyncFunction of asyncFunctions) {
      try {
        const result = await asyncFunction();
        results.push(result);
      } catch (error) {
        console.error(error);
      }
    }
    return results;
  } else {
    return await Promise.all(asyncFunctions.map((fn) => fn())); // Map to call each function
  }
}
