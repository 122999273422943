export enum SupportedSceneObjectTypes {
  asset = 'asset',
  ui = 'ui',
  group = 'group',
  viewport = 'viewport',
  light = 'light',

  // Viewport's children
  head = 'head',
  interactions = 'interactions',
  controller = 'controller',
}
export enum ModeTypes {
  edit = 'edit',
  preview = 'preview',
}
export enum SceneObjectFileTypes {
  glb = 'glb',
  fbx = 'fbx',
}
export enum ControllerSubtype {
  left = 'left',
  right = 'right',
}

export enum SupportedControllerModels {
  quest3 = 'quest3',
  hands = 'hands',
}

export enum SupportedLightTypes {
  spot = 'spot',
  directional = 'directional',
  point = 'point',
}

export enum SceneObjectActionTypes {
  insert = 'insert',
  update = 'update',
  delete = 'delete',
  group = 'group',
  ungroup = 'ungroup',
}
export enum SceneEventActionTypes {
  insert = 'insert',
  update = 'update',
  delete = 'delete',
}
export enum SceneEventTriggerTargets {
  scene = 'Anywhere in Scene',
  object = 'On this Object',
}

export const ScenePrimaryObjects = [
  SupportedSceneObjectTypes.asset,
  SupportedSceneObjectTypes.ui,
  SupportedSceneObjectTypes.head,
  SupportedSceneObjectTypes.controller,
  SupportedSceneObjectTypes.light,
];

export const SupportedGroupTypes = [
  SupportedSceneObjectTypes.group,
  SupportedSceneObjectTypes.viewport,
  SupportedSceneObjectTypes.interactions,
];

export const ContantScaleObjectTypes = [
  SupportedSceneObjectTypes.head,
  SupportedSceneObjectTypes.controller,
  SupportedSceneObjectTypes.interactions,
  SupportedSceneObjectTypes.viewport,
  SupportedSceneObjectTypes.light,
];

export interface cameraConfigInterface {
  position: [number, number, number];
  target: [number, number, number];
  upVec: [number, number, number];
}

export interface bboxInfoInterface {
  refBBox: undefined | THREE.Box3;
  position: [number, number, number];
  rotation: [number, number, number];
  scale: [number, number, number];
}

export interface gizmoInfoInterface {
  position: [number, number, number];
  rotation: [number, number, number];
  scale: [number, number, number];
  show: [boolean, boolean, boolean];
}

export interface sceneObjectRefInterface {
  id: string;
  type: SupportedSceneObjectTypes;
  transform: THREE.Matrix4;
}

export interface refTransformsInterface {
  gizmoRef: THREE.Matrix4;
  bboxRef: THREE.Matrix4;
  sceneObjectsRef: sceneObjectRefInterface[];
}

export enum SceneActionSubType {
  copy = 'copy',
  ungroup = 'ungroup',
}

// Abstract Scene Object Interface
// Always define default values for non-optional local properties
export interface SceneObjectLocalProperties {
  originalBBox?: THREE.Box3;
  animationState?: {
    name: string;
    isPlaying: boolean | undefined;
    time: number | undefined;
    loop: THREE.AnimationActionLoopStyles | undefined;
  }[];
  annotationslength?: number;
  annotationShow?: boolean;
  insertedThisSession: boolean;
  isChild?: boolean;
  updateTexture?: boolean;
  material_base?: {
    name: string;
    type: string;
    color: string;
    opacity: number;
    metalness: number;
    roughness: number;
    displacementScale: number;
    aoMapIntensity: number;
    normalScale: [number, number];
    map: {
      map: MaterialMap;
      aomap: MaterialMap;
      rmap: MaterialMap;
      dmap: MaterialMap;
      nmap: MaterialMap;
      metmap: MaterialMap;
    };
  } | null;
  updateStore?: boolean;
  children: {
    id: string;
    type: SupportedSceneObjectTypes;
  }[];
  subType?: SceneActionSubType;
  isPrimary: boolean; // used by viewport scene object
  localThreejsObjectJSON?: any;
  currentState?: number;
  events?: {
    id: string;
    play: boolean;
  }[];
}
export const SceneObjectLocalPropertiesDefaults: SceneObjectLocalProperties = {
  insertedThisSession: false,
  children: [],
  isPrimary: false,
};

export interface SceneObjectMetadata {}
export interface SceneObjectAnimation {}
export interface SceneObjectBackendProperties<Metadata extends SceneObjectMetadata> {
  id: string;
  project_id: string;
  scene_id: string;
  created_at: string;
  position: [number, number, number];
  rotation: [number, number, number];
  scale: [number, number, number];
  constrain_proportions: boolean;
  type: SupportedSceneObjectTypes;
  parent_group_id: string | null;
  hidden: boolean;
  locked: boolean;
  name: string;
  background: boolean;
  system_generated: boolean;
  material: string;
  metadata: Metadata;
  animation: AssetObjectAnimation;
}

export interface SceneObject<Metadata extends SceneObjectMetadata> {
  id: string;
  type: SupportedSceneObjectTypes;
  localProperties: SceneObjectLocalProperties;
  backendProperties: SceneObjectBackendProperties<Metadata>;
}
export interface SceneEvent {
  id?: string;
  project_id: string;
  scene_id: string;
  workspace_id: string;
  name: string;
  trigger: string;
  target: string;
  triggerObject: string;
  targetObject: string | null;
  sequence: {
    timeline: {
      name: string;
      duration: number;
      delay: number;
    }[];
  };
}

// SCENE OBJECT === ASSET

export interface AssetObjectAnimation extends SceneObjectAnimation {
  currentState: number;
  states: {
    name: string;
    position: [number, number, number];
    rotation: [number, number, number];
    scale: [number, number, number];
    material_base: any;
  }[];
}
export type MaterialMap = {
  src: string | null;
  tiling: [number, number];
  offset: [number, number];
  rotation: number;
};
export interface AssetObjectMetadata extends SceneObjectMetadata {
  file: string;
  filetype?: SceneObjectFileTypes;
  material_base: {
    name: string;
    type: string;
    color: string;
    opacity: number;
    metalness: number;
    roughness: number;
    displacementScale: number;
    aoMapIntensity: number;
    normalScale: [number, number];
    map: {
      map: MaterialMap;
      aomap: MaterialMap;
      rmap: MaterialMap;
      dmap: MaterialMap;
      nmap: MaterialMap;
      metmap: MaterialMap;
    };
  } | null;
  metadata: null | any;
}
export const AssetObjectMetadataDefaults: AssetObjectMetadata = {
  file: '',
  material_base: null,
  metadata: null,
};
export const AssetObjectAnimationDefaults: AssetObjectAnimation = {
  currentState: 0,
  states: [],
};
export interface AssetObject extends SceneObject<AssetObjectMetadata> {
  type: SupportedSceneObjectTypes.asset;
}

// SCENE OBJECT === VIEWPORT

export interface ViewportObjectMetadata extends SceneObjectMetadata {
  type: string;
  label: string;
}

export const ViewportObjectMetadataDefaults: ViewportObjectMetadata = {
  type: 'viewport',
  label: 'viewport-primary',
};

export interface ControllerMetadata extends SceneObjectMetadata {
  type: SupportedControllerModels;
  subType: ControllerSubtype;
  actionConfig?: Record<string, any>;
  colorConfig?: Record<string, any>;
}

export interface HeadObjectMetadata extends SceneObjectMetadata {
  fov: number;
  height: number;
  width: number;
}

export const HeadObjectMetadataDefaults: HeadObjectMetadata = {
  fov: 60,
  height: 1024,
  width: 1024,
};

export interface HeadObject extends SceneObject<HeadObjectMetadata> {
  type: SupportedSceneObjectTypes.head;
}

export interface ViewportObject extends SceneObject<ViewportObjectMetadata> {
  type: SupportedSceneObjectTypes.viewport;
}

// SCENE OBJECT === UI
export interface UIObjectMetadata extends SceneObjectMetadata {
  height: number;
  width: number;
  corner_radius: number;
  corner_steps: number;
  curve_angle: number;
  curve_steps: number;
  material_url: string;
  meta?: any;
}

export const UIObjectMetadataDefaults: UIObjectMetadata = {
  height: 1,
  width: 1,
  corner_radius: 0,
  corner_steps: 20,
  curve_angle: 0,
  curve_steps: 20,
  material_url: '',
  meta: null,
};

export interface UIObject extends SceneObject<UIObjectMetadata> {
  type: SupportedSceneObjectTypes.ui;
}

export interface ControllerObject extends SceneObject<ControllerMetadata> {
  type: SupportedSceneObjectTypes.controller;
}

// SCENE OBJECT === Group
export interface GroupObject extends SceneObject<SceneObjectMetadata> {
  type: SupportedSceneObjectTypes.group;
}

export type SceneObjectPropsType = {
  config: AssetObject;
  onClick?: Function;
  onPointerOut?: () => void;
  onPointerOver: (asset?: any) => void;
  handleDrag?: Function;
  disablePivot?: boolean;
  onRightClick?: Function;
  onDoubleClick?: Function;
};

// SCENE OBJECT === LIGHT

export interface LightObjectMetadata extends SceneObjectMetadata {
  color: string;
  intensity: number;
  subtype: SupportedLightTypes;
  config?: Record<string, any>;
}

export const LightObjectMetadataDefaults: LightObjectMetadata = {
  color: '#FFFFFF' as string,
  intensity: 1.0,
  subtype: SupportedLightTypes.point,
};

export interface LightObject extends SceneObject<LightObjectMetadata> {
  type: SupportedSceneObjectTypes.light;
}

export interface PointLightObjectMetadata extends LightObjectMetadata {
  config: {
    distance: number;
    decay: number;
  };
}

export const PointLightObjectMetadataDefaults: PointLightObjectMetadata = {
  ...LightObjectMetadataDefaults,
  subtype: SupportedLightTypes.point,
  config: {
    distance: 0.0,
    decay: 2.0,
  },
};

export interface DirectionalLightObjectMetadata extends LightObjectMetadata {
  config: {
    target: [number, number, number];
  };
}

export const DirectionalLightObjectMetadataDefaults: DirectionalLightObjectMetadata = {
  ...LightObjectMetadataDefaults,
  subtype: SupportedLightTypes.directional,
  config: {
    target: [0.0, 0.0, 0.0],
  },
};

export interface SpotLightObjectMetadata extends LightObjectMetadata {
  config: {
    distance: number;
    decay: number;
    angle: number;
    penumbra: number;
    target: [number, number, number];
  };
}

export const SpotLightObjectMetadataDefaults: SpotLightObjectMetadata = {
  ...LightObjectMetadataDefaults,
  subtype: SupportedLightTypes.spot,
  config: {
    distance: 0.0,
    decay: 2.0,
    angle: Math.PI / 3,
    penumbra: 0,
    target: [0.0, 0.0, 0.0],
  },
};
