import type { FigmaAccessToken, FigmaAccessTokenUser } from 'src/features/Integrations/types';
import { generateUrl } from 'src/utils/url';

const figmaClientId = String(process.env.REACT_APP_FIGMA_CLIENT_ID);
const figmaSecret = String(process.env.REACT_APP_FIGMA_CLIENT_SECRET);
const FIGMA_OAUTH_URL = 'https://www.figma.com/api/oauth';
const CALLBACK = window.location.origin + '/integrations';

export const getFigmaAuthCodeUrl = (sessionId: string) => {
  return generateUrl('https://www.figma.com/oauth', {
    client_id: figmaClientId,
    redirect_uri: CALLBACK,
    scope: 'files:read',
    state: sessionId,
    response_type: 'code',
  });
};

export const getFigmaAccessTokenFromCode = async (code: string): Promise<FigmaAccessTokenUser> => {
  const url = generateUrl(`${FIGMA_OAUTH_URL}/token`, {
    client_id: figmaClientId,
    client_secret: figmaSecret,
    redirect_uri: CALLBACK,
    code: code,
    grant_type: 'authorization_code',
  });

  const data = await fetch(url, {
    method: 'POST',
  });

  return data.json();
};

export const refreshFigmaAccessToken = async (refreshToken: string): Promise<FigmaAccessToken> => {
  const url = generateUrl(`${FIGMA_OAUTH_URL}/refresh`, {
    client_id: figmaClientId,
    client_secret: figmaSecret,
    refresh_token: refreshToken,
  });

  const data = await fetch(url, {
    method: 'POST',
  });

  return data.json();
};

export const fetchFigmaFiles = async (token: string, nodes: any[]) => {
  const filesToFetch = nodes.reduce((acc, node) => {
    const { key } = extractKeyAndNodeIds(node.metadata.meta.node_url);

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(node);

    return acc;
  }, {});

  const urls = Object.keys(filesToFetch);

  const data = await Promise.all(
    urls.map((key) => {
      return fetch(`https://api.figma.com/v1/files/${key}?depth=2`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }).then((res) => res.json());
    })
  );

  const imagesToSync = [] as any[];

  data.forEach((node, i) => {
    const existingUIAssets = filesToFetch[urls[i]];

    existingUIAssets.forEach((asset: any) => {
      const assetLastUpdated = asset.metadata?.meta?.lastUpdated
        ? new Date(asset.metadata?.meta?.lastUpdated).getTime()
        : 0;
      const figmaLastUpdated = new Date(node.lastModified).getTime();

      if (assetLastUpdated < figmaLastUpdated) {
        imagesToSync.push(asset);
      }
    });
  });

  return imagesToSync;
};

export function extractKeyAndNodeIds(figmaUrl: string) {
  const regex = /\/design\/([^\/]+)\/([^\/?]+)\?[^#]*node-id=([^&]+)/;
  const match = figmaUrl.match(regex);

  if (match) {
    const [, key, file, nodeId] = match;
    return { key, file, nodeId };
  } else {
    return { key: '', file: '', nodeId: '' };
  }
}

export const getFigmaAssetImage = async (token: string, url: string) => {
  const { key, nodeId } = extractKeyAndNodeIds(url);

  const data = await fetch(`https://api.figma.com/v1/images/${key}?ids=${nodeId}&format=svg`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return data.json();
};
