import * as THREE from 'three';
import { Image } from '@react-three/drei';
import { useEffect, useMemo } from 'react';
import { ThreeEvent } from '@react-three/fiber';
import 'src/utils/geometry';
import { SceneObjectActionTypes, SupportedSceneObjectTypes, UIObject } from 'src/types';
import { useSceneViewer } from '../hooks/useSceneViewer';
import { CloudStorageManager } from 'src/services/storage';
import { getCloudStorageCacheKey, uploadProjectAssetPath } from 'src/utils/cloud';
import store from 'src/store/store';
import { useCache } from 'src/hooks/useCache';
import { useAppSelector } from 'src/store/reducers/hook';

function PreviewUiAsset(props: any) {
  const scaleMatrix = new THREE.Matrix4();
  scaleMatrix.scale(
    new THREE.Vector3(
      props.config.backendProperties.scale[0],
      props.config.backendProperties.scale[1],
      props.config.backendProperties.scale[2]
    )
  );

  return (
    <group
      position={props.config.backendProperties.position}
      rotation={props.config.backendProperties.rotation}
      scale={props.config.backendProperties.scale}
    >
      <UiObject config={props.config} />
    </group>
  );
}

const UiObject = (props: { config: UIObject }) => {
  // const { uiInstances, setUiInstances } = useContext<ContextType>(context);
  const { handleSceneObjectAction } = useSceneViewer();
  const { getCacheItem, cacheItem } = useCache();
  const isInitializing = useAppSelector((s) => s.app.isInitializing);

  useEffect(() => {
    let absAngle = Math.abs(props.config.backendProperties.metadata.curve_angle);
    let bbox = new THREE.Box3().setFromCenterAndSize(
      new THREE.Vector3(),
      new THREE.Vector3(
        props.config.backendProperties.metadata.width,
        props.config.backendProperties.metadata.height,
        0.0
      )
    );
    if (absAngle !== 0.0) {
      let ratio = absAngle / (2 * Math.PI);
      let radius = props.config.backendProperties.metadata.width / (2 * Math.PI * ratio);
      let bendDirection = props.config.backendProperties.metadata.curve_angle >= 0 ? 1 : -1;
      const tempPoint = new THREE.Vector2(0, 0).rotateAround(
        new THREE.Vector2(0, radius),
        absAngle / 2.0
      );
      const maxZ = tempPoint.y * bendDirection;
      bbox = new THREE.Box3().setFromCenterAndSize(
        new THREE.Vector3(0.0, 0.0, maxZ / 2.0),
        new THREE.Vector3(
          props.config.backendProperties.metadata.width,
          props.config.backendProperties.metadata.height,
          Math.abs(maxZ)
        )
      );
    }

    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: props.config.id,
        type: props.config.type,
        localProperties: {
          originalBBox: bbox,
        },
        backendProperties: {},
      },
    ]);
  }, [
    props.config.backendProperties.metadata.curve_angle,
    props.config.backendProperties.metadata.height,
    props.config.backendProperties.metadata.width,
  ]);

  const material_URL = useMemo(() => {
    const workspace = store.getState().app.currentUser?.workspace_id;
    const projectId = store.getState().app.projectId;

    const stored = localStorage.getItem(getCloudStorageCacheKey(workspace!));
    const cachedItem = stored ? JSON.parse(stored) : undefined;

    if (!cachedItem) return '';

    const cloud = new CloudStorageManager(cachedItem.provider, cachedItem.config);
    const fileName = props.config.backendProperties.metadata.material_url;

    const key = `${uploadProjectAssetPath('ui', workspace!, projectId)}/${fileName}`;
    const url = getCacheItem(key) || cloud.getSignedUrl(key);

    cacheItem(key, url);

    return url;
  }, [isInitializing]);

  return (
    <Image
      radius={props.config.backendProperties.metadata.corner_radius}
      segments={props.config.backendProperties.metadata.corner_steps}
      url={material_URL}
      transparent
      side={THREE.DoubleSide}
    >
      <bentPlaneGeometry
        args={[
          props.config.backendProperties.metadata.curve_angle,
          props.config.backendProperties.metadata.width,
          props.config.backendProperties.metadata.height,
          props.config.backendProperties.metadata.curve_steps,
          props.config.backendProperties.metadata.curve_steps,
        ]}
      />
    </Image>
  );
};

export default PreviewUiAsset;
