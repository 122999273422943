import { Stack, Kbd, Text, Flex } from '@chakra-ui/react';

interface ShortcutProps {
  shortcut: any;
  kbd?: boolean;
  label?: string;
}

const replaceKey = (key: string) => {
  switch (key) {
    case 'ArrowUp':
      return '↑';
    case 'ArrowDown':
      return '↓';
    case 'ArrowLeft':
      return '←';
    case 'ArrowRight':
      return '→';
    case '$mod':
      return 'CMD';
    case 'Delete':
    case 'Backspace':
      return '⌫';
    default:
      return key.toUpperCase();
  }
};

export const Shortcut = ({ label, shortcut, kbd = true }: ShortcutProps) => {
  if (!shortcut) return <></>;

  const title = label ?? shortcut.title;

  return (
    <Stack
      direction="row"
      align="center"
      w="100%"
      gap={1}
      justify="space-between"
      userSelect="none"
    >
      <Text fontSize="sm" m={0}>
        {title}
      </Text>
      {!shortcut.hidden && (
        <Flex gap={1}>
          {shortcut.keystroke.split('+').map((key: string, index: number) => (
            <Kbd size="xs" color="gray.200" fontSize="smaller" key={title + key} variant="dashy">
              {replaceKey(key)}
            </Kbd>
          ))}
        </Flex>
      )}
    </Stack>
  );
};
