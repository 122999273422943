const PlayIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={15} stroke="#000" viewBox="-0.5 0 7 7">
      <g>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g fill="#fff" transform="translate(-347 -3766)">
            <g transform="translate(56 160)">
              <path d="M296.495 3608.573l-3.994-2.43c-.669-.408-1.501.107-1.501.926v4.862c0 .82.832 1.333 1.5.927l3.995-2.43c.673-.41.673-1.445 0-1.855"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PlayIcon;
