import { useToast } from '@chakra-ui/react';
import { supabase } from 'src/SupabaseClient';
import { identifyUser, resetUser, trackEvent } from 'src/services/analytics';
import { EVENTS, PROPERTIES } from 'src/services/analytics/events';
import { setCurrentUser } from 'src/store/reducers/app';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { addUser } from 'src/store/reducers/users';
import { ROUTES } from 'src/utils/constants';
import { getCurrentUser } from 'src/utils/requests';
import { Provider } from '@supabase/supabase-js';

type LoginParams = {
  email: string;
  password: string;
};

type SignupParams = {
  fullname: string;
  email: string;
  password: string;
};

export const useAuth = () => {
  const dispatch = useAppDispatch();
  // const { clearStores } = useAppStore();
  const toast = useToast();
  const isAuthenticated = useAppSelector((store) => store.app.isAuthenticated);

  const addAuthenticatedUser = (newLoggedInUser: any) => {
    const user = getCurrentUser(newLoggedInUser);
    // const lastLoggedInUser = store.getState().app.lastLoggedIn;

    if (user) {
      // if (lastLoggedInUser !== user?.id) {
      //   clearStores();
      // }

      identifyUser(user.email, {
        [PROPERTIES.USER_ID]: user.id,
        [PROPERTIES.USER_EMAIL]: user.email,
      });

      dispatch(setCurrentUser(user));
      dispatch(addUser(user));

      trackEvent(EVENTS.LOGGED_IN, {
        [PROPERTIES.USER_ID]: user.id,
        [PROPERTIES.USER_EMAIL]: user.email,
      });
    }
  };

  const login = async (params: LoginParams, redirectTo: string = ROUTES.projects) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword(params);

      if (error) throw error;

      addAuthenticatedUser(data?.user);

      return { error };
    } catch (error) {
      return {
        error,
      };
    }
  };

  const loginWithToken = async (token: string) => {
    const { data } = await supabase.auth.signInWithIdToken({
      provider: 'google',
      token,
    });

    if (data) {
      addAuthenticatedUser(data.user);
    }
  };

  const loginWithProvider = async (provider: Provider) => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider,
      options: {
        queryParams: {
          access_type: 'offline',
          prompt: 'consent',
        },
        redirectTo: window.location.origin,
        // skipBrowserRedirect: true,
      },
    });

    switch (provider) {
      case 'google':
        if (data.url) return openPopup(data.url);
        break;
      default:
        break;
    }

    return { error };
  };

  const openPopup = (url: string) => {
    const width = 500;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    // window features for popup
    const windowFeatures = `scrollbars=no, resizable=no, copyhistory=no, location=no, width=${width}, height=${height}, top=${top}, left=${left}`;
    const popup = window.open(url, 'popup', windowFeatures);
    return popup;
  };

  const signup = async (params: SignupParams) => {
    try {
      trackEvent(EVENTS.SIGNUP_START, { [PROPERTIES.USER_EMAIL]: params.email });
      const { data, error } = await supabase.auth.signUp({
        ...params,
        options: {
          data: {
            username: params.email,
            full_name: params.fullname,
          },
        },
      });

      if (error) {
        trackEvent(EVENTS.SIGNUP_ERROR, {
          [PROPERTIES.USER_EMAIL]: params.email,
          [PROPERTIES.ERROR_MSG]: error.message,
        });
        throw error;
      }

      if (data) {
        trackEvent(EVENTS.SIGNUP_COMPLETE, {
          [PROPERTIES.USER_EMAIL]: params.email,
          [PROPERTIES.FULLNAME]: params.fullname,
        });

        toast({
          title: 'Check your email for verification link',
          status: 'success',
          duration: 8000,
          isClosable: true,
        });
      }

      return { error };
    } catch (error) {
      return { error };
    }
  };

  const logout = async () => {
    try {
      await supabase.auth.signOut();

      resetUser();
      dispatch(setCurrentUser(null));
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return {
    login,
    logout,
    signup,
    loginWithToken,
    loginWithProvider,
    addAuthenticatedUser,
    isAuthenticated,
  };
};
