import { Avatar, AvatarProps, Flex } from '@chakra-ui/react';
import { useAppSelector } from 'src/store/reducers/hook';
import store from 'src/store/store';
import { stringToColor } from '../utils/colors';

type UserAvatarProps = AvatarProps;
export const UserAvatar = (props: UserAvatarProps) => {
  const { id, ...restProps } = props;
  const isFollowing = useAppSelector((store) => store.collaboration.following) === id;

  const avatarProps = {
    id,
    size: 'sm',
    color: 'white',
    ...(id ? { bg: stringToColor(id) } : {}),
    ...restProps,
  };

  return (
    <Avatar
      // @ts-ignore;
      {...avatarProps}
      transition="border 200ms ease-in"
      border={isFollowing ? '2px solid white' : '2px solid transparent'}
    />
  );
};

type AvatarsProps = { users: Array<any>; onClick?: (userId: string) => void };

export const Avatars = (props: AvatarsProps) => {
  const { users } = props;

  const handleOnClick = (userId: string) => {
    if (!userId) return;

    if (props.onClick) props.onClick(userId);
  };

  const loggedInUser = store.getState().app.currentUser;
  const restUsers = users?.filter((user) => user?.id !== loggedInUser?.id);
  const currentUser = users?.find((user) => user?.id === loggedInUser?.id);

  return (
    <Flex px={1} gap={1}>
      {restUsers?.map((user, index) => (
        <UserAvatar
          onClick={() => handleOnClick(user?.id)}
          id={user?.id}
          key={user?.id + index}
          userSelect="none"
          src={user?.avatar_url}
          cursor={props.onClick && 'pointer'}
          name={user?.fullname || user?.username}
        />
      ))}
      {currentUser && (
        <UserAvatar
          id={currentUser.id}
          userSelect="none"
          src={currentUser.avatar_url}
          key={currentUser.id}
          name={currentUser.fullname || currentUser.username}
        />
      )}
    </Flex>
  );
};
