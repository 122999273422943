import { useContext, ChangeEvent } from 'react';
import { context, ContextType } from 'src/components/sceneViewer/context';
import PropertyField from './PropertyField';
import PropertyHeading from './PropertyHeading';
import { radToDeg, degToRad, applyProportionalScaling, quaternionToEuler } from 'src/utils/helper';
import {
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from '@chakra-ui/react';
import { BiExpandVertical, BiCollapseVertical } from 'react-icons/bi';
import { SceneObjectActionTypes, SupportedSceneObjectTypes, bboxInfoInterface } from 'src/types';
import * as THREE from 'three';
import { useSceneViewer } from '../hooks/useSceneViewer';
import { getSceneObject, getWorldTransform, toLocalTransform } from '../helpers';
import { useSceneInteractions } from '../hooks/useSceneInteractions';
import { useAppSelector } from 'src/store/reducers/hook';
enum axis {
  x = 'x',
  y = 'y',
  z = 'z',
}
enum alignType {
  min = 'min',
  center = 'center',
  max = 'max',
  distribute = 'distribute',
}
export default function MultiSelectProperties() {
  // scene_scale is used to scale the grid to the size of the scene. (1 = cm, 0.01 = m)
  const scene_scale = 1.0;

  const { multiSelectConstrainProportions, setMultiSelectConstrainProportions } =
    useContext<ContextType>(context);
  const selectedObjects = useAppSelector((store) => store.sceneViewer.selectedObjects);
  const { handleSceneObjectAction, onSelectObject } = useSceneViewer();
  const { onBBoxUpdate } = useSceneInteractions();
  const bboxInfo = useAppSelector((store) => store.sceneViewer.bboxInfo);
  const getObjectMatrix = (type: SupportedSceneObjectTypes, id: string) => {
    let position = [0.0, 0.0, 0.0];
    let rotation = [0.0, 0.0, 0.0];
    let scale = [1.0, 1.0, 1.0];

    const sceneObject = getSceneObject(id);
    if (sceneObject) {
      const worldTransform = getWorldTransform(sceneObject.id);
      // position = sceneObject.backendProperties.position;
      // rotation = sceneObject.backendProperties.rotation;
      // if (sceneObject.type !== SupportedSceneObjectTypes.viewport) {
      //     scale = sceneObject.backendProperties.scale;
      // }

      position = worldTransform.position;
      rotation = worldTransform.rotation;
      scale = worldTransform.scale;
    }

    const matrix = new THREE.Matrix4().compose(
      new THREE.Vector3(position[0], position[1], position[2]),
      new THREE.Quaternion().setFromEuler(new THREE.Euler(rotation[0], rotation[1], rotation[2])),
      new THREE.Vector3(scale[0], scale[1], scale[2])
    );

    return matrix;
  };

  const handlePropertyChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: string,
    propIndex: number
  ) => {
    let position = [...bboxInfo.position];
    let rotation = [...bboxInfo.rotation];
    let scale = [...bboxInfo.scale];

    if (field === 'position') {
      const updatedValue = parseFloat(event.target.value) / scene_scale;
      position[propIndex] = updatedValue;
    } else if (field === 'rotation') {
      rotation[propIndex] = degToRad(parseFloat(event.target.value));
    } else if (field === 'scale') {
      scale[propIndex] = parseFloat(event.target.value);
    } else if (field === 'bbox') {
      const size = new THREE.Vector3(1, 1, 1);
      bboxInfo.refBBox?.getSize(size);
      const oldDim = propIndex === 0 ? size.x : propIndex === 1 ? size.y : size.z;
      scale[propIndex] = parseFloat(event.target.value) / scene_scale / oldDim;
    }

    if (multiSelectConstrainProportions) {
      scale = applyProportionalScaling([...bboxInfo.scale] as any, [...scale] as any);
    }

    const updatedMatrix = new THREE.Matrix4().compose(
      new THREE.Vector3(position[0], position[1], position[2]),
      new THREE.Quaternion().setFromEuler(new THREE.Euler(rotation[0], rotation[1], rotation[2])),
      new THREE.Vector3(scale[0], scale[1], scale[2])
    );

    const oldMatrix = new THREE.Matrix4().compose(
      new THREE.Vector3(bboxInfo.position[0], bboxInfo.position[1], bboxInfo.position[2]),
      new THREE.Quaternion().setFromEuler(
        new THREE.Euler(bboxInfo.rotation[0], bboxInfo.rotation[1], bboxInfo.rotation[2])
      ),
      new THREE.Vector3(bboxInfo.scale[0], bboxInfo.scale[1], bboxInfo.scale[2])
    );
    const changeMatrix = updatedMatrix.multiply(oldMatrix.invert());

    selectedObjects.map((selected) => {
      const objectMatrix = getObjectMatrix(selected.type, selected.id);
      if (objectMatrix !== undefined) {
        const finalMatrix = changeMatrix.clone();
        finalMatrix.copy(finalMatrix.multiply(objectMatrix.clone()));
        const finalPosition = new THREE.Vector3();
        const finalRotation = new THREE.Quaternion();
        const finalScale = new THREE.Vector3(1, 1, 1);
        finalMatrix.decompose(finalPosition, finalRotation, finalScale);

        const p = [finalPosition.x, finalPosition.y, finalPosition.z];
        const r = quaternionToEuler([
          finalRotation.x,
          finalRotation.y,
          finalRotation.z,
          finalRotation.w,
        ]);
        const s = [finalScale.x, finalScale.y, finalScale.z];

        const localTransform = toLocalTransform(selected.id, {
          position: p as [number, number, number],
          rotation: r as [number, number, number],
          scale: s as [number, number, number],
        });

        handleSceneObjectAction(SceneObjectActionTypes.update, [
          {
            id: selected.id,
            type: selected.type,
            localProperties: {},
            backendProperties:
              selected.type === SupportedSceneObjectTypes.viewport
                ? {
                    position: localTransform.position,
                    rotation: localTransform.rotation,
                  }
                : {
                    position: localTransform.position,
                    rotation: localTransform.rotation,
                    scale: localTransform.scale,
                  },
          },
        ]);
      }
    });
    onBBoxUpdate({
      position: position,
      rotation: rotation,
      scale: scale,
    } as Partial<bboxInfoInterface>);
  };

  const getSortedObjectsAlongAxis = (axis: axis, availableSpace: number) => {
    let totalspace = 0;
    let gap = 0;

    let objects = selectedObjects.map((selectedObject) => {
      let objectPosition = [0, 0, 0];
      let objectRotation = [0, 0, 0];
      let objectScale = [1, 1, 1];
      let objectBBox = undefined;
      let sceneObject = getSceneObject(selectedObject.id);
      if (sceneObject) {
        const worldTransform = getWorldTransform(selectedObject.id);
        objectPosition = worldTransform.position;
        objectRotation = worldTransform.rotation;
        objectBBox = sceneObject.localProperties.originalBBox?.clone();
        objectScale = worldTransform.scale;
      }

      if (objectBBox) {
        objectBBox.applyMatrix4(
          new THREE.Matrix4().compose(
            new THREE.Vector3(objectPosition[0], objectPosition[1], objectPosition[2]),
            new THREE.Quaternion().setFromEuler(
              new THREE.Euler(objectRotation[0], objectRotation[1], objectRotation[2])
            ),
            new THREE.Vector3(objectScale[0], objectScale[1], objectScale[2])
          )
        );
        totalspace += objectBBox.max[axis] - objectBBox.min[axis];

        return {
          id: selectedObject.id,
          bbox: objectBBox,
        };
      }
      return null;
    });

    objects = objects.filter((object) => object !== null);
    // @ts-ignore
    objects?.sort((a, b) => {
      if (a?.bbox.min[axis] && b?.bbox.min[axis]) {
        return a?.bbox.min[axis] - b?.bbox.min[axis];
      }
    });
    gap = (availableSpace - totalspace) / (objects.length - 1);
    let position: {
      [key: string]: {
        min: number;
        max: number;
      };
    } = {};
    for (let i = 0; i < objects.length; i++) {
      if (i > 0 && i < objects.length - 1) {
        position[objects[i]!.id] = {
          min: position[objects[i - 1]!.id].max + gap,
          max:
            position[objects[i - 1]!.id].max +
            gap +
            objects[i]!.bbox.max[axis] -
            objects[i]!.bbox.min[axis],
        };
      } else {
        position[objects[i]!.id] = {
          min: objects[i]!.bbox.min[axis],
          max: objects[i]!.bbox.max[axis],
        };
      }
    }

    return { position: position };
  };

  const handleAlignment = (axis: axis, type: alignType) => {
    let Parentbbox = bboxInfo.refBBox?.clone();
    const pendingUpdates = [] as any[];

    if (Parentbbox) {
      Parentbbox.applyMatrix4(
        new THREE.Matrix4().compose(
          new THREE.Vector3(bboxInfo.position[0], bboxInfo.position[1], bboxInfo.position[2]),
          new THREE.Quaternion().setFromEuler(
            new THREE.Euler(bboxInfo.rotation[0], bboxInfo.rotation[1], bboxInfo.rotation[2])
          ),
          new THREE.Vector3(bboxInfo.scale[0], bboxInfo.scale[1], bboxInfo.scale[2])
        )
      );
    }
    let sortedobjects: {
      position: {
        [key: string]: {
          min: number;
          max: number;
        };
      };
    } = {
      position: {},
    };
    if (type === alignType.distribute && Parentbbox) {
      let availableSpace = Parentbbox.max[axis] - Parentbbox.min[axis];
      sortedobjects = getSortedObjectsAlongAxis(axis, availableSpace);
    }

    selectedObjects.map((selectedObject, index) => {
      let objectPosition = [0, 0, 0];
      let objectRotation = [0, 0, 0];
      let objectScale = [1, 1, 1];
      let objectBBox = undefined;
      let sceneObject = getSceneObject(selectedObject.id);
      let offset = [0, 0, 0];
      if (sceneObject) {
        const worldTransform = getWorldTransform(selectedObject.id);
        objectPosition = worldTransform.position;
        objectRotation = worldTransform.rotation;
        objectBBox = sceneObject.localProperties.originalBBox?.clone();
        objectScale = worldTransform.scale;
      }

      if (objectBBox) {
        objectBBox.applyMatrix4(
          new THREE.Matrix4().compose(
            new THREE.Vector3(objectPosition[0], objectPosition[1], objectPosition[2]),
            new THREE.Quaternion().setFromEuler(
              new THREE.Euler(objectRotation[0], objectRotation[1], objectRotation[2])
            ),
            new THREE.Vector3(objectScale[0], objectScale[1], objectScale[2])
          )
        );
        let CurrentCenterValue = new THREE.Vector3();
        objectBBox.getCenter(CurrentCenterValue);

        offset[0] = CurrentCenterValue.x - objectPosition[0];
        offset[1] = CurrentCenterValue.y - objectPosition[1];
        offset[2] = CurrentCenterValue.z - objectPosition[2];
        if (Parentbbox) {
          if (type === alignType.max) {
            let dx = Math.max(objectBBox.max[axis], objectBBox.min[axis]);
            objectBBox.max[axis] += Parentbbox.max[axis] - dx;
            objectBBox.min[axis] += Parentbbox.max[axis] - dx;
          }
          if (type === alignType.min) {
            let dx = Math.min(objectBBox.max[axis], objectBBox.min[axis]);
            objectBBox.max[axis] -= dx - Parentbbox.min[axis];
            objectBBox.min[axis] -= dx - Parentbbox.min[axis];
          }
          if (type === alignType.center) {
            let ObjectCenterValue = new THREE.Vector3();
            let ParentCenterValue = new THREE.Vector3();
            objectBBox.getCenter(ObjectCenterValue);
            Parentbbox.getCenter(ParentCenterValue);
            let dx = ParentCenterValue[axis] - ObjectCenterValue[axis];
            objectBBox.max[axis] += dx;
            objectBBox.min[axis] += dx;
          }
          if (type === alignType.distribute) {
            objectBBox.min[axis] = sortedobjects.position[selectedObject.id].min;
            objectBBox.max[axis] = sortedobjects.position[selectedObject.id].max;
          }
        }
        let NewCenterValue = new THREE.Vector3();
        objectBBox.getCenter(NewCenterValue);
        NewCenterValue.x -= offset[0];
        NewCenterValue.y -= offset[1];
        NewCenterValue.z -= offset[2];
        let p = [NewCenterValue.x, NewCenterValue.y, NewCenterValue.z];
        const localTransform = toLocalTransform(selectedObject.id, {
          position: p as [number, number, number],
          rotation: objectRotation as [number, number, number],
          scale: objectScale as [number, number, number],
        });
        pendingUpdates.push({
          type: selectedObject.type,
          id: selectedObject.id,
          localProperties: {},
          backendProperties:
            selectedObject.type === SupportedSceneObjectTypes.viewport
              ? {
                  position: localTransform.position,
                  rotation: localTransform.rotation,
                }
              : {
                  position: localTransform.position,
                  rotation: localTransform.rotation,
                  scale: localTransform.scale,
                },
        });
      }
    });
    handleSceneObjectAction(SceneObjectActionTypes.update, pendingUpdates);
    onSelectObject([]);
    onSelectObject([...selectedObjects]);
  };

  let properties = null;

  if (selectedObjects.length > 1) {
    let boundingBoxProperties = null;
    let alingmentProperties = null;
    const size = new THREE.Vector3();
    bboxInfo.refBBox?.getSize(size);
    const width = size.x * bboxInfo.scale[0] * scene_scale;
    const height = size.y * bboxInfo.scale[1] * scene_scale;
    const depth = size.z * bboxInfo.scale[2] * scene_scale;
    boundingBoxProperties = (
      <>
        <br />
        <PropertyHeading>Bounding Box</PropertyHeading>
        <label className="label">Dimension</label>
        <PropertyField
          value={width.toFixed(2)}
          field="bbox"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handlePropertyChange}
          type="number"
        />
        <PropertyField
          value={height.toFixed(2)}
          field="bbox"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handlePropertyChange}
          type="number"
        />
        <PropertyField
          value={depth.toFixed(2)}
          field="bbox"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handlePropertyChange}
          type="number"
        />
      </>
    );

    alingmentProperties = (
      <Flex minWidth="auto" flexWrap={'wrap'} alignItems="center" gap="0">
        <Tooltip label="Align min X" zIndex={1001} bg="grey">
          <Button
            onClick={() => {
              handleAlignment(axis.x, alignType.min);
            }}
            variant={'ghost'}
            width={'2px'}
            p={0}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              id="icon"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 14.5H1V1.5H2V14.5ZM14 6.5H4V4.5H14V6.5ZM4 11.5H10V9.5H4V11.5Z"
                fill="white"
              ></path>
            </svg>
          </Button>
        </Tooltip>
        <Tooltip label="Align center X" zIndex={1001} bg="grey">
          <Button
            onClick={() => {
              handleAlignment(axis.x, alignType.center);
            }}
            variant={'ghost'}
            width={'10px'}
            p={0}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              id="icon"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 1.5H7V4.5H2V6.5H7V9.5H4V11.5H7V14.5H8V11.5H11V9.5H8V6.5H13V4.5H8V1.5Z"
                fill="white"
              ></path>
            </svg>
          </Button>
        </Tooltip>
        <Tooltip label="Align max X" zIndex={1001} bg="grey">
          <Button
            onClick={() => {
              handleAlignment(axis.x, alignType.max);
            }}
            variant={'ghost'}
            width={'10px'}
            p={0}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              id="icon"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 14.5H15V1.5H14V14.5ZM2 6.5H12V4.5H2V6.5ZM12 11.5H6V9.5H12V11.5Z"
                fill="white"
              ></path>
            </svg>
          </Button>
        </Tooltip>
        <Tooltip label="Align max Y" zIndex={1001} bg="grey">
          <Button
            onClick={() => {
              handleAlignment(axis.y, alignType.max);
            }}
            variant={'ghost'}
            width={'10px'}
            p={0}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              id="icon"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 14V4H4V14H6ZM14 2V1H1V2H14ZM11 4V10H9V4H11Z"
                fill="white"
              ></path>
            </svg>
          </Button>
        </Tooltip>
        <Tooltip label="Align center Y" zIndex={1001} bg="grey">
          <Button
            onClick={() => {
              handleAlignment(axis.y, alignType.center);
            }}
            variant={'ghost'}
            width={'10px'}
            p={0}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              id="icon"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 7V2H6V7H9V4H11V7H14V8H11V11H9V8H6V13H4V8H1V7H4Z"
                fill="white"
              ></path>
            </svg>
          </Button>
        </Tooltip>
        <Tooltip label="Align min Y" zIndex={1001} bg="grey">
          <Button
            onClick={() => {
              handleAlignment(axis.y, alignType.min);
            }}
            variant={'ghost'}
            width={'10px'}
            p={0}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              id="icon"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 2V12H4V2H6ZM14 14V15H1V14H14ZM11 12V6H9V12H11Z"
                fill="white"
              ></path>
            </svg>
          </Button>
        </Tooltip>

        <Menu>
          <Tooltip label="Align Z.." bg="grey">
            <MenuButton
              variant={'ghost'}
              paddingLeft={3}
              alignItems={'center'}
              width={'10px'}
              as={Button}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                id="icon"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 6.5C3.82843 6.5 4.5 7.17157 4.5 8C4.5 8.82843 3.82843 9.5 3 9.5C2.17157 9.5 1.5 8.82843 1.5 8C1.5 7.17157 2.17157 6.5 3 6.5ZM8 6.5C8.82843 6.5 9.5 7.17157 9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5ZM14.5 8C14.5 7.17157 13.8284 6.5 13 6.5C12.1716 6.5 11.5 7.17157 11.5 8C11.5 8.82843 12.1716 9.5 13 9.5C13.8284 9.5 14.5 8.82843 14.5 8Z"
                  fill="white"
                ></path>
              </svg>
            </MenuButton>
          </Tooltip>
          <MenuList>
            <MenuItem
              onClick={() => {
                handleAlignment(axis.z, alignType.min);
              }}
            >
              Align min Z
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleAlignment(axis.z, alignType.center);
              }}
            >
              Align center Z
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleAlignment(axis.z, alignType.max);
              }}
            >
              Align max Z
            </MenuItem>
          </MenuList>
        </Menu>
        <Menu>
          <Tooltip label="Distribute..." bg="grey">
            <MenuButton
              variant={'ghost'}
              paddingLeft={3}
              alignItems={'center'}
              width={'10px'}
              as={Button}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                id="icon"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 15L2 1H3L3 15H2ZM13 15L13 1H14L14 15H13ZM7 4L7 12H9L9 4H7Z"
                  fill="white"
                ></path>
              </svg>
            </MenuButton>
          </Tooltip>
          <MenuList>
            <MenuItem
              onClick={() => {
                handleAlignment(axis.x, alignType.distribute);
              }}
            >
              Distribute X
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleAlignment(axis.y, alignType.distribute);
              }}
            >
              Distribute Y
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleAlignment(axis.z, alignType.distribute);
              }}
            >
              Distribute Z
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    );

    properties = (
      <div className="multiSelectProperties">
        {alingmentProperties}
        <PropertyHeading>Transform</PropertyHeading>
        <label className="label">Position</label>
        <PropertyField
          value={(bboxInfo.position[0] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handlePropertyChange}
          type="number"
        />
        <PropertyField
          value={(bboxInfo.position[1] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handlePropertyChange}
          type="number"
        />
        <PropertyField
          value={(bboxInfo.position[2] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handlePropertyChange}
          type="number"
        />

        <br />
        <label className="label">Rotation</label>
        <PropertyField
          value={radToDeg(bboxInfo.rotation[0]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handlePropertyChange}
          type="number"
        />
        <PropertyField
          value={radToDeg(bboxInfo.rotation[1]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handlePropertyChange}
          type="number"
        />
        <PropertyField
          value={radToDeg(bboxInfo.rotation[2]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handlePropertyChange}
          type="number"
        />

        <br />
        <label className="label">Scale</label>
        <PropertyField
          value={bboxInfo.scale[0].toFixed(2)}
          field="scale"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handlePropertyChange}
          type="number"
        />
        <PropertyField
          value={bboxInfo.scale[1].toFixed(2)}
          field="scale"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handlePropertyChange}
          type="number"
        />
        <PropertyField
          value={bboxInfo.scale[2].toFixed(2)}
          field="scale"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handlePropertyChange}
          type="number"
        />

        <Tooltip label="Constrain Proportions" zIndex={1001} bg="grey">
          <Button
            className={`prop-btn ${multiSelectConstrainProportions ? 'prop-btn-active' : ''}`}
            size="sm"
            onClick={() => {
              setMultiSelectConstrainProportions(!multiSelectConstrainProportions);
            }}
          >
            <Icon
              color="white"
              as={multiSelectConstrainProportions ? BiCollapseVertical : BiExpandVertical}
            ></Icon>
          </Button>
        </Tooltip>
        {boundingBoxProperties}
      </div>
    );
  }

  return <>{properties}</>;
}
