import { SupportedFiles, SupportedSceneObjectTypes } from 'src/types';

export const getSupportedFiles = (files: FileList) => {
  return Array.from(files).filter((file) => {
    return getFileType(file);
  });
};

export const getFileType = (file: File): keyof typeof SupportedFiles | undefined => {
  if (file.type === '') {
    const typeFromName = file.name.split('.').pop();
    if (typeFromName && SupportedFiles['3D'].includes(typeFromName)) return '3D';
  }

  if (SupportedFiles.ui.includes(file.type)) return 'ui';
};

export const getFileTypeFromExtension = (path: string) => {
  const extension = getFileExtension({ name: path });

  const fileType = {
    type: extension,
    category: null,
  } as any;

  Object.keys(SupportedFiles).forEach((fileCategory) => {
    const types = SupportedFiles[fileCategory as keyof typeof SupportedFiles];

    if (types.includes(extension)) {
      fileType.category = fileCategory;
    }
  });

  return fileType;
};

export const getFileExtension = (file: File | { name: string }): string => {
  const extension = file.name.split('.').pop();
  return (extension as string).toLowerCase();
};

export const getNameWithoutExtension = (fileName: string): string => {
  const filename_and_extensions = fileName.split('.');
  return filename_and_extensions[filename_and_extensions.length - 2];
};

export const getAssetTypeFromName = (fileName: string): SupportedSceneObjectTypes => {
  const type = getFileTypeFromExtension(fileName);
  switch (type.categroy) {
    case 'ui':
      return SupportedSceneObjectTypes.ui;
    default:
      return SupportedSceneObjectTypes.asset;
  }
};
