import { useEffect, useState } from 'react';

import { useAppSelector } from 'src/store/reducers/hook';
import { setCurrentMaterial } from 'src/store/reducers/sceneViewer';
import store from 'src/store/store';

import AssetMaterials from './AssetMaterials';
import MaterialMap from './MaterialMap';

const MaterialsPropertyContainer = ({ selectedObjects }: any) => {
  const currentMaterial = useAppSelector((store) => store.sceneViewer.currentMaterial);
  const [editMap, setEditMap] = useState<{
    show: boolean;
    type: string;
  }>({
    show: false,
    type: '',
  });

  useEffect(() => {
    store.dispatch(
      setCurrentMaterial({
        show: false,
        current: {} as any,
      })
    );
  }, [selectedObjects]);
  useEffect(() => {
    if (currentMaterial?.show === false) {
      setEditMap({
        show: false,
        type: '',
      });
    }
  }, [currentMaterial?.show]);
  if (!currentMaterial?.show || !selectedObjects?.length) return <></>;

  return (
    <>
      <div className={`sidePanel MaterialPanel panel`} style={{ display: 'block' }}>
        <AssetMaterials
          currentMaterial={currentMaterial.current}
          show={currentMaterial.show}
          setEditMap={setEditMap}
        />
      </div>
      {editMap.show && (
        <div className={`mapPanel MaterialMapPanel panel`} style={{ display: 'block' }}>
          <MaterialMap
            currentMaterial={currentMaterial.current}
            show={currentMaterial.show}
            editMap={editMap}
            setEditMap={setEditMap}
          />
        </div>
      )}
    </>
  );
};

export default MaterialsPropertyContainer;
