import * as Sentry from '@sentry/react';
import { IS_DEV } from 'src/utils/constants';

export const initSentry = () => {
  if (IS_DEV) return;

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', process.env.REACT_APP_SUPABASE_URL],
  });
};
