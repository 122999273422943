import { useEffect, useRef, useState } from 'react';
import PropertyHeading from './PropertyHeading';

import {
  AssetObject,
  AssetObjectAnimation,
  SceneEventActionTypes,
  SceneEventTriggerTargets,
  SceneObjectActionTypes,
  SupportedSceneObjectTypes,
} from 'src/types';
import { useAppSelector } from 'src/store/reducers/hook';
import { useSceneViewer } from '../hooks/useSceneViewer';
import { Flex, Input, Select, Tooltip } from '@chakra-ui/react';
import { getSceneObject } from '../helpers';
import { events } from '@react-three/fiber';
import PropertyField from './PropertyField';
import { CloseIcon, PlusSquareIcon } from '@chakra-ui/icons';

export default function AssetEvents(props: { id: string; index: string }) {
  // scene_scale is used to scale the grid to the size of the scene. (1 = cm, 0.01 = m)

  const { handleSceneObjectAction, handleSceneEventsAction } = useSceneViewer();

  const assetSceneObject = useAppSelector(
    (store) => store.sceneViewer.entities[props.id]
  ) as AssetObject;

  const sceneObjectList = useAppSelector((store) => store.sceneViewer.entities);
  const assetEvent = useAppSelector((store) => store.sceneViewer.events[props.index]);
  const showEvents = useAppSelector((store) => store.sceneViewer.showEvents);
  const handleTriggerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let newAssetEvent = { ...assetEvent };
    newAssetEvent.trigger = e.target.value;
    handleSceneEventsAction(SceneEventActionTypes.update, [newAssetEvent]);
  };
  const handleTargetChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let newAssetEvent = { ...assetEvent };
    newAssetEvent.target = e.target.value;
    handleSceneEventsAction(SceneEventActionTypes.update, [newAssetEvent]);
  };
  const handleAddSequence = () => {
    let newAssetEvent = { ...assetEvent };

    // Clone the timeline array to ensure it's extensible
    newAssetEvent.sequence = {
      ...newAssetEvent.sequence,
      timeline: [...newAssetEvent.sequence.timeline],
    };

    // Add the new sequence
    newAssetEvent.sequence.timeline.push({
      name: assetSceneObject.backendProperties.animation.states[0].name,
      duration: 1,
      delay: 0,
    });

    handleSceneEventsAction(SceneEventActionTypes.update, [newAssetEvent]);
  };
  const handleRemoveSequence = (index: number) => {
    let newAssetEvent = { ...assetEvent };

    // Clone the timeline array to ensure it's extensible
    newAssetEvent.sequence = {
      ...newAssetEvent.sequence,
      timeline: [...newAssetEvent.sequence.timeline],
    };

    // Remove the sequence
    newAssetEvent.sequence.timeline.splice(index, 1);

    handleSceneEventsAction(SceneEventActionTypes.update, [newAssetEvent]);
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    let newAssetEvent = { ...assetEvent };
    // Clone the timeline array to ensure it's extensible
    newAssetEvent.sequence = {
      ...newAssetEvent.sequence,
      timeline: newAssetEvent.sequence.timeline.map((item) => ({ ...item })),
    };
    // Update the state name
    newAssetEvent.sequence.timeline[index].name = e.target.value;
    handleSceneEventsAction(SceneEventActionTypes.update, [newAssetEvent]);
  };
  const handleDurationDelayChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    action: string,
    index: number
  ) => {
    let newAssetEvent = { ...assetEvent };
    newAssetEvent.sequence = {
      ...newAssetEvent.sequence,
      timeline: newAssetEvent.sequence.timeline.map((item) => ({ ...item })),
    };
    if (action === 'duration') {
      newAssetEvent.sequence.timeline[index].duration = parseFloat(e.target.value);
    } else {
      newAssetEvent.sequence.timeline[index].delay = parseFloat(e.target.value);
    }
    handleSceneEventsAction(SceneEventActionTypes.update, [newAssetEvent]);
  };

  const handleTargetObjectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let newAssetEvent = { ...assetEvent };
    newAssetEvent.targetObject = e.target.value;
    handleSceneEventsAction(SceneEventActionTypes.update, [newAssetEvent]);
  };
  let assetProperties = null;
  useEffect(() => {}, [assetSceneObject, showEvents.show]);
  if (assetSceneObject && assetEvent) {
    assetProperties = (
      <div style={{ padding: '10px' }} className="assetMaterials">
        <PropertyHeading>Events : {assetEvent.name}</PropertyHeading>
        <Flex>
          <label className="label">trigger</label>
          <Select
            px={2}
            value={assetEvent.trigger}
            onChange={handleTriggerChange}
            variant="unstyled"
            height={'30px'}
          >
            <option value="start">start</option>
            <option value="click">Click</option>
            <option value="hover">Hover</option>
          </Select>
        </Flex>
        {assetEvent.trigger !== 'start' && (
          <Flex>
            <label className="label">target</label>
            <Select
              px={2}
              value={assetEvent.target}
              onChange={handleTargetChange}
              variant="unstyled"
              height={'30px'}
            >
              <option value={SceneEventTriggerTargets.object}>on this object</option>
              <option value={SceneEventTriggerTargets.scene}>Anywhere in Scene</option>
            </Select>
          </Flex>
        )}
        <hr />
        <PropertyHeading>Actions</PropertyHeading>{' '}
        <Flex>
          <label className="label">TargetObject</label>
          <Select
            px={2}
            value={assetEvent.targetObject || 'this object'}
            onChange={handleTargetObjectChange}
            variant="unstyled"
            height={'30px'}
          >
            <option value={assetSceneObject.id}>{assetSceneObject.backendProperties.name}</option>

            {Object.keys(sceneObjectList).map((item) => {
              if (sceneObjectList[item].id !== assetSceneObject.id)
                return (
                  <option key={item} value={item}>
                    {sceneObjectList[item].backendProperties.name}
                  </option>
                );
            })}
          </Select>
        </Flex>
        <PropertyHeading
          display={'flex'}
          pr={5}
          my={4}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          Sequence
          <PlusSquareIcon onClick={handleAddSequence} cursor={'pointer'} />
        </PropertyHeading>
        <div style={{ maxHeight: '220px', overflowY: 'auto', overflowX: 'hidden' }}>
          {assetEvent.sequence.timeline.length > 0 &&
            assetEvent.sequence.timeline.map((item, index) => {
              return (
                <Flex direction={'column'} justifyContent={'center'} alignItems={'center'}>
                  {index !== 0 && (
                    <>
                      <div className="sequence_fields">
                        <PropertyField
                          value={item.duration}
                          instanceIndex={-1}
                          propIndex={index}
                          field={'duration'}
                          handlePropertyChange={handleDurationDelayChange}
                          type="number"
                        />

                        <PropertyField
                          value={item.delay}
                          instanceIndex={-1}
                          propIndex={index}
                          field={'delay'}
                          handlePropertyChange={handleDurationDelayChange}
                          type="number"
                        />
                      </div>
                    </>
                  )}
                  <Flex
                    w={'100%'}
                    justifyContent={'start'}
                    alignItems={'center'}
                    sx={{
                      '&:hover .second-child': {
                        display: 'block',
                      },
                    }}
                    gap={2}
                  >
                    <label className="Evlabel">{index + 1}</label>
                    <Select
                      value={item.name}
                      onChange={(e) => handleStateChange(e, index)}
                      backgroundColor={'rgb(40, 40, 40)'}
                      border={'none'}
                      width={'72%'}
                      height={'25px'}
                    >
                      {assetEvent.targetObject === assetSceneObject.id &&
                        assetSceneObject.backendProperties.animation.states.map((state) => (
                          <option key={state.name} value={state.name}>
                            <span>{state.name}</span>
                          </option>
                        ))}

                      {assetEvent.targetObject !== assetSceneObject.id &&
                        Object.keys(sceneObjectList).map((item) => {
                          if (sceneObjectList[item].id === assetEvent.targetObject)
                            return sceneObjectList[item].backendProperties.animation.states.map(
                              (state) => (
                                <option key={state.name} value={state.name}>
                                  {state.name}
                                </option>
                              )
                            );
                        })}
                    </Select>
                    <CloseIcon
                      cursor={'pointer'}
                      backgroundColor={'#2c2a2c'}
                      rounded={100}
                      p={1}
                      display={'none'}
                      className="second-child"
                      onClick={() => handleRemoveSequence(index)}
                    />
                  </Flex>
                </Flex>
              );
            })}
        </div>
      </div>
    );
  }

  return <>{assetProperties}</>;
}
