import { AspectRatio, Box, Flex, GridItem, Image, Stack, Text } from '@chakra-ui/react';
import timeHelper from 'src/utils/time';

interface CardProps {
  imageSrc: string;
  name: string;
  timestamp?: number;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const Card = (props: CardProps) => {
  const { imageSrc, name, timestamp, onClick } = props;

  return (
    <GridItem
      borderRadius={16}
      border="1px solid #333"
      minH="fit-content"
      transition={'all .2s ease-in-out'}
      p={2}
      maxW="20rem"
      onClick={onClick}
      _hover={{ backgroundColor: 'rgba(50,50,50,255)' }}
    >
      <Flex flexDirection="column" gap={4}>
        <AspectRatio minH="12rem" ratio={16 / 9} objectFit="cover" objectPosition="center">
          <Image src={imageSrc} borderRadius={8} />
        </AspectRatio>
        <Stack gap={2}>
          <Text m={0}>{name}</Text>
          {timestamp && (
            <Text m={0} fontSize="small" color="gray.500">
              {timeHelper(timestamp).fromNow()}
            </Text>
          )}
        </Stack>
      </Flex>
    </GridItem>
  );
};

export default Card;
