import { toast } from 'react-toastify';

import { getCloudStorage } from 'src/hooks';
import { CloudStorageManager } from 'src/services/storage';
import { addItem } from 'src/store/reducers/cache';
import store from 'src/store/store';
import { AssetCategories } from 'src/types';

import { getCloudStorageCacheKey, uploadProjectAssetPath } from './cloud';
import { getPublicAssetURL } from './public';

export const S3_BUCKET_URL = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com`;

type UploadWithToastOptions = {
  toastId?: string | number | null;
  showToast?: boolean;
  parentname?: string;
  childname?: string;
};

export const uploadFileToS3WithProgressToast = async (
  file: File,
  dstKey: string,
  options: UploadWithToastOptions = {
    toastId: null,
    showToast: true,
    parentname: '',
    childname: '',
  }
) => {
  const cloudStorage = await getCloudStorage();

  if (file && cloudStorage) {
    let toastId = options.toastId; // Changed type here

    try {
      await cloudStorage.upload(file, dstKey);

      if (options.showToast) {
        // upload.on('httpUploadProgress', (evt) => {
        //   // Calculate the upload percentage
        //   const percent = Math.round((evt.loaded / evt.total) * 100);
        //   // Display or update the toast
        //   if (toastId) {
        //     toast.update(toastId, {
        //       render: `Uploading ${options.parentname} - ${options.childname} : ${percent}%`,
        //     });
        //   }
        // });
      }

      if (options.showToast && toastId) {
        toast.update(toastId, {
          render: `Uploading ${options.parentname} - ${options.childname} completed`,
          progress: undefined,
          autoClose: 4000,
        });
      }
    } catch (err) {
      console.error('Error uploading file:', err);

      if (options.showToast && toastId) {
        toast.dismiss(toastId);
      }

      toast.error('Save failed', {
        position: 'bottom-center',
      });

      throw err;
    }
  }
};

// This does not wait for the upload to finish, even if called with await.
export const uploadFileToS3Async = async (file: File, dstKey: string) => {
  const cloudStorage = await getCloudStorage();

  if (file && cloudStorage) {
    try {
      await cloudStorage.upload(file, dstKey);
    } catch (err) {
      console.error('Error uploading file:', err);
      throw err;
    }
  }
};

export const getFileFromS3 = async (filename: string) => {
  const cloudStorage = await getCloudStorage();

  if (cloudStorage) {
    try {
      const data = await cloudStorage.get(filename);
      return data;
    } catch (err) {
      console.error('Error getting file:', err);
      throw err;
    }
  }
};

export const copyFileInS3 = async (srcBucket: string, srcKey: string, dstKey: string) => {
  const cloudStorage = await getCloudStorage();

  const sourceKey = `${srcBucket}/${srcKey}`;

  if (cloudStorage) {
    try {
      const copy = await cloudStorage.copy(sourceKey, dstKey);
      return copy;
    } catch (err) {
      console.error('Error copying file:', err);
      throw err;
    }
  }
};

export const getTextureURL = (textureKey: string, tag: string) => {
  if (textureKey.startsWith('data:') || textureKey.startsWith('blob:')) return textureKey;

  const isPublic = textureKey?.split('/')?.length === 1;

  if (isPublic && tag) {
    const prefix = textureKey.split('_')[0];
    const publicBucketURL = getPublicAssetURL();
    const srcKey = 'materials/' + tag + '/' + prefix + '/' + textureKey;

    return `${publicBucketURL}/${srcKey}`;
  }

  return getAssetUrlWithToken(textureKey, 'textures');
};

export const getAssetUrlWithToken = (
  fileKey: string,
  assetType: AssetCategories = 'objects',
  sceneId?: string
) => {
  const workspace = store.getState().app.currentUser?.workspace_id;
  const projectId = store.getState().app.projectId;

  const stored = localStorage.getItem(getCloudStorageCacheKey(workspace!));
  const cachedItem = stored ? JSON.parse(stored) : undefined;

  if (!cachedItem) return;

  const cloud = new CloudStorageManager(cachedItem.provider, cachedItem.config);
  const key = `${uploadProjectAssetPath(assetType, workspace!, projectId, sceneId)}/${fileKey}`;
  const url = store.getState().cache.items[key]?.value || cloud.getSignedUrl(key);

  store.dispatch(addItem({ key, value: url }));
  return url;
};
