import {
  Box,
  Button,
  Text,
  Container,
  Heading,
  Input,
  useColorModeValue,
  VStack,
  Image,
  Center,
  Flex,
  CloseButton,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepSeparator,
  StepIcon,
  FormControl,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { createWorkspaceAPI } from 'src/apis/workspace';
import CloudStorage from 'src/components/WorkspaceSettings/CloudStorage';
import StorageAssetTable from 'src/components/WorkspaceSettings/StorageAssetTable';
import { setCurrentUser } from 'src/store/reducers/app';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { supabase } from 'src/SupabaseClient';
import { DEFAULT_INTEGRATION, ROUTES } from 'src/utils/constants';
import { getCurrentUser } from 'src/utils/requests';
import { generateUUID } from 'three/src/math/MathUtils';
import { z } from 'zod';

const WorkspaceNameScheme = z.object({
  name: z
    .string()
    .min(3, 'Workspace name must be at least 3 characters long')
    .max(32, 'Workspace name can not be more than 32 characters long'),
});

type WorkspaceNameSchemeType = z.infer<typeof WorkspaceNameScheme>;

const steps = [
  {
    title: 'Workspace',
  },
  {
    title: 'Storage',
  },
];

const CreateWorkspace = () => {
  const [step, setStep] = useState<number>(1);
  const [showImport, setShowImport] = useState(false);

  const navigate = useNavigate();
  const bgColor = useColorModeValue('gray.50', 'gray.800');

  const workspace = useAppSelector((store) => store.app.currentUser?.workspace_id);

  const onStorageSetup = (storage: string) => {
    if (storage !== 'unproject') {
      setShowImport(true);
    } else {
      navigate(ROUTES.projects);
    }
  };

  const onSubmitWorkpace = () => {
    setStep((s) => s + 1);
  };

  return (
    <Box bg={bgColor} minHeight="100vh">
      <Flex position="sticky" w="full" p={6} justify="space-between" align="center">
        <Image src="/logo_256.png" alt="unproject logo" h={8} w={8} />
        {workspace && (
          <CloseButton
            onClick={() => {
              navigate(ROUTES.projects);
            }}
          />
        )}
      </Flex>
      <Container
        overflow="auto"
        position="relative"
        maxW={step === 1 ? 'container.md' : 'container.xl'}
        justifyContent="center"
        h="full"
      >
        <VStack
          spacing={8}
          position="absolute"
          w="full"
          top="20%"
          align={step !== 1 ? 'center' : 'stretch'}
        >
          {showImport ? (
            <StorageAssetTable />
          ) : (
            <>
              {step === 1 ? (
                <NewForm onFinish={onSubmitWorkpace} />
              ) : (
                <Flex direction="column" position="relative" align="center" gap={10}>
                  <CloudStorage
                    key="cloud-storage"
                    defaultValue={DEFAULT_INTEGRATION.name}
                    active={DEFAULT_INTEGRATION.name}
                    onFinish={onStorageSetup}
                  />
                </Flex>
              )}
              <Center position="fixed" left="50%" bottom="5%">
                <Stepper size="xs" colorScheme="ghost" index={step - 1}>
                  {steps.map((step, index) => (
                    <Step key={index}>
                      <StepIndicator>
                        <StepStatus complete={<StepIcon />} />
                      </StepIndicator>
                      <StepSeparator />
                    </Step>
                  ))}
                </Stepper>
              </Center>
            </>
          )}

          <Box h="20%" />
        </VStack>
      </Container>
    </Box>
  );
};

const NewForm = ({ onFinish }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const fullname = useAppSelector((store) => store.app.currentUser?.fullname);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const textColor = useColorModeValue('gray.800', 'white');

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<WorkspaceNameSchemeType>({ resolver: zodResolver(WorkspaceNameScheme) });

  const onCreateWorkspace = async (data: any) => {
    if (!data) return;

    setIsLoading(true);
    try {
      const workspaceId = generateUUID();
      await createWorkspaceAPI(workspaceId, data.name);

      const { data: updateUser } = await supabase.auth.updateUser({
        data: {
          workspace_id: workspaceId,
        },
      });

      if (updateUser?.user) {
        const currentUser = getCurrentUser(updateUser.user);
        if (currentUser.workspace_id) {
          dispatch(setCurrentUser(currentUser));
          onFinish();
        } else {
          toast({
            title: 'Unable to update workspace!',
            colorScheme: 'gray',
            status: 'error',
            containerStyle: {
              margin: 13,
            },
          });
        }
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Center mb={4}>
        <Image src="/logo_256.png" alt="unproject logo" h={20} w={20} />
      </Center>
      <Heading as="h1" size="2xl" textAlign="center" color={textColor}>
        Create New Workspace
      </Heading>
      <Text fontSize="lg" textAlign="center" color={textColor}>
        Let's start by giving your workspace a name. You can always change it later.
      </Text>
      <form onSubmit={handleSubmit(onCreateWorkspace)}>
        <VStack spacing={6}>
          <FormControl isInvalid={!!errors.name?.message}>
            <Input
              autoFocus
              placeholder={fullname ? `${fullname}'s workspace` : 'Enter workspace name'}
              size="lg"
              {...register('name')}
              variant="ghost"
            />
          </FormControl>
          <Button
            type="submit"
            size="lg"
            width="full"
            isDisabled={isLoading || !isValid}
            isLoading={isLoading}
          >
            Continue
          </Button>
        </VStack>
      </form>
    </>
  );
};

export default CreateWorkspace;
