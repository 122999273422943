import { ThreeEvent } from '@react-three/fiber';
import { useAssetUpload } from 'src/features/SceneViewer/hooks/useAssetUpload';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { setSharedMemory } from 'src/store/reducers/InstanceReducer';
import { openContextMenu } from 'src/store/reducers/modals';
import store from 'src/store/store';
import { SceneActionSubType, SceneObjectActionTypes, SupportedSceneObjectTypes } from 'src/types';
import {
  clearClipboard,
  doesClipboardContainImage,
  pasteImageFromClipboard,
} from 'src/utils/clipboard';
import { generateNewViewportLabel } from 'src/utils/helper';
import { getShortId } from 'src/utils/ids';
import { Vector3 } from 'three';
import { RightClickMenuItem } from '../context';
import {
  calculateCameraConfig,
  calculateGroupCenter,
  getCompositeTransform,
  getSceneObject,
  getWorldTransform,
} from '../helpers';
import { useSceneViewer } from './useSceneViewer';
import short from 'short-uuid';
const useSceneMenu = () => {
  const dispatch = useAppDispatch();
  const { uploadImage, uploadAssets } = useAssetUpload();

  const {
    handleSceneObjectAction,
    onSelectObject,
    onCurrentViewportUpdate,
    onCameraConfigUpdate,
    getSelectedObjects,
  } = useSceneViewer();

  // Delete Scene Objects
  const deleteSceneObject = async (
    objectList: any[],
    id: string,
    type: SupportedSceneObjectTypes,
    currentViewport: string | null
  ) => {
    onSelectObject([]);
    const sceneObject = getSceneObject(id);
    // do not delete primary viewport
    if (sceneObject) {
      if (sceneObject.type === SupportedSceneObjectTypes.viewport) {
        if (sceneObject.localProperties.isPrimary) {
          return;
        }

        if (currentViewport !== null) {
          if (id === currentViewport) {
            let primaryViewportId = null as null | string;
            objectList.forEach((item) => {
              if (item.type === SupportedSceneObjectTypes.viewport) {
                if (item.localProperties.isPrimary) {
                  primaryViewportId = item.backendProperties.id;
                }
              }
            });
            // setCurrentViewport(primaryViewportId);
            onCurrentViewportUpdate(primaryViewportId);
          }
        }
      }
    }

    handleSceneObjectAction(SceneObjectActionTypes.delete, [
      {
        id: id,
        type: type,
      },
    ]);
  };

  // Duplicate scene object
  const dupliateSceneObject = async (
    objectList: any[],
    id: string,
    type: SupportedSceneObjectTypes
  ) => {
    let sceneObject = { ...getSceneObject(id) } as any;
    if (sceneObject) {
      const backendProperties = { ...sceneObject.backendProperties } as any;
      delete backendProperties.id;
      if (sceneObject.type === SupportedSceneObjectTypes.viewport) {
        delete backendProperties.metadata.label;
        backendProperties['metadata']['label'] = generateNewViewportLabel(
          objectList.filter((item) => item.type === SupportedSceneObjectTypes.viewport)
        );
      }

      handleSceneObjectAction(SceneObjectActionTypes.insert, [
        {
          id: null,
          type: sceneObject.type,
          localProperties: {
            id,
            subType: SceneActionSubType.copy,
          },
          backendProperties: backendProperties,
        },
      ]);
    }
  };

  const handlePasteFromClipboard = (boundingBox: any = {}) => {
    const sceneId = store.getState().instance.current_sceneId;
    const { refBBox, ...transforms } = boundingBox;

    pasteImageFromClipboard((blob) => {
      const name = getShortId();
      try {
        const file = new File([blob], name + '.png', {
          type: 'image/png',
        });

        uploadImage(file, {
          operation: {
            type: SceneObjectActionTypes.insert,
            data: {
              backendProperties: {
                ...transforms,
                scene_id: sceneId,
              },
            },
          },
        });
      } catch (err) {
        console.error('Error in file upload or database update:', err);
      }
    });
  };

  const handlePaste = async (e?: ClipboardEvent) => {
    const items = e?.clipboardData?.items;
    const selectedItems = getSelectedObjects();
    const boundingBox = selectedItems.length > 0 ? store.getState().sceneViewer.bboxInfo : null;
    if (items) {
      const { refBBox, ...transforms } = boundingBox || {};
      handlePasteFromSystem(items, transforms || {});
    } else {
      const hasClipboardImage = await doesClipboardContainImage();
      if (hasClipboardImage) {
        handlePasteFromClipboard(boundingBox);
      } else {
        handlePasteFromSharedMem(boundingBox);
      }
    }
  };

  // Copy Scene Object
  const copySceneObject = async (
    objectList: any[],
    id: string,
    type: SupportedSceneObjectTypes,
    pushToExistingMemory: boolean = false
  ) => {
    const sceneObject = getSceneObject(id);
    if (sceneObject) {
      const objectCopy = { ...sceneObject.backendProperties } as any;
      delete objectCopy.id;
      delete objectCopy.scene_id;
      if (type === SupportedSceneObjectTypes.viewport) {
        delete objectCopy.label;
        objectCopy['label'] = generateNewViewportLabel(
          objectList.filter((item) => item.type === SupportedSceneObjectTypes.viewport)
        );
      }

      if (!pushToExistingMemory) {
        dispatch(
          setSharedMemory([
            {
              objectType: type,
              data: objectCopy,
              id,
            },
          ])
        );
      } else {
        const sharedMem = store.getState().instance.sharedMemory;
        clearClipboard();

        dispatch(
          setSharedMemory([
            ...sharedMem,
            {
              objectType: type,
              data: objectCopy,
              id,
            },
          ])
        );
      }
    }
  };

  const getAssetsCenter = (assets: any[]): [number, number, number] => {
    if (!assets.length) return [0, 0, 0];

    const center = [0, 0, 0];
    assets.forEach((item) => {
      const { position: itemPosition } = item.data || {};
      if (itemPosition) {
        center[0] += itemPosition[0];
        center[1] += itemPosition[1];
        center[2] += itemPosition[2];
      }
    });

    return center.map((coord) => coord / assets.length) as [number, number, number];
  };

  const updateAssetTransformsFromSelection = (
    asset: any,
    center: [number, number, number],
    boundingBoxInfo: any
  ) => {
    if (!boundingBoxInfo) return asset;

    const { position, rotation, scale } = boundingBoxInfo;

    const updatedData = { ...asset };

    updatedData.position = [
      position[0] + (updatedData.position[0] - center[0]),
      position[1] + (updatedData.position[1] - center[1]),
      position[2] + (updatedData.position[2] - center[2]),
    ];

    updatedData.rotation = rotation;

    // Update scale except for viewports
    if (asset.objectType !== SupportedSceneObjectTypes.viewport) {
      updatedData.scale = scale;
    }

    return updatedData;
  };

  const handlePasteFromSharedMem = (boundingBoxInfo?: any) => {
    const sharedMem = store.getState().instance.sharedMemory;
    const scene_id = store.getState().instance.current_sceneId;

    if (sharedMem.length === 0) return;

    const center: [number, number, number] = boundingBoxInfo
      ? getAssetsCenter(sharedMem)
      : [0, 0, 0];

    sharedMem.forEach((item) => {
      if (!item.data) return;

      const updatedData = updateAssetTransformsFromSelection(item.data, center, boundingBoxInfo);

      handleSceneObjectAction(SceneObjectActionTypes.insert, [
        {
          id: null,
          type: item.objectType,
          localProperties: { id: item.id, subType: SceneActionSubType.copy },
          backendProperties: {
            ...updatedData,
            scene_id,
          },
        },
      ]);
    });
  };

  const handlePasteFromSystem = (clipboardItems: any, transforms: any) => {
    const firstItem = clipboardItems[0];

    if (firstItem.kind === 'file') {
      const file = firstItem.getAsFile();

      if (file) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        const fileList = dataTransfer.files;

        uploadAssets(fileList, {
          upload: {
            level: 'project',
          },
          data: {
            backendProperties: transforms,
          },
        });
      }
    }
  };

  const updateGroupChildren = (
    selected: Array<{ id: string; type: SupportedSceneObjectTypes }>,
    data: { center: Vector3; type: SupportedSceneObjectTypes; group_id: string }
  ) => {
    const updateList = [] as any[];
    const { center, type, group_id } = data;
    selected.forEach((selectedObject) => {
      const sceneObject = getSceneObject(selectedObject.id);
      if (sceneObject) {
        const worldTransform = getWorldTransform(sceneObject.id);

        updateList.push({
          type: selectedObject.type,
          id: selectedObject.id,
          localProperties: {},
          backendProperties: {
            position: [
              worldTransform.position[0] - center.x,
              worldTransform.position[1] - center.y,
              worldTransform.position[2] - center.z,
            ],
            rotation: worldTransform.rotation,
            scale: worldTransform.scale,
            parent_group_id: group_id,
          },
        });
      }
    });

    handleSceneObjectAction(SceneObjectActionTypes.update, updateList);

    onSelectObject([
      {
        id: group_id,
        type,
      },
    ]);
  };

  const groupSceneObject = (selected?: Array<{ id: string; type: SupportedSceneObjectTypes }>) => {
    const selectedObjects = selected ?? getSelectedObjects();

    if (selectedObjects.length > 1) {
      const center = calculateGroupCenter(selectedObjects);
      if (center) {
        const projectId = store.getState().app.projectId;
        const scene_id = store.getState().instance.current_sceneId;

        let systemGeneratedAsset: any;

        selectedObjects.forEach((item) => {
          const asset = getSceneObject(item.id);

          if (asset?.backendProperties?.system_generated) {
            systemGeneratedAsset = asset;
          }
        });

        if (systemGeneratedAsset) {
          const parentGroupId = systemGeneratedAsset.localProperties.children
            ? systemGeneratedAsset.id
            : systemGeneratedAsset.backendProperties.parent_group_id;
          updateGroupChildren(
            selectedObjects.filter((asset) => systemGeneratedAsset.id !== asset.id),
            { center, type: systemGeneratedAsset.type, group_id: parentGroupId }
          );
        } else {
          const actionPromise = handleSceneObjectAction(SceneObjectActionTypes.insert, [
            {
              id: null,
              type: SupportedSceneObjectTypes.group,
              localProperties: {},
              backendProperties: {
                project_id: projectId,
                scene_id: scene_id,
                position: [center.x, center.y, center.z],
                animation: {
                  currentState: 0,
                  states: [
                    {
                      name: 'base',
                      position: [0, 0, 0],
                      rotation: [0, 0, 0],
                      scale: [1, 1, 1],
                    },
                  ],
                  events: [
                    {
                      name: 'start',
                      id: short.generate(),
                      trigger: 'click',
                      target: 'On this object',
                      object: 'this object',
                      from: 'base',
                      to: 'base',
                    },
                  ],
                  externalEvents: [],
                },
              },
            },
          ]);

          actionPromise.then((insertPromises) => {
            if (insertPromises) {
              Promise.all(insertPromises).then((insertedData) => {
                const group_id = insertedData[0][0].id;
                updateGroupChildren(selectedObjects, {
                  center,
                  type: SupportedSceneObjectTypes.group,
                  group_id,
                });
              });
            }
          });
        }
      }
    }
  };

  const ungroupSceneObject = () => {
    const selectedObjects = getSelectedObjects();

    if (
      selectedObjects.length === 1 &&
      selectedObjects[0].type === SupportedSceneObjectTypes.group
    ) {
      const groupObject = getSceneObject(selectedObjects[0].id);
      if (groupObject?.backendProperties.system_generated) return;

      if (groupObject) {
        if (groupObject.localProperties.children) {
          let updateList = [
            {
              ...groupObject,
              localProperties: {
                parent: true,
                children: [],
              },
            },
          ] as any[];

          groupObject.localProperties.children.forEach((child) => {
            const updatedChildTransform = getCompositeTransform(child.id, groupObject.id);
            updateList.push({
              type: child.type,
              id: child.id,
              localProperties: {},
              backendProperties: {
                position: updatedChildTransform.position,
                rotation: updatedChildTransform.rotation,
                scale: updatedChildTransform.scale,
                parent_group_id: groupObject.backendProperties.parent_group_id,
              },
            });
          });

          onSelectObject([...groupObject.localProperties.children]);
          handleSceneObjectAction(SceneObjectActionTypes.ungroup, updateList, true, false);
        }
      }
    }
  };

  const enterViewport = (id: string) => {
    const viewportObject = getSceneObject(id);
    if (viewportObject) {
      onCameraConfigUpdate(
        calculateCameraConfig(
          viewportObject.backendProperties.position,
          viewportObject.backendProperties.rotation
        )
      );
    }
  };

  const onSetObjectsProperty = (id: string, type: SupportedSceneObjectTypes, data: any = {}) => {
    const asset = getSceneObject(id)?.backendProperties;

    if (!asset) return;
    if (asset.system_generated) return;

    return handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id,
        type,
        localProperties: {},
        backendProperties: {
          background: !asset.background,
          ...data,
          ...(asset.parent_group_id ? { parent_group_id: null } : {}),
        },
      },
    ]);
  };

  const getContextMenu = () => {
    const sharedMem = store.getState().instance.sharedMemory;
    const selectedObjects = getSelectedObjects();

    const contextMenu: RightClickMenuItem = {
      copy: {
        function: () => {
          const selectedObjects = getSelectedObjects();
          const sceneObjectList = Object.values(store.getState().sceneViewer.entities);
          selectedObjects.forEach((item, index) => {
            copySceneObject(sceneObjectList, item.id, item.type, index !== 0);
          });
        },
        disabled: false,
      },
      setAsBackground: {
        function: () => {
          const selectedObjects = getSelectedObjects();
          selectedObjects.forEach((item) => onSetObjectsProperty(item.id, item.type));
        },
        disabled: false,
      },
      duplicate: {
        function: () => {
          const selectedObjects = getSelectedObjects();
          const sceneObjectList = Object.values(store.getState().sceneViewer.entities);
          selectedObjects.forEach((item) => {
            dupliateSceneObject(sceneObjectList, item.id, item.type);
          });
        },
        disabled: false,
      },
      paste: {
        function: () => {
          handlePaste();
        },
        disabled: false,
      },
      delete: {
        function: () => {
          const currentViewport = store.getState().sceneViewer.currentViewport;
          const selectedObjects = getSelectedObjects();
          const sceneObjectList = Object.values(store.getState().sceneViewer.entities);

          selectedObjects.forEach((item) => {
            deleteSceneObject(sceneObjectList, item.id, item.type, currentViewport);
          });
        },
        disabled: false,
      },
      ...(selectedObjects.length > 1 && {
        group: {
          function: () => {
            groupSceneObject();
          },
          disabled: selectedObjects.length < 2,
        },
      }),
    };

    const isSingleObjectSelected = selectedObjects.length === 1;

    if (isSingleObjectSelected) {
      const { id, type } = selectedObjects[0];
      if (type === SupportedSceneObjectTypes.viewport) {
        // Disable delete if selected object is primary viewport
        const viewportObject = getSceneObject(id);
        if (viewportObject) {
          if (viewportObject.type === SupportedSceneObjectTypes.viewport) {
            if (viewportObject.localProperties.isPrimary) {
              contextMenu['delete'].disabled = true;
            }
          }
        }

        contextMenu['enterViewport'] = {
          function: () => {
            enterViewport(id);
          },
          disabled: false,
        };
      }

      if (type === SupportedSceneObjectTypes.group) {
        const obj = getSceneObject(id);

        contextMenu['ungroup'] = {
          function: () => {
            ungroupSceneObject();
          },
          disabled: !!obj?.backendProperties?.system_generated,
        };
      }
    }

    return contextMenu;
  };

  const createContextMenu = (event: ThreeEvent<MouseEvent>) => {
    dispatch(
      openContextMenu({
        items: getContextMenu(),
        position: { x: event.clientX, y: event.clientY },
      })
    );
  };

  return {
    getContextMenu,
    createContextMenu,
    handlePaste,
    handlePasteFromSystem,
    onSetObjectsProperty,
  };
};

export default useSceneMenu;
