import { CloudStorageFactory } from './cloud';
import type { CloudProviderConfig, CloudStorage } from './storage.types';

export class CloudStorageManager<T extends CloudProviderConfig['provider']> {
  private storage: CloudStorage;

  constructor(provider: T, config: Extract<CloudProviderConfig, { provider: T }>['config']) {
    this.storage = CloudStorageFactory.createStorage(provider, config as any);
  }

  async upload(file: File, path: string) {
    return this.storage.upload(file, path);
  }

  async get(path: string) {
    return this.storage.get(path);
  }

  async create(container: string) {
    return this.storage.create(container);
  }

  async copy(srcPath: string, dstPath: string) {
    return this.storage.copy(srcPath, dstPath);
  }

  async delete(path: string) {
    return this.storage.delete(path);
  }

  getSignedUrl(key: string) {
    return this.storage.getSignedUrl(key);
  }

  async copyFromPublicURL(srcKey: string, dstKey: string) {
    return this.storage.copyFromPublicURL(srcKey, dstKey);
  }

  async getAssets(container: string, prefix?: string) {
    return this.storage.getAssets(container, prefix);
  }

  getStorage() {
    return this.storage;
  }
}
