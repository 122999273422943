import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllEdgesAPI, getAllNodesAPI, getSceneComments } from 'src/apis';
import { getPrimaryViewport } from 'src/components/sceneViewer/helpers';
import { useSceneViewer } from 'src/components/sceneViewer/hooks/useSceneViewer';
import { useCanvas } from 'src/features/StoryBoard/hooks/useCanvas';
import { setBackgroundScene } from 'src/store/reducers/InstanceReducer';
import { appendComments } from 'src/store/reducers/comments';
import { useAppSelector } from 'src/store/reducers/hook';
import { setMaterials, updateFirstFetchDone } from 'src/store/reducers/sceneViewer';
import store from 'src/store/store';
import { getConnectedNodes } from 'src/utils/storyboard';
import { useDebouncedCallback } from 'use-debounce';
import { useAssetUpload } from './useAssetUpload';
import { fetchFigmaFiles } from 'src/services/figma';
import useUndoRedo from 'src/hooks/useUndoRedo';
import { getAllIntegrationsAuthAPI } from 'src/apis/integrations';
import { setIntegrationsAuth } from 'src/store/reducers/integrations';
import { getAllMaterialsAPI } from 'src/apis/materials';

const useSceneViewerInit = () => {
  const [enableAutoSync, setEnableAutoSync] = useState(false);
  const [run, setRun] = useState(false);

  const dispatch = useDispatch();
  const { change } = useUndoRedo();
  const { setBoardNodes, setBoardEdges } = useCanvas();
  const { uploadThumbnail, syncFigmaFile } = useAssetUpload();
  const { fetchSceneObjects, onCurrentViewportUpdate, cleanup, fetchEvents } = useSceneViewer();

  const sceneId = useAppSelector((store) => store.instance.current_sceneId);
  const autoSyncEnabledByUser = useAppSelector((store) => store.integrations.sync?.['figma']);

  const flushCache = () => {
    const cache = store.getState().cache.items;
    const sceneThumbnailCache = Object.keys(cache).filter(
      (key) => !!cache[key] && !key.startsWith('workspace')
    );

    Promise.all(
      sceneThumbnailCache.map((key) => uploadThumbnail(key, 'viewport-primary', cache[key].value))
    );
  };

  const changeBackgroundScene = () => {
    const sceneId = store.getState().instance.current_sceneId;
    if (!sceneId) return;

    const path = getConnectedNodes(sceneId);
    const firstSceneId = path.left.at(-1)?.id ?? sceneId;

    if (firstSceneId) {
      dispatch(setBackgroundScene(firstSceneId));
    }
  };

  const fetchFigmaUIChanges = useDebouncedCallback(async (interval) => {
    const figmaAuth = store.getState().integrations.auth['figma']?.auth_data?.access_token;
    if (!figmaAuth) return;

    const figmaFileInfo = Object.values(store.getState().sceneViewer.entities)
      .filter(
        (asset) =>
          asset.type === 'ui' && (asset.backendProperties as any)?.metadata?.meta?.type === 'figma'
      )
      .map((asset: any) => asset.backendProperties);

    const imagesToSync = await fetchFigmaFiles(figmaAuth, figmaFileInfo);

    if (imagesToSync.length > 0) {
      clearInterval(interval);
      const promises = imagesToSync.map((asset: any) => {
        return syncFigmaFile(asset);
      });

      if (promises?.length) {
        await Promise.all(promises);
      }
      setRun((r) => !r);
    }
  }, 1000);

  const fetch = useDebouncedCallback((sceneId: string) => {
    fetchEvents();
    fetchSceneObjects()
      .then(() => {
        const primaryViewport = getPrimaryViewport() ?? null;
        if (primaryViewport) {
          onCurrentViewportUpdate(primaryViewport.id);
        } else {
          onCurrentViewportUpdate(null);
        }
        setTimeout(() => {
          dispatch(updateFirstFetchDone(sceneId));
        }, 2000);
      })
      .then(() => {
        setEnableAutoSync(true);
      });

    const projectId = store.getState().app.projectId;

    getSceneComments(projectId, sceneId)
      .then((data) => {
        if (data) {
          dispatch(appendComments(data));
        }
      })
      .catch((err) => console.error(err));
  }, 500);

  useEffect(() => {
    if (fetch.isPending()) fetch.cancel();
    change(sceneId);
    cleanup(['firstFetchDone', 'cameraConfig']);
    fetch(sceneId);

    return () => {
      setEnableAutoSync(false);
    };
  }, [sceneId]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (enableAutoSync && autoSyncEnabledByUser) {
      interval = setInterval(() => {
        try {
          fetchFigmaUIChanges(interval);
        } catch (err) {
          console.error(err);
        }
      }, 10000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [sceneId, run, autoSyncEnabledByUser]);

  useEffect(() => {
    changeBackgroundScene();
    const userId = store.getState().app.currentUser?.id;
    const projectId = store.getState().app.projectId;
    if (userId && projectId) {
      getAllIntegrationsAuthAPI({ userId }).then((res) => {
        dispatch(setIntegrationsAuth(res));
      });
      getAllMaterialsAPI(projectId).then((res) => {
        dispatch(setMaterials(res));
      });
    }

    window.onload = async () => {
      const projectId = store.getState().app.projectId;
      if (projectId) {
        await Promise.all([
          getAllNodesAPI(projectId, (nodes) => setBoardNodes(nodes)),
          getAllEdgesAPI(projectId, (edges) => setBoardEdges(edges)),
        ]);
      }

      changeBackgroundScene();
    };

    return () => {
      flushCache();
      change('storyboard');
      cleanup();
    };
  }, []);
};

export default useSceneViewerInit;
