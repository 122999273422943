import { Box, Stack } from '@chakra-ui/react';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { ModalType, SceneObjectActionTypes, SupportedSceneObjectTypes } from 'src/types';
import { getShortId } from 'src/utils/ids';
import { Vector3 } from 'three';
import { SortableTree } from '../Tree/Tree';
import { calculateGroupCenter, getSceneObject, getWorldTransform } from './helpers';
import { useSceneInteractions } from './hooks/useSceneInteractions';
import { useSceneViewer } from './hooks/useSceneViewer';
import PropertyHeading from './propertyPanel/PropertyHeading';
import { openContextMenu, openModal } from 'src/store/reducers/modals';
import useSceneMenu from './hooks/useSceneMenu';
import store from 'src/store/store';

type Node = any;

function buildTree(list: any[]): Node[] {
  const data = JSON.parse(JSON.stringify(list)) as Node[];

  const nodeMap = new Map<string, Node>();

  for (const item of data) {
    nodeMap.set(item.backendProperties.id, {
      id: item.backendProperties.id,
      data: {
        type: item.backendProperties.type,
        name: item.backendProperties.name,
        hidden: item.backendProperties.hiddden,
        locked: item.backendProperties.locked,
        system_generated: item.backendProperties.system_generated,
        parent_group_id: item.backendProperties.parent_group_id,
        created_at: item.backendProperties.created_at,
      },
    });
  }

  for (const [_, node] of nodeMap) {
    const parent = nodeMap.get(node.data.parent_group_id);

    if (parent) {
      if (!parent.children) {
        parent.children = [];
      }

      parent.children.push(node);
    }
  }

  const rootNodes = data
    .filter((node) => !node.backendProperties.parent_group_id)
    .map((node) => {
      return { ...nodeMap.get(node.id) };
    });

  return rootNodes;
}

const LayersPanel = () => {
  const dispatch = useAppDispatch();
  const { onSetObjectsProperty } = useSceneMenu();
  const { onGizmoUpdate } = useSceneInteractions();
  const { onSelectObject, handleSceneObjectAction } = useSceneViewer();

  const tree = useAppSelector((store) => store.sceneViewer.ids);

  const selectedObjects = useAppSelector((store) => store.sceneViewer.selectedObjects);

  const { key, foreground, background } = useMemo(() => {
    const foreground = [] as Node[];
    const background = [] as Node[];

    const backgroundAssets = new Set();

    tree.forEach((id: string) => {
      const item = getSceneObject(id);
      if (item && item.backendProperties.background) {
        const children = item.localProperties.children;
        if (children) {
          children.forEach((child) => {
            backgroundAssets.add(child.id);
          });
        }
      }
    });

    tree.forEach((id: string) => {
      const item = getSceneObject(id);

      if (item) {
        if (backgroundAssets.has(item.id) || item.backendProperties.background) {
          background.push(item);
        } else {
          foreground.push(item);
        }
      }
    });

    return {
      key: getShortId(),
      foreground: buildTree(foreground),
      background: buildTree(background),
    };
  }, [tree]);

  const onClick = (id: UniqueIdentifier, type: SupportedSceneObjectTypes) => {
    onGizmoUpdate({ show: [true, true, true] });
    onSelectObject([{ id, type }]);
  };

  const onRename = async (data: { id: any; type: SupportedSceneObjectTypes; name: string }) => {
    const { id, type, name } = data;

    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id,
        type,
        localProperties: {},
        backendProperties: {
          name,
        },
      },
    ]);
  };

  const onDragEnd = (
    id: UniqueIdentifier,
    type: SupportedSceneObjectTypes,
    parentId: UniqueIdentifier | null
  ) => {
    if (id && type) {
      let transforms = {};
      let center: Vector3 | undefined = new Vector3(0, 0, 0);

      if (parentId) {
        const children = getSceneObject(parentId.toString())?.localProperties?.children || [];
        center = calculateGroupCenter([...children, { id: id.toString(), type }]);
      }

      const sceneObject = getSceneObject(id.toString());
      if (sceneObject && center) {
        const worldTransform = getWorldTransform(sceneObject.id);

        transforms = {
          position: [
            worldTransform.position[0] - center.x,
            worldTransform.position[1] - center.y,
            worldTransform.position[2] - center.z,
          ],
          rotation: worldTransform.rotation,
          scale: worldTransform.scale,
        };
      }

      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: id.toString(),
          type,
          localProperties: {},
          backendProperties: {
            ...transforms,
            parent_group_id: parentId,
          },
        },
      ]);
    }
  };

  const handleContextMenu = (event: any) => {
    event.preventDefault();

    const isOpen = store.getState().modal.contextMenu.isOpen;

    if (isOpen) return;

    const element = event.target?.closest('li[data-asset-id]');
    const id = element?.getAttribute('data-asset-id');
    const type = element?.getAttribute('data-asset-type');
    const locked = element?.getAttribute('data-asset-locked');

    const background =
      event.target
        ?.closest('div[data-tree-container-id')
        ?.getAttribute('data-tree-container-id') === 'background';

    if (id && type) {
      dispatch(
        openContextMenu({
          items: {
            lock: {
              disabled: false,
              label: locked === 'true' ? 'Unlock' : 'Lock',
              function: () => {
                handleSceneObjectAction(SceneObjectActionTypes.update, [
                  {
                    id,
                    type,
                    localProperties: {},
                    backendProperties: {
                      locked: !(locked === 'true'),
                    },
                  },
                ]);
              },
            },
            setAsBackground: {
              disabled: false,
              label: background ? 'Set as Foreground' : 'Set as Background',
              function: () => {
                if (background) {
                  dispatch(
                    openModal({
                      type: ModalType.foreground,
                      data: {
                        id,
                        type,
                        background: !background,
                      },
                    })
                  );
                } else {
                  onSetObjectsProperty(id, type, {
                    background: !background,
                  });
                }
              },
            },
            delete: {
              disabled: false,
              function: () => {
                handleSceneObjectAction(SceneObjectActionTypes.delete, [
                  {
                    id,
                    type,
                  },
                ]);
              },
            },
          },
          position: { x: event.clientX, y: event.clientY },
        })
      );
    }
  };

  return (
    <>
      <Stack className="panel" flex={2} flexShrink={0} px={2} overflow="hidden">
        <PropertyHeading mb={1}>Foreground</PropertyHeading>
        <Box
          overflowY="scroll"
          data-tree-container-id="foreground"
          onContextMenu={handleContextMenu}
        >
          <SortableTree
            key={key}
            defaultItems={foreground}
            collapsible
            selectedId={selectedObjects.at(0)?.id}
            onRename={onRename}
            onClick={onClick}
            onDragEndComplete={onDragEnd}
            activeId={selectedObjects.at(0)?.id}
          />
        </Box>
      </Stack>
      <Stack className="panel" flex={1} flexShrink={0} px={2} overflow="hidden">
        <PropertyHeading mb={1}>Background</PropertyHeading>
        <Box
          overflowY="scroll"
          data-tree-container-id="background"
          onContextMenu={handleContextMenu}
        >
          <SortableTree
            key={key}
            defaultItems={background}
            collapsible
            disableInteraction
            onRename={onRename}
            onDragEndComplete={onDragEnd}
          />
        </Box>
      </Stack>
    </>
  );
};

export default LayersPanel;
