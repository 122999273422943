import { S3 } from 'aws-sdk';
import { getContainerAndPath, getDefaultContainer } from 'src/utils/cloud';
import type { AWSConfig, CloudStorage } from './storage.types';

export class AWSStorage implements CloudStorage {
  private s3: S3;

  constructor(config: AWSConfig) {
    this.s3 = new S3(config);
  }

  async get(key: string): Promise<any> {
    const params = {
      Key: key,
    } as any;

    const data = await this.s3.getObject(params).promise();

    return data.Body;
  }

  async copy(srcKey: string, dstKey: string): Promise<void> {
    const params = {
      ...this.s3.config.params,
      CopySource: srcKey,
      Key: dstKey,
    } as any;

    this.s3.copyObject(params).promise();
  }

  async getAssets(container: string, prefix?: string | undefined): Promise<any> {
    const params = {
      Prefix: prefix,
      Bucket: container,
    } as any;

    let contents = [] as any[];
    let isTruncated = true as any;
    let continuationToken = null;

    while (isTruncated) {
      if (continuationToken) params.ContinuationToken = continuationToken;

      try {
        const response = await this.s3.listObjectsV2(params).promise();
        contents = contents.concat(response.Contents);
        isTruncated = response.IsTruncated;
        continuationToken = response.NextContinuationToken;
      } catch (error) {
        console.error('Error listing S3 objects:', error);
        break;
      }
    }

    return contents;
  }

  async upload(file: File, key: string) {
    const params = {
      Key: key,
      Body: file,
    } as any;

    return this.s3.upload(params).promise();
  }

  async create(key: string): Promise<void> {
    const params = {
      Key: key,
    } as any;

    await this.s3.putObject(params).promise();
  }

  getSignedUrl(key: string): string {
    const params = {
      Key: key,
      Bucket: getDefaultContainer(),
      Expires: 7 * 86400,
    };

    return this.s3.getSignedUrl('getObject', params);
  }

  async copyFromPublicURL(srcKey: string, dstKey: string) {
    const url = new URL(srcKey);
    const sourceBucket = url.hostname.split('.')[0];
    const sourceKey = url.pathname.substring(1);

    const { container, path } = getContainerAndPath(dstKey);

    if (!path) return;

    const params = {
      Bucket: container,
      CopySource: `/${sourceBucket}/${sourceKey}`,
      Key: path,
    };

    try {
      return new Promise((resolve, reject) => {
        this.s3.copyObject(params, (err, data) => {
          if (err) {
            console.error('Error copying object:', err);
            reject(err);
          } else {
            console.log('Object copied successfully:', data);
            resolve(data);
          }
        });
      });
    } catch (error) {
      console.error('Error copying object:', error);
    }
  }

  async delete(key: string): Promise<void> {
    const params = {
      Key: key,
    } as any;

    await this.s3.deleteObject(params).promise();
  }
}
