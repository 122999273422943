import { Center, Divider, Flex, Heading, IconButton, Kbd, Stack, Text } from '@chakra-ui/react';
import { BsLayers } from 'react-icons/bs';
import { RiArrowLeftLine, RiChat3Line, RiText } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import Tooltip from 'src/components/Tooltip';
import Invite from 'src/features/Invite';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { selectTool } from 'src/store/reducers/storyboard';
import { ToolType } from 'src/types';
import { ROUTES } from 'src/utils/constants';
import { SHORTCUTS } from 'src/utils/shortcuts';

import Collaborators from 'src/features/Collaboration/components/Collaborators';
import { Shortcut } from 'src/components/Shortcut';
import FloatingContainer from 'src/components/FloatingContainer';

const StoryboardTools = () => {
  const dispatch = useAppDispatch();
  const activeTool = useAppSelector((store) => store.storyboard.tool);

  const handleOnToolClick = (newTool: ToolType | undefined) => {
    dispatch(selectTool(newTool));
  };

  return (
    <>
      <Flex gap={2}>
        <Tooltip label={<Shortcut shortcut={SHORTCUTS.addText} />}>
          <IconButton
            size="sm"
            variant="ghost"
            bgColor={activeTool === ToolType.text ? 'blue.500' : ''}
            onClick={() => handleOnToolClick(ToolType.text)}
            color="#e6e6e6"
            aria-label={SHORTCUTS.addText.title}
            icon={<RiText size={20} />}
          />
        </Tooltip>
        <Tooltip label={<Shortcut shortcut={SHORTCUTS.addScene} />}>
          <IconButton
            size="sm"
            variant="ghost"
            bgColor={activeTool === ToolType.scene ? 'blue.500' : ''}
            onClick={() => handleOnToolClick(ToolType.scene)}
            aria-label={SHORTCUTS.addScene.title}
            color="#e6e6e6"
            icon={<BsLayers size={20} />}
          />
        </Tooltip>
      </Flex>
      <Divider orientation="vertical" bg="gray.700" m={0} h="1rem" />
      <Tooltip label={<Shortcut shortcut={SHORTCUTS.addComment} />}>
        <IconButton
          size="sm"
          variant="ghost"
          disabled
          onClick={() => handleOnToolClick(ToolType.comment)}
          bgColor={activeTool === ToolType.comment ? 'blue.500' : ''}
          color="#e6e6e6"
          aria-label={SHORTCUTS.addComment.title}
          icon={<RiChat3Line size={20} />}
        />
      </Tooltip>
    </>
  );
};

const BoardTools = () => {
  const navigate = useNavigate();
  const id = useAppSelector((store) => store.app.projectId);

  return (
    <>
      <FloatingContainer position="absolute" height="50px" left={6}>
        <Center px={3} gap={3}>
          <Tooltip label="Back to projects">
            <IconButton
              size="sm"
              color="#e6e6e6"
              variant="ghost"
              onClick={() => {
                navigate(ROUTES.projects);
              }}
              aria-label="Add Comment"
              icon={<RiArrowLeftLine size={20} />}
            />
          </Tooltip>
          <Divider orientation="vertical" bg="gray.700" m={0} h="1rem" />
          <StoryboardTools />
        </Center>
      </FloatingContainer>
      <Flex
        align="center"
        justifyContent="center"
        zIndex={2}
        height="50px"
        position="absolute"
        left="calc(50% - 50px)"
      >
        <Heading as="h4" m={0} size="md" color="#e6e6e6" userSelect="none">
          Storyboard
        </Heading>
      </Flex>
      <FloatingContainer pl={1} gap={2} position="absolute" right={6}>
        <Center gap={2}>
          <Collaborators />
          <Invite id={id} type="workspace" />
        </Center>
      </FloatingContainer>
    </>
  );
};

export default BoardTools;
