import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  AspectRatio,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useAppSelector } from 'src/store/reducers/hook';
import { getAssetUrlWithToken } from 'src/utils/aws';
import { fuzzySearch } from 'src/utils/fuzzy';
import { getPublicAssetURL } from 'src/utils/public';
import { getUniqueStrings } from 'src/utils/string';
import { useDebouncedCallback } from 'use-debounce';
import { UIcon } from '../icons';

const MaterialLibrary = ({ library, onSelect }: any) => {
  const materials = useAppSelector((store) => store.sceneViewer.materials);
  const [results, setResults] = useState();
  const [tag, setTag] = useState('All');

  const handleClick = (e: any) => {
    const item = e.target?.closest('[data-material-id]');
    const id = item?.dataset?.materialId;

    if (id) {
      onSelect(id);
    }
  };

  const { materialList, categories } = useMemo(() => {
    const allMaterials = Object.values(materials);
    const libraryMaterials =
      library.id === 2
        ? allMaterials.filter((material) => material.public && !material.project_id)
        : allMaterials.filter((material) => material.project_id);

    const tagMaterials = libraryMaterials.filter((material) =>
      tag === 'All' ? material : tag === material.tag
    );

    return {
      materialList: tagMaterials,
      categories: [
        'All',
        ...getUniqueStrings(
          tagMaterials.filter((material) => !!material.tag).map((material) => material.tag)
        ),
      ],
    };
  }, [materials, library.id, tag]);

  console.log('categories');

  return (
    <Flex flexDirection="column" height="full" gap={3} p={2} onClick={handleClick}>
      <Search items={materialList} onSearch={setResults} />
      <Flex align="center" gap={2} justify="space-between">
        <Text m={0} fontSize="small" color="gray">
          Category
        </Text>
        <Menu>
          <MenuButton
            size="xs"
            rounded="md"
            variant="ghost"
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            {tag}
          </MenuButton>
          <MenuList p={3} maxW="32ch">
            {categories.map((tag) => (
              <MenuItem
                key={tag}
                aria-label={tag}
                overflow="hidden"
                rounded="md"
                w="full"
                fontSize="sm"
                onClick={() => setTag(tag)}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {tag}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
      <Grid
        width="100%"
        templateColumns="repeat(auto-fill, minmax(6rem, 1fr))"
        overflowY="scroll"
        justifyContent="center"
      >
        {(results ?? materialList)?.map((material, i) => {
          return <MaterialPreview key={material.id} material={material} />;
        })}
      </Grid>
    </Flex>
  );
};

const Search = ({ items, onSearch, placeholder = 'Search Materials' }: any) => {
  const handleSearch = useDebouncedCallback((value) => {
    const results =
      !value || value === '' ? items : fuzzySearch(items, value, (item: any) => item.name);
    onSearch(results);
  }, 500);

  const handleChange = (e: any) => {
    const value = e.target.value;
    handleSearch(value);
  };

  return (
    <InputGroup size="sm" variant="outline">
      <InputLeftElement>
        <UIcon name="search" fontSize="14px" />
      </InputLeftElement>
      <Input rounded="md" autoComplete="on" placeholder={placeholder} onChange={handleChange} />
    </InputGroup>
  );
};

const MaterialPreview = ({ material }: any) => {
  const url = useMemo(() => {
    const isPublic = material.public || material.thumbnail?.split('/')?.length > 1;

    if (isPublic) {
      const publicBucketURL = getPublicAssetURL();
      const srcKey = 'materials/' + material.tag + '/' + material.thumbnail;

      return `${publicBucketURL}/${srcKey}`;
    }

    return getAssetUrlWithToken(material.thumbnail, 'textures');
  }, [material.thumbnail]);

  return (
    <GridItem
      key={material.id}
      rounded="md"
      userSelect="none"
      data-material-id={material.id}
      transition={'all .2s ease-in-out'}
      p={2}
      h="fit-content"
      _hover={{ backgroundColor: 'rgba(50,50,50, 0.5)' }}
    >
      <Flex h="full" flexDirection="column" align="center" gap={2}>
        <AspectRatio w="3.5rem" ratio={1 / 1} objectFit="contain" objectPosition="center">
          <Image borderRadius="full" src={url} />
        </AspectRatio>
        <Text m={0} fontSize="small" color="gray.500">
          {material.name}
        </Text>
      </Flex>
    </GridItem>
  );
};

export default MaterialLibrary;
