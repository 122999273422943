import * as THREE from 'three';

import { ControllerObject, SceneObjectActionTypes, SupportedSceneObjectTypes } from 'src/types';
import { ControllerModel } from 'src/components/sceneViewer/propertyPanel/interactionsProperties/controllerView/ControllerView';
import { getControllerBBox, getControllerModel } from 'src/utils/controllers';
import { useSceneViewer } from '../hooks/useSceneViewer';

type PreviewInteractionAssetProps = {
  config: ControllerObject;
};

function PreviewInteractionAsset(props: PreviewInteractionAssetProps) {
  const scaleMatrix = new THREE.Matrix4();
  const controllerObject = props.config.backendProperties;

  scaleMatrix.scale(
    new THREE.Vector3(
      controllerObject.scale[0],
      controllerObject.scale[1],
      controllerObject.scale[2]
    )
  );

  return (
    <group
      position={controllerObject.position}
      rotation={controllerObject.rotation}
      scale={controllerObject.scale}
    >
      <SceneControllerAsset
        config={props.config}
        model={
          getControllerModel(controllerObject.metadata.type)[controllerObject.metadata.subType]
        }
      />
    </group>
  );
}

const SceneControllerAsset = (props: { config: ControllerObject; model: any }) => {
  const { handleSceneObjectAction } = useSceneViewer();
  if (props.config.localProperties.originalBBox === undefined) {
    const controllerBBox = getControllerBBox(
      props.config.backendProperties.metadata,
      props.config.backendProperties.metadata.subType
    );

    const bbox = new THREE.Box3(
      new THREE.Vector3(controllerBBox.min[0], controllerBBox.min[1], controllerBBox.min[2]),
      new THREE.Vector3(controllerBBox.max[0], controllerBBox.max[1], controllerBBox.max[2])
    );

    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: props.config.id,
        type: props.config.type,
        localProperties: {
          originalBBox: bbox,
        },
        backendProperties: {},
      },
    ]);
  }

  try {
    return (
      <group scale={[4.0, 4.0, 4.0]}>
        <ControllerModel
          model={props.model}
          config={props.config.backendProperties.metadata.colorConfig}
          position={[0.0, 0.0, 0.0]}
          rotation={props.model.rotation}
          scale={[1.0, 1.0, 1.0]}
        />
      </group>
    );
  } catch (error) {
    return <></>;
  }
};

export default PreviewInteractionAsset;
