import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import { Icon, IconProps } from '@chakra-ui/react';
import {
  BsCaretDownFill,
  BsCollection,
  BsLightbulb,
  BsLightbulbOff,
  BsSearch,
} from 'react-icons/bs';
import { FaCheckSquare } from 'react-icons/fa';
import { FaDatabase, FaEarthAmericas, FaKey } from 'react-icons/fa6';
import { LuDownload } from 'react-icons/lu';
import { MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { TbTexture } from 'react-icons/tb';

const ICONS = {
  key: FaKey,
  globe: FaEarthAmericas,
  db: FaDatabase,
  materials: BsCollection,
  search: BsSearch,
  add: AddIcon,
  close: CloseIcon,
  texture: TbTexture,
  bulbOn: BsLightbulb,
  bulbOff: BsLightbulbOff,
  menu: BsCaretDownFill,
  delete: RiDeleteBinLine,
  download: LuDownload,
  unckecked: MdOutlineCheckBoxOutlineBlank,
  checked: FaCheckSquare,
};

interface UIconProps extends IconProps {
  name: keyof typeof ICONS;
}

export const UIcon = (props: UIconProps) => {
  const { name, ...iconProps } = props;
  const ICON = ICONS[name];
  return ICON ? <Icon as={ICON} {...iconProps} /> : null;
};
