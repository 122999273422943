import { Clone, Html, useAnimations } from '@react-three/drei';
import { ThreeEvent, useLoader } from '@react-three/fiber';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import * as THREE from 'three';
import { SkeletonUtils } from 'three-stdlib';
import { DRACOLoader, FBXLoader, GLTFLoader } from 'three/examples/jsm/Addons.js';

import { context, ContextType } from 'src/components/sceneViewer/context';
import { useAppSelector } from 'src/store/reducers/hook';
import {
  AssetObject,
  gizmoInfoInterface,
  SceneObjectActionTypes,
  SceneObjectFileTypes,
  SupportedSceneObjectTypes,
  SceneObjectPropsType,
  ModeTypes,
} from 'src/types';
import { getAssetUrlWithToken } from 'src/utils/aws';

import useAssetManipulation from '../hooks/useAssetManipulation';
import { useSceneInteractions } from '../hooks/useSceneInteractions';
import { useSceneViewer } from '../hooks/useSceneViewer';
import AnnotationContainer from './AnnotationContainer';

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');

const defaultSceneAssetProps = {
  onClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  handleDrag: (l: THREE.Matrix4, dl: THREE.Matrix4, w: THREE.Matrix4, dw: THREE.Matrix4) => {},
  onRightClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  onDoubleClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  disablePivot: false,
};

function SceneAsset(props: any & typeof defaultSceneAssetProps) {
  const { snapToGround, setSnapToGround } = useContext<ContextType>(context);

  const isInitializing = useAppSelector((store) => store.app.isInitializing);
  const { handleSceneObjectAction, getSelectedObjects } = useSceneViewer();
  const { onGizmoUpdate } = useSceneInteractions();

  const modelUrl = useMemo(() => {
    return getAssetUrlWithToken(props.config.backendProperties.metadata.file);
  }, [isInitializing, props.config.backendProperties.metadata.file]);

  const scaleMatrix = new THREE.Matrix4();

  scaleMatrix.scale(
    new THREE.Vector3(
      props.config.backendProperties.scale[0],
      props.config.backendProperties.scale[1],
      props.config.backendProperties.scale[2]
    )
  );

  let asset = (
    <AssetElement
      url={modelUrl}
      config={props.config}
      onClick={props.onClick}
      onRightClick={props.onRightClick}
      onDoubleClick={props.onDoubleClick}
    />
  );

  if (props.config.backendProperties.metadata.filetype === SceneObjectFileTypes.fbx) {
    asset = (
      <FBXAssetElement
        url={modelUrl}
        config={props.config}
        onClick={props.onClick}
        onRightClick={props.onRightClick}
        onDoubleClick={props.onDoubleClick}
      />
    );
  }

  if (props.config.localProperties.localThreejsObjectJSON !== undefined) {
    if (props.config.backendProperties.metadata.filetype === SceneObjectFileTypes.fbx) {
      asset = (
        <FBXAssetElement
          url={props.config.localProperties.localThreejsObjectJSON as string}
          config={props.config}
          onClick={props.onClick}
          onRightClick={props.onRightClick}
          onDoubleClick={props.onDoubleClick}
        />
      );
    } else {
      asset = (
        <LocalAssetElementLoader
          url={modelUrl}
          config={props.config}
          onClick={props.onClick}
          onRightClick={props.onRightClick}
          onDoubleClick={props.onDoubleClick}
        />
      );
    }
  }

  useEffect(() => {
    if (snapToGround) {
      const selectedObjects = getSelectedObjects();
      if (selectedObjects.length === 1) {
        if (selectedObjects[0].id === props.config.backendProperties.id) {
          snapAssetToGround();
          setSnapToGround(false);
        }
      }
    }
  }, [snapToGround]);

  const snapAssetToGround = () => {
    if (props.config.localProperties.originalBBox !== undefined) {
      const eulerRotation = new THREE.Euler(
        props.config.backendProperties.rotation[0],
        props.config.backendProperties.rotation[1],
        props.config.backendProperties.rotation[2]
      );

      const globalYAxis = new THREE.Vector3(0, 1, 0);
      const globalNegYAxis = new THREE.Vector3(0, -1, 0);
      const localXAxis = new THREE.Vector3(1, 0, 0).applyEuler(eulerRotation);
      const localYAxis = new THREE.Vector3(0, 1, 0).applyEuler(eulerRotation);
      const localZAxis = new THREE.Vector3(0, 0, 1).applyEuler(eulerRotation);

      const angleX = Math.abs(localXAxis.angleTo(globalYAxis));
      const angleY = Math.abs(localYAxis.angleTo(globalYAxis));
      const angleZ = Math.abs(localZAxis.angleTo(globalYAxis));
      const negAngleX = Math.abs(localXAxis.angleTo(globalNegYAxis));
      const negAngleY = Math.abs(localYAxis.angleTo(globalNegYAxis));
      const negAngleZ = Math.abs(localZAxis.angleTo(globalNegYAxis));

      let closestAxis;
      let globalAlignAxis;
      let showGizmo = [true, true, true];
      if (
        angleX < angleY &&
        angleX < angleZ &&
        angleX < negAngleX &&
        angleX < negAngleY &&
        angleX < negAngleZ
      ) {
        closestAxis = localXAxis;
        globalAlignAxis = globalYAxis;
        showGizmo[0] = false;
      } else if (
        angleY < angleX &&
        angleY < angleZ &&
        angleY < negAngleX &&
        angleY < negAngleY &&
        angleY < negAngleZ
      ) {
        closestAxis = localYAxis;
        globalAlignAxis = globalYAxis;
        showGizmo[1] = false;
      } else if (
        angleZ < angleX &&
        angleZ < angleY &&
        angleZ < negAngleX &&
        angleZ < negAngleY &&
        angleZ < negAngleZ
      ) {
        closestAxis = localZAxis;
        globalAlignAxis = globalYAxis;
        showGizmo[2] = false;
      } else if (
        negAngleX < negAngleY &&
        negAngleX < negAngleZ &&
        negAngleX < angleX &&
        negAngleX < angleY &&
        negAngleX < angleZ
      ) {
        closestAxis = localXAxis;
        globalAlignAxis = globalNegYAxis;
        showGizmo[0] = false;
      } else if (
        negAngleY < negAngleX &&
        negAngleY < negAngleZ &&
        negAngleY < angleX &&
        negAngleY < angleY &&
        negAngleY < angleZ
      ) {
        closestAxis = localYAxis;
        globalAlignAxis = globalNegYAxis;
        showGizmo[1] = false;
      } else {
        closestAxis = localZAxis;
        globalAlignAxis = globalNegYAxis;
        showGizmo[2] = false;
      }

      // setGizmoInfo({
      //   ...gizmoInfo,
      //   show: showGizmo,
      // });
      onGizmoUpdate({
        show: showGizmo,
      } as Partial<gizmoInfoInterface>);

      const alignmentQuaternion = new THREE.Quaternion()
        .setFromUnitVectors(closestAxis, globalAlignAxis)
        .normalize();
      const quaternion = new THREE.Quaternion().setFromEuler(eulerRotation).normalize();
      const resultQuaternion = alignmentQuaternion.multiply(quaternion).normalize();
      const resultEuler = new THREE.Euler().setFromQuaternion(
        resultQuaternion,
        eulerRotation.order
      );

      const bBox = props.config.localProperties.originalBBox.clone();
      bBox?.applyMatrix4(
        new THREE.Matrix4().compose(
          new THREE.Vector3(
            props.config.backendProperties.position[0],
            props.config.backendProperties.position[1],
            props.config.backendProperties.position[2]
          ),
          resultQuaternion,
          new THREE.Vector3(
            props.config.backendProperties.scale[0],
            props.config.backendProperties.scale[1],
            props.config.backendProperties.scale[2]
          )
        )
      );

      const center = new THREE.Vector3();
      const size = new THREE.Vector3();
      bBox?.getCenter(center);
      bBox?.getSize(size);

      let offset = props.config.backendProperties.position[1] - center.y;
      const sign = center.y >= 0.01 ? 1.0 : -1.0;

      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: props.config.id,
          type: props.config.type,
          localProperties: {},
          backendProperties: {
            rotation: [resultEuler.x, resultEuler.y, resultEuler.z],
            position: [
              props.config.backendProperties.position[0],
              +0.01 + (size.y / 2.0) * sign + offset,
              props.config.backendProperties.position[2],
            ],
          },
        },
      ]);
    }
  };

  return (
    <group
      onPointerOver={props.onPointerOver}
      onPointerOut={props.onPointerOut}
      position={props.config.backendProperties.position}
      rotation={props.config.backendProperties.rotation}
      scale={props.config.backendProperties.scale}
    >
      <ErrorBoundary fallback={<></>}>{asset}</ErrorBoundary>
    </group>
  );
}

const LocalAssetElementLoader = (props: {
  url: string;
  config: AssetObject;
  onClick: Function;
  onRightClick: Function;
  onDoubleClick: Function;
}) => {
  const objectLoader = new GLTFLoader();
  const [object, setObject] = useState<any>(undefined);
  const [gotObject, setGotObject] = useState<boolean>(false);

  if (!gotObject) {
    setGotObject(true);
    objectLoader.parse(
      props.config.localProperties.localThreejsObjectJSON as any,
      '',
      (gltf) => {
        setObject(gltf);
      },
      (err) => {
        console.log(err);
        setGotObject(false);
      }
    );
  }

  if (object === undefined) {
    return <></>;
  } else {
    return (
      <LocalAssetElement
        object={object}
        config={props.config}
        onClick={props.onClick}
        onRightClick={props.onRightClick}
        onDoubleClick={props.onDoubleClick}
      />
    );
  }
};

const LocalAssetElement = (props: {
  object: any;
  config: AssetObject;
  onClick: Function;
  onRightClick: Function;
  onDoubleClick: Function;
}) => {
  const { handleSceneObjectAction } = useSceneViewer();
  const primitiveRef = useRef<THREE.Group>(null);
  const newclone = useMemo(() => SkeletonUtils.clone(props.object.scene), [props.object.scene]);
  const { actions, names } = useAnimations(props.object.animations, primitiveRef);
  const [annotations, setAnnotations] = useState<JSX.Element[]>([]);

  useAssetManipulation({
    config: props.config,
    ref: primitiveRef,
    actions: actions,
    names: names,
    scene: props.object.scene,
    Mode: ModeTypes.edit,
  });

  useEffect(() => {
    if (props.config.localProperties.originalBBox === undefined) {
      const getannotations = [] as JSX.Element[];
      props.object.scene.traverse((o: any) => {
        if (o.userData.prop) {
          getannotations.push(
            <Html
              key={o.uuid}
              position={[o.position.x, o.position.y, o.position.z]}
              distanceFactor={0.5}
            >
              <AnnotationContainer data={o.userData.prop} index={getannotations.length + 1} />
            </Html>
          );
        }
      });
      setAnnotations(getannotations);
      let bbox: THREE.Box3 | undefined;
      if (names.length > 0) {
        // @ts-ignore
        bbox = new THREE.Box3().setFromObject(primitiveRef.current, true);
      } else {
        bbox = new THREE.Box3().setFromObject(props.object.scene, true);
      }
      let animationState: {
        name: string;
        isPlaying: boolean | undefined;
        time: number | undefined;
        loop: THREE.AnimationActionLoopStyles | undefined;
      }[];
      if (names.length > 0) {
        animationState = names.map((name) => {
          let action = actions[name];
          return {
            name: name,
            isPlaying: action?.isRunning(),
            time: action?.getClip().duration,
            loop: action?.loop,
          };
        });
      } else {
        animationState = [];
      }

      let updatedLocalProperties = {
        originalBBox: bbox,
        animationState: animationState,
        annotationslength: getannotations.length,
        annotationShow: false,
        updateTexture: true,
        material_base: props.config.backendProperties.metadata.material_base,
      };
      let updatedBackendProperties = {} as any;

      if (props.config.localProperties.insertedThisSession) {
        const center = new THREE.Vector3();
        const size = new THREE.Vector3();
        bbox?.getCenter(center);
        bbox?.getSize(size);
        let offset = props.config.backendProperties.position[1] - center.y;
        let ischiild = props.config.localProperties.isChild;
        updatedBackendProperties['position'] = [
          props.config.backendProperties.position[0],
          ischiild ? props.config.backendProperties.position[1] : 0.01 + size.y / 2.0 + offset,
          props.config.backendProperties.position[2],
        ];
        updatedBackendProperties['animation'] = {
          ...props.config.backendProperties.animation,
        };
        updatedBackendProperties['animation'].states = [
          {
            name: 'base',
            position: [
              props.config.backendProperties.position[0],
              ischiild ? props.config.backendProperties.position[1] : 0.01 + size.y / 2.0 + offset,
              props.config.backendProperties.position[2],
            ],
            rotation: props.config.backendProperties.rotation,
            scale: props.config.backendProperties.scale,
            material_base: props.config.backendProperties.metadata.material_base,
          },
        ];
      }

      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: props.config.id,
          type: props.config.type,
          localProperties: updatedLocalProperties,
          backendProperties: updatedBackendProperties,
        },
      ]);
    }
  }, [props.config]);

  return (
    <group
      ref={primitiveRef}
      onClick={(event) => props.onClick(event, props.config.id, SupportedSceneObjectTypes.asset)}
      onContextMenu={(event) =>
        props.onRightClick(event, props.config.id, SupportedSceneObjectTypes.asset)
      }
      onDoubleClick={(event) =>
        props.onDoubleClick(event, props.config.id, SupportedSceneObjectTypes.asset)
      }
      renderOrder={1}
    >
      {names.length > 0 ? (
        <primitive object={newclone}>
          {props.config.localProperties.annotationShow ? annotations : null}
        </primitive>
      ) : (
        <primitive object={props.object.scene}>
          {props.config.localProperties.annotationShow ? annotations : null}
        </primitive>
      )}
    </group>
  );
};

const AssetElement = (props: {
  url: string;
  config: AssetObject;
  onClick: Function;
  onRightClick: Function;
  onDoubleClick: Function;
}) => {
  const { handleSceneObjectAction } = useSceneViewer();
  // const { getCacheItem } = useCache();

  // const loaderConfiguration = () => {
  //   return async (loader: GLTFLoader) => {
  //     const dracoLoader = new DRACOLoader();
  //     dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
  //     loader.setDRACOLoader(dracoLoader);

  //     try {
  //       const workspace = store.getState().app.currentUser?.workspace_id;
  //       const projectId = store.getState().app.projectId;
  //       const cachedItem = getCacheItem(getCloudStorageCacheKey(workspace!));

  //       const cloud = new CloudStorageManager(cachedItem.provider, cachedItem.config);
  //       const fileName = props.config.backendProperties.metadata.file;

  //       const key = `${uploadProjectAssetPath('objects', workspace!, projectId)}/${fileName}`;
  //       const newURL = cloud.getSignedUrl(key);

  //       const originalLoad = loader.load;

  //       loader.load = function (url, onLoad, onProgress, onError) {
  //         return originalLoad.call(this, newURL, onLoad, onProgress, onError);
  //       };
  //     } catch (error) {
  //       console.error('Error fetching model:', error);
  //     }
  //   };
  // };

  const object = useLoader(GLTFLoader, props.url as string, (loader) => {
    loader.setDRACOLoader(dracoLoader);
  });

  const primitiveRef = useRef<THREE.Group>(null);
  const newclone = useMemo(() => SkeletonUtils.clone(object.scene), [object.scene]);
  const { actions, names } = useAnimations(object.animations, primitiveRef);
  const [annotations, setAnnotations] = useState<JSX.Element[]>([]);

  useAssetManipulation({
    config: props.config,
    ref: primitiveRef,
    actions: actions,
    names: names,
    scene: object.scene,
    Mode: ModeTypes.edit,
  });

  useEffect(() => {
    Object.values(object.materials).forEach((material) => {
      if (material) {
        material.depthWrite = true;
      }
    });

    if (props.config.localProperties.originalBBox === undefined) {
      const getannotations = [] as JSX.Element[];

      object.scene.traverse((o: any) => {
        if (o.userData.prop) {
          getannotations.push(
            <Html
              key={o.uuid}
              position={[o.position.x, o.position.y, o.position.z]}
              distanceFactor={0.5}
            >
              <AnnotationContainer data={o.userData.prop} index={getannotations.length + 1} />
            </Html>
          );
        }
      });
      setAnnotations(getannotations);
      let bbox: THREE.Box3 | undefined;
      if (names.length > 0) {
        // @ts-ignore
        bbox = new THREE.Box3().setFromObject(primitiveRef.current, true);
      } else {
        bbox = new THREE.Box3().setFromObject(object.scene, true);
      }
      let animationState: {
        name: string;
        isPlaying: boolean | undefined;
        time: number | undefined;
        loop: THREE.AnimationActionLoopStyles | undefined;
      }[];
      if (names.length > 0) {
        animationState = names.map((name) => {
          let action = actions[name];
          return {
            name: name,
            isPlaying: action?.isRunning(),
            time: action?.getClip().duration,
            loop: action?.loop,
          };
        });
      } else {
        animationState = [];
      }

      let updatedLocalProperties = {
        originalBBox: bbox,
        animationState: animationState,
        annotationslength: getannotations.length,
        annotationShow: false,
        material_base: props.config.backendProperties.metadata.material_base,
        updateTexture: true,
      };
      let updatedBackendProperties = {} as any;

      if (props.config.localProperties.insertedThisSession) {
        const center = new THREE.Vector3();
        const size = new THREE.Vector3();
        bbox?.getCenter(center);
        bbox?.getSize(size);
        let offset = props.config.backendProperties.position[1] - center.y;
        let ischiild = props.config.localProperties.isChild;
        updatedBackendProperties['position'] = [
          props.config.backendProperties.position[0],
          0.01 + size.y / 2.0 + offset,
          props.config.backendProperties.position[2],
        ];
        updatedBackendProperties['animation'] = {
          ...props.config.backendProperties.animation,
        };
        updatedBackendProperties['animation'].states = [
          {
            name: 'base',
            position: [
              props.config.backendProperties.position[0],
              ischiild ? props.config.backendProperties.position[1] : 0.01 + size.y / 2.0 + offset,
              props.config.backendProperties.position[2],
            ],
            rotation: props.config.backendProperties.rotation,
            scale: props.config.backendProperties.scale,
            material_base: props.config.backendProperties.metadata.material_base,
          },
        ];
      }

      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: props.config.id,
          type: props.config.type,
          localProperties: updatedLocalProperties,
          backendProperties: updatedBackendProperties,
        },
      ]);
    }
  }, [props.config]);

  return (
    <group
      ref={primitiveRef}
      onClick={(event) => props.onClick(event, props.config.id, SupportedSceneObjectTypes.asset)}
      onContextMenu={(event) =>
        props.onRightClick(event, props.config.id, SupportedSceneObjectTypes.asset)
      }
      onDoubleClick={(event) =>
        props.onDoubleClick(event, props.config.id, SupportedSceneObjectTypes.asset)
      }
      renderOrder={1}
    >
      {names.length > 0 ? (
        <primitive object={newclone}>
          {props.config.localProperties.annotationShow ? annotations : null}
        </primitive>
      ) : (
        <Clone object={object.scene}>
          {props.config.localProperties.annotationShow ? annotations : null}
        </Clone>
      )}
    </group>
  );
};

const FBXAssetElement = (props: {
  url: string;
  config: AssetObject;
  onClick: Function;
  onRightClick: Function;
  onDoubleClick: Function;
}) => {
  const [annotations, setAnnotations] = useState<JSX.Element[]>([]);

  let object = useLoader(FBXLoader, props.url as string);

  const primitiveRef = useRef<THREE.Group>(null);
  const newclone = useMemo(() => SkeletonUtils.clone(object), [object]);

  const { actions, names } = useAnimations(object.animations, primitiveRef);
  const { handleSceneObjectAction } = useSceneViewer();

  useAssetManipulation({
    config: props.config,
    ref: primitiveRef,
    actions: actions,
    names: names,
    scene: object,
    Mode: ModeTypes.edit,
  });

  useEffect(() => {
    if (props.config.localProperties.originalBBox === undefined) {
      const getannotations = [] as JSX.Element[];

      object.traverse((o: any) => {
        if (o.userData.prop) {
          getannotations.push(
            <Html
              key={o.uuid}
              position={[o.position.x, o.position.y, o.position.z]}
              distanceFactor={0.5}
            >
              <AnnotationContainer data={o.userData.prop} index={getannotations.length + 1} />
            </Html>
          );
        }
      });

      setAnnotations(getannotations);

      let bbox: THREE.Box3 | undefined;
      if (names.length > 0) {
        // @ts-ignore
        bbox = new THREE.Box3().setFromObject(primitiveRef.current, true);
      } else {
        bbox = new THREE.Box3().setFromObject(object, true);
      }

      let animationState: {
        name: string;
        isPlaying: boolean | undefined;
        time: number | undefined;
        loop: THREE.AnimationActionLoopStyles | undefined;
      }[];

      if (names.length > 0) {
        animationState = names.map((name) => {
          let action = actions[name];
          return {
            name: name,
            isPlaying: action?.isRunning(),
            time: action?.getClip().duration,
            loop: action?.loop,
          };
        });
      } else {
        animationState = [];
      }

      let updatedLocalProperties = {
        originalBBox: bbox,
        animationState: animationState,
        annotationslength: getannotations.length,
        annotationShow: false,
        material_base: props.config.backendProperties.metadata.material_base,
        updateTexture: true,
      };

      let updatedBackendProperties = {} as any;

      if (props.config.localProperties.insertedThisSession) {
        const center = new THREE.Vector3();
        const size = new THREE.Vector3();
        bbox?.getCenter(center);
        bbox?.getSize(size);
        let offset = props.config.backendProperties.position[1] - center.y;
        let ischiild = props.config.localProperties.isChild;
        updatedBackendProperties['position'] = [
          props.config.backendProperties.position[0],
          0.01 + size.y / 2.0 + offset,
          props.config.backendProperties.position[2],
        ];
        updatedBackendProperties['animation'] = {
          ...props.config.backendProperties.animation,
        };
        updatedBackendProperties['animation'].states = [
          {
            name: 'base',
            position: [
              props.config.backendProperties.position[0],
              ischiild ? props.config.backendProperties.position[1] : 0.01 + size.y / 2.0 + offset,
              props.config.backendProperties.position[2],
            ],
            rotation: props.config.backendProperties.rotation,
            scale: props.config.backendProperties.scale,
            material_base: props.config.backendProperties.metadata.material_base,
          },
        ];
      }

      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: props.config.id,
          type: props.config.type,
          localProperties: updatedLocalProperties,
          backendProperties: updatedBackendProperties,
        },
      ]);
    }
  }, [props.config]);

  return (
    <group
      ref={primitiveRef}
      onClick={(event) => props.onClick(event, props.config.id, SupportedSceneObjectTypes.asset)}
      onContextMenu={(event) =>
        props.onRightClick(event, props.config.id, SupportedSceneObjectTypes.asset)
      }
      onDoubleClick={(event) =>
        props.onDoubleClick(event, props.config.id, SupportedSceneObjectTypes.asset)
      }
      renderOrder={1}
    >
      {names.length > 0 ? (
        <primitive object={newclone}>
          {props.config.localProperties.annotationShow ? annotations : null}
        </primitive>
      ) : (
        <Clone object={object}>
          {props.config.localProperties.annotationShow ? annotations : null}
        </Clone>
      )}
    </group>
  );
};

SceneAsset.defaultProps = defaultSceneAssetProps;
export default SceneAsset;
