import { useAppDispatch } from 'src/store/reducers/hook';
import {
  addSceneObject,
  updateSceneObject,
  removeSceneObject,
  removeSceneEvents,
  cleanSceneViewer,
  updateShowLoading,
  updateCurrentViewport,
  updateCameraConfig,
  SceneViewerKeys,
  setSceneObjectList,
  setSelectedObjects,
  updateShowEvents,
  setSceneEvents,
  updateSceneEvents,
  addSceneEvents,
} from 'src/store/reducers/sceneViewer';
import {
  copyGroupInstanceAPI,
  deleteGroupEdgeAPI,
  deleteGroupInstanceAPI,
  getBackgroundGroupInstancesAPI,
  getEventsAPI,
  getSceneGroupInstancesAPI,
  insertGroupInstanceAPI,
  insertEventAPI,
  ungroupAPI,
  updateGroupInstanceAPI,
  updateEventAPI,
  deleteEventAPI,
} from 'src/apis';
import { setSceneId } from 'src/store/reducers/InstanceReducer';
import store from 'src/store/store';
import { eulerToQuaternion, quaternionToEuler } from 'src/utils/helper';
import * as TYPES from 'src/types';
import useUndoRedo from 'src/hooks/useUndoRedo';
import {
  calculateCameraConfig,
  calculateGroupBBox,
  checkBBoxUpdatedNeeded,
  getDefaultLocalProperties,
  getDefaultMetadata,
  getOldestParent,
  getParentType,
  getSceneObject,
  getWorldTransform,
} from '../helpers';
import { useDebouncedCallback } from 'use-debounce';
import { RealtimePostgresChangesPayload } from '@supabase/supabase-js';
import { useContext } from 'react';
import { context } from '../context';
import { isObjEmpty } from 'src/utils/merge';
import {
  BROADCAST_EVENTS,
  Channels,
  HeadObject,
  SceneObjectActionTypes,
  SupportedSceneObjectTypes,
} from 'src/types';
import { ChannelManager } from 'src/utils/Channel';
import { generateUUID } from 'three/src/math/MathUtils';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'src/utils/constants';

interface TargetType {
  type: TYPES.SupportedSceneObjectTypes;
  id: string | null;
  localProperties?: any;
  backendProperties?: any;
}

export const useSceneViewer = () => {
  const dispatch = useAppDispatch();
  const { takeScreenshot } = useContext(context);
  const { execute, pause, resume, update } = useUndoRedo();
  const location = useLocation();
  const supabaseUpdateDelay = 600;

  const updateParentBBox = (
    id: string,
    checkUpdateNeeded: boolean = true,
    localPropertiesChanges?: any,
    backendPropertiesChanges?: any
  ) => {
    let updateNeeded = false;
    if (checkUpdateNeeded) {
      if (localPropertiesChanges || backendPropertiesChanges) {
        updateNeeded = checkBBoxUpdatedNeeded(localPropertiesChanges, backendPropertiesChanges);
      } else {
        console.error('Cannot check if updated Needed! Changes not passed as parameters');
      }
    } else {
      updateNeeded = true;
    }

    if (updateNeeded) {
      const sceneObject = getSceneObject(id);
      if (sceneObject) {
        if (sceneObject.backendProperties.parent_group_id !== null) {
          const parentObject = getSceneObject(sceneObject.backendProperties.parent_group_id);
          if (parentObject) {
            const bbox = calculateGroupBBox(parentObject.localProperties.children);
            if (bbox) {
              const sceneObjectChanges = {
                id: parentObject.id,
                type: parentObject.type,
                localProperties: {
                  originalBBox: bbox.clone(),
                },
                backendProperties: {},
              };

              dispatch(
                updateSceneObject({
                  id: parentObject.id,
                  type: parentObject.type,
                  sceneObjectChanges: sceneObjectChanges,
                })
              );
              updateParentBBox(parentObject.id, false);
            }
          }
        }
      }
    }
  };

  const updateParentChildren = (parent_id: string) => {
    const parentObject = getSceneObject(parent_id);
    if (parentObject) {
      const updatedChildrenList = [] as any[];
      Object.values(store.getState().sceneViewer.entities).forEach((sceneObject) => {
        if (sceneObject.backendProperties.parent_group_id === parentObject.id) {
          updatedChildrenList.push({
            id: sceneObject.id,
            type: sceneObject.type,
          });
        }
      });

      const sceneObjectChanges = {
        id: parentObject.id,
        type: parentObject.type,
        localProperties: {
          children: [...updatedChildrenList],
        },
        backendProperties: {},
      };
      dispatch(
        updateSceneObject({
          id: parentObject.id,
          type: parentObject.type,
          sceneObjectChanges: sceneObjectChanges,
        })
      );

      if (updatedChildrenList.length) {
        updateParentBBox(updatedChildrenList[0].id, false);
      }
    }
  };

  const onSceneObjectInsert = <
    T_LP extends Partial<TYPES.SceneObjectLocalProperties>,
    T_BP extends TYPES.SceneObjectBackendProperties<TYPES.SceneObjectMetadata>,
  >(
    type: TYPES.SupportedSceneObjectTypes,
    localProperties: T_LP,
    backendProperties: T_BP
  ) => {
    const updateStore = localProperties.updateStore;

    if (updateStore === false) return;

    const defaultLocalProperties = getDefaultLocalProperties(type);
    const defaultMetadata = getDefaultMetadata(type);

    if (defaultMetadata === undefined) {
      console.error('Unsupported type in onSceneObjectInsert: ', type);
      return;
    }

    const newBackendProperties = { ...backendProperties } as any;
    if (newBackendProperties['rotation'] !== undefined) {
      if (newBackendProperties['rotation'].length === 4) {
        newBackendProperties['rotation'] = quaternionToEuler(newBackendProperties['rotation']);
      }
    }

    newBackendProperties.type = type;

    if (newBackendProperties['metadata'] !== undefined) {
      newBackendProperties['metadata'] = {
        ...defaultMetadata,
        ...newBackendProperties['metadata'],
      };
    }

    dispatch(
      addSceneObject({
        sceneObject: {
          id: backendProperties.id,
          type: type,
          localProperties: {
            ...defaultLocalProperties,
            ...localProperties,
          },
          backendProperties: newBackendProperties,
        },
      })
    );

    // If parent_group_id is there in backendProperties, then update that group's children
    if (newBackendProperties['parent_group_id'] !== undefined) {
      updateParentChildren(newBackendProperties['parent_group_id']);
    }
  };
  const onSceneEventInsert = (event: any) => {
    dispatch(addSceneEvents({ event: event }));
  };
  const onSceneObjectDelete = (type: TYPES.SupportedSceneObjectTypes, id: string) => {
    // If object is part of a group, then update group's children list and bbox
    const sceneObject = getSceneObject(id);
    if (sceneObject) {
      if (sceneObject.backendProperties.parent_group_id !== null) {
        updateParentChildren(sceneObject.backendProperties.parent_group_id);
      }
    }

    dispatch(removeSceneObject({ id: id, type: type }));
  };
  const onSceneEventDelete = (id: string) => {
    dispatch(removeSceneEvents({ id: id }));
  };
  const updateMetadataIfExists = (nodeToUpdate: any, changes: Record<string, any>) => {
    let metadata;

    if (changes['metadata'] !== undefined) {
      metadata = {
        ...nodeToUpdate.backendProperties.metadata,
        ...changes['metadata'],
      };
    }

    return metadata;
  };

  const onSceneObjectUpdate = <
    T_LP extends Partial<TYPES.SceneObjectLocalProperties>,
    T_BP extends Partial<TYPES.SceneObjectBackendProperties<TYPES.SceneObjectMetadata>>,
  >(
    id: string,
    type: TYPES.SupportedSceneObjectTypes,
    localPropertiesChanges: T_LP,
    backendPropertiesChanges: T_BP
  ) => {
    const nodeToUpdate = Object.values(store.getState().sceneViewer.entities).find(
      (item) => item.id === id && item.type === type
    );
    if (!nodeToUpdate) return;

    const initialParentId = nodeToUpdate.backendProperties.parent_group_id;

    const metadata = updateMetadataIfExists(nodeToUpdate, backendPropertiesChanges);

    const sceneObjectChanges = {
      id: id,
      type: type,
      localProperties: {
        ...localPropertiesChanges,
      },
      backendProperties: {
        ...backendPropertiesChanges,
        ...(metadata && { metadata }),
      },
    };

    dispatch(
      updateSceneObject({
        id: id,
        type: type,
        sceneObjectChanges: sceneObjectChanges,
      })
    );

    let updateDone = false;
    if (backendPropertiesChanges['parent_group_id'] !== undefined) {
      if (initialParentId === null && backendPropertiesChanges['parent_group_id'] !== null) {
        // setting parent_id from null to new parent
        // update parent's children list and bbox
        updateParentChildren(backendPropertiesChanges['parent_group_id']);
        updateDone = true;
      } else if (initialParentId !== null && backendPropertiesChanges['parent_group_id'] === null) {
        // setting parent_id back to null
        // update old parent's children list and bbox
        updateParentChildren(initialParentId);
        updateDone = true;
      } else if (initialParentId !== null && backendPropertiesChanges['parent_group_id'] !== null) {
        if (initialParentId !== backendPropertiesChanges['parent_group_id']) {
          // changing parent
          // update both parent's children list and bbox
          updateParentChildren(initialParentId);
          updateParentChildren(backendPropertiesChanges['parent_group_id']);
          updateDone = true;
        }
      }
    }

    if (!updateDone) {
      updateParentBBox(id, true, localPropertiesChanges, backendPropertiesChanges);
    }
  };
  const onSceneEventUpdate = (event: any) => {
    dispatch(
      updateSceneEvents({
        id: event.id,
        changes: event,
      })
    );
  };

  const fetchBackgroundAssets = async (sceneId: string) => {
    const sceneInstance = store
      .getState()
      .storyboard.nodes.find((node) => node.id === sceneId) as any;

    const storyline = store
      .getState()
      .storyboard.nodes.filter(
        (node: any) => node.storyline === sceneInstance?.storyline && node.id !== sceneId
      )
      .map((node) => node.id);

    return await getBackgroundGroupInstancesAPI(storyline);
  };

  /**
   * Fetch all objects from group_instances table
   * Also calculate bbox for all non-group objects
   * @param sceneId
   * @param storyLineId
   */

  const fetchGroupObjects = async (sceneId: string) => {
    const projectId = store.getState().app.projectId;

    const result = await Promise.all([
      getSceneGroupInstancesAPI(projectId, sceneId),
      fetchBackgroundAssets(sceneId),
    ]);

    if (result[0] && result[1]) {
      const allGroupInstances = [...result[0], ...result[1]];

      const instances = allGroupInstances
        .filter(
          (item) => !(item.type === TYPES.SupportedSceneObjectTypes.viewport && item.background)
        )
        .map((item) => {
          const eulerRotation = quaternionToEuler(item.rotation);
          let isPrimary = false;

          if (item.type === TYPES.SupportedSceneObjectTypes.viewport) {
            if (item.metadata?.label === 'viewport-primary') {
              isPrimary = true;
            }
          }

          const sceneObject = {
            id: item.id,
            type: item.type,
            localProperties: {
              insertedThisSession: false,
              isPrimary: isPrimary,
            },
            backendProperties: {
              ...item,
              rotation: eulerRotation,
            },
          } as TYPES.GroupObject;

          return sceneObject;
        });

      dispatch(setSceneObjectList(instances));
    }
  };

  const debouncedSceneObjectUpdate = useDebouncedCallback(
    async (targets: TargetType[], pushToHistory: boolean = true) => {
      if (pushToHistory) {
        const historyId = store.getState().history.pausedAt;
        if (historyId) {
          resume();
          update({
            id: historyId,
            // redo function
            executeFn: () => {
              targets.forEach((target) => {
                onSceneObjectUpdate(
                  target.id as string,
                  target.type,
                  target.localProperties,
                  target.backendProperties
                );
                const updateAPI = updateGroupInstanceAPI;
                if (updateAPI && !isObjEmpty(target.backendProperties)) {
                  updateAPI(target.id as string, { ...target.backendProperties });
                }
              });
            },
          });
        }
      }

      const promises = [] as any[];

      targets.forEach((target) => {
        const updateAPI = updateGroupInstanceAPI;

        if (target.type === TYPES.SupportedSceneObjectTypes.viewport) {
          takeScreenshot(false);
        }

        if (updateAPI && !isObjEmpty(target.backendProperties)) {
          promises.push(updateAPI(target.id as string, target.backendProperties));
        }
      });

      return promises;
    },
    supabaseUpdateDelay
  );

  /**
   * Fetch all the sceneObjects for the current scene from supabase
   */
  const fetchSceneObjects = async () => {
    const sceneId = store.getState().instance.current_sceneId;

    await fetchGroupObjects(sceneId);

    console.log('Scene Objects Fetched');
    updateAllChildrenList();
  };

  const notifyOthersOnCameraChange = (viewport: any) => {
    const rotation = eulerToQuaternion(viewport.rotation);
    const payload = {
      rotation: { x: rotation[0], y: rotation[1], z: rotation[2], w: rotation[3] },
      position: {
        x: viewport.position[0],
        y: viewport.position[1],
        z: viewport.position[2],
      },
    };

    const usersPresent = store.getState().collaboration.enabled;
    if (!usersPresent) return;

    const enabled = store.getState().collaboration.enabled;

    const userId = store.getState().app.currentUser?.id;
    const sceneId = store.getState().instance.current_sceneId;

    if (enabled && userId) {
      ChannelManager.sendToChannel(Channels.heads, BROADCAST_EVENTS.head, {
        ...payload,
        sceneId,
        userId,
      });
    }
  };
  const fetchEvents = async () => {
    const projectId = store.getState().app.projectId;
    const sceneId = store.getState().instance.current_sceneId;
    const events = await getEventsAPI(projectId, sceneId);
    console.log('Events Fetched');
    if (events) {
      dispatch(setSceneEvents(events));
    }
  };
  const handleSceneEventsAction = async (
    action: TYPES.SceneEventActionTypes,
    targets: TYPES.SceneEvent[]
  ) => {
    if (action === TYPES.SceneEventActionTypes.insert) {
      const promises = [] as any[];
      targets.forEach((target) => {
        const insertAPI = insertEventAPI;
        if (insertAPI) {
          promises.push(insertAPI(target));
        }
      });

      // Local Memory Updates
      Promise.all(promises).then((returnedProperties) => {
        returnedProperties.forEach((targetProperty) => {
          onSceneEventInsert(targetProperty[0]);
        });
      });
      return promises;
    } else if (action === TYPES.SceneEventActionTypes.update) {
      const promises = [] as any[];
      let updatedTargets = targets;
      targets.forEach((target) => {
        const updateAPI = updateEventAPI;
        if (updateAPI) {
          promises.push(updateAPI(target.id as string, target));
        }
      });
      updatedTargets.forEach((target) => {
        onSceneEventUpdate(target);
      });

      return promises;
    } else if (action === TYPES.SceneEventActionTypes.delete) {
      const promises = [] as any[];
      targets.forEach((target) => {
        const deleteAPI = deleteEventAPI;
        if (deleteAPI) {
          promises.push(deleteAPI(target.id as string));
        }
      });

      // Local Memory Updates
      Promise.all(promises).then(() => {
        targets.forEach((target) => {
          onSceneEventDelete(target.id as string);
        });
      });
      return promises;
    }
  };

  /**
   * Perform action of list of targets
   * @param action
   * @param targets
   * @param pushToHistory Default value if true
   * @param insertedThisSession Only used if (action === SceneObjectActionTypes.insert). Default value is true
   */
  const handleSceneObjectAction = async (
    action: TYPES.SceneObjectActionTypes,
    targets: TargetType[],
    pushToHistory: boolean = true,
    insertedThisSession: boolean = true
  ) => {
    if (action === TYPES.SceneObjectActionTypes.insert) {
      const promises = [] as any[];

      // Call API
      targets.forEach((target) => {
        const defaultMetadata = getDefaultMetadata(target.type);

        if (
          target.localProperties.subType === TYPES.SceneActionSubType.copy &&
          target.type === TYPES.SupportedSceneObjectTypes.group
        ) {
          const { scene_id, ...extras } = target.backendProperties;
          promises.push(copyGroupInstanceAPI(target.localProperties.id, scene_id, extras));
        } else {
          const insertAPI = insertGroupInstanceAPI;
          if (insertAPI && defaultMetadata) {
            const backendProperties = { ...target.backendProperties };
            backendProperties['type'] = target.type;
            if (backendProperties['metadata'] !== undefined) {
              backendProperties['metadata'] = {
                ...defaultMetadata,
                ...backendProperties['metadata'],
              };
            } else {
              console.error('Empty metadata passed in insert function!');
            }

            promises.push(insertAPI({ ...backendProperties }));
          }
        }
      });

      // Local Memory Updates
      Promise.all(promises)
        .then((returnedProperties) => {
          returnedProperties.forEach((targetProperty: any, targetIndex) => {
            if (Array.isArray(targetProperty)) {
              targetProperty.forEach((item) => {
                onSceneObjectInsert(
                  item.type,
                  {
                    ...targets[targetIndex].localProperties,
                  },
                  item
                );
              });
            } else {
              const backendProperties = { ...targetProperty[0] };
              onSceneObjectInsert(
                targets[targetIndex].type,
                {
                  insertedThisSession: insertedThisSession,
                },
                backendProperties
              );
            }
          });

          if (pushToHistory) {
            execute({
              // redo function
              type: 'insert-group-instance',
              executeFn: () => {
                returnedProperties.forEach((targetProperty: any, targetIndex) => {
                  if (Array.isArray(targetProperty)) {
                    targetProperty.forEach((item) => {
                      onSceneObjectInsert(
                        item.type,
                        {
                          insertedThisSession: insertedThisSession,
                        },
                        item
                      );
                    });

                    insertGroupInstanceAPI(targetProperty);
                  } else {
                    console.log('target property', targetProperty);
                    const backendProperties = { ...targetProperty[0] };

                    onSceneObjectInsert(
                      targets[targetIndex].type,
                      {
                        insertedThisSession: insertedThisSession,
                      },
                      {
                        ...backendProperties,
                      }
                    );

                    const insertAPI = insertGroupInstanceAPI;

                    if (insertAPI) {
                      insertAPI({ ...targetProperty[0] });
                    }
                  }
                });
              },
              // undo function
              undoFn: () => {
                returnedProperties.forEach((targetProperty: any, targetIndex) => {
                  const promises = targetProperty.map((item: any) => {
                    onSceneObjectDelete(item.id, item.type);
                    return deleteGroupInstanceAPI(item.id);
                  });

                  Promise.all(promises);
                });
              },
            });
          }
        })
        .catch((error) => {
          console.error('Insert failed: ', error);
        });

      return promises;
    } else if (action === TYPES.SceneObjectActionTypes.update) {
      const oldValues = [] as any[];
      let updatedTargets = targets;

      if (pushToHistory) {
        updatedTargets = targets.map((target) => {
          const sceneObject = getSceneObject(target.id as string);
          if (sceneObject) {
            oldValues.push(sceneObject);
            const metadata = updateMetadataIfExists(sceneObject, target.backendProperties);

            if (metadata) {
              return {
                ...target,
                backendProperties: {
                  ...target.backendProperties,
                  metadata,
                },
              };
            }
          }
          return target;
        });
      }

      updatedTargets.forEach((target) => {
        onSceneObjectUpdate(
          target.id as string,
          target.type,
          target.localProperties,
          target.backendProperties
        );
      });

      if (pushToHistory) {
        execute({
          type: 'update-group-instance',
          executeFn: () => {},
          // undo function
          undoFn: () => {
            oldValues.forEach((oldSceneObject) => {
              onSceneObjectUpdate(
                oldSceneObject.id,
                oldSceneObject.type,
                oldSceneObject.localProperties,
                oldSceneObject.backendProperties
              );

              const updateAPI = updateGroupInstanceAPI;
              if (updateAPI && !isObjEmpty(oldSceneObject.backendProperties)) {
                updateAPI(oldSceneObject.id, { ...oldSceneObject.backendProperties });
              }
            });
          },
        });
        pause();
      }

      return debouncedSceneObjectUpdate(updatedTargets, pushToHistory);
    } else if (action === TYPES.SceneObjectActionTypes.ungroup) {
      const oldValues = [] as any[];
      if (pushToHistory) {
        targets.forEach((target) => {
          const sceneObject = getSceneObject(target.id as string);
          if (sceneObject) {
            oldValues.push(sceneObject);
          }
        });
      }

      const parentToUngroup = targets[0];

      if (parentToUngroup?.id) {
        ungroupAPI(parentToUngroup.id);
        onSceneObjectDelete(parentToUngroup.type, parentToUngroup.id);

        targets.slice(1).forEach((target) => {
          onSceneObjectUpdate(
            target.id!,
            target.type,
            target.localProperties,
            target.backendProperties
          );
        });
      }
    } else if (action === TYPES.SceneObjectActionTypes.delete) {
      if (pushToHistory) {
        const oldValues = [] as any[];
        targets.forEach((target) => {
          const sceneObject = getSceneObject(target.id as string);
          if (sceneObject) {
            oldValues.push(sceneObject);
          }
        });

        execute({
          type: 'delete-group-instance',
          // redo function
          executeFn: () => {
            oldValues.forEach((oldSceneObject) => {
              onSceneObjectDelete(oldSceneObject.type, oldSceneObject.id as string);
              const deleteAPI = deleteGroupEdgeAPI;
              if (deleteAPI) {
                deleteAPI(oldSceneObject.id as string);
              }
            });
          },
          // undo function
          undoFn: () => {
            oldValues.forEach((oldSceneObject) => {
              onSceneObjectInsert(
                oldSceneObject.type as TYPES.SupportedSceneObjectTypes,
                oldSceneObject.localProperties,
                oldSceneObject.backendProperties
              );

              const insertAPI = insertGroupInstanceAPI;
              if (insertAPI) {
                insertAPI({ ...oldSceneObject.backendProperties });
              }
            });
          },
        });
      }

      targets.forEach((target) => {
        onSceneObjectDelete(target.type, target.id as string);
        const deleteAPI = deleteGroupEdgeAPI;
        if (deleteAPI) {
          deleteAPI(target.id as string);
        }
      });
    }
  };

  /**
   * Get socket handler for scene object
   * @param type Currently supported types - asset, viewport, ui
   * @returns
   */
  const getSceneObjectHandler = () => {
    return (payload: RealtimePostgresChangesPayload<any>) => {
      switch (payload.eventType) {
        case 'INSERT':
          const sceneObject = getSceneObject(payload.new.id);
          if (!sceneObject) {
            const backendProperties = { ...payload.new };
            if (backendProperties['rotation'] !== undefined) {
              if (backendProperties['rotation'].length === 4) {
                backendProperties['rotation'] = quaternionToEuler(backendProperties['rotation']);
              }
            }
            onSceneObjectInsert(
              backendProperties.type,
              { insertedThisSession: false },
              { ...backendProperties }
            );
          }
          break;
        case 'UPDATE':
          const backendProperties = { ...payload.new };
          if (backendProperties['rotation'] !== undefined) {
            if (backendProperties['rotation'].length === 4) {
              backendProperties['rotation'] = quaternionToEuler(backendProperties['rotation']);
            }
          }
          onSceneObjectUpdate(payload.new.id, backendProperties.type, {}, { ...backendProperties });
          break;
        case 'DELETE':
          const sceneAsset = getSceneObject(payload.old.id);
          if (sceneAsset) {
            onSceneObjectDelete(sceneAsset.type, payload.old.id);
          }
          break;
      }
    };
  };

  const disableLoading = () => {
    dispatch(updateShowLoading(false));
  };

  const enableLoading = () => {
    dispatch(updateShowLoading(true));
  };

  const cleanup = (keysToExclude: SceneViewerKeys = ['firstFetchDone', 'cameraConfig']) => {
    dispatch(cleanSceneViewer(keysToExclude));
  };

  const onCurrentViewportUpdate = (newViewport: string | null) => {
    dispatch(updateCurrentViewport(newViewport));
  };

  const onCameraConfigUpdate = (updatedConfig: TYPES.cameraConfigInterface) => {
    dispatch(updateCameraConfig(updatedConfig));
  };

  /**
   * Update All Group Instances Children List
   */
  const updateAllChildrenList = () => {
    const childrenLists = {} as {
      [key: string]: {
        id: string;
        type: TYPES.SupportedSceneObjectTypes;
      }[];
    };

    const sceneObjects = Object.values(store.getState().sceneViewer.entities);

    sceneObjects.forEach((item) => {
      const parentGroupId = item.backendProperties.parent_group_id;

      if (parentGroupId !== null) {
        if (childrenLists[parentGroupId] === undefined) {
          childrenLists[parentGroupId] = [
            {
              id: item.id,
              type: item.type,
            },
          ];
        } else {
          childrenLists[parentGroupId].push({
            id: item.id,
            type: item.type,
          });
        }
      }
    });

    Object.keys(childrenLists).forEach((parent) => {
      const parentType = getParentType(parent);
      onSceneObjectUpdate(
        parent,
        parentType as TYPES.SupportedSceneObjectTypes,
        {
          children: [...childrenLists[parent]],
        },
        {}
      );
    });
  };
  const handleShowEvents = (show: boolean, id: string, index: string) => {
    dispatch(updateShowEvents({ show, id, index }));
  };
  const changeScene = (sceneId: string) => {
    if (sceneId) {
      dispatch(setSceneId(sceneId));
    }

    const enabled = store.getState().collaboration.enabled;

    if (enabled) {
      ChannelManager.sendToChannel(Channels.heads, BROADCAST_EVENTS.scene_change, {
        sceneId,
        userId: store.getState().app.currentUser?.id,
      });
    }
  };

  const createViewportChildren = (viewportId: string, sceneId: string) => {
    const projectId = store.getState().app.projectId;
    const headGroupId = generateUUID();
    const promise = handleSceneObjectAction(SceneObjectActionTypes.insert, [
      {
        type: SupportedSceneObjectTypes.group,
        id: headGroupId,
        localProperties: {},
        backendProperties: {
          id: headGroupId,
          system_generated: true,
          parent_group_id: viewportId,
          name: 'head',
          project_id: projectId,
          position: [0.0, 0.0, 0.0],
          scene_id: sceneId,
        },
      },
    ]);

    promise.then((res) => {
      if (res) {
        Promise.all(res).then(() => {
          handleSceneObjectAction(SceneObjectActionTypes.insert, [
            {
              id: null,
              type: SupportedSceneObjectTypes.head,
              localProperties: {},
              backendProperties: {
                name: 'head-asset',
                system_generated: true,
                parent_group_id: headGroupId,
                project_id: projectId,
                locked: true,
                scene_id: sceneId,
                position: [0.0, 0.0, 0.0],
                metadata: {
                  fov: 60.0,
                },
              },
            },
          ]);
        });
      }
    });
  };

  const onCreateViewport = (viewport: any = {}) => {
    const projectId = store.getState().app.projectId;
    const viewportId = generateUUID();

    const promise = handleSceneObjectAction(
      SceneObjectActionTypes.insert,
      [
        {
          type: SupportedSceneObjectTypes.viewport,
          id: null,
          localProperties: {},
          backendProperties: {
            project_id: projectId,
            id: viewportId,
            position: [0.0, 1.7, 3.0],
            system_generated: true,
            metadata: {
              label: 'viewport-primary',
            },
            ...viewport,
          },
        },
      ],
      false,
      true
    );

    promise.then((res) => {
      if (res) {
        Promise.all(res).then(() => createViewportChildren(viewportId, viewport.scene_id));
      }
    });
  };

  const setCameraFromCurrentViewport = () => {
    const viewport = store.getState().sceneViewer.currentViewport;

    if (viewport !== null) {
      if (location.pathname === ROUTES.preview) {
        const transform = store.getState().sceneViewer.entities[viewport];
        const p = transform.backendProperties.animation.states[0].position;
        const r = transform.backendProperties.animation.states[0].rotation;
        onCameraConfigUpdate(calculateCameraConfig(p, r));
      } else {
        const headObject = Object.values(store.getState().sceneViewer.entities).find((item) => {
          if (item.type === SupportedSceneObjectTypes.head) {
            const parent = getOldestParent(item.id) as any;
            return parent?.id === viewport;
          }
        }) as HeadObject;

        if (headObject) {
          const transform = getWorldTransform(headObject.id);

          onCameraConfigUpdate(calculateCameraConfig(transform.position, transform.rotation));
        }
      }
    }
  };

  const onSelectObject = (selected: any) => {
    dispatch(setSelectedObjects(selected));
  };

  const getSelectedObjects = () => {
    return store.getState().sceneViewer.selectedObjects;
  };

  return {
    fetchSceneObjects,
    fetchEvents,
    changeScene,
    handleSceneObjectAction,
    handleSceneEventsAction,
    handleShowEvents,
    getSceneObjectHandler,
    disableLoading,
    onCreateViewport,
    enableLoading,
    cleanup,
    onSelectObject,
    getSelectedObjects,
    onCurrentViewportUpdate,
    onCameraConfigUpdate,
    notifyOthersOnCameraChange,
    setCameraFromCurrentViewport,
  };
};
