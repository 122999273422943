export const TABLES = {
  instances: 'instances',
  scene_instances: 'scene_instances',
  text_instances: 'text_instances',
  comment_instances: 'comment_instances',
  link_instances: 'link_instances',
  camera_instances: 'camera_instances',
  voicenote_instances: 'voicenote_instances',
  project_users: 'project_users',
  projects: 'projects',
  users: 'users',
  invites: 'invites',
  comments: 'comments',
  ui_instances: 'ui_instances',
  scene_comments: 'scene_comments',
  integrations_auth: 'integrations_auth',
  group_instances: 'group_instances',
  events: 'events',
};

export enum ROUTES {
  login = '/',
  home = '/storyboard',
  editor = '/editor',
  preview = '/preview',
  register = '/signup',
  dashboard = '/dashboard',
  integrations = '/integrations',
  projects = '/projects',
  auth = '/auth/callback',
  create_workspace = '/workspace/new',
}

export const IS_DEV = process.env.NODE_ENV === 'development';
// export const IS_DEV = false;

export const DEFAULT_INTEGRATION = {
  id: 'k6a9a679-7e04-412e-b491-a23e501b44cf',
  name: 'unproject',
  enabled: true,
  logo: '/logo_256.png',
  description:
    'Unproject is your go-to cloud storage solution, offering seamless and secure file management. As our default provider, it combines reliability, ease of use, and robust features to meet all your data storage needs.',
  created_at: '2024-06-21T18:59:32.280806+00:00',
  category: 'storage',
  metadata: {
    colorScheme: 'black',
  },
};
