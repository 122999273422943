import './InteractionProperties.css';
import { Select } from '@chakra-ui/react';
import { ControllerView } from './controllerView/ControllerView';
import {
  ControllerSubtype,
  SupportedControllerModels,
  SceneObjectActionTypes,
  SupportedSceneObjectTypes,
  ControllerObject,
} from 'src/types';
import { useSceneViewer } from '../../hooks/useSceneViewer';
import { HandView } from './handView/HandView';
import store from 'src/store/store';
import { generateUUID } from 'three/src/math/MathUtils';
import { getControllerMetadata } from '../../helpers';
import { findChildrenOfType } from 'src/utils/tree';

export const InteractionsProperties = (props: { config: null | any; parent: string }) => {
  const config = props?.config;
  const { handleSceneObjectAction } = useSceneViewer();

  const handleInsertControllers = (properties: any, value: SupportedControllerModels) => {
    const handsIds = {
      left: generateUUID(),
      right: generateUUID(),
    };
    const promises = handleSceneObjectAction(SceneObjectActionTypes.insert, [
      {
        id: null,
        type: SupportedSceneObjectTypes.group,
        localProperties: {},
        backendProperties: {
          name: 'right hand',
          ...properties,
          position: [0.15, 0, 0],
          scale: [1, 1, 1],
          id: handsIds.right,
          metadata: null,
        },
      },
      {
        id: null,
        type: SupportedSceneObjectTypes.group,
        localProperties: {},
        backendProperties: {
          name: 'left hand',
          ...properties,
          scale: [1, 1, 1],
          id: handsIds.left,
          metadata: null,
        },
      },
    ]);

    promises.then((res) => {
      if (res)
        Promise.all(res).then(() => {
          handleSceneObjectAction(SceneObjectActionTypes.insert, [
            {
              id: null,
              type: SupportedSceneObjectTypes.controller,
              localProperties: {},
              backendProperties: {
                ...properties,
                position: [0, 0, 0],
                parent_group_id: handsIds.left,
                metadata: {
                  subType: ControllerSubtype.left,
                  ...getControllerMetadata(value, ControllerSubtype.left),
                  ...properties.metadata,
                },
              },
            },
            {
              id: null,
              type: SupportedSceneObjectTypes.controller,
              localProperties: {},
              backendProperties: {
                ...properties,
                position: [0, 0, 0],
                parent_group_id: handsIds.right,
                metadata: {
                  subType: ControllerSubtype.right,
                  ...getControllerMetadata(value, ControllerSubtype.right),
                  ...properties.metadata,
                },
              },
            },
          ]);
        });
    });
  };

  const handleInsertInteraction = (value: SupportedControllerModels) => {
    const interactionId = generateUUID();

    const properties = {
      position: [-0.15, 0, 0],
      rotation: [0.0, 0.0, 0.0],
      scale: [0.5, 0.5, 0.5],
      project_id: store.getState().app.projectId,
      scene_id: store.getState().instance.current_sceneId,
      system_generated: true,
      parent_group_id: interactionId,
      type: SupportedSceneObjectTypes.controller,
      metadata: {
        interactionId,
        type: value,
      },
    };

    const promises = handleSceneObjectAction(SceneObjectActionTypes.insert, [
      {
        id: null,
        type: SupportedSceneObjectTypes.interactions,
        localProperties: {},
        backendProperties: {
          id: interactionId,
          system_generated: true,
          name: 'hands',
          position: [0, -0.3, -0.5],
          project_id: properties.project_id,
          scene_id: properties.scene_id,
          metadata: {
            type: value,
          },
          parent_group_id: props.parent,
        },
      },
    ]);

    promises.then((res) => {
      if (res)
        Promise.all(res).then(() => {
          handleInsertControllers(properties, value);
        });
    });
  };

  const handleUpdateInteraction = (selectedValue: SupportedControllerModels) => {
    const controllersToUpdate = Object.values(store.getState().sceneViewer.entities)
      .filter((i: any) => i.backendProperties.metadata?.interactionId === config.id)
      .map((controller: any) => ({
        id: controller.id,
        type: controller.type,
        localProperties: {},
        backendProperties: {
          metadata: {
            ...controller.backendProperties.metadata,
            ...getControllerMetadata(selectedValue, controller.backendProperties.metadata.subType),
            type: selectedValue,
          },
        },
      }));

    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: config?.id,
        type: SupportedSceneObjectTypes.interactions,
        localProperties: {},
        backendProperties: {
          metadata: {
            interactionId: config.id,
            type: selectedValue,
          },
        },
      },
      ...controllersToUpdate,
    ]);
  };

  const handleInteractionsChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;

    if (!config && selectedValue === 'none') {
      return;
    }

    if (config?.metadata?.type === selectedValue) {
      return;
    }

    if (selectedValue === 'none') {
      if (config.id)
        handleSceneObjectAction(SceneObjectActionTypes.delete, [
          {
            id: config?.id,
            type: SupportedSceneObjectTypes.interactions,
          },
        ]);
    } else {
      if (config?.id) {
        handleUpdateInteraction(selectedValue as SupportedControllerModels);
      } else {
        handleInsertInteraction(selectedValue as SupportedControllerModels);
      }
    }
  };

  const getControllerAsset = (controllerModel: 'left' | 'right') => {
    const sceneAssets = Object.values(store.getState().sceneViewer.entities);

    const controllers = findChildrenOfType(
      sceneAssets,
      config?.id,
      SupportedSceneObjectTypes.controller
    );
    const controllerObject = controllers.find(
      (item) => item.backendProperties.metadata.subType === controllerModel
    ) as ControllerObject;

    return controllerObject;
  };

  const handleControllerStateChange = (
    controllerModel: 'left' | 'right',
    targetButton: string,
    newState: string
  ) => {
    const controllerObject = getControllerAsset(controllerModel);

    if (controllerObject) {
      const updatedBackendProperties = structuredClone(controllerObject.backendProperties);
      updatedBackendProperties.metadata['colorConfig'][targetButton] = newState;

      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: controllerObject.id,
          type: controllerObject.type,
          localProperties: {},
          backendProperties: updatedBackendProperties,
        },
      ]);
    }
  };

  const handleHandStateChange = (controllerModel: 'left' | 'right', newState: string) => {
    const controllerObject = getControllerAsset(controllerModel);

    if (controllerObject) {
      const newConfig = structuredClone(controllerObject.backendProperties.metadata);
      newConfig['actionConfig'][controllerModel] = newState;
      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: controllerObject.id,
          type: controllerObject.type,
          localProperties: {},
          backendProperties: {
            metadata: newConfig,
          },
        },
      ]);
    }
  };

  return (
    <div>
      <div className="interactions-heading">Interaction</div>
      <Select
        backdropBlur="8px"
        color="white"
        bg="rgba(18, 17, 23, 0.6)"
        w="50%"
        h="25px"
        display="inline-block"
        fontSize="12px"
        margin="10px 10px"
        variant="filled"
        onChange={(event) => handleInteractionsChange(event)}
        value={config?.metadata?.type ?? 'none'}
        sx={{
          '> option': {
            background: 'black',
            color: 'white',
            backdropBlur: '8px',
          },
          ':hover': {
            backdropBlur: '8px',
            color: 'white',
            bg: 'rgba(18, 17, 23, 0.6)',
          },
        }}
      >
        <option value="none">None</option>
        <option value="quest3">Quest 3</option>
        <option value="hands">Hands</option>
      </Select>
      {props.config ? (
        config?.metadata?.type === 'quest3' ? (
          <ControllerView
            config={config}
            parentViewport={props.parent}
            handleControllerStateChange={handleControllerStateChange}
          />
        ) : (
          <HandView
            config={config}
            parentViewport={props.parent}
            handleHandStateChange={handleHandStateChange}
          />
        )
      ) : null}
    </div>
  );
};
