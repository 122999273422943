import {
    Bounds,
    Environment,
    GizmoHelper,
    GizmoViewport,
    Grid,
    OrbitControls,
    PerspectiveCamera,
    Stage,
    useProgress,
  } from '@react-three/drei';
  import { Canvas } from '@react-three/fiber';
  import { DragEvent, DragEventHandler, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
  import Scene from 'src/components/sceneViewer/scene/Scene';
  import { useAssetUpload } from 'src/features/SceneViewer/hooks/useAssetUpload';
  import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
  import store from 'src/store/store';
  import { stringToColor } from 'src/utils/colors';
  import { sceneRefInterface } from './context';
  import { useSceneViewer } from './hooks/useSceneViewer';
  import WASDcontroles from './WASDcontroles';
  import { createShorcutKeybindings } from 'src/utils/shortcuts';
  import { openContextMenu } from 'src/store/reducers/modals';
  import useSceneMenu from './hooks/useSceneMenu';
  import { setActiveTool } from 'src/store/reducers/InstanceReducer';
  import { AssetObject, GroupObject, InstanceToolType, LightObject, SupportedSceneObjectTypes, UIObject, ViewportObject } from 'src/types';
  import { doesClipboardContainImage } from 'src/utils/clipboard';
  import { Vector3 } from 'three';
  import { getSceneObject } from './helpers';
  
  import { toast } from 'react-toastify';
import SceneAsset from './scene/SceneAsset';
import { Raytracer } from '@react-three/lgl';
import SceneUiAsset from './scene/SceneUiAsset';
import SceneGroupAsset from './scene/SceneGroupAsset';
import SceneLightAsset from './scene/SceneLightAsset';
import {Pathtracer} from '@react-three/gpu-pathtracer';
  
  const RaytracerPanel = forwardRef<sceneRefInterface, any>((props, ref) => {
    const dispatch = useAppDispatch();
    const containerRef = useRef<any>();
    const { progress } = useProgress();
    const toastId = useRef<number | null>(null);

    const sceneObjectList = useAppSelector((store) => store.sceneViewer.ids);

  const { viewportObjectList, assetObjectList, uiObjectList, groupObjectList, lightList } = useMemo(() => {
    const list = {
      viewportObjectList: [] as ViewportObject[],
      assetObjectList: [] as AssetObject[],
      uiObjectList: [] as UIObject[],
      groupObjectList: [] as GroupObject[],
      lightList: [] as LightObject[],
    };

    sceneObjectList.forEach((id) => {
      const asset = getSceneObject(id);

      if (asset) {
        switch (asset.type) {
          case SupportedSceneObjectTypes.viewport:
            list.viewportObjectList.push(asset);
            break;
          case SupportedSceneObjectTypes.asset:
            list.assetObjectList.push(asset);
            break;
          case SupportedSceneObjectTypes.ui:
            list.uiObjectList.push(asset);
            break;
          case SupportedSceneObjectTypes.group:
            list.groupObjectList.push(asset);
            break;
          case SupportedSceneObjectTypes.light:
            list.lightList.push(asset);
            break;
          default:
            console.log('not supported');
        }
      }
    });

    return list;
  }, [sceneObjectList]);
    
  
    if (progress > 0) {
      toast.update(11, {
        render: `Rendering scene ${progress.toFixed(2)}%`,
        progress: progress / 100,
        autoClose: progress === 100 ? 2000 : false,
        onOpen: () => {
          if (progress === 100) {
            toast.dismiss(11);
          }
        },
      });
    } else {
      toast(`Rendering scene ${progress.toFixed(2)}%`, {
        toastId: 11,
        position: 'bottom-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: progress / 100,
      });
    }
    
    return (
      <div
        className="rayPanel"
        ref={containerRef}
        id="rayPanel"
        style={{
          transition: 'border 200ms ease-in',
          borderRadius: props.isFollowing ? '1rem' : 'none',
          border: props.isFollowing
            ? `5px solid ${stringToColor(props.isFollowing)}`
            : '5px solid transparent',
        }}
        onDragOver={(e) => e.preventDefault()}
      >
        <Canvas
          className="rayViewer"
        //   eventSource={containerRef}
        //   onPointerMissed={(event) => onCanvasClicked(event)}
        //   style={{ background: 'red' }}
        //   gl={{
        //     antialias: true,
        //     powerPreference: 'high-performance',
        //   }}
        //   onCreated={({ gl }) => {
        //     gl.setPixelRatio(window.devicePixelRatio);
        //   }}
        //   flat
        //   shadows
        >
            <PerspectiveCamera
              makeDefault
              position={[-5.0, 10.0, 5.0]}
              near={0.025}
            />
            {/* <Pathtracer
              samples={32}
              bounces={3}
              backgroundIntensity={0}
            > */}
            <Raytracer
                samples={32}
                bounces={3}
                // enableTemporalDenoise
                // enableSpatialDenoise
                movingDownsampling
                envMapIntensity={1.0}
                enviromentVisible={true}
            >
                {/* <ambientLight intensity={0.0} /> */}
                {/* <color attach="background" args={['red']} /> */}
            {assetObjectList.map((data) => {
                if (data.backendProperties.parent_group_id !== null) {
                return null;
                }
                return (
                // <Suspense key={data.id}>
                    <SceneAsset
                    key={data.id}
                    config={data}
                    onPointerOver={() => {}}
                    onPointerOut={() => {}}
                    onClick={() => {}}
                    onRightClick={() => {}}
                    disablePivot={props.disablePivot || data.backendProperties.background}
                    />
                // </Suspense>
                );
            })}

            {uiObjectList.map((data) => {
                if (data.backendProperties.parent_group_id !== null) {
                return null;
                }
                return (
                    <SceneUiAsset
                    config={data}
                    onPointerOver={() => {}}
                    onPointerOut={() => {}}
                    onClick={() => {}}
                    onRightClick={() => {}}
                    />
                );
            })}

            {groupObjectList.map((data) => {
                if (data.backendProperties.parent_group_id !== null) return null;
                return (
                    <SceneGroupAsset
                    key={data.id}
                    config={data}
                    onPointerOver={() => {}}
                    onPointerOut={() => {}}
                    onClick={() => {}}
                    onRightClick={() => {}}
                    onDoubleClick={() => {}}
                    />

                );
            })}
            
            {
                lightList.map((data) => {
                return (
                    <SceneLightAsset
                    id={data.id}
                    key={data.id}
                    config={data}
                    onPointerOver={() => {}}
                    onPointerOut={() => {}}
                    onClick={() => {}}
                    onRightClick={() => {}}
                    onDoubleClick={() => {}}
                    lgl={true}
                    />
                )
                })
            }

            </Raytracer>
            {/* </Pathtracer> */}
            <Environment preset="city" />
            <OrbitControls
                makeDefault
            />
        </Canvas>
      </div>
    );
  });
  
  export default RaytracerPanel;
  