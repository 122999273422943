import { AddIcon } from '@chakra-ui/icons';
import { Button, Flex, Grid, Input, Skeleton, Stack } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { getWorkspaceAssets } from 'src/apis';
import { useAssetUpload } from 'src/features/SceneViewer/hooks/useAssetUpload';
import { useAppSelector } from 'src/store/reducers/hook';
import { AssetCategories, SupportedFiles } from 'src/types';
import Card from './Card';
import { AssetCategory } from './WorkspaceSettings/StorageAssetTable';

interface UserAssetsProps {
  type: 'workspace' | 'project';
  onClick: (assetPath: string) => Promise<void> | void;
}

const UserAssets = (props: UserAssetsProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [assets, setAssets] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeType, setActiveType] = useState<any>('objects');

  const { uploadAssets } = useAssetUpload();
  const workspace = useAppSelector((state) => state.app.currentUser?.workspace_id);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const assetMapper = (file: any) => {
    return {
      name: file.name,
      timestamp: file.created_at ?? Date.now(),
      imageSrc: './unnamed.webp',
      data: file,
    };
  };

  const handleFileChange = (event: any) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      try {
        uploadAssets(files, {
          upload: {
            level: 'workspace',
            category: activeType,
          },
        });
        setAssets((s: any) => [...s, ...Array.from(files).map(assetMapper)]);
      } catch (err) {
        console.error('Unable to upload', err);
      }
    }
  };

  const fetchAssets = async (workspace: string, activeType: AssetCategories) => {
    setIsLoading(true);

    getWorkspaceAssets(workspace, activeType)
      .then((res: any) => {
        const items = res?.map(assetMapper);

        setAssets(items);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (workspace) {
      fetchAssets(workspace, activeType);
    }
  }, [workspace, activeType]);

  const accept =
    activeType === 'objects'
      ? SupportedFiles['3D'].map((s) => `.${s}`).join(',')
      : SupportedFiles['ui'].join(',');

  return (
    <Stack gap={8}>
      <Flex justify="space-between">
        <AssetCategory active={activeType} setActive={setActiveType} />
        <Input
          type="file"
          accept={accept}
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <Button
          py={2}
          px={5}
          h="36px"
          size="sm"
          rounded="full"
          leftIcon={<AddIcon />}
          onClick={handleButtonClick}
        >
          Add
        </Button>
      </Flex>
      <Skeleton startColor="none" endColor="none" isLoaded={!isLoading}>
        <Grid
          width="100%"
          templateColumns="repeat(auto-fill, minmax(18rem, 1fr))"
          gap={4}
          justifyContent="center"
        >
          {assets?.map((asset: any, i: number) => (
            <Card
              key={i}
              imageSrc={asset.imageSrc}
              name={asset.name}
              timestamp={asset.timestamp}
              onClick={(e) => {
                e.stopPropagation();
                if (props.onClick) props.onClick(asset.data);
              }}
            />
          ))}
        </Grid>
      </Skeleton>
    </Stack>
  );
};

export default UserAssets;
