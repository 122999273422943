import { Badge, Box, Flex, Image, Text } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import { Tables } from 'src/types';

type Integration = Tables<'integrations'>;

interface IntegrationsProps {
  active?: string;
  isReadonly?: boolean;
  services: Array<Integration>;
  selected: string | undefined;
  onSelect: Dispatch<SetStateAction<string>>;
}

const IntegrationCards = (props: IntegrationsProps) => {
  const { services, selected, active, isReadonly } = props;

  return (
    <Flex align="center" gap={4} wrap="wrap" mb={6}>
      {services
        .sort((a, b) => {
          return a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1;
        })
        .map((provider) => {
          const colorScheme = (provider.metadata as any)?.colorScheme;
          const isIntegrated = active === provider.name;

          return (
            <Flex
              key={provider.name}
              maxW="sm"
              h="fit-content"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              p={6}
              bg="rgba(40,40,40,255)"
              position="relative"
              borderColor={selected === provider.name ? 'blue.500' : '#333'}
              justifyContent="center"
              align="center"
              gap={6}
              boxShadow="md"
              onClick={() => {
                if (!isReadonly) props.onSelect(provider.name!);
              }}
              transition="all 0.2s"
              _hover={
                !isReadonly
                  ? {
                      bg: 'rgba(50,50,50,255)',
                      cursor: 'pointer',
                    }
                  : {}
              }
              opacity={provider.enabled ? 1 : 0.3}
              pointerEvents={provider.enabled && !isReadonly ? 'auto' : 'none'}
            >
              <Image
                h={32}
                w={32}
                objectFit="cover"
                objectPosition="center"
                src={provider.logo}
                alt={provider.name!}
              />
              <Box pb={4}>
                <Flex alignItems="center" justify="space-between" gap={4}>
                  <Flex flex={1} gap={2}>
                    <Badge borderRadius="full" px="2" colorScheme={colorScheme}>
                      {provider.name}
                    </Badge>
                    {isIntegrated && (
                      <Badge borderRadius="full" colorScheme="green" px="2">
                        default
                      </Badge>
                    )}
                    {!provider.enabled && (
                      <Badge borderRadius="full" px="2">
                        coming soon
                      </Badge>
                    )}
                  </Flex>
                </Flex>

                <Text mb={0} mt={2} color="gray.600">
                  {provider.description!.length > 120
                    ? provider.description?.slice(0, 100) + '...'
                    : provider.description}
                </Text>
              </Box>
            </Flex>
          );
        })}
    </Flex>
  );
};

export default IntegrationCards;
