import { Box, Center, Grid, GridItem } from '@chakra-ui/react';
import BoardTools from './components/BoardTools';
import StoryBoardCanvas from './components/Canvas';
import CanvasContainer from './components/nodes/CanvasContainer';
import Comments from '../Comments';
import CursorAnchors from './components/CursorAnchors';
import Collaboration from '../Collaboration';
import { ReactFlowProvider } from 'reactflow';

type StoryboardProps = {
  readOnly?: boolean;
};

const StoryBoard: React.FC<StoryboardProps> = () => {
  return (
    <Box h="100%" p={2} bg="#191B23" color="blackAlpha.700" fontWeight="bold" position="relative">
      <BoardTools />
      <CursorAnchors />
      <CanvasContainer />
      <Box w="full" h="full" position="relative">
        <ReactFlowProvider>
          <StoryBoardCanvas />
        </ReactFlowProvider>
        <Comments />
        {/*<Debugger />*/}
      </Box>
      <Collaboration />
    </Box>
  );
};

export default StoryBoard;
