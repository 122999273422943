import { supabase } from 'src/SupabaseClient';
import { Tables } from 'src/types';
import { TABLES } from 'src/utils/constants';

export const addIntegrationAuthAPI = async (
  auth: Omit<Tables<'integrations_auth'>, 'id' | 'created_at'>
) => {
  const { data, error } = await supabase
    .from(TABLES.integrations_auth)
    .insert(auth)
    .select()
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const deleteIntegrationAuthAPI = async (id: string) => {
  await supabase.from(TABLES.integrations_auth).delete().eq('id', id);
};

export const getAllIntegrationsAPI = async () => {
  const { data, error } = await supabase.from('integrations').select('*');

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const getAllIntegrationsAuthAPI = async ({
  workspaceId,
  userId,
}: {
  workspaceId?: string;
  userId?: string;
}) => {
  let query = supabase.from('integrations_auth') as any;

  if (workspaceId) {
    query = query.select('name,auth_type,workspace_id,user_id').eq('workspace_id', workspaceId);
  }

  if (userId) {
    query = query.select('*').eq('user_id', userId).eq('scope', 'user');
  }

  const { data, error } = await query;

  if (error) {
    throw new Error(error.message);
  }

  return data;
};
