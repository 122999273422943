import { createSlice } from '@reduxjs/toolkit';
import type { Tables, User } from 'src/types';

type AppType = {
  isAuthenticated: boolean;
  isInitializing: boolean;
  currentUser: User | undefined;
  isOnline: boolean;
  projectId: string;
  project: {
    id: string;
    name: string;
  };
  lastLoggedIn: string | undefined;

  // Workspace
  workspaces: Tables<'workspaces'>[];
  setWorkspaces: (workspaces: Tables<'workspaces'>) => void;
};

const initialState = {
  isAuthenticated: false,
  isInitializing: false,
  projectId: '',
} as AppType;

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setProjectId(state, { payload: projectId }) {
      state.projectId = projectId;
    },
    setProject(state, { payload: project }) {
      state.project = project;
      state.projectId = project.id;
    },
    setCurrentUser(state, { payload: currentUser }) {
      if (!currentUser) {
        state.isAuthenticated = false;
        state.projectId = '';
        state.lastLoggedIn = state.currentUser?.id;
        state.currentUser = undefined;
      } else {
        state.isAuthenticated = true;
        state.currentUser = currentUser;
      }
    },
    setWorkspaces(state, { payload: workspaces }) {
      state.workspaces = workspaces;
    },
    setIsInitializing(state, { payload }: { payload: boolean }) {
      state.isInitializing = payload;
    },
    setIsOnline(state, { payload: isOnline }) {
      state.isOnline = isOnline;
    },
  },
});

export const {
  setProjectId,
  setProject,
  setIsInitializing,
  setCurrentUser,
  setIsOnline,
  setWorkspaces,
} = appSlice.actions;

export default appSlice.reducer;
