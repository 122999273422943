import './ControllerPropertyList.css';
import { ControllerOption } from './ControllerOption';

export const ControllerPropertyList = (props: {
  parentController: 'left' | 'right';
  config: any;
  handleControllerStateChange: Function;
}) => {
  return (
    <div className="controllerProperties">
      <div className="controllerProperties-heading">{props.parentController}</div>
      <div className="controllerProperties-controls">
        <ul>
          {Object.keys(props.config).map((item, i) => {
            return (
              <li key={item + i}>
                <ControllerOption
                  label={item}
                  parentController={props.parentController}
                  state={props.config[item]}
                  handleControllerStateChange={props.handleControllerStateChange}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
