import { Environment, Grid } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { Bloom, EffectComposer, SSAO, ToneMapping } from '@react-three/postprocessing';
import { BlendFunction, ToneMappingMode } from 'postprocessing';
import { useEffect, useMemo, useRef } from 'react';
import { Color, TextureLoader } from 'three';

import { useAppSelector } from 'src/store/reducers/hook';
import { getTextureURL } from 'src/utils/aws';
import { getPublicAssetURL } from 'src/utils/public';

import { PBRNeutralToneMappingEffect } from './PBRNeutralToneMappingEffect';

function Ground() {
  // scene_scale is used to scale the grid to the size of the scene. (1 -> m, 100 -> cm)

  const scene_scale = 1.0;
  const grid_scale_factor = 1.0 / scene_scale;
  const gridConfig = {
    cellSize: 0.5, // size of subdivision in meters
    cellThickness: 0.6,
    cellColor: '#6f6f6f',
    sectionSize: 1.0, // size of main-division in meters
    sectionThickness: 0.8,
    sectionColor: '#6f6f6f',
    fadeDistance: 30,
    fadeStrength: 0.5,
    followCamera: true,
    infiniteGrid: true,
  };
  return <Grid position={[0, -0.01, 0]} args={[5, 5, 5, 5]} {...gridConfig} name="ground" />;
}

function Background({
  url,
  color,
  isBackgroundEnabled,
}: {
  url: string;
  color: string;
  isBackgroundEnabled: boolean;
}) {
  const { scene } = useThree();
  useEffect(() => {
    try {
      if (isBackgroundEnabled) {
        if (url !== '') {
          const loader = new TextureLoader();
          loader.load(url, (loadedTexture) => {
            scene.background = loadedTexture;
          });
        } else {
          scene.background = new Color(color);
        }
      } else {
        scene.background = new Color('#202020');
      }
    } catch (e) {
      scene.background = new Color('#202020');
      console.log(e);
    }
  }, [url, color, isBackgroundEnabled]);

  return null;
}
function getToneMappingMode(toneMappingString?: string): ToneMappingMode | undefined {
  switch (toneMappingString) {
    case 'ACES_FILMIC':
      return ToneMappingMode.ACES_FILMIC;
    case 'NEUTRAL':
      return ToneMappingMode.NEUTRAL;
    case 'LINEAR':
      return ToneMappingMode.LINEAR;
    case 'REINHARD':
      return ToneMappingMode.REINHARD;
    case 'CINEON':
      return ToneMappingMode.OPTIMIZED_CINEON;
    case 'UNCHARTED2':
      return ToneMappingMode.UNCHARTED2;
    case 'AGX':
      return ToneMappingMode.AGX;
    case 'REINHARD2':
      return ToneMappingMode.REINHARD2;
    case 'REINHARD2_ADAPTIVE':
      return ToneMappingMode.REINHARD2_ADAPTIVE;
    default:
      return undefined; // Default to 'None' if the string is unrecognized or undefined
  }
}

const SceneCanvasProperties = ({ id, cameraViewer }: { id: string; cameraViewer: boolean }) => {
  const metadata = useAppSelector((store) => store.metadata.metadata[id]?.metadata);

  const environment = useMemo(() => {
    const bucketURL = getPublicAssetURL();

    const key = metadata?.environment?.key;

    if (!key) return;

    return `${bucketURL}/${metadata?.environment?.key}`;
  }, [metadata?.environment?.key]);

  const isBackgroundEnabled = metadata?.background?.enabled ?? true;
  const isAmbientLightEnabled = metadata?.ambientLight?.enabled ?? true;
  const isEnvironmentEnabled = metadata?.environment?.enabled ?? true;
  const isGridEnabled = metadata?.ground?.gridEnabled ?? true;
  const isSSAOEnabled = metadata?.effects?.ssao ?? true;
  const isBloomEnabled = metadata?.effects?.bloom ?? true;
  const effectComposerRef = useRef(null);
  const textureURL = useMemo(() => {
    if (!metadata?.background?.img) return '';
    return getTextureURL(metadata?.background?.img, '');
  }, [metadata?.background?.img]);
  const toneMappingMode = getToneMappingMode(metadata?.effects?.tonemaping);
  return (
    <>
      {cameraViewer === false && (
        <EffectComposer renderPriority={2} ref={effectComposerRef} enableNormalPass={isSSAOEnabled}>
          {isSSAOEnabled && (
            /* @ts-ignore  */
            <SSAO
              blendFunction={BlendFunction.MULTIPLY} // Use NORMAL to see the effect
              samples={11}
              radius={1}
              intensity={1}
              bias={0.001}
            />
          )}
          {metadata?.effects?.tonemaping && metadata?.effects?.tonemaping === 'PBR_NEUTRAL' && (
            <PBRNeutralToneMappingEffect param={0.1} />
          )}
          {metadata?.effects?.tonemaping &&
            metadata?.effects?.tonemaping !== 'none' &&
            metadata?.effects?.tonemaping !== 'PBR_NEUTRAL' && (
              <ToneMapping mode={toneMappingMode} />
            )}
          {isBloomEnabled && <Bloom mipmapBlur intensity={0.5} luminanceThreshold={2} />}
        </EffectComposer>
      )}
      <Background
        url={textureURL}
        isBackgroundEnabled={isBackgroundEnabled}
        color={metadata?.background?.color ?? '#202020'}
      />

      {isAmbientLightEnabled && (
        <ambientLight
          color={metadata?.ambientLight?.color ?? '#ffffff'}
          intensity={metadata?.ambientLight?.intensity ?? 2.0}
        />
      )}
      {environment && isEnvironmentEnabled && (
        <Environment background={!isBackgroundEnabled} files={environment} />
      )}
      {isGridEnabled && cameraViewer === false && <Ground />}
      {cameraViewer === false && (
        <mesh position={[0, 0, 0]} scale={100} rotation={[-Math.PI / 2, 0, 0]} receiveShadow>
          <planeGeometry />
          <shadowMaterial
            transparent
            opacity={parseFloat((metadata?.ground?.shadow / 100).toFixed(2))}
          />
        </mesh>
      )}
    </>
  );
};

export default SceneCanvasProperties;
