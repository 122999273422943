export const migrations = {
  2: (state: any) => {
    return {
      ...state,
      sceneViewer: {
        ...state.sceneViewer,
        firstFetchDone: {},
      },
    };
  },
  3: (state: any) => {
    return {
      ...state,
      sceneViewer: {
        ...state.sceneViewer,
        selectedObjects: [],
      },
    };
  },
  4: (state: any) => {
    const { sceneObjectList, ...restSceneViewer } = state.sceneViewer;
    return {
      ...state,
      sceneViewer: {
        ...restSceneViewer,
        ids: [],
        entities: {},
      },
    };
  },
  5: (state: any) => {
    return {
      ...state,
      sceneViewer: {
        ...state.sceneViewer,
        materials: {},
        currentMaterial: {},
      },
    };
  },
  6: (state: any) => {
    return {
      ...state,
      sceneViewer: {
        ...state.sceneViewer,
        events: {},
        showEvents: {
          show: false,
          id: '',
          index: '',
        },
      },
    };
  },
};
