import { Suspense, ReactNode, forwardRef, useImperativeHandle, useMemo } from 'react';

import { useThree } from '@react-three/fiber';
import { sceneRefInterface } from 'src/components/sceneViewer/context';

import SceneComment from '../scene/SceneComment';
import { useAppSelector } from 'src/store/reducers/hook';
import store from 'src/store/store';
import {
  AssetObject,
  GroupObject,
  SupportedSceneObjectTypes,
  UIObject,
  ViewportObject,
} from 'src/types';

import PreviewAsset from './PreviewAsset';
import PreviewGroupAsset from './PreviewGroupAsset';
import PreviewUiAsset from './PreviewUiAsset';
import { getSceneObject } from '../helpers';
import PreviewSceneObject from './PreviewSceneObject';

type SceneProps = {
  children?: ReactNode;
  addCamera?: boolean;
  disablePivot?: boolean;
};

const PreviewScene = forwardRef<sceneRefInterface, SceneProps>((props, ref) => {
  // State Variables

  const { scene } = useThree();
  // const dispatch = useDispatch();

  const commentIds = useAppSelector((store) => store.comments.ids);

  const sceneObjectList = useAppSelector((store) => store.sceneViewer.ids);

  const { viewportObjectList, assetObjectList, uiObjectList, groupObjectList } = useMemo(() => {
    const list = {
      viewportObjectList: [] as ViewportObject[],
      assetObjectList: [] as AssetObject[],
      uiObjectList: [] as UIObject[],
      groupObjectList: [] as GroupObject[],
    };

    sceneObjectList.forEach((id) => {
      const asset = getSceneObject(id);

      if (asset) {
        switch (asset.type) {
          case SupportedSceneObjectTypes.viewport:
            list.viewportObjectList.push(asset);
            break;
          case SupportedSceneObjectTypes.asset:
            list.assetObjectList.push(asset);
            break;
          case SupportedSceneObjectTypes.ui:
            list.uiObjectList.push(asset);
            break;
          case SupportedSceneObjectTypes.group:
            list.groupObjectList.push(asset);
            break;
          default:
            console.log('not supported');
        }
      }
    });

    return list;
  }, [sceneObjectList]);

  const comments = useMemo(() => {
    return commentIds.filter((id) => {
      const comment = store.getState().comments.entities[id];
      return !comment.parent_id && !comment.filename;
    });
  }, [commentIds]);

  useImperativeHandle(
    ref,
    () => ({
      scene: scene,
    }),
    [scene]
  );

  return (
    <>
      <ambientLight intensity={2.0} />
      <color attach="background" args={['#15151a']} />
      {/* Adding Assets to Scene*/}
      {assetObjectList.map((data) => {
        if (data.backendProperties.parent_group_id !== null) {
          return null;
        }

        return (
          <PreviewSceneObject id={data.id}>
            <PreviewAsset />
          </PreviewSceneObject>
        );
      })}

      {uiObjectList.map((data) => {
        if (data.backendProperties.parent_group_id !== null) {
          return null;
        }
        return (
          <PreviewSceneObject id={data.id}>
            <PreviewUiAsset />
          </PreviewSceneObject>
        );
      })}

      {viewportObjectList.map((data) => {
        if (data.backendProperties.parent_group_id !== null) return null;

        return (
          <PreviewSceneObject id={data.id}>
            <PreviewGroupAsset />
          </PreviewSceneObject>
        );
      })}

      {groupObjectList.map((data) => {
        if (data.backendProperties.parent_group_id !== null) return null;
        return (
          <PreviewSceneObject id={data.id}>
            <PreviewGroupAsset />
          </PreviewSceneObject>
        );
      })}

      {!props.disablePivot && (
        <>
          {comments.map((id) => {
            const node = store.getState().comments.entities[id];
            return <SceneComment key={id} node={node} />;
          })}
        </>
      )}
      {/* <FloatingUsers /> */}
    </>
  );
});

export default PreviewScene;
