import { supabase } from 'src/SupabaseClient';

export const getWorkspaceAPI = async () => {
  const { data, error } = await supabase.from('workspaces').select('*');

  return { data, error };
};

export const createWorkspaceAPI = async (id: string, name: string) => {
  const { data, error } = await supabase.from('workspaces').insert({ id, name });

  return { data };
};
