import { FiShare2 } from 'react-icons/fi';
import { Button, useDisclosure } from '@chakra-ui/react';

import InviteModal from './components/InviteModal';
import { Enums } from 'src/types';

type Props = {
  id: string;
  type: Enums<'user_invite_type'>;
};

const Invite = (props: Props) => {
  const { id, type } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        fontSize="sm"
        borderRadius="full"
        leftIcon={<FiShare2 />}
        colorScheme="blue"
      >
        Invite
      </Button>

      <InviteModal id={id} type={type} isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Invite;
