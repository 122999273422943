import {
  Badge,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { BiRevision } from 'react-icons/bi';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useCloudStorage } from 'src/hooks';
import { CloudStorageManager } from 'src/services/storage';
import { CloudProviderConfig } from 'src/services/storage/storage.types';
import store from 'src/store/store';
import {
  AssetCategories,
  ASSET_CATEGORY_COLORS,
  SceneObjectActionTypes,
  SupportedSceneObjectTypes,
} from 'src/types';
import { getBlobName, getDefaultContainer, uploadProjectAssetPath } from 'src/utils/cloud';
import { ROUTES } from 'src/utils/constants';
import { delay } from 'src/utils/time';
import { getAssetTypeFromName, getNameWithoutExtension } from 'src/utils/upload';
import { useSceneViewer } from '../sceneViewer/hooks/useSceneViewer';
import FileExplorer from './FileExplorer';

interface StorageAssetTableProps {
  service?: any;
}

const StorageAssetTable = (props: StorageAssetTableProps) => {
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { getCloudStorage } = useCloudStorage();
  const [isFetching, setIsFetching] = useState(false);
  const [itemsToImport, setItemsToImport] = useState<any>({
    objects: new Set(),
    ui: new Set(),
    textures: new Set(),
  });

  const [stack, setStack] = useState<{ active: string; items: Record<string, any[]> }[]>([
    {
      active: 'root',
      items: {
        root: [],
      },
    },
  ]);
  const [activeType, setActiveType] = useState<any>('objects');

  const toast = useToast();
  const navigate = useNavigate();
  const { handleSceneObjectAction } = useSceneViewer();
  const cloudStorage = useRef<CloudStorageManager<CloudProviderConfig['provider']>>();

  const cloudItemMapper = (item: any, type: 'container' | 'blob', container?: string) => {
    const lastModified = item.properties.lastModified;
    const createdAt = item.properties.createdOn ?? lastModified;

    return {
      id: item.name,
      name: item.name,
      type,
      container,
      lastModified: lastModified ? new Date(lastModified).toLocaleString() : undefined,
      createdAt: createdAt ? new Date(createdAt).toLocaleString() : undefined,
    };
  };

  const onLoad = async () => {
    setIsFetching(true);
    try {
      cloudStorage.current = await getCloudStorage();

      await delay(10000);
      const containers = await (cloudStorage.current?.getStorage() as any)?.getList();

      if (containers?.length) {
        const defaultContainer = getDefaultContainer();
        setStack([
          {
            active: 'root',
            items: {
              root: containers
                .filter((container: any) => container.name !== defaultContainer)
                .map((item: any) => cloudItemMapper(item, 'container')),
            },
          },
        ]);

        setItemsToImport({
          objects: new Set(),
          ui: new Set(),
          textures: new Set(),
        });

        setPage(0);
      }
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
    }
  };

  const onClick = async (container: string) => {
    setIsFetching(true);
    try {
      const blobs = await cloudStorage.current?.getAssets(container);
      if (blobs?.length) {
        setPage((s) => s + 1);
        setStack((s) => [
          s[0],
          {
            active: container,
            items: {
              ...(s.at(1)?.items ?? {}),
              [container]: blobs.map((item: any) => cloudItemMapper(item, 'blob', container)),
            },
          },
        ]);
      }
      setIsFetching(false);
    } catch (err) {
      console.error(err);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (!cloudStorage.current) {
      onLoad();
    }
  }, []);

  const handleImport = async () => {
    setIsLoading(true);

    try {
      try {
        await cloudStorage.current?.create(getDefaultContainer());
      } catch (err) {
        console.error('Unable to create container', err);
      }
      const cloudStorageClient = cloudStorage.current?.getStorage() as any;
      const itemsToCopy = [] as {
        srcKey: string;
        dstKey: string;
        file: string;
        category: string;
      }[];
      const workspace = store.getState().app.currentUser?.workspace_id;

      if (!workspace) throw new Error('workspace not found');

      stack.slice(1).forEach((page) => {
        const items = Object.values(page.items).flat();

        items.forEach((item: any) => {
          Object.keys(itemsToImport).forEach((type) => {
            if (itemsToImport[type].has(item.name)) {
              const srcKey = `${item.container}/${item.name}`;
              const file = getBlobName(item.name)!;
              const dstKey = `${getDefaultContainer()}/${uploadProjectAssetPath(type as any, workspace)}/${file}`;
              itemsToCopy.push({ srcKey, dstKey, file, category: type });
            }
          });
        });
      });

      await Promise.all(
        itemsToCopy.map((item) => cloudStorageClient.copy(item.srcKey, item.dstKey))
      );

      await handleSceneObjectAction(
        SceneObjectActionTypes.insert,
        itemsToCopy.map((item) => {
          return {
            id: null,
            type: getAssetTypeFromName(item.file),
            backendProperties: {
              project_id: null,
              scene_id: null,
              workspace_id: workspace,
              category: item.category,
              name: getNameWithoutExtension(item.file),
              metadata: {
                file: item.file,
              },
            },
          };
        })
      );

      navigate(ROUTES.projects);
      setIsLoading(false);
    } catch (er) {
      setIsLoading(false);
      toast({
        title: 'Unable to import',
        status: 'error',
      });
      console.error(er);
    }
  };

  const onSelect = (path: string) => {
    setItemsToImport((s: any) => {
      const updatedState = {} as any;
      const types = Object.keys(s);
      let wasPresent = false;

      types.forEach((type) => {
        let existingState = s[type];

        if (s[type].has(path)) {
          existingState = new Set(s[type]);
          wasPresent = true;
          existingState.delete(path);
        }

        updatedState[type] = existingState;
      });

      if (!wasPresent) {
        const existingState = new Set(s[activeType]);
        existingState.add(path);
        updatedState[activeType] = existingState;
      }

      return updatedState;
    });
  };

  const getColorScheme = (id: string) => {
    const keys = Object.keys(ASSET_CATEGORY_COLORS);
    for (let i = 0; i < keys.length; i++) {
      if (itemsToImport[keys[i]].has(id)) return ASSET_CATEGORY_COLORS[keys[i] as AssetCategories];
    }
    return ASSET_CATEGORY_COLORS['objects'];
  };

  return (
    <Flex direction="column" gap={4} overflowY="scroll">
      <Flex direction="column" gap={4} position="sticky">
        <Flex direction="column" gap={2}>
          <Flex align="center" gap={4}>
            <Heading size="md" m={0}>
              Import Assets
            </Heading>
            <IconButton
              variant="ghost"
              aria-label="reload"
              size="sm"
              onClick={onLoad}
              icon={<BiRevision />}
              isLoading={isFetching}
            />
          </Flex>
          <Text fontSize="md" color="gray.500">
            Before starting, import all necessary assets into your workspace. Allow time for the
            system to process and apply the imported settings. This may take a few moments.
          </Text>
        </Flex>
        <AssetCategory active={activeType} setActive={setActiveType} items={itemsToImport} />
      </Flex>
      <FileExplorer
        getColorScheme={getColorScheme}
        active={activeType}
        isLoading={isFetching}
        onSelect={onSelect}
        data={stack[page].items[stack[page].active]}
        onClick={onClick}
      />
      <Flex justify="space-between" align="center" mt={20}>
        <Pagination page={page} length={stack.length} onChange={setPage} />
        <Flex align="center" gap={6}>
          <Button variant="link" color="gray.600" isDisabled={isFetching}>
            skip
          </Button>
          <Button isLoading={isLoading} isDisabled={isLoading} onClick={handleImport}>
            Import
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const AssetCategory = ({
  items,
  active,
  setActive,
}: {
  items?: any;
  active: string;
  setActive: any;
}) => {
  return (
    <Flex align="center" gap={4} mb={2} width="fit-content" borderRadius="full">
      {Object.keys(ASSET_CATEGORY_COLORS).map((type) => {
        const color = ASSET_CATEGORY_COLORS[type as AssetCategories];
        return (
          <Badge
            key={type}
            py={2}
            px={5}
            cursor="pointer"
            onClick={() => setActive(type)}
            rounded="full"
            border="1px solid #333"
            variant={type === active ? 'solid' : 'link'}
            colorScheme={color}
          >
            <Center gap={2}>
              {type}
              {items?.[type]?.size > 0 && (
                <Text m={0} fontSize="xs" colorScheme="green">
                  {items[type].size}
                </Text>
              )}
            </Center>
          </Badge>
        );
      })}
    </Flex>
  );
};

export const Pagination = ({
  page,
  length,
  onChange,
}: {
  page: number;
  length: number;
  onChange: any;
}) => {
  return (
    <Stack direction="row" gap={1} align="center">
      <IconButton
        variant="ghost"
        aria-label="Previous page"
        size="sm"
        isDisabled={page === 0}
        onClick={(e) => {
          e.stopPropagation();
          onChange((s: number) => s - 1);
        }}
        icon={<FaArrowLeft />}
      />
      {Array(length)
        .fill(0)
        .map((_, i) => {
          return (
            <Button
              key={i}
              variant="ghost"
              aria-label="page"
              size="sm"
              isDisabled={i === page}
              onClick={(e) => {
                e.stopPropagation();
                onChange(i);
              }}
            >
              {i + 1}
            </Button>
          );
        })}
      <IconButton
        variant="ghost"
        aria-label="Next page"
        size="sm"
        isDisabled={page === length - 1}
        onClick={(e) => {
          e.stopPropagation();
          onChange((s: number) => s + 1);
        }}
        icon={<FaArrowRight />}
      />
    </Stack>
  );
};

export default StorageAssetTable;
