import { useState } from 'react';

import { Link } from 'react-router-dom';
import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Heading,
  Input,
  Text,
} from '@chakra-ui/react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuth } from 'src/hooks';
import { OAuthButton } from 'src/components/OAuthButton';

const LoginScheme = z.object({
  email: z.string().email({
    message: 'Must be a valid email',
  }),
  password: z.string().min(1, 'Enter password'),
});

type LoginSchemeType = z.infer<typeof LoginScheme>;

enum LoginProvider {
  OAUTH = 'oauth',
  EMAIL = 'email',
}

function Login(props: any) {
  const { login } = useAuth();
  const [isLoading, setIsLoading] = useState<LoginProvider | null>(null);
  const [hasError, setHasError] = useState<any>('');

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<LoginSchemeType>({ resolver: zodResolver(LoginScheme) });

  const handleLogin = async (formData: any) => {
    setIsLoading(LoginProvider.EMAIL);
    setHasError(null);

    const { error } = await login(formData);

    if (error) setHasError(error);
    setIsLoading(null);
  };

  return (
    <div className="App">
      <form onSubmit={handleSubmit(handleLogin)}>
        <Flex
          backgroundColor="rgba(26,32,44,255)"
          h="100vh"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Flex
            w="400px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bg="rgba(45,55,72,255)"
            p={12}
            borderRadius={8}
            position="relative"
            boxShadow="lg"
          >
            {hasError && (
              <Text position="absolute" bottom={-16} color="red.200" fontSize="sm">
                {hasError?.message}
              </Text>
            )}
            <Heading color="rgba(239,239,241,255)" mb={6}>
              Log In
            </Heading>

            <FormControl isInvalid={!!errors.email?.message}>
              <Input
                placeholder="Email"
                autoFocus
                type="email"
                variant="filled"
                mb={3}
                {...register('email')}
              />
            </FormControl>
            <FormControl isInvalid={!!errors.password?.message}>
              <Input
                placeholder="Password"
                type="password"
                variant="filled"
                {...register('password')}
                mb={6}
              />
            </FormControl>
            <Button
              type="submit"
              isLoading={isLoading === LoginProvider.EMAIL}
              isDisabled={isLoading === LoginProvider.EMAIL || !isValid}
              colorScheme="teal"
              w="full"
            >
              Log In
            </Button>

            <Box position="relative" padding={10} w="100%">
              <Divider w="full" bg="gray" />
              <AbsoluteCenter px="4" bg="rgba(45,55,72,255)">
                OR
              </AbsoluteCenter>
            </Box>

            <OAuthButton />

            <Flex>
              <Text></Text>
              <Text color="white">
                Don't have account ? Please{' '}
                <Link style={{ color: 'rgba(129,230,217,255)' }} to="/signup">
                  Sign Up
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </form>
    </div>
  );
}

export default Login;
