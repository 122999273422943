import { Quaternion, Vector3 } from '@react-three/fiber';

export enum InstanceToolType {
  comment = 'comment',
  asset = 'asset',
}

export const SupportedFiles = {
  ui: ['image/jpeg', 'image/png', 'images/jpg'],
  '3D': ['gltf', 'glb', 'fbx', 'obj'],
};

export interface IUserInfo {
  userId: string;
}

export interface IFloatingHead extends ISceneContext {
  position: Vector3;
  rotation: Quaternion;
}

export interface IFloatingControllers extends ISceneContext {
  left: {
    position: Vector3;
    rotation: Quaternion;
  };
  right: {
    position: Vector3;
    rotation: Quaternion;
  };
}

export interface IFloatingUser {
  head: IFloatingHead;
  controllers: IFloatingControllers;
  context?: ISceneContext;
}

export interface ISceneContext extends IUserInfo {
  sceneId?: string;
}
