import { Box, Button, Flex, IconButton, Image, Select } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import short from 'short-uuid';

import { updateMaterialAPI } from 'src/apis/materials';
import { UIcon } from 'src/components/icons';
import { useAssetUpload } from 'src/features/SceneViewer/hooks/useAssetUpload';
import { useAppSelector } from 'src/store/reducers/hook';
import { addMaterial } from 'src/store/reducers/sceneViewer';
import store from 'src/store/store';
import { SceneObjectActionTypes } from 'src/types';
import { getTextureURL, uploadFileToS3WithProgressToast } from 'src/utils/aws';

import { useSceneViewer } from '../hooks/useSceneViewer';
import PropertyField from './PropertyField';

enum TextureMapTypes {
  color = 'color',
  normal = 'normal',
  roughness = 'roughness',
  metalness = 'metalness',
  displacement = 'displacement',
  ao = 'ao',
}

// scene_scale is used to scale the grid to the size of the scene. (1 = cm, 0.01 = m)
const getPropertyName = (maptype: string) => {
  let propertyname = '';
  switch (maptype) {
    case TextureMapTypes.color:
      propertyname = 'map';
      break;
    case TextureMapTypes.normal:
      propertyname = 'nmap';
      break;
    case TextureMapTypes.roughness:
      propertyname = 'rmap';
      break;
    case TextureMapTypes.metalness:
      propertyname = 'metmap';
      break;
    case TextureMapTypes.displacement:
      propertyname = 'dmap';
      break;
    case TextureMapTypes.ao:
      propertyname = 'aomap';
      break;

    default:
      break;
  }
  return propertyname;
};
export default function MaterialMap(props: {
  currentMaterial: { id: string; type: 'materials' | 'entities'; assets: string[] };
  show: boolean;
  setEditMap: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      type: string;
    }>
  >;
  editMap: {
    show: boolean;
    type: string;
  };
}) {
  const { handleSceneObjectAction } = useSceneViewer();
  const { getAssetPath } = useAssetUpload();
  const materialObj = useAppSelector(
    (store) => store.sceneViewer[props.currentMaterial?.type]?.[props.currentMaterial?.id]
  );
  let propertyname = getPropertyName(props.editMap.type);

  const handleTextureChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      var file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async function (event) {
        const uuid = short.generate();

        const { key, path } = getAssetPath(file.name, {
          category: 'textures',
          level: 'project',
          uuid,
        });

        try {
          let toastId = toast(`Uploading Texture`, {
            position: 'bottom-center',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });

          uploadFileToS3WithProgressToast(file, path, {
            toastId: toastId,
            showToast: true,
            parentname: materialObj.name ?? materialObj.backendProperties.name,
            childname: file.name,
          });

          if (props.currentMaterial?.type === 'materials') {
            const material_base = {
              material_base: {
                ...materialObj.material_base,
                map: {
                  ...materialObj.material_base.map,
                  [propertyname]: {
                    ...materialObj.material_base.map[propertyname],
                    src: key,
                  },
                },
              },
            };

            updateMaterialAPI(materialObj.id, material_base);
            store.dispatch(
              addMaterial({
                ...materialObj,
                ...material_base,
              })
            );
          } else {
            handleSceneObjectAction(SceneObjectActionTypes.update, [
              {
                id: materialObj.id,
                type: materialObj.type,
                localProperties: {
                  ...materialObj.localProperties,
                  updateTexture: true,
                  material_base: {
                    ...materialObj.localProperties.material_base,
                    map: {
                      ...materialObj.localProperties.material_base?.map,
                      [`${propertyname}`]: {
                        ...materialObj.localProperties.material_base?.map[`${propertyname}`],
                        src: URL.createObjectURL(file),
                      },
                    },
                  },
                },
                backendProperties: {
                  ...materialObj.backendProperties,
                  metadata: {
                    ...materialObj.backendProperties.metadata,
                    material_base: {
                      ...materialObj.backendProperties.metadata.material_base,
                      map: {
                        ...materialObj.backendProperties.metadata.material_base?.map,
                        [`${propertyname}`]: {
                          ...materialObj.localProperties.material_base?.map[`${propertyname}`],
                          src: key,
                        },
                      },
                    },
                  },
                },
              },
            ]);
          }
        } catch (err) {
          console.error('Error in file upload or database update:', err);
        }
      };
      reader.readAsDataURL(file);
      e.target.value = '';
    }
  };
  const handleTilingChange = (e: React.ChangeEvent<HTMLInputElement>, action: string) => {
    let newTiling: [number, number] = [0, 0];

    if (action === 'x') {
      if (props.currentMaterial?.type === 'materials') {
        newTiling = [
          parseFloat(e.target.value),
          materialObj.material_base.map[propertyname].tiling[1],
        ];
      } else {
        newTiling = [
          parseFloat(e.target.value),
          materialObj.localProperties.material_base?.map[`${propertyname}`].tiling[1],
        ];
      }
    } else {
      if (props.currentMaterial?.type === 'materials') {
        newTiling = [
          materialObj.material_base.map[propertyname].tiling[0],
          parseFloat(e.target.value),
        ];
      } else {
        newTiling = [
          materialObj.localProperties.material_base?.map[`${propertyname}`].tiling[0],
          parseFloat(e.target.value),
        ];
      }
    }
    if (props.currentMaterial?.type === 'materials') {
      const material_base = {
        material_base: {
          ...materialObj.material_base,
          map: {
            ...materialObj.material_base.map,
            [propertyname]: {
              ...materialObj.material_base.map[propertyname],
              tiling: newTiling,
            },
          },
        },
      };

      updateMaterialAPI(materialObj.id, material_base);
      store.dispatch(
        addMaterial({
          ...materialObj,
          ...material_base,
        })
      );
    } else {
      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: materialObj.id,
          type: materialObj.type,
          localProperties: {
            ...materialObj.localProperties,
            updateTexture: true,
            material_base: {
              ...materialObj.localProperties.material_base,
              map: {
                ...materialObj.localProperties.material_base?.map,
                [`${propertyname}`]: {
                  ...materialObj.localProperties.material_base?.map[`${propertyname}`],
                  tiling: newTiling,
                },
              },
            },
          },
          backendProperties: {
            ...materialObj.backendProperties,
            metadata: {
              ...materialObj.backendProperties.metadata,
              material_base: {
                ...materialObj.backendProperties.metadata.material_base,
                map: {
                  ...materialObj.backendProperties.metadata.material_base?.map,
                  [`${propertyname}`]: {
                    ...materialObj.localProperties.material_base?.map[`${propertyname}`],
                    tiling: newTiling,
                  },
                },
              },
            },
          },
        },
      ]);
    }
  };
  const handleOffsetChange = (e: React.ChangeEvent<HTMLInputElement>, action: string) => {
    let newOffset: [number, number] = [0, 0];

    if (action === 'x') {
      if (props.currentMaterial?.type === 'materials') {
        newOffset = [
          parseFloat(e.target.value),
          materialObj.material_base.map[propertyname].offset[1],
        ];
      } else {
        newOffset = [
          parseFloat(e.target.value),
          materialObj.localProperties.material_base?.map[`${propertyname}`].offset[1],
        ];
      }
    } else {
      if (props.currentMaterial?.type === 'materials') {
        newOffset = [
          materialObj.material_base.map[propertyname].offset[0],
          parseFloat(e.target.value),
        ];
      } else {
        newOffset = [
          materialObj.localProperties.material_base?.map[`${propertyname}`].offset[0],
          parseFloat(e.target.value),
        ];
      }
    }
    if (props.currentMaterial?.type === 'materials') {
      const material_base = {
        material_base: {
          ...materialObj.material_base,
          map: {
            ...materialObj.material_base.map,
            [propertyname]: {
              ...materialObj.material_base.map[propertyname],
              offset: newOffset,
            },
          },
        },
      };

      updateMaterialAPI(materialObj.id, material_base);
      store.dispatch(
        addMaterial({
          ...materialObj,
          ...material_base,
        })
      );
    } else {
      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: materialObj.id,
          type: materialObj.type,
          localProperties: {
            ...materialObj.localProperties,
            updateTexture: true,
            material_base: {
              ...materialObj.localProperties.material_base,
              map: {
                ...materialObj.localProperties.material_base?.map,
                [`${propertyname}`]: {
                  ...materialObj.localProperties.material_base?.map[`${propertyname}`],
                  offset: newOffset,
                },
              },
            },
          },
          backendProperties: {
            ...materialObj.backendProperties,
            metadata: {
              ...materialObj.backendProperties.metadata,
              material_base: {
                ...materialObj.backendProperties.metadata.material_base,
                map: {
                  ...materialObj.backendProperties.metadata.material_base?.map,
                  [`${propertyname}`]: {
                    ...materialObj.localProperties.material_base?.map[`${propertyname}`],
                    offset: newOffset,
                  },
                },
              },
            },
          },
        },
      ]);
    }
  };
  const handleRotationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newRotation = parseFloat(e.target.value) * (Math.PI / 180);

    if (props.currentMaterial?.type === 'materials') {
      const material_base = {
        material_base: {
          ...materialObj.material_base,
          map: {
            ...materialObj.material_base.map,
            [propertyname]: {
              ...materialObj.material_base.map[propertyname],
              rotation: newRotation,
            },
          },
        },
      };

      updateMaterialAPI(materialObj.id, material_base);
      store.dispatch(
        addMaterial({
          ...materialObj,
          ...material_base,
        })
      );
    } else {
      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: materialObj.id,
          type: materialObj.type,
          localProperties: {
            ...materialObj.localProperties,
            updateTexture: true,
            material_base: {
              ...materialObj.localProperties.material_base,
              map: {
                ...materialObj.localProperties.material_base?.map,
                [`${propertyname}`]: {
                  ...materialObj.localProperties.material_base?.map[`${propertyname}`],
                  rotation: newRotation,
                },
              },
            },
          },
          backendProperties: {
            ...materialObj.backendProperties,
            metadata: {
              ...materialObj.backendProperties.metadata,
              material_base: {
                ...materialObj.backendProperties.metadata.material_base,
                map: {
                  ...materialObj.backendProperties.metadata.material_base?.map,
                  [`${propertyname}`]: {
                    ...materialObj.localProperties.material_base?.map[`${propertyname}`],
                    rotation: newRotation,
                  },
                },
              },
            },
          },
        },
      ]);
    }
  };
  const handleTextureDelete = () => {
    if (props.currentMaterial?.type === 'materials') {
      const material_base = {
        material_base: {
          ...materialObj.material_base,
          map: {
            ...materialObj.material_base.map,
            [propertyname]: {
              ...materialObj.material_base.map[propertyname],
              src: null,
            },
          },
        },
      };

      updateMaterialAPI(materialObj.id, material_base);
      store.dispatch(
        addMaterial({
          ...materialObj,
          ...material_base,
        })
      );
    } else {
      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: materialObj.id,
          type: materialObj.type,
          localProperties: {
            ...materialObj.localProperties,
            updateTexture: true,
            material_base: {
              ...materialObj.localProperties.material_base,
              map: {
                ...materialObj.localProperties.material_base?.map,
                [`${propertyname}`]: {
                  ...materialObj.localProperties.material_base?.map[`${propertyname}`],
                  src: null,
                },
              },
            },
          },
          backendProperties: {
            ...materialObj.backendProperties,
            metadata: {
              ...materialObj.backendProperties.metadata,
              material_base: {
                ...materialObj.backendProperties.metadata.material_base,
                map: {
                  ...materialObj.backendProperties.metadata.material_base?.map,
                  [`${propertyname}`]: {
                    ...materialObj.localProperties.material_base?.map[`${propertyname}`],
                    src: null,
                  },
                },
              },
            },
          },
        },
      ]);
    }
    props.setEditMap({
      show: false,
      type: '',
    });
  };
  const handleDownload = async (fileName: string, fileSource: string) => {
    try {
      // Fetch the file from the source URL
      const response = await fetch(fileSource);
      const blob = await response.blob();

      // Create an object URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;

      document.body.appendChild(link); // Required for Firefox
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };
  useEffect(() => {
    const material = materialObj?.material_base ?? materialObj?.localProperties?.material_base;
    if (material) {
    }
  }, [
    props.currentMaterial?.id,
    materialObj?.backendProperties?.animation?.currentState,
    props.editMap.type,
  ]);

  const inputRef = useRef<HTMLInputElement>(null);
  const material = materialObj.material_base ?? materialObj.localProperties.material_base;
  const textureURL = useMemo(() => {
    if (!material?.map[`${propertyname}`].src) return '';
    return getTextureURL(material?.map[`${propertyname}`].src, materialObj.tag);
  }, [material?.map[`${propertyname}`].src]);
  if (!materialObj) return <></>;
  return (
    <div>
      <>
        <Box height="fit-content" overflowY="auto">
          <Flex
            alignItems={'center'}
            px={'10px'}
            mb={'7px'}
            mt={'2px'}
            justifyContent={'space-between'}
          >
            <Select w={'40%'} variant="unstyled" size="xs">
              <option value="Image">Image</option>
            </Select>
            <IconButton
              aria-label="close"
              size="xs"
              variant="ghost"
              icon={<UIcon name="close" fontSize={12} />}
              onClick={() =>
                props.setEditMap({
                  show: false,
                  type: '',
                })
              }
            />
          </Flex>

          <Box height={'100%'}>
            <Box
              position="relative"
              width="200px"
              minHeight="190px"
              sx={{
                '&:hover .second-child': {
                  visibility: 'visible',
                },
              }}
            >
              <Image
                borderRadius="0.25rem"
                src={textureURL}
                position="absolute"
                top="0"
                left="0"
                width="190px"
                alt="e"
                onError={(e) => {
                  e.currentTarget.src = 'unnamed.webp';
                }}
                crossOrigin=""
              />

              <Box
                position="absolute"
                top="0px"
                left="0px"
                className="second-child"
                borderRadius="0.25rem"
                backgroundColor={' rgba(18, 17, 23, 0.8) '}
                width="190px"
                height="100%"
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                visibility={'hidden'}
                alignItems={'center'}
              >
                <Flex
                  height="20%"
                  width="100%"
                  justifyContent={'end'}
                  px={2}
                  gap={2}
                  alignItems={'center'}
                >
                  <IconButton
                    aria-label="close"
                    size="xs"
                    variant="solid"
                    onClick={() =>
                      handleDownload(material.name + '-' + propertyname + '.png', textureURL)
                    }
                    icon={<UIcon name="download" fontSize={15} />}
                  />
                  <IconButton
                    aria-label="close"
                    size="xs"
                    variant="solid"
                    onClick={handleTextureDelete}
                    icon={<UIcon name="delete" fontSize={15} />}
                  />
                </Flex>
                <Flex height="80%" width="100%" justifyContent={'center'} alignItems={'center'}>
                  <Button
                    onClick={() => {
                      inputRef.current?.click();
                    }}
                    size="sm"
                    mb={'20%'}
                    variant="solid"
                  >
                    Replace
                  </Button>
                </Flex>
              </Box>
            </Box>

            <input
              ref={inputRef}
              type="file"
              id={'Color'}
              onChange={(e) => handleTextureChange(e)}
              hidden
            />
          </Box>
          <Flex justifyContent={'start'} width={'100%'} gap={4} alignItems={'center'}>
            <label className="label"> Tiling</label>
            <PropertyField
              value={material?.map[`${propertyname}`].tiling[0]}
              instanceIndex={-1}
              width={'140%'}
              propIndex={0}
              field={'x'}
              handlePropertyChange={handleTilingChange}
              type="number"
            />
            <PropertyField
              value={material?.map[`${propertyname}`].tiling[1]}
              instanceIndex={0}
              width={'140%'}
              propIndex={1}
              field={'y'}
              handlePropertyChange={handleTilingChange}
              type="number"
            />
          </Flex>
          <Flex justifyContent={'start'} width={'100%'} gap={2} alignItems={'center'}>
            <label style={{ marginRight: '20px' }} className="label">
              Offset
            </label>

            <PropertyField
              value={material?.map[`${propertyname}`].offset[0]}
              instanceIndex={-1}
              width={'140%'}
              propIndex={0}
              field={'x'}
              handlePropertyChange={handleOffsetChange}
              type="number"
            />
            <div></div>
            <PropertyField
              value={material?.map[`${propertyname}`].offset[1]}
              instanceIndex={0}
              width={'140%'}
              propIndex={1}
              field={'y'}
              handlePropertyChange={handleOffsetChange}
              type="number"
            />
          </Flex>
          <Flex justifyContent={'start'} width={'100%'} alignItems={'center'}>
            <label className="label"> Rotation</label>
            <PropertyField
              value={material?.map[`${propertyname}`].rotation * (180 / Math.PI)}
              instanceIndex={-1}
              width={'140%'}
              propIndex={0}
              field={'roation'}
              handlePropertyChange={handleRotationChange}
              type="number"
            />
          </Flex>
        </Box>
      </>
    </div>
  );
}
