import { BlobServiceClient } from '@azure/storage-blob';
import { getContainerAndPath } from 'src/utils/cloud';
import type { AzureConfig, CloudStorage } from './storage.types';

export class AzureStorage implements CloudStorage {
  private blobServiceClient: BlobServiceClient;
  private container: string = 'unproject-data';

  constructor(config: AzureConfig) {
    this.blobServiceClient = new BlobServiceClient(config.sasURI);
  }

  async getList(type: 'container' | 'blob' = 'container') {
    const data = [] as any[];

    for await (let container of this.blobServiceClient.listContainers()) {
      data.push(container);
    }

    return data;
  }

  async getAssets(container: string, prefix?: string) {
    const data = [] as any[];

    for await (let blob of this.blobServiceClient
      .getContainerClient(container)
      .listBlobsFlat({ prefix })) {
      data.push(blob);
    }

    return data;
  }

  async upload(file: File, key: string): Promise<void> {
    const containerClient = this.blobServiceClient.getContainerClient(this.container);
    const blockBlobClient = containerClient.getBlockBlobClient(key);

    const uploadBlobResponse = await blockBlobClient.upload(file, file.size);
    console.log(`Upload block blob ${key} successfully`, uploadBlobResponse.requestId);
  }

  async create(container: string): Promise<void> {
    await this.blobServiceClient.createContainer(container);
  }

  async copy(srcKey: string, dstKey: string): Promise<void> {
    const { container: sourceContainer, path: srcBlobPath } = getContainerAndPath(srcKey);
    const { container: destContainer, path: dstBlobPath } = getContainerAndPath(dstKey);

    if (!dstBlobPath || !srcBlobPath) return;

    const sourceContainerClient = this.blobServiceClient.getContainerClient(sourceContainer);
    const destContainerClient = this.blobServiceClient.getContainerClient(destContainer);

    const sourceBlobClient = sourceContainerClient.getBlobClient(srcBlobPath);

    const destBlobClient = destContainerClient.getBlobClient(dstBlobPath);
    const copyPoller = await destBlobClient.beginCopyFromURL(sourceBlobClient.url);

    await copyPoller.pollUntilDone();
  }

  async copyFromPublicURL(srcKey: string, dstKey: string) {
    const blobServiceClient = this.blobServiceClient;
    const { container, path } = getContainerAndPath(dstKey);

    if (!path) return;

    const containerClient = blobServiceClient.getContainerClient(container);
    const blockBlobClient = containerClient.getBlockBlobClient(path);

    try {
      const copyPoller = await blockBlobClient.beginCopyFromURL(srcKey);

      await copyPoller.pollUntilDone();
    } catch (err: any) {
      console.error('Error copying file:', err);
    }
  }

  async get(key: string): Promise<any> {
    const containerClient = this.blobServiceClient.getContainerClient(this.container);
    const blockBlobClient = containerClient.getBlockBlobClient(key);

    const downloadBlockBlobResponse = await blockBlobClient.download(0);
    const downloaded = await downloadBlockBlobResponse.blobBody;

    return downloaded;
  }

  getSignedUrl(key: string): string {
    const containerClient = this.blobServiceClient.getContainerClient(this.container);
    const blockBlobClient = containerClient.getBlockBlobClient(key);

    return blockBlobClient.url;
  }

  async delete(key: string): Promise<void> {
    const containerClient = this.blobServiceClient.getContainerClient(this.container);
    const blockBlobClient = containerClient.getBlockBlobClient(key);

    await blockBlobClient.delete();
  }
}
