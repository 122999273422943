import { supabase } from 'src/SupabaseClient';
import { Enums, RoleType } from 'src/types';
import { TABLES } from 'src/utils/constants';
import { getCurrentUser } from 'src/utils/requests';

export const getUser = async (userId: string) => {
  const { data: user, error } = await supabase
    .from(TABLES.users)
    .select('*')
    .eq('id', userId)
    .single();
  if (error) throw error;
  return user;
};

export const updateUser = async (updates: any) => {
  return await supabase.from(TABLES.users).update(updates).eq('id', updates.id);
};

export const getMembers = async (uid: string, type: Enums<'user_invite_type'>) => {
  const { data, error } = await supabase.rpc('get_members', { uid, type });

  if (data) {
    return {
      data: data.map(getCurrentUser),
    };
  }

  return {
    error,
  };
};

export const inviteUser = async (data: any) => {
  const res = await supabase.rpc('invite_user', data);
  return res;
};

export const updateInvitee = async (projectId: string, id: string, role: RoleType) => {
  const res = await supabase
    .from(TABLES.invites)
    .update({ role })
    .eq('email', id)
    .eq('project_id', projectId);
};
