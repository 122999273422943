import { AddIcon, ArrowBackIcon, ChatIcon } from '@chakra-ui/icons';

import './TopPanel.css';

import {
  Button,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { BsCameraVideo, BsColumns } from 'react-icons/bs';
import { CiImageOn } from 'react-icons/ci';
import { FaLocationArrow } from 'react-icons/fa';
import { FaFigma } from 'react-icons/fa6';
import { GrCube } from 'react-icons/gr';
import { HiSparkles } from 'react-icons/hi2';
import short from 'short-uuid';
import { useNavigate } from 'react-router-dom';
import { useAssetUpload } from 'src/features/SceneViewer/hooks/useAssetUpload';
import { DirectionalLightObjectMetadataDefaults, InstanceToolType, LightObjectMetadata, LightObjectMetadataDefaults, ModalType, PointLightObjectMetadataDefaults, SceneObjectActionTypes, SpotLightObjectMetadataDefaults, SupportedLightTypes, SupportedSceneObjectTypes, ViewportObject } from 'src/types';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { setActiveTool } from 'src/store/reducers/InstanceReducer';
import { openModal } from 'src/store/reducers/modals';
import store from 'src/store/store';

import { ROUTES } from 'src/utils/constants';
import { generateNewViewportLabel } from 'src/utils/helper';

import AssetDrawer from '../AssetDrawer';
import { useSceneViewer } from './hooks/useSceneViewer';
import PlayIcon from './PlayIcon';

export default function TopPanel() {
  const activeTooltype = useAppSelector((store) => store.instance.activeTool);

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { uploadAssets } = useAssetUpload();
  const { onCreateViewport, handleSceneObjectAction } = useSceneViewer();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inputImageRef = useRef<any>(null);
  const input3DAssetRef = useRef<any>(null);

  const goBack = () => {
    navigate(-1);
  };

  const addViewport = () => {
    const scene_id = store.getState().instance.current_sceneId;

    const viewportObjectList = Object.values(store.getState().sceneViewer.entities).filter(
      (item) => item.type === SupportedSceneObjectTypes.viewport
    ) as ViewportObject[];

    const label = generateNewViewportLabel(viewportObjectList);

    const newViewport = {
      scene_id: scene_id,
      position: [0.0, 1.7, 3.0],
      name: label,
      animation: {
        currentState: 0,
        states: [
          {
            name: 'base',
            position: [0.0, 1.7, 3.0],
            rotation: [0, 0, 0],
            scale: [1, 1, 1],
          },
        ],
        events: [
          {
            name: 'start',
            id: short.generate(),
            trigger: 'click',
            target: 'On this object',
            object: 'this object',
            from: 'base',
            to: 'base',
          },
        ],
        externalEvents: [],
      },
      metadata: {
        label,
      },
    };

    onCreateViewport(newViewport);
  };

  /**
   * Handler on clicking "Add 3D model"
   */
  const handleAssetChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      uploadAssets(e.target.files, {
        upload: {
          level: 'project',
        },
      });
      e.target.value = '';
      onClose();
    }
  };

  const handleClickImage = () => {
    // open file input box on click of another element
    inputImageRef.current.click();
  };

  const handleClick3DAsset = () => {
    // open file input box on click of another element
    input3DAssetRef.current.click();
  };

  const handleFigmaImport = () => {
    dispatch(
      openModal({
        type: ModalType.figmaImport,
      })
    );
  };

  const addLight = (type: SupportedLightTypes) => {
    const scene_id = store.getState().instance.current_sceneId;
    const project_id = store.getState().app.projectId;

    let name = 'point'
    let metadata = {...PointLightObjectMetadataDefaults} as LightObjectMetadata;
    if (type === SupportedLightTypes.directional) {
      name = 'directional'
      metadata = {...DirectionalLightObjectMetadataDefaults}
    } else if (type === SupportedLightTypes.spot) {
      name = 'spot'
      metadata = {...SpotLightObjectMetadataDefaults}
    }
    name = name + '-light'

    handleSceneObjectAction(SceneObjectActionTypes.insert, [
      {
        id: null,
        type: SupportedSceneObjectTypes.light,
        localProperties: {},
        backendProperties: {
          project_id: project_id,
          scene_id: scene_id,
          name: name,
          metadata: {...metadata}
        }
      }
    ])
  }

  const onSelectClick = () => {
    dispatch(setActiveTool(undefined));
  };

  const onCommentsClick = () => {
    dispatch(setActiveTool(InstanceToolType.comment));
  };

  const onAICompanionClick = () => {
    dispatch(
      openModal({
        type: ModalType.sceneAI,
        data: {},
      })
    );
  };

  return (
    <div className="topPanel">
      <div className="container">
        <Tooltip bg="grey" label="Back to storyboard">
          <Button onClick={goBack} className="btn">
            <Icon color="white">
              <ArrowBackIcon />
            </Icon>
          </Button>
        </Tooltip>
        <Menu>
          <Tooltip bg="grey" label="Add">
            <MenuButton
              as={IconButton}
              icon={<AddIcon />}
              className="btn"
              p={2}
              color="white"
              transition="all 0.2s"
            />
          </Tooltip>
          <MenuList mt={2} px={2} py={2}>
            <MenuItem icon={<BsCameraVideo size={16} />} className="menuItem" onClick={addViewport}>
              <span>Add Viewport</span>
            </MenuItem>
            <MenuItem
              icon={<CiImageOn size={16} />}
              onClick={handleClickImage}
              className="menuItem"
            >
              <input
                style={{ display: 'none' }}
                ref={inputImageRef}
                type="file"
                multiple
                onChange={handleAssetChange}
                accept=".jpg,.jpeg,.png"
              />
              <span>Upload Image</span>
            </MenuItem>
            <MenuItem icon={<GrCube size={16} />} onClick={handleClick3DAsset} className="menuItem">
              <input
                style={{ display: 'none' }}
                ref={input3DAssetRef}
                type="file"
                multiple
                onChange={handleAssetChange} // make seperate functions for this
                accept="*"
              />
              <span>Upload 3D Model</span>
            </MenuItem>
            <MenuItem icon={<FaFigma size={16} />} onClick={handleFigmaImport} className="menuItem">
              <span>Import from Figma</span>
            </MenuItem>
            {/* <MenuItem icon={<FaFigma size={16} />} onClick={addLight} className="menuItem"> */}
            <Menu>
              <Tooltip bg="grey" label="Add Light">
                <MenuButton
                  className="menuItem"
                  p={2}
                  transition="all 0.2s"
                >
                  <span>Add Light</span>
                </MenuButton>
              </Tooltip>
              <MenuList mt={2} px={2} py={2}>
                <MenuItem onClick={() => {addLight(SupportedLightTypes.point)}} className="menuItem">
                  <span>Point Light</span>
                </MenuItem>
                <MenuItem onClick={() => {addLight(SupportedLightTypes.directional)}} className="menuItem">
                  <span>Directional Light</span>
                </MenuItem>
                <MenuItem onClick={() => {addLight(SupportedLightTypes.spot)}} className="menuItem">
                  <span>Spot Light</span>
                </MenuItem>
              </MenuList>
            </Menu>
            {/* </MenuItem> */}
          </MenuList>
        </Menu>
        <Tooltip bg="grey" label="Asset library">
          <Button onClick={onOpen} className="btn">
            <Icon as={BsColumns} color="white"></Icon>
          </Button>
        </Tooltip>
        <AssetDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        <Tooltip bg="grey" label="comments (c)">
          <Button
            className={`btn btn-normal ${
              activeTooltype === InstanceToolType.comment ? 'btn-active' : ''
            }`}
            onClick={onCommentsClick}
          >
            <Icon color="white">
              <ChatIcon />
            </Icon>
          </Button>
        </Tooltip>
        <Tooltip bg="grey" label="select (v)">
          <Button
            className="btn btn_pointer"
            onClick={onSelectClick}
            style={{ transform: 'scaleX(-1)' }}
          >
            <FaLocationArrow color="white" />
          </Button>
        </Tooltip>
        <Tooltip bg="grey" label="Text to 3D">
          <Button className="btn btn-normal" width="auto" m={0} onClick={onAICompanionClick}>
            <HiSparkles size={18} color={theme.colors.yellow[200]} />
          </Button>
        </Tooltip>
        <Tooltip bg="grey" label="Play">
          <Button
            className="btn btn-normal"
            width="auto"
            m={0}
            onClick={() => {
              navigate(ROUTES.preview);
            }}
          >
            <PlayIcon />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
