import { Navigate, Route, Routes } from 'react-router-dom';

import { Login, SignUp } from './Pages';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';

import { ToastContainer } from 'react-toastify';

import RightClickMenu from './components/rightClickMenu/RightClickMenu';
import LoadingScreen from './components/sceneViewer/LoadingScreen';
import { useInit } from './hooks';
import { Authenticated, ProtectedRoute, Unauthenticated } from './layouts/Page';
import AuthCallback from './Pages/Auth';
import CreateWorkspace from './Pages/CreateWorkspace';
import Editor from './Pages/Editor';
import Home from './Pages/Home';
import Integrations from './Pages/Integrations';
import Previewer from './Pages/Previewer';
import Projects from './Pages/Projects';
import { setIsInitializing } from './store/reducers/app';
import { useAppDispatch, useAppSelector } from './store/reducers/hook';
import { ROUTES } from './utils/constants';

const InitLoading = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((store) => store.app.isInitializing);

  return (
    <LoadingScreen
      isLoading={isLoading}
      onClose={() => {
        dispatch(setIsInitializing(false));
      }}
    />
  );
};

const App = () => {
  useInit();

  return (
    <div className="App" onContextMenu={(e) => e.preventDefault()}>
      <RightClickMenu />
      <ToastContainer stacked position="bottom-center" toastStyle={{ bottom: '2rem' }} />
      <InitLoading />
      <Routes>
        <Route path="/" element={<Unauthenticated />}>
          <Route path={ROUTES.register} element={<SignUp />} />
          <Route path={ROUTES.login} element={<Login />} />
          <Route path={ROUTES.auth} element={<AuthCallback />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path={ROUTES.create_workspace} element={<CreateWorkspace />} />
        </Route>
        <Route element={<Authenticated />}>
          <Route path={ROUTES.projects} element={<Projects />} />
          <Route path={ROUTES.editor} element={<Editor />} />
          <Route path={ROUTES.preview} element={<Previewer />} />
          <Route path={ROUTES.home} element={<Home />} />
          <Route path={ROUTES.integrations} element={<Integrations />} />
        </Route>
        <Route path="*" element={<Navigate to={ROUTES.login} />} />
      </Routes>
    </div>
  );
};

export default App;
