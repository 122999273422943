import { useThree } from '@react-three/fiber';
import { gsap } from 'gsap';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as THREE from 'three';

import { useAppSelector } from 'src/store/reducers/hook';
import store from 'src/store/store';
import {
  AssetObject,
  GroupObject,
  ModeTypes,
  SceneEvent,
  SceneEventTriggerTargets,
  SceneObjectActionTypes,
  SupportedSceneObjectTypes,
  ViewportObject,
} from 'src/types';
import { loadTextureAsync } from 'src/utils/three';

import { useSceneViewer } from './useSceneViewer';

type Manipulation = {
  config: AssetObject | GroupObject | ViewportObject;
  ref: React.RefObject<THREE.Group<THREE.Object3DEventMap> | THREE.Mesh>;
  actions: {
    [x: string]: THREE.AnimationAction | null;
  };
  names: string[];
  scene: THREE.Group<THREE.Object3DEventMap>;
  Mode: ModeTypes;
};

export default function useAssetManipulation({
  config,
  ref,
  actions,
  names,
  scene,
  Mode,
}: Manipulation) {
  const { handleSceneObjectAction } = useSceneViewer();

  const sceneObjectList = useAppSelector((store) => store.sceneViewer.entities);
  const { camera } = useThree();
  const material = useAppSelector(
    (store) => store.sceneViewer.materials[config.backendProperties.material]
  );
  const updateTextures = async (mat: THREE.MeshStandardMaterial) => {
    const materialConfig = material?.material_base ?? config.localProperties.material_base;

    const name = material?.name ?? config.backendProperties.name;

    if (!materialConfig) return;

    toast(`Applying texture ${name}`, {
      toastId: 12,
      position: 'bottom-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    try {
      let colormap =
        materialConfig?.map.map.src != null
          ? await loadTextureAsync(
              materialConfig?.map.map.src as string,
              material?.tag,
              materialConfig?.map.map.tiling,
              materialConfig?.map.map.offset,
              materialConfig?.map.map.rotation
            )
          : null;
      let normalmap =
        materialConfig?.map.nmap.src != null
          ? await loadTextureAsync(
              materialConfig?.map.nmap.src as string,
              material?.tag,
              materialConfig?.map.nmap.tiling,
              materialConfig?.map.nmap.offset,
              materialConfig?.map.nmap.rotation
            )
          : null;
      let roughnessmap =
        materialConfig?.map.rmap.src != null
          ? await loadTextureAsync(
              materialConfig?.map.rmap.src as string,
              material?.tag,
              materialConfig?.map.rmap.tiling,
              materialConfig?.map.rmap.offset,
              materialConfig?.map.rmap.rotation
            )
          : null;
      let metalnessmap =
        materialConfig?.map.metmap.src != null
          ? await loadTextureAsync(
              materialConfig?.map.metmap.src as string,
              material?.tag,
              materialConfig?.map.metmap.tiling,
              materialConfig?.map.metmap.offset,
              materialConfig?.map.metmap.rotation
            )
          : null;
      let displacementmap =
        materialConfig?.map.dmap.src != null
          ? await loadTextureAsync(
              materialConfig?.map.dmap.src as string,
              material?.tag,
              materialConfig?.map.dmap.tiling,
              materialConfig?.map.dmap.offset,
              materialConfig?.map.dmap.rotation
            )
          : null;
      let aoMap =
        materialConfig?.map.aomap.src != null
          ? await loadTextureAsync(
              materialConfig?.map.aomap.src as string,
              material?.tag,
              materialConfig?.map.aomap.tiling,
              materialConfig?.map.aomap.offset,
              materialConfig?.map.aomap.rotation
            )
          : null;

      mat.setValues({
        map: colormap as THREE.Texture | null,
        normalMap: normalmap as THREE.Texture | null,
        roughnessMap: roughnessmap as THREE.Texture | null,
        metalnessMap: metalnessmap as THREE.Texture | null,
        displacementMap: displacementmap as THREE.Texture | null,
        aoMap: aoMap as THREE.Texture | null,
      });
      mat.needsUpdate = true;
    } catch (e) {
      console.log(e);
    }

    const updatedLocalProperties = {
      ...config.localProperties,
      updateTexture: false,
    };

    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: config.id,
        type: config.type,
        localProperties: updatedLocalProperties,
        backendProperties: config.backendProperties,
      },
    ]);
  };
  const playTimeline = (currentEvent: SceneEvent) => {
    const Animationtimeline = gsap.timeline({
      onComplete: () => {
        let finalStateIndex = config.backendProperties.animation.states.findIndex(
          (state) =>
            state.name ===
            currentEvent.sequence.timeline[currentEvent.sequence.timeline.length - 1].name
        );

        handleSceneObjectAction(SceneObjectActionTypes.update, [
          {
            id: config.id,
            type: config.type,
            localProperties: {
              ...config.localProperties,
              currentState: finalStateIndex,
            },
            backendProperties: config.backendProperties,
          },
        ]);
      },
    });
    let startStateIndex = config.backendProperties.animation.states.findIndex(
      (state) => state.name === currentEvent.sequence.timeline[0].name
    );

    if (startStateIndex === config.localProperties.currentState) {
      currentEvent.sequence.timeline.forEach((timeline, index) => {
        let state = config.backendProperties.animation.states.find(
          (state) => state.name === timeline.name
        );
        if (state && ref.current && index > 0) {
          Animationtimeline.to(ref.current?.position, {
            x: state.position[0],
            y: state.position[1],
            z: state.position[2],
            duration: timeline.duration,
            delay: timeline.delay,
          });
          Animationtimeline.to(
            ref.current?.rotation,
            {
              x: state.rotation[0],
              y: state.rotation[1],
              z: state.rotation[2],
              duration: timeline.duration,
              delay: timeline.delay,
            },
            `-=${timeline.duration + timeline.delay}`
          );
          Animationtimeline.to(
            ref.current?.scale,
            {
              x: state.scale[0],
              y: state.scale[1],
              z: state.scale[2],
              duration: timeline.duration,
              delay: timeline.delay,
            },
            `-=${timeline.duration + timeline.delay}`
          );
          if (
            config.type === SupportedSceneObjectTypes.viewport &&
            config.localProperties.isPrimary
          ) {
            Animationtimeline.to(
              camera.position,
              {
                x: state.position[0],
                y: state.position[1],
                z: state.position[2],
                duration: timeline.duration,
                delay: timeline.delay,
              },
              `-=${timeline.duration + timeline.delay}`
            );
            Animationtimeline.to(
              camera.rotation,
              {
                x: state.rotation[0],
                y: state.rotation[1],
                z: state.rotation[2],
                duration: timeline.duration,
                delay: timeline.delay,
              },
              `-=${timeline.duration + timeline.delay}`
            );
          }

          if (config.type === SupportedSceneObjectTypes.asset) {
            ref.current?.traverse((o: any) => {
              if (o.isMesh && state && o.name === config.backendProperties.name) {
                console.log(o.name, state.material_base.opacity);
                Animationtimeline.to(
                  o.material,
                  {
                    opacity: state.material_base.opacity,
                    roughness: state.material_base.roughness,
                    metalness: state.material_base.metalness,
                    displacementScale: state.material_base.displacementScale,
                    aoMapIntensity: state.material_base.aoMapIntensity,

                    repeat: 0,
                    duration: timeline.duration,
                    delay: timeline.delay,
                  },
                  `-=${timeline.duration + timeline.delay}`
                );
                Animationtimeline.to(
                  o.material.normalScale,
                  {
                    x: state.material_base.normalScale[0],
                    y: state.material_base.normalScale[1],
                    duration: timeline.duration,
                    delay: timeline.delay,
                  },
                  `-=${timeline.duration + timeline.delay}`
                );
              }
            });
          }
        }
      });
    }
  };

  useEffect(() => {
    if (config.type === SupportedSceneObjectTypes.asset && Mode === ModeTypes.preview) {
      scene.traverse((o: any) => {
        if ((o as THREE.Mesh).isMesh && names.length === 0) {
          const material_base = config.backendProperties.animation.states[0].material_base;
          if (o.material.name === material_base?.name) {
            o.material.setValues({
              color: new THREE.Color(material_base?.color),
            });
            o.castShadow = true;
            o.material.opacity = material_base?.opacity as number;
            o.material.metalness = material_base?.metalness as number;
            o.material.roughness = material_base?.roughness as number;
            o.material.displacementScale = material_base?.displacementScale as number;
            o.material.aoMapIntensity = material_base?.aoMapIntensity as number;
            o.material.normalScale = new THREE.Vector2(
              material_base?.normalScale[0],
              material_base?.normalScale[1]
            );
            o.material.transparent = true;
            o.material.needsUpdate = true;
            o.material.depthWrite = true;
            if (config.localProperties.updateTexture) {
              updateTextures(o.material);
            }
          }
        }
      });
    }
    if (
      config.type === SupportedSceneObjectTypes.viewport &&
      config.localProperties.isPrimary &&
      Mode === ModeTypes.preview
    ) {
      console.log('Setting Camera Position');
      camera.position.set(
        config.backendProperties.animation.states[0].position[0],
        config.backendProperties.animation.states[0].position[1],
        config.backendProperties.animation.states[0].position[2]
      );
      camera.rotation.set(
        config.backendProperties.animation.states[0].rotation[0],
        config.backendProperties.animation.states[0].rotation[1],
        config.backendProperties.animation.states[0].rotation[2]
      );
      camera.updateProjectionMatrix();
    }
  }, []);

  useEffect(() => {
    if (config.type === SupportedSceneObjectTypes.asset && Mode === ModeTypes.edit) {
      const materialConfig = material?.material_base ?? config.localProperties.material_base;

      if (materialConfig) {
        scene.traverse((o: any) => {
          if ((o as THREE.Mesh).isMesh && names.length === 0) {
            o.material.setValues({
              color: new THREE.Color(materialConfig?.color),
            });
            o.castShadow = true;
            o.material.opacity = materialConfig?.opacity as number;
            o.material.metalness = materialConfig?.metalness as number;
            o.material.roughness = materialConfig?.roughness as number;
            o.material.displacementScale = materialConfig?.displacementScale as number;
            o.material.aoMapIntensity = materialConfig?.aoMapIntensity as number;
            o.material.normalScale = new THREE.Vector2(
              materialConfig?.normalScale[0],
              materialConfig?.normalScale[1]
            );

            o.material.transparent = true;
            o.material.needsUpdate = true;
            o.material.depthWrite = true;
          }
        });
      }
    }
  }, [config.backendProperties.material, material, config.localProperties.material_base]);

  useEffect(() => {
    if (config.type === SupportedSceneObjectTypes.asset && Mode === ModeTypes.edit) {
      const materialConfig = material?.material_base ?? config.localProperties.material_base;

      if (materialConfig) {
        scene.traverse((o: any) => {
          if ((o as THREE.Mesh).isMesh && names.length === 0) {
            updateTextures(o.material);
          }
        });
      }
    }
  }, [
    config.backendProperties.material,
    material?.material_base?.map,
    config.localProperties.material_base?.map,
  ]);

  useEffect(() => {
    const currentAnimationState = config.localProperties.animationState;

    if (currentAnimationState?.length) {
      config.localProperties.animationState?.forEach((state) => {
        if (state.isPlaying) {
          actions[state.name]?.reset().fadeIn(0.5).play();
        } else {
          actions[state.name]?.fadeOut(0.5);
        }
      });
    }
  }, [config.localProperties.animationState]);
  useEffect(() => {
    if (
      Mode === ModeTypes.preview &&
      config.backendProperties.animation !== null &&
      config.localProperties.events
    ) {
      const Events = config.localProperties.events;

      Events.forEach((event) => {
        if (event.play) {
          const sceneEvents = store.getState().sceneViewer.events;
          const currentEvent = sceneEvents[event.id];
          if (currentEvent.sequence.timeline.length === 0) return;
          const Animationtimeline = gsap.timeline({
            onComplete: () => {
              let finalStateIndex = config.backendProperties.animation.states.findIndex(
                (state) =>
                  state.name ===
                  currentEvent.sequence.timeline[currentEvent.sequence.timeline.length - 1].name
              );
              if (config.localProperties.events)
                handleSceneObjectAction(SceneObjectActionTypes.update, [
                  {
                    id: config.id,
                    type: config.type,
                    localProperties: {
                      ...config.localProperties,
                      currentState: finalStateIndex,
                      events: config.localProperties.events.map((ev) => {
                        if (ev.id === event.id) {
                          return {
                            ...ev,
                            play: false,
                          };
                        }
                        return ev;
                      }),
                    },
                    backendProperties: config.backendProperties,
                  },
                ]);
            },
          });
          let startStateIndex = config.backendProperties.animation.states.findIndex(
            (state) => state.name === currentEvent.sequence.timeline[0].name
          );
          if (startStateIndex === config.localProperties.currentState) {
            currentEvent.sequence.timeline.forEach((timeline, index) => {
              let state = config.backendProperties.animation.states.find(
                (state) => state.name === timeline.name
              );

              if (state && ref.current && index > 0) {
                console.log(index);

                Animationtimeline.to(ref.current?.position, {
                  x: state.position[0],
                  y: state.position[1],
                  z: state.position[2],
                  duration: timeline.duration,
                  delay: timeline.delay,
                });
                Animationtimeline.to(
                  ref.current?.rotation,
                  {
                    x: state.rotation[0],
                    y: state.rotation[1],
                    z: state.rotation[2],
                    duration: timeline.duration,
                    delay: timeline.delay,
                  },
                  `-=${timeline.duration + timeline.delay}`
                );
                Animationtimeline.to(
                  ref.current?.scale,
                  {
                    x: state.scale[0],
                    y: state.scale[1],
                    z: state.scale[2],
                    duration: timeline.duration,
                    delay: timeline.delay,
                  },
                  `-=${timeline.duration + timeline.delay}`
                );
                if (
                  config.type === SupportedSceneObjectTypes.viewport &&
                  config.localProperties.isPrimary
                ) {
                  Animationtimeline.to(
                    camera.position,
                    {
                      x: state.position[0],
                      y: state.position[1],
                      z: state.position[2],
                      duration: timeline.duration,
                      delay: timeline.delay,
                    },
                    `-=${timeline.duration + timeline.delay}`
                  );
                  Animationtimeline.to(
                    camera.rotation,
                    {
                      x: state.rotation[0],
                      y: state.rotation[1],
                      z: state.rotation[2],
                      duration: timeline.duration,
                      delay: timeline.delay,
                    },
                    `-=${timeline.duration + timeline.delay}`
                  );
                }
                if (config.type === SupportedSceneObjectTypes.asset) {
                  ref.current?.traverse((o: any) => {
                    if (o.isMesh && state && o.name === config.backendProperties.name) {
                      Animationtimeline.to(
                        o.material,
                        {
                          opacity: state.material_base.opacity,
                          roughness: state.material_base.roughness,
                          metalness: state.material_base.metalness,
                          displacementScale: state.material_base.displacementScale,
                          aoMapIntensity: state.material_base.aoMapIntensity,

                          repeat: 0,
                          duration: timeline.duration,
                          delay: timeline.delay,
                        },
                        `-=${timeline.duration + timeline.delay}`
                      );
                      Animationtimeline.to(
                        o.material.normalScale,
                        {
                          x: state.material_base.normalScale[0],
                          y: state.material_base.normalScale[1],
                          duration: timeline.duration,
                          delay: timeline.delay,
                        },
                        `-=${timeline.duration + timeline.delay}`
                      );
                    }
                  });
                }
              }
            });
          }
        }
      });
    }
  }, [config.localProperties.events]);

  const clickFunction = () => {
    if (Mode === ModeTypes.preview && config.backendProperties.animation !== null) {
      const sceneEvents = store.getState().sceneViewer.events;
      const Events = Object.values(sceneEvents).filter(
        (event) =>
          event.triggerObject === config.id &&
          event.trigger === 'click' &&
          event.target === SceneEventTriggerTargets.object
      );
      Events.forEach((event) => {
        if (!event.id) return;
        const currentEvent = sceneEvents[event.id];
        if (currentEvent.targetObject === config.id) {
          if (currentEvent.sequence.timeline.length > 0) {
            playTimeline(currentEvent);
          }
        } else {
          if (!event.targetObject) return;
          const targetObject = sceneObjectList[event.targetObject];
          if (!targetObject.localProperties.events) return;
          handleSceneObjectAction(SceneObjectActionTypes.update, [
            {
              id: targetObject.id,
              type: targetObject.type,
              localProperties: {
                ...targetObject.localProperties,
                events: targetObject.localProperties.events.map((ev) => {
                  if (ev.id === event.id) {
                    return {
                      ...ev,
                      play: true,
                    };
                  }
                  return ev;
                }),
              },
              backendProperties: targetObject.backendProperties,
            },
          ]);
        }
      });
    }
  };
  const hoverFunction = () => {
    if (Mode === ModeTypes.preview && config.backendProperties.animation !== null) {
      const sceneEvents = store.getState().sceneViewer.events;
      const Events = Object.values(sceneEvents).filter(
        (event) =>
          event.triggerObject === config.id &&
          event.trigger === 'hover' &&
          event.target === SceneEventTriggerTargets.object
      );
      Events.forEach((event) => {
        if (!event.id) return;
        const currentEvent = sceneEvents[event.id];
        if (currentEvent.targetObject === config.id) {
          if (currentEvent.sequence.timeline.length > 0) {
            playTimeline(currentEvent);
          }
        } else {
          if (!event.targetObject) return;
          const targetObject = sceneObjectList[event.targetObject];
          if (!targetObject.localProperties.events) return;
          handleSceneObjectAction(SceneObjectActionTypes.update, [
            {
              id: targetObject.id,
              type: targetObject.type,
              localProperties: {
                ...targetObject.localProperties,
                events: targetObject.localProperties.events.map((ev) => {
                  if (ev.id === event.id) {
                    return {
                      ...ev,
                      play: true,
                    };
                  }
                  return ev;
                }),
              },
              backendProperties: targetObject.backendProperties,
            },
          ]);
        }
      });
    }
  };
  return { clickFunction, hoverFunction };
}
