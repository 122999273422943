import { Text, Flex } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useAppSelector } from 'src/store/reducers/hook';
import { DEFAULT_INTEGRATION } from 'src/utils/constants';
import CloudStorage from './CloudStorage';

const WorkspaceSettings = () => {
  const userWorkspace = useAppSelector((store) => store.app.currentUser?.workspace_id);
  const auth = useAppSelector((store) => store.integrations.auth);
  const active = useMemo(() => {
    const integrationAuth = Object.values(auth).find(
      (service) => service.workspace_id === userWorkspace
    );

    if (!integrationAuth) return DEFAULT_INTEGRATION.name;

    return integrationAuth?.name;
  }, [userWorkspace, auth]);

  return (
    <>
      <Flex justifyContent="space-between">
        <Text fontSize="30px">Workspace Settings</Text>
      </Flex>
      <CloudStorage isReadonly active={active} />
    </>
  );
};

export default WorkspaceSettings;
