import { InfoIcon } from '@chakra-ui/icons';
import {
  FormControl,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Button,
  Flex,
  Tooltip,
  InputLeftElement,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsEyeSlashFill, BsEyeFill, BsCheckLg } from 'react-icons/bs';
import { addIntegrationAuthAPI } from 'src/apis/integrations';
import { useAppDispatch } from 'src/store/reducers/hook';
import { addIntegrationAuth } from 'src/store/reducers/integrations';
import store from 'src/store/store';
import { UIcon } from '../icons';

const IntegrationForm = ({ service, integrated, onFinish }: any) => {
  const { name, config } = service;
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm();

  const onIntegrate = (data: any) => {
    setIsLoading(true);

    const currentUser = store.getState().app.currentUser;
    if (!currentUser) return console.error('No user logged in');

    const reqBody = {
      auth_data: data,
      user_id: currentUser.id,
      auth_type: 'keys',
      name,
      workspace_id: currentUser.workspace_id,
    };

    addIntegrationAuthAPI(reqBody as any)
      .then((data: any) => {
        dispatch(addIntegrationAuth(reqBody));
        if (onFinish) onFinish(name);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onIntegrate)}>
      {Object.keys(config ?? {}).map((key: string) => {
        const defaultValue = store.getState().integrations.auth[name]?.auth_data?.[key];
        const formTemplate = config[key];

        return (
          <FormControl key={key} my={4}>
            <InputGroup size="md" variant="ghost">
              {formTemplate.icon && (
                <InputLeftElement>
                  <UIcon name={formTemplate.icon} fontSize="14px" />
                </InputLeftElement>
              )}
              <Input
                autoComplete="on"
                defaultValue={integrated ? defaultValue : ''}
                type={integrated ? (show ? 'text' : 'password') : formTemplate.type}
                placeholder={formTemplate.placeholder}
                {...register(key, {
                  required: true,
                  minLength: 4,
                })}
              />
              {integrated && (
                <InputRightElement>
                  <IconButton
                    aria-label="toggle password visibility"
                    variant="ghost"
                    icon={show ? <BsEyeSlashFill /> : <BsEyeFill />}
                    size="sm"
                    onClick={() => setShow((s) => !s)}
                  />
                </InputRightElement>
              )}
              {!integrated && formTemplate.tooltip && (
                <InputRightElement mr={3}>
                  <Tooltip label={formTemplate.tooltip} aria-label="A tooltip">
                    <InfoIcon />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
        );
      })}
      <Flex justify="flex-start" mt={8}>
        {!integrated && (
          <Button
            alignSelf="right"
            type="submit"
            minW="15vw"
            leftIcon={integrated && <BsCheckLg size={20} />}
            colorScheme={integrated ? 'green' : 'gray'}
            isLoading={isLoading}
            isDisabled={!isValid || isLoading || integrated}
          >
            Continue
          </Button>
        )}
      </Flex>
    </form>
  );
};

export default IntegrationForm;
