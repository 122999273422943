import { Effect } from 'postprocessing';
import React, { forwardRef, useMemo } from 'react';
import { Uniform } from 'three';

const fragmentShader = `
  vec3 PBRNeutralToneMapping( vec3 color ) {
    const float startCompression = 0.8 - 0.04;
    const float desaturation = 0.15;

    float x = min(color.r, min(color.g, color.b));
    float offset = x < 0.08 ? x - 6.25 * x * x : 0.04;
    color -= offset;

    float peak = max(color.r, max(color.g, color.b));
    if (peak < startCompression) return color;

    const float d = 1. - startCompression;
    float newPeak = 1. - d * d / (peak + d - startCompression);
    color *= newPeak / peak;

    float g = 1. - 1. / (desaturation * (peak - newPeak) + 1.);
    return mix(color, newPeak * vec3(1, 1, 1), g);
  }

  void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor) {
    vec3 color = inputColor.rgb;
    color = PBRNeutralToneMapping(color);
    outputColor = vec4(color, inputColor.a);
  }
`;

// Effect implementation
class PBRNeutralToneMappingEffectImpl extends Effect {
  constructor({ param = 0.1 } = {}) {
    super('PBRNeutralToneMappingEffect', fragmentShader, {
      uniforms: new Map([['param', new Uniform(param)]]),
    });
  }
  //    @ts-ignore
  update(renderer, inputBuffer, deltaTime) {
    // Custom logic for updating the effect can go here
    // For this example, we're not modifying the shader's uniform on each frame
  }
}

// Effect component
export const PBRNeutralToneMappingEffect = forwardRef(({ param }: { param: any }, ref) => {
  const effect = useMemo(() => new PBRNeutralToneMappingEffectImpl(param), [param]);
  return <primitive ref={ref} object={effect} dispose={null} />;
});
