import { Canvas } from '@react-three/fiber';
import { OrbitControls, Stage } from '@react-three/drei';
import { ControllerPropertyList } from './ControllerPropertyList';
import { useGLTF } from '@react-three/drei';
import { Suspense, useMemo } from 'react';
import * as THREE from 'three';
import { getControllerModel } from 'src/utils/controllers';
import { ControllerObject, SupportedControllerModels, SupportedSceneObjectTypes } from 'src/types';
import { useAppSelector } from 'src/store/reducers/hook';
import { getSceneObject } from 'src/components/sceneViewer/helpers';
import { findChildrenOfType } from 'src/utils/tree';
import store from 'src/store/store';

export const ControllerView = (props: {
  config: any;
  parentViewport: string;
  handleControllerStateChange: Function;
}) => {
  return (
    <div className="controllerPreview">
      <Canvas
        className="controllerPreview-viewer"
        frameloop="demand"
        style={{ background: '#212121' }}
        gl={{
          antialias: true,
          powerPreference: 'high-performance',
        }}
        flat
        shadows
      >
        <OrbitControls makeDefault />
        <Stage castShadow shadows="contact">
          <hemisphereLight intensity={1.0} />
          <directionalLight castShadow />
          <ambientLight />
          <ControllersMeshes model={props.config?.metadata?.type} id={props.config.id} />
        </Stage>
      </Canvas>
      <ControllerProperties
        model={props.config?.metadata?.type}
        id={props.config.id}
        handleControllerStateChange={props.handleControllerStateChange}
      />
    </div>
  );
};

const ControllerProperties = (props: {
  model: SupportedControllerModels;
  id: string;
  handleControllerStateChange: Function;
}) => {
  const sceneAssetIds = useAppSelector((store) => store.sceneViewer.ids);

  const controllers = useMemo(() => {
    const sceneAssets = Object.values(store.getState().sceneViewer.entities);
    const controllerAssets = findChildrenOfType(
      sceneAssets,
      props.id,
      SupportedSceneObjectTypes.controller
    );

    return controllerAssets;
  }, [sceneAssetIds]);

  return (
    <>
      {controllers.map((controller: any, index) => {
        const metadata = controller.backendProperties.metadata;

        return (
          <ControllerPropertyList
            config={metadata.colorConfig}
            parentController={metadata.subType}
            handleControllerStateChange={props.handleControllerStateChange}
          />
        );
      })}
    </>
  );
};

export const ControllersMeshes = (props: { model: SupportedControllerModels; id: string }) => {
  const sceneAssetIds = useAppSelector((store) => store.sceneViewer.ids);

  const controllers = useMemo(() => {
    const controllerAssets = [] as ControllerObject[];

    sceneAssetIds.forEach((assetId) => {
      const asset = getSceneObject(assetId);
      if (asset && asset.backendProperties.parent_group_id === props.id) {
        const child = asset.localProperties.children.find(
          (child) => child.type === SupportedSceneObjectTypes.controller
        );
        if (child) {
          const controller = getSceneObject(child.id);
          if (controller) {
            controllerAssets.push(controller as ControllerObject);
          }
        }
      }
    });

    return controllerAssets;
  }, [sceneAssetIds]);

  return (
    <>
      {controllers.map((controller: any, index) => {
        const metadata = controller.backendProperties.metadata;
        const controllerModel = (getControllerModel(props.model) as any)[metadata.subType];

        return (
          <Suspense key={index}>
            <ControllerModel
              model={controllerModel}
              config={metadata.colorConfig}
              position={controllerModel.position}
              rotation={controllerModel.rotation}
              scale={controllerModel.scale}
            />
          </Suspense>
        );
      })}
    </>
  );
};

export const ControllerModel = (props: {
  model: any;
  config: any;
  position: [number, number, number];
  rotation: [number, number, number];
  scale: [number, number, number];
}) => {
  try {
    const object = useGLTF(props.model.url as string);
    const { root, ...materialConfig } = props.config;

    const bodyMeshnode = props.model.info.body.meshNode;
    const materialKeys = Object.keys(materialConfig);

    return (
      <group
        position={props.position}
        rotation={props.rotation}
        scale={props.scale}
        castShadow
        receiveShadow
      >
        <group receiveShadow castShadow>
          <mesh
            receiveShadow
            castShadow
            material-color={root}
            name={bodyMeshnode as string}
            geometry={(object.nodes[bodyMeshnode] as THREE.Mesh).geometry}
            rotation={(object.nodes[bodyMeshnode] as THREE.Mesh).rotation}
            position={(object.nodes[bodyMeshnode] as THREE.Mesh).position}
            scale={(object.nodes[bodyMeshnode] as THREE.Mesh).scale}
            material={object.materials[props.model.info.body.material] as THREE.Material}
            children={[
              materialKeys.map((controlKey, index) => {
                const meshNode = props.model.info[controlKey].meshNode;
                const materialName = props.model.info[controlKey].material;
                let material = object.materials[materialName].clone();

                if (materialConfig[controlKey] !== '#ffffff') {
                  material = new THREE.MeshStandardMaterial({
                    color: materialConfig[controlKey],
                    emissive: materialConfig[controlKey],
                    roughness: 1,
                    transparent: true,
                    metalness: 0,
                    opacity: 0.5,
                  });
                }

                return (
                  <mesh
                    key={index}
                    receiveShadow
                    castShadow
                    position={(object.nodes[meshNode] as THREE.Mesh).position}
                    rotation={(object.nodes[meshNode] as THREE.Mesh).rotation}
                    scale={(object.nodes[meshNode] as THREE.Mesh).scale}
                    geometry={(object.nodes[meshNode] as THREE.Mesh).geometry}
                    material={material}
                  />
                );
              }),
            ]}
          />
        </group>
      </group>
    );
  } catch (error) {
    // console.log(error)
    return <></>;
  }
};
