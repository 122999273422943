import { supabase } from 'src/SupabaseClient';
import { Tables } from 'src/types';
import { TABLES } from 'src/utils/constants';

export const getAllProjectsAPI = async (workspace: string): Promise<any> => {
  const { data } = await supabase.rpc('get_projects_by_workspace', { workspace }).select('*');

  return data;
};

export const getProjectAPI = async (projectId: string) => {
  const { data } = await supabase.from(TABLES.projects).select('*').eq('id', projectId);
  return data;
};

export const createProjectAPI = async (name: any) => {
  const { data, error } = await supabase
    .rpc('create_new_project', {
      project_name: name,
    })
    .single();

  return { data, error };
};

export const deleteProjectAPI = async (projectId: string) => {
  await supabase.from(TABLES.projects).delete().eq('id', projectId);
};

export const updateProjectAPI = async (projectId: string, data: Partial<Tables<'projects'>>) => {
  await supabase.from(TABLES.projects).update(data).eq('id', projectId);
};

export const updateRoleOfUser = async (projectId: string, userId: string, role: string) => {
  const { data, error } = await supabase
    .from(TABLES.project_users)
    .update({ role })
    .eq('project_id', projectId)
    .eq('user_id', userId)
    .select('*');

  return { data, error };
};

export const getInvitedUsers = async ({
  workspaceId,
  projectId,
}: {
  workspaceId?: string;
  projectId?: string;
}) => {
  let query = supabase.from('invites').select('*').neq('invite_status', 'accepted');

  if (workspaceId) {
    query = query.eq('workspace_id', workspaceId);
  }

  if (projectId) {
    query = query.eq('project_id', projectId);
  }

  const { data, error } = await query;

  if (data) {
    const invitedUsers = data.map((invite: any) => {
      return {
        email: invite.email,
        role: invite.role,
        inviteBy: invite.invited_by,
      };
    });

    return invitedUsers;
  }

  return { error };
};
