import { Flex, Heading, Text } from '@chakra-ui/react';
import { useState } from 'react';
import Integrations from './Integrations';

interface CloudStorageProps {
  onFinish?: any;
  active?: string;
  defaultValue?: string;
  isReadonly?: boolean;
}

const CloudStorage = (props: CloudStorageProps) => {
  const [selected, setSelected] = useState<string | undefined>(props.defaultValue);

  return (
    <Flex direction="column" gap={4} overflowY="scroll">
      <Flex direction="column" gap={2} position="sticky">
        <Heading size="md">Cloud Storage</Heading>
        <Text fontSize="md" color="gray.500">
          Select the cloud storage providers you'd like to integrate with your account. Enable
          seamless access and management of your files across different platforms like AWS, Azure,
          and more. (Note: This can't be changed later!)
        </Text>
      </Flex>
      <Integrations
        selected={selected}
        active={props.active}
        onSelect={setSelected}
        isReadonly={props.isReadonly}
        onFinish={props.onFinish}
      />
    </Flex>
  );
};

export default CloudStorage;
