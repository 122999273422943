import { Flex, Grid, Text } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/skeleton';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router';
import { updateProjectAPI, deleteProjectAPI } from 'src/apis';
import Project from 'src/components/Project';
import { setProject } from 'src/store/reducers/app';
import { useAppDispatch } from 'src/store/reducers/hook';
import { setSharedMemory } from 'src/store/reducers/InstanceReducer';
import { changeViewport } from 'src/store/reducers/storyboard';
import { Tables } from 'src/types';
import { ROUTES } from 'src/utils/constants';

interface RecentsProps {
  projects: Tables<'projects'>[];
  isLoading: boolean;
  setProjects: Dispatch<SetStateAction<Tables<'projects'>[]>>;
}

const Recents = (props: RecentsProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<string | undefined>();

  const onDoubleClick = (id: string, name: string) => {
    dispatch(setSharedMemory([]));
    dispatch(setProject({ id, name }));
    dispatch(changeViewport(undefined));
    navigate(ROUTES.home);
  };

  const handleClick = (e: any) => {
    const item = e.target?.closest('[data-project-id]');
    if (item) {
      const id = item.dataset.projectId;
      setSelected(id);

      if (e.detail === 2) {
        const name = item.dataset.projectName;
        onDoubleClick(id, name);
      }
    } else {
      setSelected(undefined);
    }
  };

  const onRename = async (id: string, name: string) => {
    await updateProjectAPI(id, { name });
    props.setProjects((existingProjects) =>
      existingProjects.map((item) => (item.id === id ? { ...item, name } : item))
    );
  };

  const onDelete = async (id: string) => {
    await deleteProjectAPI(id);
    props.setProjects((s) => s.filter((project) => project.id !== id));
  };

  return (
    <Flex flexDirection="column" height="full" onClick={handleClick} onContextMenu={handleClick}>
      <Flex justifyContent="space-between">
        <Text fontSize="30px">Recently Added</Text>
      </Flex>
      <Skeleton startColor="none" endColor="none" isLoaded={!props.isLoading}>
        <Grid
          width="100%"
          templateColumns="repeat(auto-fill, minmax(18rem, 1fr))"
          gap={4}
          justifyContent="center"
        >
          {props.projects?.map((project) => (
            <Project
              key={project.id}
              project={project}
              selected={selected}
              onRename={onRename}
              onDelete={onDelete}
            />
          ))}
        </Grid>
      </Skeleton>
    </Flex>
  );
};

export default Recents;
