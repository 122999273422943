import { useEffect, useState } from 'react';

import './PropertyField.css';

import { Input } from '@chakra-ui/react';
import classnames from 'classnames';

export default function PropertyField(props: any) {
  const [value, setValue] = useState<any>(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleBlur = (event: any) => {
    props.handlePropertyChange(event, props.field, props.propIndex);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleBlur(event);
    }
  };

  let disabled = false;
  if (props.disabled !== undefined) {
    disabled = props.disabled;
  }

  return (
    <span className={classnames('propField', props.classNames)}>
      <Input
        variant="ghost"
        className="propField-input"
        value={value}
        style={{ width: props.width ? props.width : 'inherit' }}
        pointerEvents={disabled ? 'none' : 'auto'}
        type={props.type}
        name={props.name ?? 'input'}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        onBlur={(event) => handleBlur(event)}
        onKeyDown={(event) => handleKeyDown(event)}
        step={props.step}
        max={props.max}
      />
    </span>
  );
}
