import { useAppSelector } from 'src/store/reducers/hook';
import IntegrationCards from './IntegrationCards';
import IntegrationSettings from './IntegrationSettings';

interface IntegrationProps {
  selected: any;
  active?: string;
  onSelect: any;
  onFinish: any;
  isReadonly?: boolean;
}

const Integrations = (props: IntegrationProps) => {
  const { selected, onSelect, active, isReadonly } = props;
  const integrations = useAppSelector((store) => store.integrations.items).filter(
    (i) => i.category === 'storage'
  );

  return (
    <div>
      <IntegrationCards
        services={integrations}
        selected={selected}
        onSelect={onSelect}
        active={active}
        isReadonly={isReadonly}
      />
      {selected && !isReadonly && (
        <IntegrationSettings
          active={active}
          onFinish={props.onFinish}
          service={integrations.find((integration) => integration.name === selected)}
        />
      )}
    </div>
  );
};

export default Integrations;
