import { Flex, FlexProps } from '@chakra-ui/react';

interface FloatingContainerProps extends FlexProps {
  children: React.ReactNode;
}

const FloatingContainer: React.FC<FloatingContainerProps> = (props) => {
  const { children, ...styles } = props;

  return (
    <Flex
      bg="rgba(18, 17, 23, 0.6)"
      border="1px solid #333"
      w="fit-content"
      borderRadius="full"
      boxShadow="0 2px rgba(0, 0, 0, 0.3)"
      backdropFilter="blur(16px)"
      align="center"
      zIndex={2}
      p={1}
      {...(styles as any)}
    >
      {children}
    </Flex>
  );
};

export default FloatingContainer;
