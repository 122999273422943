import { sceneRefInterface } from './context';
import { forwardRef } from 'react';
import PropertyPanel from 'src/components/sceneViewer/propertyPanel/PropertyPanel';
import VoiceNotes from 'src/features/VoiceNotes';
import { useAppSelector } from 'src/store/reducers/hook';
import { InstanceToolType } from 'src/types';
import MaterialsPropertyContainer from './propertyPanel/MaterialsPropertyContainer';
import AssetEvents from './propertyPanel/AssetEvents';

const SidePanel = forwardRef<sceneRefInterface, any>((props, ref) => {
  const activePanelTool = useAppSelector((store) => store.instance.activeTool);
  const showEvents = useAppSelector((store) => store.sceneViewer.showEvents);
  const selectedObjects = useAppSelector((store) => store.sceneViewer.selectedObjects);

  let multiSelectAlign = false;

  if (selectedObjects.length > 1) {
    multiSelectAlign = true;
  }

  return (
    <>
      <div
        className={`sidePanel rightSidePanel panel ${multiSelectAlign ? 'multiSelectAlign' : ''}`}
      >
        {activePanelTool === InstanceToolType.comment ? <VoiceNotes /> : <PropertyPanel />}
      </div>
      <MaterialsPropertyContainer selectedObjects={selectedObjects} />
      {showEvents.show && (
        <div
          className={`sidePanel EventsPanel panel `}
          style={{
            display: 'block',
          }}
        >
          <AssetEvents id={showEvents.id} index={showEvents.index} />
        </div>
      )}
    </>
  );
});

export default SidePanel;
