import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from 'src/hooks';
import { useAppSelector } from 'src/store/reducers/hook';
import store from 'src/store/store';
import { ROUTES } from 'src/utils/constants';

const Location = ({ children }: any) => {
  const location = useLocation();
  useEffect(() => {
    let title = 'Unproject';
    switch (location.pathname) {
      case ROUTES.login:
        title = 'Login';
        break;
      case ROUTES.register:
        title = 'Register';
        break;
      case ROUTES.projects:
        title = 'Unproject';
        break;
      case ROUTES.editor:
        title = store.getState().app.project?.name || 'Unproject';
        break;
      case ROUTES.home:
        title = store.getState().app.project?.name || 'Unproject';
        break;
      default:
        title = 'Unproject';
    }

    document.title = title;
  }, [location]);

  return children;
};

export const ProtectedRoute = ({ redirectTo = ROUTES.login }: { redirectTo?: string }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <Location>
      <Outlet />
    </Location>
  ) : (
    <Navigate to={redirectTo} />
  );
};

export const Authenticated = () => {
  const { isAuthenticated } = useAuth();

  const currentUser = useAppSelector((store) => store.app.currentUser);
  const workspaceExist = currentUser && currentUser.workspace_id;

  return isAuthenticated ? (
    workspaceExist ? (
      <Location>
        <Outlet />
      </Location>
    ) : (
      <Navigate to={ROUTES.create_workspace} />
    )
  ) : (
    <Navigate to={ROUTES.login} />
  );
};

export const Unauthenticated = () => {
  const { isAuthenticated } = useAuth();
  const currentUser = useAppSelector((store) => store.app.currentUser);
  const workspaceExist = currentUser && currentUser.workspace_id;

  return isAuthenticated ? (
    <Navigate to={workspaceExist ? ROUTES.projects : ROUTES.create_workspace} />
  ) : (
    <Location>
      <Outlet />
    </Location>
  );
};
