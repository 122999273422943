import { useHelper } from '@react-three/drei';
import { extend, ThreeEvent } from '@react-three/fiber';
// @ts-ignore
import { DirectionalLight, PointLight, RectAreaLight } from 'lgl-tracer';
import { useRef } from 'react';
import * as THREE from 'three';

import {
  DirectionalLightObjectMetadata,
  LightObject,
  PointLightObjectMetadata,
  SpotLightObjectMetadata,
  SupportedLightTypes,
  SupportedSceneObjectTypes,
} from 'src/types';

// extend({ LglPointLight: PointLight, LglDirectionalLight: DirectionalLight})
extend({
  LglPointLight: PointLight,
  LglRectAreaLight: RectAreaLight,
  LglDirectionalLight: DirectionalLight,
});

const defaultSceneLightAssetProps = {
  onClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  handleDrag: (l: THREE.Matrix4, dl: THREE.Matrix4, w: THREE.Matrix4, dw: THREE.Matrix4) => {},
  onRightClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  onDoubleClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  disablePivot: false,
  lgl: false,
};

function SceneLightAsset(props: any & typeof defaultSceneLightAssetProps) {
  const scaleMatrix = new THREE.Matrix4();
  scaleMatrix.scale(new THREE.Vector3(1.0, 1.0, 1.0));

  return (
    <group onPointerOver={props.onPointerOver} onPointerOut={props.onPointerOut}>
      {/* <pointLight intensity={1.0} position={[0.0, 0.0, 5.0]}/> */}
      <LightMesh
        type={props.config.backendProperties.metadata.subtype}
        config={props.config}
        onClick={props.onClick}
        onRightClick={props.onRightClick}
        onDoubleClick={props.onDoubleClick}
        lgl={props.lgl}
      />
    </group>
  );
}

const LightMesh = (props: {
  type: SupportedLightTypes;
  config: LightObject;
  onClick: Function;
  onRightClick: Function;
  onDoubleClick: Function;
  lgl: Boolean;
}) => {
  let light = null;
  if (props.type === SupportedLightTypes.point) {
    light = (
      <PointLightI
        config={props.config.backendProperties.metadata as PointLightObjectMetadata}
        lgl={props.lgl}
        fullConfig={props.config}
      />
    );
  } else if (props.type === SupportedLightTypes.directional) {
    light = (
      <DirectionalLightI
        config={props.config.backendProperties.metadata as DirectionalLightObjectMetadata}
        lgl={props.lgl}
        fullConfig={props.config}
      />
    );
  } else if (props.type === SupportedLightTypes.spot) {
    light = (
      <SpotLight config={props.config.backendProperties.metadata as SpotLightObjectMetadata} />
    );
  }

  return (
    <group
      onClick={(event: ThreeEvent<MouseEvent>) =>
        props.onClick(event, props.config.id, SupportedSceneObjectTypes.light)
      }
      onContextMenu={(event: ThreeEvent<MouseEvent>) =>
        props.onRightClick(event, props.config.id, SupportedSceneObjectTypes.light)
      }
      onDoubleClick={(event: ThreeEvent<MouseEvent>) =>
        props.onDoubleClick(event, props.config.id, SupportedSceneObjectTypes.light)
      }
    >
      {light}
    </group>
  );
};

const DirectionalLightI = (props: {
  config: DirectionalLightObjectMetadata;
  lgl: Boolean;
  fullConfig: LightObject;
}) => {
  // extend({ PointLight, DirectionalLight})
  const dirLight = useRef<THREE.DirectionalLight>(null) as any;
  // useHelper(dirLight, THREE.DirectionalLightHelper, 1.0, "white");
  if (props.lgl) {
    return (
      <>
        {/* @ts-ignore */}
        <lglDirectionalLight
          position={props.fullConfig.backendProperties.position}
          color={props.config.color}
          intensity={props.config.intensity}
          // ref={dirLight}
        />
      </>
    );
  } else {
    return (
      <directionalLight
        castShadow
        position={props.fullConfig.backendProperties.position}
        color={props.config.color}
        intensity={props.config.intensity}
        ref={dirLight}
      />
    );
  }
};

const PointLightI = (props: {
  config: PointLightObjectMetadata;
  lgl: Boolean;
  fullConfig: LightObject;
}) => {
  // const pointLight = useRef<THREE.PointLight>(null) as any;
  // useHelper(pointLight, THREE.PointLightHelper, 1.0, "white");
  // extend({ PointLight, DirectionalLight})
  if (props.lgl) {
    return (
      <>
        {/* @ts-ignore */}
        <lglPointLight
          position={props.fullConfig.backendProperties.position}
          // rotation={props.config.backendProperties.rotation}
          color={props.config.color}
          intensity={props.config.intensity}
          // ref={pointLight}
          decay={props.config.config.decay}
          distance={props.config.config.distance}
        />
      </>
    );
  } else {
    return (
      <>
        <pointLight
          castShadow
          position={props.fullConfig.backendProperties.position}
          color={props.config.color}
          intensity={props.config.intensity}
          // ref={pointLight}
          decay={props.config.config.decay}
          distance={props.config.config.distance}
        />
      </>
    );
  }
};

const SpotLight = (props: { config: SpotLightObjectMetadata }) => {
  const spotLight = useRef<THREE.SpotLight>(null) as any;
  useHelper(spotLight, THREE.SpotLightHelper, 'white');
  return (
    <>
      <spotLight
        color={props.config.color}
        intensity={props.config.intensity}
        ref={spotLight}
        decay={props.config.config.decay}
        distance={props.config.config.distance}
        angle={props.config.config.angle}
        penumbra={props.config.config.penumbra}
      />
    </>
  );
};

SceneLightAsset.defaultProps = defaultSceneLightAssetProps;
export default SceneLightAsset;
