import * as THREE from 'three';
import { Clone, Html, useAnimations, useGLTF } from '@react-three/drei';
import { useEffect, useState, useRef, useMemo } from 'react';
import { ThreeEvent, useLoader } from '@react-three/fiber';
import {
  AssetObject,
  ModeTypes,
  SceneEvent,
  SceneObjectActionTypes,
  SceneObjectFileTypes,
  SupportedSceneObjectTypes,
} from 'src/types';
import { useSceneViewer } from '../hooks/useSceneViewer';

import store from 'src/store/store';
import { ErrorBoundary } from 'react-error-boundary';

import { GLTFLoader, FBXLoader, DRACOLoader } from 'three/examples/jsm/Addons.js';
import { SkeletonUtils } from 'three-stdlib';
import AnnotationContainer from '../scene/AnnotationContainer';
import { useCache } from 'src/hooks/useCache';
import { getCloudStorageCacheKey, uploadProjectAssetPath } from 'src/utils/cloud';
import { CloudStorageManager } from 'src/services/storage';
import useAssetManipulation from '../hooks/useAssetManipulation';
import { useAppSelector } from 'src/store/reducers/hook';
type PreviewAssetProps = {
  config: AssetObject;
};

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');

function PreviewAsset(props: any) {
  const { getCacheItem, cacheItem } = useCache();
  const isInitializing = useAppSelector((store) => store.app.isInitializing);
  const assetEvents = Object.values(useAppSelector((store) => store.sceneViewer.events)).filter(
    (event) => event.targetObject === props.config.id
  );

  const modelUrl = useMemo(() => {
    const workspace = store.getState().app.currentUser?.workspace_id;
    const projectId = store.getState().app.projectId;

    const stored = localStorage.getItem(getCloudStorageCacheKey(workspace!));
    const cachedItem = stored ? JSON.parse(stored) : undefined;

    if (!cacheItem) return;

    const cloud = new CloudStorageManager(cachedItem.provider, cachedItem.config);
    const fileName = props.config.backendProperties.metadata.file;

    const key = `${uploadProjectAssetPath('objects', workspace!, projectId)}/${fileName}`;
    const url = getCacheItem(key) || cloud.getSignedUrl(key);

    cacheItem(key, url);

    return url;
  }, [isInitializing]);

  const scaleMatrix = new THREE.Matrix4();
  scaleMatrix.scale(
    new THREE.Vector3(
      props.config.backendProperties.scale[0],
      props.config.backendProperties.scale[1],
      props.config.backendProperties.scale[2]
    )
  );

  let asset = <AssetElement url={modelUrl} config={props.config} events={assetEvents} />;

  if (props.config.backendProperties.metadata.filetype === SceneObjectFileTypes.fbx) {
    asset = <FBXAssetElement url={modelUrl} config={props.config} events={assetEvents} />;
  }

  if (props.config.localProperties.localThreejsObjectJSON !== undefined) {
    if (props.config.backendProperties.metadata.filetype === SceneObjectFileTypes.fbx) {
      asset = (
        <FBXAssetElement
          url={props.config.localProperties.localThreejsObjectJSON as string}
          config={props.config}
          events={assetEvents}
        />
      );
    } else {
      asset = <LocalAssetElementLoader url={modelUrl} config={props.config} events={assetEvents} />;
    }
  }

  return (
    <group
      position={props.config.backendProperties.animation.states[0].position}
      rotation={props.config.backendProperties.animation.states[0].rotation}
      scale={props.config.backendProperties.animation.states[0].scale}
    >
      <ErrorBoundary fallback={<></>}>{asset}</ErrorBoundary>
    </group>
  );
}

const LocalAssetElementLoader = (props: {
  url: string;
  config: AssetObject;
  events: SceneEvent[];
}) => {
  const objectLoader = new GLTFLoader();
  const [object, setObject] = useState<any>(undefined);
  const [gotObject, setGotObject] = useState<boolean>(false);

  if (!gotObject) {
    setGotObject(true);
    objectLoader.parse(
      props.config.localProperties.localThreejsObjectJSON as any,
      '',
      (gltf) => {
        setObject(gltf);
      },
      (err) => {
        console.log(err);
        setGotObject(false);
      }
    );
  }

  if (object === undefined) {
    return <></>;
  } else {
    return <LocalAssetElement object={object} config={props.config} events={props.events} />;
  }
};
const LocalAssetElement = (props: { object: any; config: AssetObject; events: SceneEvent[] }) => {
  const { handleSceneObjectAction } = useSceneViewer();
  const primitiveRef = useRef<THREE.Group>(null);
  const newclone = useMemo(() => SkeletonUtils.clone(props.object.scene), [props.object.scene]);
  const { actions, names } = useAnimations(props.object.animations, primitiveRef);
  const [annotations, setAnnotations] = useState<JSX.Element[]>([]);

  const { clickFunction, hoverFunction } = useAssetManipulation({
    config: props.config,
    ref: primitiveRef,
    actions: actions,
    names: names,
    scene: props.object.scene,
    Mode: ModeTypes.preview,
  });

  useEffect(() => {
    const getannotations = [] as JSX.Element[];
    props.object.scene.traverse((o: any) => {
      if (o.userData.prop) {
        getannotations.push(
          <Html
            key={o.uuid}
            position={[o.position.x, o.position.y, o.position.z]}
            distanceFactor={0.5}
          >
            <AnnotationContainer data={o.userData.prop} index={getannotations.length + 1} />
          </Html>
        );
      }
    });
    setAnnotations(getannotations);

    let events: {
      id: string;
      play: boolean;
    }[] = [];
    if (props.events && props.events.length > 0) {
      events = props.events.map((event) => {
        return {
          id: event.id as string,
          play: event.trigger === 'start' ? true : false,
        };
      });
    }
    let updatedLocalProperties = {
      updateTexture: true,
      material_base: props.config.backendProperties.metadata.material_base,
      events: events,
      currentState: 0,
    };

    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: props.config.id,
        type: props.config.type,
        localProperties: updatedLocalProperties,
        backendProperties: props.config.backendProperties,
      },
    ]);
  }, [props.events.length]);

  return (
    <group onClick={clickFunction} onPointerOver={hoverFunction} ref={primitiveRef} renderOrder={1}>
      {names.length > 0 ? (
        <primitive object={newclone}>
          {props.config.localProperties.annotationShow ? annotations : null}
        </primitive>
      ) : (
        <primitive object={props.object.scene}>
          {props.config.localProperties.annotationShow ? annotations : null}
        </primitive>
      )}
    </group>
  );
};
const AssetElement = (props: { url: string; config: AssetObject; events: SceneEvent[] }) => {
  const { handleSceneObjectAction } = useSceneViewer();

  const object = useLoader(GLTFLoader, props.url as string, (loader) => {
    loader.setDRACOLoader(dracoLoader);
  });

  const primitiveRef = useRef<THREE.Group>(null);
  const newclone = useMemo(() => SkeletonUtils.clone(object.scene), [object.scene]);
  const { actions, names } = useAnimations(object.animations, primitiveRef);
  const [annotations, setAnnotations] = useState<JSX.Element[]>([]);
  const { clickFunction, hoverFunction } = useAssetManipulation({
    config: props.config,
    ref: primitiveRef,
    actions: actions,
    names: names,
    scene: object.scene,
    Mode: ModeTypes.preview,
  });
  useEffect(() => {
    Object.values(object.materials).forEach((material) => {
      if (material) {
        material.depthWrite = true;
      }
    });
    const getannotations = [] as JSX.Element[];

    object.scene.traverse((o: any) => {
      if (o.userData.prop) {
        getannotations.push(
          <Html
            key={o.uuid}
            position={[o.position.x, o.position.y, o.position.z]}
            distanceFactor={0.5}
          >
            <AnnotationContainer data={o.userData.prop} index={getannotations.length + 1} />
          </Html>
        );
      }
    });
    setAnnotations(getannotations);

    let events: {
      id: string;
      play: boolean;
    }[] = [];
    if (props.events && props.events.length > 0) {
      events = props.events.map((event) => {
        return {
          id: event.id as string,
          play: event.trigger === 'start' ? true : false,
        };
      });
    }
    let updatedLocalProperties = {
      material_base: props.config.backendProperties.metadata.material_base,
      updateTexture: true,
      events: events,
      currentState: 0,
    };

    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: props.config.id,
        type: props.config.type,
        localProperties: updatedLocalProperties,
        backendProperties: props.config.backendProperties,
      },
    ]);
  }, [props.events.length]);

  return (
    <group onClick={clickFunction} onPointerOver={hoverFunction} ref={primitiveRef} renderOrder={1}>
      {names.length > 0 ? (
        <primitive object={newclone}>
          {props.config.localProperties.annotationShow ? annotations : null}
        </primitive>
      ) : (
        <Clone object={object.scene}>
          {props.config.localProperties.annotationShow ? annotations : null}
        </Clone>
      )}
    </group>
  );
};

const FBXAssetElement = (props: { url: string; config: AssetObject; events: SceneEvent[] }) => {
  const { handleSceneObjectAction } = useSceneViewer();
  let object = useLoader(FBXLoader, props.url as string);
  const primitiveRef = useRef<THREE.Group>(null);
  const newclone = useMemo(() => SkeletonUtils.clone(object), [object]);
  const { actions, names } = useAnimations(object.animations, primitiveRef);
  const [annotations, setAnnotations] = useState<JSX.Element[]>([]);
  const { clickFunction, hoverFunction } = useAssetManipulation({
    config: props.config,
    ref: primitiveRef,
    actions: actions,
    names: names,
    scene: object,
    Mode: ModeTypes.preview,
  });
  useEffect(() => {
    const getannotations = [] as JSX.Element[];

    object.traverse((o: any) => {
      if (o.userData.prop) {
        getannotations.push(
          <Html
            key={o.uuid}
            position={[o.position.x, o.position.y, o.position.z]}
            distanceFactor={0.5}
          >
            <AnnotationContainer data={o.userData.prop} index={getannotations.length + 1} />
          </Html>
        );
      }
    });
    setAnnotations(getannotations);

    let events: {
      id: string;
      play: boolean;
    }[] = [];
    if (props.events && props.events.length > 0) {
      events = props.events.map((event) => {
        return {
          id: event.id as string,
          play: event.trigger === 'start' ? true : false,
        };
      });
    }
    let updatedLocalProperties = {
      material_base: props.config.backendProperties.metadata.material_base,
      updateTexture: true,
      events: events,
      currentState: 0,
    };

    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: props.config.id,
        type: props.config.type,
        localProperties: updatedLocalProperties,
        backendProperties: props.config.backendProperties,
      },
    ]);
  }, [props.events.length]);

  return (
    <group onClick={clickFunction} onPointerOver={hoverFunction} ref={primitiveRef} renderOrder={1}>
      {names.length > 0 ? (
        <primitive object={newclone}>
          {' '}
          {props.config.localProperties.annotationShow ? annotations : null}
        </primitive>
      ) : (
        <Clone object={object}>
          {' '}
          {props.config.localProperties.annotationShow ? annotations : null}
        </Clone>
      )}
    </group>
  );
};

export default PreviewAsset;
