import {
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  Modal,
  Stack,
  ChakraProvider,
  Progress,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { ModalType, SceneObjectActionTypes, SupportedSceneObjectTypes } from 'src/types';
import { closeModal } from 'src/store/reducers/modals';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF, Clone, Stage, Bounds, useProgress, Html } from '@react-three/drei';
import store from 'src/store/store';
import { exampleJSON } from 'src/utils/sceneAI';
import { useState, Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { copyFileInS3 } from 'src/utils/aws';
import { useSceneViewer } from 'src/components/sceneViewer/hooks/useSceneViewer';
import { useAssetUpload } from 'src/features/SceneViewer/hooks/useAssetUpload';
import { useCloudStorage } from 'src/hooks';
import { getPublicAssetURL } from 'src/utils/public';

const Preview3DAssetModal = () => {
  const dispatch = useAppDispatch();
  const modal = useAppSelector((store) => store.modal.active[ModalType.assetPreview]);

  const onClose = () => {
    dispatch(closeModal(ModalType.assetPreview));
  };

  if (!modal) return null;

  return (
    <Modal onClose={onClose} isOpen={!!modal} size="6xl">
      <ModalOverlay bg="#23232362" backdropFilter="blur(5px)" />
      <ModalContent h="80vh" p={0}>
        <ModalCloseButton zIndex={10} />
        <PreviewContainer onClose={onClose} />
      </ModalContent>
    </Modal>
  );
};

const PreviewContainer = ({ onClose }: any) => {
  const [isRefining, setIsRefining] = useState<boolean>(false);
  const [showRefined, setShowRefined] = useState<boolean>(false);
  const { handleSceneObjectAction } = useSceneViewer();
  const { getAssetPath } = useAssetUpload();
  const { getCloudStorage } = useCloudStorage();

  const handleRefine = () => {
    setIsRefining(true);
  };

  let urlObject = {
    preview: '',
    refined: '',
  };

  const promptData = store.getState().modal.active[ModalType.assetPreview];
  if (promptData && promptData.data) {
    const options = Object.keys((exampleJSON as any)[promptData.data.prompt]);
    let optionIndex = 0;
    optionIndex = promptData.data.optionIndex;
    urlObject = (exampleJSON as any)[promptData.data.prompt][options[optionIndex]];
  }

  const handleLoad = async (urlObject: any) => {
    let url = '';
    const projectId = store.getState().app.projectId;
    const scene_id = store.getState().instance.current_sceneId;
    if (showRefined) {
      url = urlObject.refined;
    } else {
      url = urlObject.preview;
    }
    const urlSplits = url.split('/');
    const assetName = urlSplits[urlSplits.length - 1];
    console.log('urlObject', urlObject, assetName);

    // Copy the asset to project folder.
    const srcBucket = getPublicAssetURL();
    const srcKey = 'generatedModels/' + assetName;
    const workspace = store.getState().app.currentUser?.workspace_id;

    if (!workspace) return;
    const srcFilePath = `${srcBucket}/${srcKey}`;
    const { path, key } = getAssetPath(assetName, {
      category: 'objects',
      level: 'project',
    });

    const cloudStorage = await getCloudStorage();

    if (!cloudStorage) return;

    await cloudStorage.copyFromPublicURL(srcFilePath, path);

    await copyFileInS3(srcBucket, srcKey, path).then(() => {
      handleSceneObjectAction(SceneObjectActionTypes.insert, [
        {
          id: null,
          type: SupportedSceneObjectTypes.asset,
          localProperties: {
            insertedThisSession: true,
          },
          backendProperties: {
            project_id: projectId,
            scene_id: scene_id,
            metadata: {
              file: key,
            },
          },
        },
      ]);
      onClose();
    });
  };

  const onRefine = () => {
    setShowRefined(true);
    setIsRefining(false);
  };

  return (
    <>
      <ModalBody overflow="hidden">
        <Canvas>
          <Suspense fallback={<Loader />}>
            {!isRefining}
            <Stage shadows="contact" adjustCamera={false}>
              <Bounds fit>
                {isRefining ? (
                  <Loader onLoad={onRefine} />
                ) : (
                  <ExampleMesh
                    urlObject={urlObject}
                    modelType={showRefined ? 'refined' : 'preview'}
                  />
                )}
              </Bounds>
            </Stage>
            <OrbitControls rotateSpeed={2.0} autoRotate={true} enablePan={false} makeDefault />
          </Suspense>
        </Canvas>
      </ModalBody>
      <Stack
        position="absolute"
        bottom="1"
        w="full"
        direction="row"
        p={4}
        spacing={4}
        align="center"
        justify="center"
      >
        <Button
          onClick={handleRefine}
          isDisabled={showRefined || isRefining}
          isLoading={isRefining}
        >
          {showRefined ? 'Refined' : 'Refine'}
        </Button>
        <Button
          onClick={() => {
            handleLoad(urlObject);
          }}
        >
          Load on Scene
        </Button>
        <Button onClick={onClose}>Download</Button>
      </Stack>
    </>
  );
};

function Loader(props: any) {
  const { progress } = useProgress();

  useEffect(() => {
    if (props.onLoad) {
      setTimeout(() => {
        props.onLoad();
      }, 2000);
    }
  }, []);

  return (
    <Html center>
      <ChakraProvider>
        <Progress
          w={200}
          rounded="md"
          isIndeterminate={props.onLoad}
          size="sm"
          variant="ghost"
          value={progress}
          bg="rgb(18, 17, 23)"
        />
      </ChakraProvider>
    </Html>
  );
}

function ErrorFallback() {
  return <Html center>Error!</Html>;
}

export const PreviewAsset = ({ assetURL }: { assetURL: string }) => {
  return (
    <Canvas>
      <Suspense fallback={<Loader />}>
        <Stage shadows="contact" adjustCamera={false}>
          <Bounds fit>
            <ExampleMesh urlObject={{ preview: assetURL, refined: '' }} modelType="preview" />
          </Bounds>
        </Stage>
        <OrbitControls rotateSpeed={2.0} autoRotate={true} enablePan={false} makeDefault />
      </Suspense>
    </Canvas>
  );
};

const ExampleMesh = (props: {
  urlObject: {
    preview: string;
    refined: string;
  };
  modelType: 'preview' | 'refined';
}) => {
  return (
    <ErrorBoundary fallback={<ErrorFallback />} resetKeys={[props.modelType]}>
      <MeshObject url={props.urlObject[props.modelType]} />
    </ErrorBoundary>
  );
};

const MeshObject = (props: { url: string }) => {
  const object = useGLTF(props.url);
  return <Clone object={object.scene} />;
};

export default Preview3DAssetModal;
