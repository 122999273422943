import { AWSStorage } from './aws';
import { AzureStorage } from './azure';
import type { CloudStorage, CloudProviderConfig, AWSConfig, AzureConfig } from './storage.types';

export class CloudStorageFactory {
  static createStorage<T extends CloudProviderConfig['provider']>(
    provider: T,
    config: Extract<CloudProviderConfig, { provider: T }>['config']
  ): CloudStorage {
    switch (provider) {
      case 'aws':
        return new AWSStorage(config as AWSConfig);
      case 'azure':
        return new AzureStorage(config as AzureConfig);
      default:
        throw new Error(`Unknown provider: ${provider}`);
    }
  }
}
