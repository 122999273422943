import { supabase } from 'src/SupabaseClient';
import { Tables } from 'src/types';

export const getAllMetadataAPI = async (projectId: string) => {
  const { data: instances } = await supabase
    .from('metadata')
    .select('*')
    .eq('project_id', projectId);

  return instances;
};

export const insertMetadataAPI = async (metadata: any) => {
  const { data: insertedData, error } = await supabase
    .from('entity_metadata')
    .insert(metadata)
    .select()
    .single();

  return insertedData;
};

export const deleteMetadataAPI = async (id: string) => {
  await supabase.from('entity_metadata').delete().eq('id', id);
};

export const updateMetadataAPI = async (id: string, data: any) => {
  await supabase.from('entity_metadata').update(data).eq('id', id);
};
