import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_INTEGRATION } from 'src/utils/constants';

const initialState = {
  items: [DEFAULT_INTEGRATION] as any[],
  auth: {} as Record<string, any>,
  sync: {} as Record<string, any>,
};

const integrationSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    setIntegrationsAuth(state, { payload: integrations }) {
      state.auth = integrations.reduce((acc: Record<string, any>, data: any) => {
        acc[data.name] = data;
        return acc;
      }, {});
    },
    setIntegrations(state, { payload: integrations }) {
      state.items = [DEFAULT_INTEGRATION, ...integrations];
    },
    updateIntegrationService(state, { payload: { name, data } }) {
      state.auth[name] = data;
    },
    addIntegrationAuth(state, { payload: auth }) {
      state.auth[auth.name] = auth;
    },
    removeIntegrationAuth(state, { payload: name }) {
      delete state.auth[name];
    },
    toggleAutoSync(state, { payload: name }) {
      if (!state.sync) {
        state.sync = {};
      }
      state.sync[name] = !state.sync[name];
    },
    clearIntegrations() {
      return initialState;
    },
  },
});

export const {
  setIntegrations,
  setIntegrationsAuth,
  removeIntegrationAuth,
  addIntegrationAuth,
  clearIntegrations,
  toggleAutoSync,
} = integrationSlice.actions;

export default integrationSlice.reducer;
