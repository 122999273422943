import { cloneElement, ReactElement, Suspense } from 'react';
import { useAppSelector } from 'src/store/reducers/hook';
import type { SceneObjectPropsType } from 'src/types';

interface SceneObjectProps extends Partial<SceneObjectPropsType> {
  id: string;
  children: ReactElement;
}

const checkIfInteractionIsDisabled = (data: any) => {
  if (!data?.backendProperties) return true;

  return data.backendProperties.background || data.backendProperties.locked;
};

const SceneObject = ({ id, children, ...restProps }: SceneObjectProps) => {
  const sceneObject = useAppSelector((store) => store.sceneViewer.entities[id]);

  if (sceneObject.backendProperties.parent_group_id !== null) {
    return null;
  }

  const disableInteraction = checkIfInteractionIsDisabled(sceneObject);

  return (
    <Suspense key={sceneObject.id}>
      {cloneElement(children, {
        ...restProps,
        config: sceneObject,
        onClick: disableInteraction ? () => {} : restProps.onClick,
        onRightClick: disableInteraction ? () => {} : restProps.onRightClick,
        onDoubleClick: disableInteraction ? () => {} : restProps.onDoubleClick,
        onPointerOver: () => restProps.onPointerOver?.(sceneObject),
        disablePivot: disableInteraction,
      })}
    </Suspense>
  );
};

export default SceneObject;
