import {
  Bounds,
  GizmoHelper,
  GizmoViewport,
  Grid,
  OrbitControls,
  PerspectiveCamera,
  useProgress,
} from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { DragEvent, DragEventHandler, forwardRef, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Scene from 'src/components/sceneViewer/scene/Scene';
import { useAssetUpload } from 'src/features/SceneViewer/hooks/useAssetUpload';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { setActiveTool } from 'src/store/reducers/InstanceReducer';
import { openContextMenu } from 'src/store/reducers/modals';
import store from 'src/store/store';
import { InstanceToolType } from 'src/types';
import { doesClipboardContainImage } from 'src/utils/clipboard';
import { stringToColor } from 'src/utils/colors';
import { createShorcutKeybindings } from 'src/utils/shortcuts';

import { sceneRefInterface } from './context';
import { getSceneObject } from './helpers';
import useSceneMenu from './hooks/useSceneMenu';
import { useSceneViewer } from './hooks/useSceneViewer';
import SceneCanvasProperties from './SceneCanvasProperties';
import WASDcontroles from './WASDcontroles';

const MainPanel = forwardRef<sceneRefInterface, any>((props, ref) => {
  const dispatch = useAppDispatch();
  const containerRef = useRef<any>();
  const orbitControlsRef = useRef<any>();
  const cameraRef = useRef<any>();
  const [tooglePointerControls, setTooglePointerControls] = useState<boolean>(false);
  const cameraConfig = useAppSelector((store) => store.sceneViewer.cameraConfig);
  const { notifyOthersOnCameraChange, onSelectObject, getSelectedObjects } = useSceneViewer();
  const { uploadAssets } = useAssetUpload();
  const { handlePaste } = useSceneMenu();
  const { progress } = useProgress();

  // When clicked on empty space in Canvas
  const onCanvasClicked = (event: any) => {
    if ((event.target as any).id === 'mainPanel') {
      onSelectObject([]);
    }

    // on Right Click
    if (event.button === 2) {
      const sharedMem = store.getState().instance.sharedMemory;

      doesClipboardContainImage().then((isImage) => {
        dispatch(
          openContextMenu({
            items: {
              paste: {
                function: () => handlePaste(),
                args: [],
                disabled: !(isImage || sharedMem.length !== 0),
              },
              addComment: {
                function: () => {
                  dispatch(setActiveTool(InstanceToolType.comment));
                },
              },
            },
            position: {
              x: event.clientX,
              y: event.clientY,
            },
          })
        );
      });
    } else {
      dispatch(
        openContextMenu({
          items: undefined,
        })
      );
    }
  };

  useEffect(() => {
    if (orbitControlsRef.current && cameraRef.current && cameraConfig) {
      orbitControlsRef.current.reset();
      cameraRef.current.position.set(
        cameraConfig.position[0],
        cameraConfig.position[1],
        cameraConfig.position[2]
      );
      cameraRef.current.up.set(cameraConfig.upVec[0], cameraConfig.upVec[1], cameraConfig.upVec[2]);
      orbitControlsRef.current.target.set(
        cameraConfig.target[0],
        cameraConfig.target[1],
        cameraConfig.target[2]
      );
    }
  }, [cameraConfig, orbitControlsRef, cameraRef]);

  const handlePointerLock = () => {
    setTooglePointerControls((prev) => {
      if (prev === true) {
        document.exitPointerLock();
      }

      return !prev;
    });
  };

  useEffect(() => {
    const unsubscribe = createShorcutKeybindings({
      pointerLock: handlePointerLock,
    });

    return unsubscribe;
  }, []);

  if (progress > 0) {
    toast.update(11, {
      render: `Rendering scene ${progress.toFixed(2)}%`,
      progress: progress / 100,
      autoClose: progress === 100 ? 2000 : false,
      onOpen: () => {
        if (progress === 100) {
          toast.dismiss(11);
        }
      },
    });
  } else {
    toast(`Rendering scene ${progress.toFixed(2)}%`, {
      toastId: 11,
      position: 'bottom-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: progress / 100,
    });
  }
  const handleUploadFiles: DragEventHandler = (event: DragEvent) => {
    event.preventDefault();
    const files = event.dataTransfer.files;

    if (files.length) {
      uploadAssets(files, {
        upload: {
          level: 'project',
        },
      });
    }
  };

  return (
    <div
      className="mainPanel"
      ref={containerRef}
      id="mainPanel"
      style={{
        transition: 'border 200ms ease-in',
        borderRadius: props.isFollowing ? '1rem' : 'none',
        border: props.isFollowing
          ? `5px solid ${stringToColor(props.isFollowing)}`
          : '5px solid transparent',
      }}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => handleUploadFiles(e)}
    >
      <Canvas
        className="mainViewer"
        eventSource={containerRef}
        onPointerMissed={(event) => onCanvasClicked(event)}
        style={{ background: '#202020' }}
        gl={{
          antialias: true,
          powerPreference: 'high-performance',
        }}
        onCreated={({ gl }) => {
          gl.setPixelRatio(window.devicePixelRatio);
        }}
        flat
        shadows
      >
        <SceneCanvasProperties cameraViewer={false} id={props.id} />

        {/* <Stage
          shadows
          adjustCamera={false}
          center={{
            disable: true,
          }}
        > */}
        {/* <directionalLight castShadow intensity={2.0} /> */}
        <PerspectiveCamera makeDefault position={[-5.0, 10.0, 5.0]} near={0.025} ref={cameraRef} />
        <Bounds observe margin={1.2}>
          <Scene addCamera={true} ref={ref}>
            {!props.isFollowing && (
              <GizmoHelper renderPriority={3} alignment="bottom-right" margin={[100, 100]}>
                <GizmoViewport labelColor="white" axisHeadScale={1} />
              </GizmoHelper>
            )}
          </Scene>
        </Bounds>

        <WASDcontroles
          ref={cameraRef}
          orbitControlsRef={orbitControlsRef}
          controlsEnabled={tooglePointerControls}
        />

        <OrbitControls
          enabled={!tooglePointerControls}
          ref={orbitControlsRef}
          enableDamping={false}
          makeDefault
          onChange={(e) => {
            const isFollowing = store.getState().collaboration.following;
            if (cameraRef.current && !isFollowing) {
              const camera = cameraRef.current;
              notifyOthersOnCameraChange({
                position: [camera.position.x, camera.position.y, camera.position.z],
                rotation: [
                  camera.rotation.x,
                  camera.rotation.y,
                  camera.rotation.z,
                  camera.rotation.w,
                ],
              });
            }
          }}
        />
        {/* </Stage> */}
      </Canvas>
    </div>
  );
});

export default MainPanel;
