export const generateUrl = (url: string, params: Record<string, string>): string => {
  const urlObject = new URL(url);

  for (const key in params) {
    urlObject.searchParams.append(key, params[key]);
  }

  return urlObject.toString();
};

export const getNonce = () => {
  const nonce = btoa(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(32))));
  return nonce;
};

export const getHashedNonce = async () => {
  const nonce = getNonce();
  const encoder = new TextEncoder();
  const encodedNonce = encoder.encode(nonce);

  const hashBuffer = await crypto.subtle.digest('SHA-256', encodedNonce);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashedNonce = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

  return hashedNonce;
};
