export function stringToColor(uuid: string): string {
  // Hash the UUID to a number
  let hash = 0;
  for (let i = 0; i < uuid.length; i++) {
    hash = uuid.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert the hash to an HSL color
  const hue = hash % 360; // Ensure hue is within [0, 360)
  const saturation = 70; // Saturation (80%) for vibrant colors
  const lightness = 40; // Lightness (50%) for vibrant colors

  // Convert HSL to RGB
  const hslToRgb = (h: number, s: number, l: number): [number, number, number] => {
    s /= 100;
    l /= 100;
    const k = (n: number) => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = (n: number) => l - a * Math.max(Math.min(k(n) - 3, 9 - k(n), 1), -1);
    return [Math.round(f(0) * 255), Math.round(f(8) * 255), Math.round(f(4) * 255)];
  };

  const [r, g, b] = hslToRgb(hue, saturation, lightness);

  // Convert RGB to hex
  const toHex = (color: number): string => {
    const hex = color.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
