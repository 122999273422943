import { useAppSelector } from 'src/store/reducers/hook';
import IntegrationForm from './IntegrationForm';

const IntegrationSettings = ({ service, active, onFinish }: any) => {
  const serviceAuth = useAppSelector((s) => s.integrations.auth[service.name]);
  const integrated = serviceAuth?.name === active;

  return (
    <IntegrationForm
      key={service.name}
      service={service}
      integrated={integrated}
      onFinish={onFinish}
    />
  );
};

export default IntegrationSettings;
