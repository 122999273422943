import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState, useRef, useEffect } from 'react';
import { getMembers, inviteUser } from 'src/apis/users';
import store from 'src/store/store';
import InviteList from './InviteList';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { Enums, Invitee, ProjectMember } from 'src/types';
import RoleMenu from './RoleMenu';
import { useAppDispatch } from 'src/store/reducers/hook';
import { setUsers } from 'src/store/reducers/users';
import { runBatch } from 'src/utils/requests';
import { trackEvent } from 'src/services/analytics';
import { EVENTS, PROPERTIES } from 'src/services/analytics/events';
import { getInvitedUsers } from 'src/apis';

const InviteSchema = z.object({
  email: z.string().email({
    message: 'Must be a valid email',
  }),
  role: z.string().optional(),
});

type InviteSchemaType = z.infer<typeof InviteSchema>;

interface InviteModalProps {
  isOpen: boolean;
  id: string;
  type: Enums<'user_invite_type'>;
  onClose: () => void;
}

const InviteModal: React.FC<InviteModalProps> = ({ isOpen, onClose, id, type }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [memebers, setMembers] = useState<ProjectMember[]>([]);
  const [invitees, setInvitees] = useState<Invitee[]>([]);

  const {
    register,
    handleSubmit,
    setValue,

    formState: { errors },
  } = useForm<InviteSchemaType>({ resolver: zodResolver(InviteSchema) });

  const finalRef = useRef(null);
  const dispatch = useAppDispatch();
  const initialRef = useRef<HTMLInputElement>(null);

  const handleInvite = async ({ email, role = 'edit' }: any) => {
    const projectId = store.getState().app.projectId;
    const workspaceId = store.getState().app.currentUser?.workspace_id;
    const currentUserEmail = store.getState().app.currentUser?.email;

    try {
      setIsLoading(true);

      const inviteIn =
        type === 'project'
          ? { project_id: projectId, workspace_id: workspaceId }
          : { workspace_id: workspaceId };

      await inviteUser({
        ...inviteIn,
        email_id: email,
        role,
      });

      trackEvent(EVENTS.INVITE_USER, {
        [PROPERTIES.INVITEE]: email,
        [PROPERTIES.INVITER]: currentUserEmail,
      });

      setInvitees((prev) => [...prev, { email, username: email, fullname: '', role }]);
      setIsLoading(false);
      setValue('email', '');
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const workspaceId = store.getState().app.currentUser?.workspace_id;
    const projectId = store.getState().app.projectId;

    const id = type === 'project' ? projectId : workspaceId;

    if (!id) return;

    const promises = [
      getMembers(id, type).then(async (res) => {
        if (res.data) {
          setMembers(res.data);
          dispatch(setUsers(res.data));
        }
      }),
    ];

    const params = type === 'project' ? { projectId } : { workspaceId };

    promises.push(
      getInvitedUsers(params).then(async (res: any) => {
        if (!res?.error) setInvitees(res);
      })
    );

    runBatch(promises);
  }, []);

  const title = type === 'project' ? 'Project' : 'Workspace';

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
    >
      <ModalOverlay bg="transparent" />
      <ModalContent
        border="1px solid #333"
        rounded="2xl"
        bg="rgba(18, 17, 23, 0.6) !important"
        backdropFilter="blur(10px)"
      >
        <ModalHeader>Invite Users</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <form onSubmit={handleSubmit(handleInvite)}>
            <FormControl isInvalid={!!errors?.email?.message}>
              <InputGroup size="lg">
                <Input
                  key={isOpen}
                  autoFocus
                  {...register('email')}
                  placeholder="Enter email id"
                  pr="9.5rem"
                />
                <InputRightElement width="9rem">
                  <Flex gap={0} alignItems="center">
                    <RoleMenu
                      h="3rem"
                      pr={1}
                      size="md"
                      onChangeRole={(newRole) => setValue('role', newRole)}
                    />
                    <Button
                      h="3rem"
                      size="md"
                      borderLeftRadius={0}
                      borderRightRadius={5}
                      type="submit"
                      isLoading={isLoading}
                      colorScheme={!isLoading ? 'blue' : ''}
                    >
                      Invite
                    </Button>
                  </Flex>
                </InputRightElement>
              </InputGroup>
              {errors?.email?.message && (
                <FormErrorMessage>{errors.email.message}</FormErrorMessage>
              )}
            </FormControl>
          </form>
          <Stack mt={8} gap={1}>
            <Text fontSize="medium" fontWeight="semibold">
              Members
            </Text>
            <InviteList
              invitees={invitees}
              projectMembers={memebers}
              setProjectMembers={setMembers}
              setInvitees={setInvitees}
            />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InviteModal;
