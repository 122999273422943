import { RepeatWrapping, SRGBColorSpace, TextureLoader } from 'three';

import { getTextureURL } from './aws';

export const loadTextureAsync = (
  key: string,
  tag: string,
  tiling: [number, number],
  offset: [number, number],
  rotation: number
) => {
  const textureURL = getTextureURL(key, tag);

  return new Promise((resolve, reject) => {
    const loader = new TextureLoader();

    loader.setCrossOrigin('anonymous');
    loader.crossOrigin = 'anonymous';
    loader.load(
      textureURL,
      (texture) => {
        texture.flipY = false;
        texture.colorSpace = SRGBColorSpace;
        texture.wrapS = texture.wrapT = RepeatWrapping;
        texture.repeat.set(tiling[0], tiling[1]);
        texture.offset.set(offset[0], offset[1]);
        texture.rotation = rotation;
        resolve(texture);
      },
      undefined,
      reject
    );
  });
};
