import { Center, Flex, Heading, Text, Tooltip } from '@chakra-ui/react';
import type { RoleType } from 'src/types';
import RoleMenu from './RoleMenu';
import { UserAvatar } from '../../../components/Avatar';

type StyleProps = {
  opacity?: number;
};

type UserAccessItemProps = StyleProps & {
  disabled?: boolean;
  id: string;
  name: string;
  tooltip: string;
  itsMe?: boolean;
  avatarPlaceholder?: string;
  role: RoleType;
  handleRoleChange: (role: RoleType) => Promise<void>;
};

const UserAccessItem = ({
  disabled,
  name,
  role = 'edit',
  tooltip,
  itsMe,
  avatarPlaceholder,
  handleRoleChange,
  ...props
}: UserAccessItemProps) => {
  return (
    <Flex gap={4} alignItems="center">
      <Flex flex="1" gap="4" alignItems="center" flexWrap="nowrap" {...props}>
        <UserAvatar id={props.id} color="white" name={avatarPlaceholder ?? name} size="sm" />
        <Tooltip openDelay={500} label={tooltip}>
          <Center>
            <Heading mb={0} size="sm" fontWeight="normal">
              {name}
            </Heading>
            <Text m={0} fontSize="sm" px={2} color="gray.500">
              {itsMe ? '(You)' : ''}
            </Text>
          </Center>
        </Tooltip>
      </Flex>
      {disabled || role === 'admin' ? (
        <Text m={0} fontSize="medium" px={4} fontWeight="semibold" colorScheme="whiteAlpha">
          {role}
        </Text>
      ) : (
        <RoleMenu role={role} onChangeRole={handleRoleChange} />
      )}
    </Flex>
  );
};

export default UserAccessItem;
